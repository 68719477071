import React from "react";
import {PageMenu} from "../../../Common/PageMenu/PageMenu";

import InformationIcon from "../../../../assets/icons/information.svg?react";
import InformationHighIcon from "../../../../assets/icons/information-high.svg?react";
import PinOnMapIcon from "../../../../assets/icons/pin-on-map.svg?react";
import PinOnMapHighIcon from "../../../../assets/icons/pin-on-map-high.svg?react";
import PlugIcon from "../../../../assets/icons/plug.svg?react";
import PlugHighIcon from "../../../../assets/icons/plug-high.svg?react";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import FileTextHighIcon from "../../../../assets/icons/file-text-high.svg?react";
import CameraIcon from "../../../../assets/icons/camera.svg?react";
import CameraHighIcon from "../../../../assets/icons/camera-high.svg?react";
import DoubleBedIcon from "../../../../assets/icons/double-bed.svg?react";
import DoubleBedHighIcon from "../../../../assets/icons/double-bed-high.svg?react";
import BookmarkIcon from "../../../../assets/icons/bookmark.svg?react";
import BookmarkHighIcon from "../../../../assets/icons/bookmark-high.svg?react";
import CoffeeMachineIcon from "../../../../assets/icons/coffee-machine.svg?react";
import CoffeeMachineHighIcon from "../../../../assets/icons/coffee-machine-high.svg?react";
import ContactIcon from "../../../../assets/icons/contacts.svg?react";
import TicketIcon from "../../../../assets/icons/ticket.svg?react";
import TagIcon from "../../../../assets/icons/tag.svg?react";

export const RentalInfosMenu = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: InformationIcon,
          IconActive: InformationHighIcon,
          anchor: "infos-card",
          labelI18n: "Rental.Infos.Infos.title",
        },
        {
          Icon: PinOnMapIcon,
          IconActive: PinOnMapHighIcon,
          anchor: "localisation-card",
          labelI18n: "Rental.Localisation.title",
        },
        {
          Icon: PlugIcon,
          IconActive: PlugHighIcon,
          anchor: "platforms-card",
          labelI18n: "Rental.Infos.Platforms.title",
        },
        {
          Icon: FileTextIcon,
          IconActive: FileTextHighIcon,
          anchor: "description-card",
          labelI18n: "Rental.Infos.Description.title",
        },
        {
          Icon: CameraIcon,
          IconActive: CameraHighIcon,
          anchor: "photos-card",
          labelI18n: "Rental.Infos.Photos.title",
        },
        {
          Icon: DoubleBedIcon,
          IconActive: DoubleBedHighIcon,
          anchor: "bedrooms-card",
          labelI18n: "Rental.Infos.Bedrooms.title",
        },
        {
          Icon: BookmarkIcon,
          IconActive: BookmarkHighIcon,
          anchor: "reservation-rules-card",
          labelI18n: "Rental.Infos.ReservationRules.title",
        },
        {
          Icon: CoffeeMachineIcon,
          IconActive: CoffeeMachineHighIcon,
          anchor: "facilities-card",
          labelI18n: "Rental.Infos.Facilities.title",
        },
        {
          Icon: FileTextIcon,
          IconActive: FileTextHighIcon,
          anchor: "note-card",
          labelI18n: "Rental.Infos.Note.title",
        },
        {
          Icon: TicketIcon,
          IconActive: TicketIcon,
          anchor: "admin",
          labelI18n: "Rentals.admin",
        },
        {
          Icon: ContactIcon,
          IconActive: ContactIcon,
          anchor: "contact",
          labelI18n: "Global.contacts",
        },
        {
          Icon: TagIcon,
          IconActive: TagIcon,
          anchor: "billing-card",
          labelI18n: "Rental.BillingInfos.title",
        },
      ]}
    />
  );
};
