import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PaymentExtraProps } from "../Contact/ContactCard.type";
import { ExtraCardSkeleton } from "./ExtraCardSkeleton";
import { useForm } from "react-hook-form";
import SparklesIcon from "../../../../assets/icons/sparkles.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import { ReviewContentsForm } from "../Instructions/InstructionCard.type";
import {
  useDisableExtras,
  useEnableExtras,
} from "../../../../hooks/api/guestPage";
import { AddExtraModal } from "./AddExtraModal";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import { Badge } from "../../../Common/Badge/Badge";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import DraftIcon from "../../../../assets/icons/draft.svg?react";
import InfoIcon from "../../../../assets/icons/information-fill.svg?react";
import CopyIcon from "../../../../assets/icons/copy.svg?react";
import { DeleteExtaModal } from "./DeleteExtaModal";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const ExtrasCard: React.FC<PaymentExtraProps> = ({
  loading,
  guestPage,
  rentalId,
  rental,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [titleModal, setTitleModal] = useState("");
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [listExtras, setListExtras] = useState(
    guestPage?.extras_available ?? []
  );
  const [displayModalDeleting, setDisplayModalDeleting] = useState(false);
  const disableSwitchExtra = !guestPage?.extras_payment_account_id;

  const [activeExtra, setActiveExtra] = useState<boolean>(
    guestPage?.extras_enabled === 1 ? true : false
  );
  const [selectedExtra, setSelectedExtra] = useState<any>(null);

  const listAvailableUntil = [
    {
      value: "CHECKOUT",
      label: t(
        "Rental.Infos.TravelerPage.ExtraCard.immediatelyAfterReservation"
      ),
    },
    {
      value: "CHECKOUT-1",
      label: t("Rental.Infos.TravelerPage.ExtraCard.immediatelyAfterCheckin"),
    },
    {
      value: "CHECKOUT-2",
      label: t("Rental.Infos.TravelerPage.ExtraCard.onHourLaterCheckin"),
    },
    {
      value: "CHECKOUT-3",
      label: t("Rental.Infos.TravelerPage.ExtraCard.fourHourLaterCheckin"),
    },
    {
      value: "CHECKOUT-4",
      label: t("Rental.Infos.TravelerPage.ExtraCard.onDayAfterCheckin"),
    },
    {
      value: "CHECKOUT-5",
      label: t("Rental.Infos.TravelerPage.ExtraCard.fourHourAfterCheckout"),
    },
    {
      value: "CHECKOUT-6",
      label: t("Rental.Infos.TravelerPage.ExtraCard.untilCheckout"),
    },
  ];

  const getDisponiblity = (value: string) => {
    const item = listAvailableUntil.find((item) => item.value === value);
    return item
      ? `${t("Rental.Infos.TravelerPage.ExtraCard.available")} ${item.label}`
      : "";
  };

  const handleStatusExtas = () => {
    if (disableSwitchExtra) {
      return;
    }
    setActiveExtra(!activeExtra);
    setValidationLoading(true);

    if (activeExtra) {
      useDisableExtras(
        rentalId,
        (response) => {
          setValidationLoading(false);
        },
        (error) => {
          setValidationLoading(false);
        }
      );
    } else {
      useEnableExtras(
        rentalId.toString(),
        (response) => {
          setValidationLoading(false);
        },
        (error) => {
          console.log("error", error);
          setValidationLoading(false);
        }
      );
    }
  };

  const handleAddExtra = (title: string) => {
    setTitleModal(title);
    setEditModalVisible(true);
    setSelectedExtra({
      name: { fr: "", en: "", de: "", es: "" },
      description: { fr: "", en: "", de: "", es: "" },
      unit_price: 0,
      cost_price: 0,
      extra_enabled: 1,
      maximum_quantity: 0,
      available_until: "CHECKOUT",
      confirmation_enabled: 0,
      photo: [],
    });
  };

  const handleDeleteExtra = (value: any, title: string) => {
    setDisplayModalDeleting(true);
    setSelectedExtra(value);
  };

  const handleEditExtra = (value: any, title: string) => {
    setTitleModal(title);
    setSelectedExtra(value);
    setEditModalVisible(true);
  };

  const handleDuplicateExtra = (value: any, title: string) => {
    setTitleModal(title);
    const reformatedExtra = { ...value, id: null };
    setSelectedExtra(reformatedExtra);
    setEditModalVisible(true);
  };

  useEffect(() => {
    if (disableSwitchExtra) {
      setActiveExtra(false);
    }
  }, [disableSwitchExtra]);

  if (loading) return <ExtraCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={SparklesIcon}
      label={t("Rental.Infos.TravelerPage.ExtraCard.title")}
      anchor="extras-card"
      {...(disableSwitchExtra && {
        tooltip: {
          value: t("Rental.Infos.TravelerPage.ExtraCard.switchDisableToolTip"),
        },
      })}
      classNames={{
        iconAndToolTip: disableSwitchExtra ? "flex flex-1 justify-end" : "",
      }}
      switchButton={{
        label: t("Rental.Infos.TravelerPage.ContactCard.switch"),
        value: activeExtra,
        loading: validationLoading,
        onClick: () => {
          handleStatusExtas();
        },
        disabled:
          disableSwitchExtra ||
          !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
      }}
      customButton={{
        label: t("Rental.Infos.TravelerPage.ExtraCard.add"),
        rightIcon: PlusIcon,
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: () => {
          console.log("add extra");
          handleAddExtra(t("Rental.Infos.TravelerPage.ExtraCard.add"));
        },
      }}
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        <p className="text-low-contrast">
          {t("Rental.Infos.TravelerPage.ExtraCard.descriptionExtra")}
        </p>
        {listExtras.map((extra: any, index: number) => (
          <div className="flex flex-col gap-4" key={index}>
            <SettingElement
              Icon={InformationIcon}
              title={extra?.name?.fr}
              description={extra?.description?.fr}
              src={
                extra?.photo?.length > 0 ? extra.photo[0].original_url : null
              }
              type="file"
              subtile={getDisponiblity(extra?.available_until)}
            >
              <div className="flex items-center space-x-10">
                <div className="flex space-x-4">
                  <div>
                    <Tooltip
                      Icon={
                        <Badge
                          label={
                            extra?.extra_enabled
                              ? t("Rental.Infos.TravelerPage.ExtraCard.actif")
                              : t("Rental.Infos.TravelerPage.ExtraCard.inactif")
                          }
                          status={extra?.extra_enabled ? "success" : "fail"}
                          size="large"
                        />
                      }
                      value={
                        extra?.extra_enabled
                          ? t("Rental.Infos.TravelerPage.ExtraCard.actif")
                          : t("Rental.Infos.TravelerPage.ExtraCard.inactif")
                      }
                      classNames={{
                        container: "top-full mt-1 right-0",
                      }}
                    />
                  </div>
                  <p className="text-active font-bold mt-2">
                    {extra?.unit_price} {extra?.currency?.symbol}
                  </p>
                  <div
                    className="font-bold mt-2"
                    onClick={() =>
                      handleDuplicateExtra(
                        extra,
                        t(`Rental.Infos.TravelerPage.ExtraCard.duplicate`)
                      )
                    }
                  >
                    <CopyIcon />
                  </div>
                  <div
                    className="font-bold mt-2"
                    onClick={() =>
                      handleEditExtra(
                        extra,
                        t("Rental.Infos.TravelerPage.ExtraCard.edit")
                      )
                    }
                  >
                    <EditIcon />
                  </div>
                  <div
                    className="font-bold mt-2"
                    onClick={() =>
                      handleDeleteExtra(extra, t(`Rental.Infos.TravelerPage`))
                    }
                  >
                    <DraftIcon />
                  </div>
                </div>
              </div>
            </SettingElement>
          </div>
        ))}

        {listExtras?.length === 0 && (
          <div className="flex flex-col justify-between items-center">
            <p className="text-low-contrast text-md">
              {t("Rental.Infos.TravelerPage.ExtraCard.emptyExtra")}
            </p>
          </div>
        )}

        <AddExtraModal
          isVisible={isEditModalVisible}
          onClose={() => setEditModalVisible(false)}
          titleModal={titleModal}
          rentalId={rentalId}
          setIsEditModalVisible={setEditModalVisible}
          setListExtras={setListExtras}
          selectedExtra={selectedExtra}
          rental={rental}
        />
        <DeleteExtaModal
          exta_selected={selectedExtra}
          displayModalDeleting={displayModalDeleting}
          setDisplayModalDeleting={setDisplayModalDeleting}
          rentalId={rentalId}
          setListExtras={setListExtras}
        />
      </div>
    </Card>
  );
};
