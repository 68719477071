import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CheckGreenIcon from "../../assets/icons/check-green.svg?react";
import BriefCaseIcon from "../../assets/icons/briefcase.svg?react";
import MoneyIcon from "../../assets/icons/dollar.svg?react";
import ShoppingCartIcon from "../../assets/icons/shopping-cart.svg?react";
import FileErrorIcon from "../../assets/icons/file-error.svg?react";
import DashboardStatsItem, {
  DashboardStatsItemProps,
  DashboardStatsType,
} from "./DashboardStatsItem";
import DashboardStatsItemSkeleton from "./DashboardStatsItemSkeleton";
import {
  DashboardData,
  StatsDashboard,
  UserResponse,
} from "../../types/GETTypes";
import {capitalizeFirstLetter} from "../../helpers/stringHelper";
import paths from "../../constants/paths";
import {hasPermissionWithUser} from "../../helpers/workspaceHelper";

type DashboardStatsProps = {
  dashboardData: DashboardData | undefined;
  isLoading: boolean;
  isOwner: boolean;
  user: UserResponse | undefined;
};

const DashboardStats: React.FC<DashboardStatsProps> = ({
  dashboardData,
  isLoading,
  isOwner = true,
  user,
}) => {
  const {t} = useTranslation();

  const data = isOwner
    ? dashboardData?.reservations?.stats
    : dashboardData?.operator_tasks?.stats;

  const statsInitial: DashboardStatsItemProps[] = [
    {
      header: "",
      title: "",
      description: "",
      url: paths.ANALYTICS,
      linkLabel: t("Dashboard.viewStats"),
      type: "stats",
      hide: false,
    },
    {
      header: "",
      title: "",
      description: "",
      url: paths.PAYMENTS_RESERVATIONS,
      linkLabel: t("Dashboard.viewPayments"),
      type: "payments",
      hide: false,
    },
    {
      header: "",
      title: "",
      description: "",
      url: paths.PAYMENTS_DEPOSITS,
      linkLabel: t("Dashboard.viewDeposits"),
      type: "cautions",
      hide: false,
    },
    {
      header: "",
      title: "",
      description: "",
      url: paths.MANAGEMENT_TASKS,
      linkLabel: t("Dashboard.viewTasks"),
      type: "tasks",
      hide: false,
    },
    {
      header: "",
      title: "",
      description: "",
      url: paths.PAYMENTS_EXTRAS,
      linkLabel: t("Dashboard.viewExtras"),
      type: "extras",
      hide: false,
    },
  ];

  const [stats, setStats] = useState<DashboardStatsItemProps[]>(statsInitial);

  useEffect(() => {
    replaceDataByType(data, "stats");
    replaceDataByType(data, "payments");
    replaceDataByType(data, "cautions");
    replaceDataByType(data, "tasks");
    replaceDataByType(data, "extras");
  }, [data, user]);

  const replaceDataByType = (
    data: StatsDashboard | undefined,
    type: DashboardStatsType
  ) => {
    setStats((oldStats) =>
      oldStats.map((stat) => {
        if (stat.type === type) {
          switch (type) {
            case "stats":
              return {
                ...stat,
                header:
                  data?.occupancy_rate?.value &&
                  Number(data.occupancy_rate.value) >= 1
                    ? `${Number(data.occupancy_rate.value)?.toLocaleString(
                        "fr-FR"
                      )}%`
                    : "0%",
                title: `${t("Dashboard.occupancyRate")} ${capitalizeFirstLetter(
                  data?.occupancy_rate?.month
                )} ${data?.occupancy_rate?.year}`,
                hide: !hasPermissionWithUser(user, "analytics"),
              };
            case "payments":
              return {
                ...stat,
                header:
                  data?.reservations_not_paid?.count &&
                  data.reservations_not_paid.count >= 1 ? (
                    data.reservations_not_paid.count?.toLocaleString("fr-FR")
                  ) : data?.reservations_not_paid?.count === 0 ? (
                    <CheckGreenIcon width={30} height={30} />
                  ) : (
                    <BriefCaseIcon width={30} height={30} />
                  ),
                title:
                  data?.reservations_not_paid?.count &&
                  data.reservations_not_paid.count >= 1
                    ? t("Dashboard.bookings")
                    : data?.reservations_not_paid?.count === 0
                    ? ""
                    : "",
                description:
                  data?.reservations_not_paid?.count &&
                  data.reservations_not_paid.count >= 1
                    ? t("Dashboard.notPaid")
                    : data?.reservations_not_paid?.count === 0
                    ? t("Dashboard.allPaidBookings")
                    : t("Dashboard.noneBookings"),
                hide: !hasPermissionWithUser(user, "payment_reservations"),
              };
            case "cautions":
              return {
                ...stat,
                header:
                  data?.deposit_to_collect?.count &&
                  data.deposit_to_collect.count >= 1 ? (
                    <CheckGreenIcon width={30} height={30} />
                  ) : (
                    <MoneyIcon width={30} height={30} />
                  ),
                title: "",
                description:
                  data?.deposit_to_collect?.count &&
                  data.deposit_to_collect.count >= 1
                    ? t("Dashboard.paidAllDeposits")
                    : t("Dashboard.noneDeposits"),
                hide: !hasPermissionWithUser(user, "payment_cautions"),
              };
            case "tasks":
              return {
                ...stat,
                header:
                  data?.tasks_not_assigned?.count &&
                  data.tasks_not_assigned.count >= 1 ? (
                    data.tasks_not_assigned.count?.toLocaleString("fr-FR")
                  ) : (
                    <FileErrorIcon width={30} height={30} />
                  ),
                title:
                  data?.tasks_not_assigned?.count &&
                  data.tasks_not_assigned.count >= 1
                    ? t("Dashboard.tasks")
                    : "",
                description:
                  data?.tasks_not_assigned?.count &&
                  data.tasks_not_assigned.count >= 1
                    ? t("Dashboard.toAssign")
                    : t("Dashboard.noneTasks"),
                hide: !hasPermissionWithUser(
                  user,
                  "operational_management_tasks"
                ),
              };
            case "extras":
              return {
                ...stat,
                header:
                  data?.extras_orders?.count &&
                  data.extras_orders.count >= 1 ? (
                    data.extras_orders.count?.toLocaleString("fr-FR")
                  ) : (
                    <ShoppingCartIcon width={30} height={30} />
                  ),
                title:
                  data?.extras_orders?.count && data.extras_orders.count >= 1
                    ? t("Dashboard.extrasOrders")
                    : "",
                description:
                  data?.extras_orders?.count && data.extras_orders.count >= 1
                    ? t("Dashboard.toValidate")
                    : t("Dashboard.noneExtrasOrders"),
                hide: !hasPermissionWithUser(user, "payment_extras"),
              };
            default:
              return stat;
          }
        }
        return stat;
      })
    );
  };

  return (
    <div>
      <div className="w-full h-[200px]  relative">
        <div className="left-0 top-0 w-full h-full relative">
          {isLoading
            ? Array.from({length: 5}).map((nb, index) => (
                <div className="absolute" style={{left: index * 230}}>
                  <DashboardStatsItemSkeleton />
                </div>
              ))
            : stats
                .filter((stat) => !stat.hide)
                ?.map((stat, index) => (
                  <div className="absolute" style={{left: index * 230}}>
                    <DashboardStatsItem {...stat} />
                  </div>
                ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardStats;
