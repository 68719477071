import React, {ReactNode} from "react";

import AirBnBWhiteIcon from "../assets/icons/airbnb-white.svg?react";
import BookingIcon from "../assets/icons/booking.svg?react";
import SuperHoteIcon from "../assets/icons/superhote-circle.svg?react";
import PricelabsIcon from "../assets/icons/pricelabs.svg?react";
import OtherPlatformIcon from "../assets/icons/globe.svg?react";
import {PlatformEnum, PlatformNameEnum} from "../enums/GETenums";
import {PLATFORM_RESERVATION} from "../pages/Booking/BookingPage.type";
import {DistributionKeyEnum, PlatformResponse} from "../types/GETTypes";
import {capitalizeFirstLetter} from "./stringHelper";
import ExternalIcon from "../assets/icons/web.svg?react";


export const getEnumKeyByValue = (
  enumObj: any,
  value: number | string,
): string | undefined => {
  return Object.keys(enumObj).find((key) => enumObj[key] === value);
};

export const getPlatformIcon = (
  platform: PlatformResponse | null | PlatformNameEnum,
  size: "small" | "normal" | "large" = "normal",
  index?: number,
) => {
  const name =
    typeof platform === "object" && platform !== null
      ? "name" in platform
        ? platform.name
        : platform
      : platform;
  const getSize = () => {
    if (size === "small") {
      return "w-4 h-4";
    } else if (size === "normal") {
      return "w-6 h-6";
    } else if (size === "large") {
      return "w-8 h-8";
    }
  };

  if (name?.toUpperCase() === "AIRBNB.COM")
    return <AirBnBWhiteIcon className={`${getSize()}`} />;
  else if (name?.toUpperCase() === "BOOKING.COM")
    return <BookingIcon className={`${getSize()}`} />;
  else if (name?.toUpperCase() === "DIRECT")
    return <SuperHoteIcon className={`${getSize()}`} />;
  else if (name?.toUpperCase() === "PRICELABS")
    return <PricelabsIcon className={`${getSize()}`} />;
  else if (name?.toUpperCase() === "ICAL")
    return <ExternalIcon className={`${getSize()}`} />;
  else
    return (
      <div
        className={`${getSize()} rounded-full bg-element-background border-1 border-element-border flex items-center justify-center font-semibold text-[12px] text-low-contrast`}
      >
        {name?.toUpperCase().substring(0, 1)}
      </div>
    );
};

export const getPlatformIconByEnum = (
  platform: PlatformEnum | DistributionKeyEnum,
  size: "small" | "normal" | "large" = "normal"
) => {
  // Define a mapping for platform icons
  const platformIconMap: Record<
    PlatformEnum | DistributionKeyEnum,
    React.ElementType | null
  > = {
    [PlatformEnum.AIRBNB]: AirBnBWhiteIcon,
    [PlatformEnum.BOOKING]: BookingIcon,
    [PlatformEnum.DIRECT]: SuperHoteIcon,
    [PlatformEnum.PRICELABS]: PricelabsIcon,
    [PlatformEnum.ABRITEL]: null,
    [PlatformEnum.ICALENDAR]: null,
    [DistributionKeyEnum.AIRBNB]: AirBnBWhiteIcon,
    [DistributionKeyEnum.BOOKING]: BookingIcon,
    [DistributionKeyEnum.DIRECT]: SuperHoteIcon,
    [DistributionKeyEnum.OTHERS]: OtherPlatformIcon,
  };

  const sizeClassMap: Record<"small" | "normal" | "large", string> = {
    small: "w-4 h-4",
    normal: "w-6 h-6",
    large: "w-8 h-8",
  };

  const IconComponent = platformIconMap[platform];
  const sizeClass = sizeClassMap[size];

  return IconComponent ? <IconComponent className={sizeClass} /> : null;
};

export const getPlatformNode = (platform: PlatformResponse): ReactNode => {
  return (
    <>
      <div className="flex items-center px-2 space-x-2">
        {getPlatformIcon(platform, "large")}
        <p className="text-base">{capitalizeFirstLetter(platform.name)}</p>
      </div>
    </>
  );
};

export const getPlatformByName = (name?: string) => {
  if (name?.toUpperCase() === "AIRBNB.COM") {
    return PLATFORM_RESERVATION.AIRBNB;
  } else if (name?.toUpperCase() === "BOOKING.COM") {
    return PLATFORM_RESERVATION.BOOKING;
  } else if (name?.toUpperCase() === "DIRECT") {
    return PLATFORM_RESERVATION.DIRECT;
  } else if (name?.toUpperCase() === "EXTERNAL") {
    return PLATFORM_RESERVATION.EXTERNAL;
  } else return "";
};

export const getPlatformNameByEnum = (
  platform: PlatformEnum | DistributionKeyEnum
): string | null => {
  const platformNameMap: Record<PlatformEnum | DistributionKeyEnum, string> = {
    [PlatformEnum.AIRBNB]: "Airbnb",
    [PlatformEnum.BOOKING]: "Booking",
    [PlatformEnum.DIRECT]: "Superhote",
    [PlatformEnum.PRICELABS]: "Pricelabs",
    [PlatformEnum.ABRITEL]: "Abritel",
    [PlatformEnum.ICALENDAR]: "ICal",
    [DistributionKeyEnum.AIRBNB]: "Airbnb",
    [DistributionKeyEnum.BOOKING]: "Booking",
    [DistributionKeyEnum.OTHERS]: "Others",
    [DistributionKeyEnum.DIRECT]: "Superhote",
  };

  // Return the name if it exists in the map, otherwise return null
  return platformNameMap[platform] || null;
};

export const getNameByPlatform = (name?: DistributionKeyEnum) => {
  if (name === DistributionKeyEnum.AIRBNB) {
    return "Airbnb.com";
  } else if (name === DistributionKeyEnum.BOOKING) {
    return "Booking.com";
  } else if (name === DistributionKeyEnum.DIRECT) {
    return "Direct";
  } else return "";
};
