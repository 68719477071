import React from "react";


export const AutoMessageSuggestionModalSkeleton: React.FC<{
  isVisible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ }) => {
  return (
                <div>
                  <p className="text-sm font-light text-low-contrast mb-4  bg-slate-300">
                  </p>
                  <p className="text-sm font-light text-red-500 mb-4  bg-slate-300">
                  </p>

                  <div className="flex flex-col border-2 border-gray-200 rounded-lg mb-4 bg-[#F8F8F8] gap-1">
                    <div className="flex flex-row p-2 gap-2 overflow-x-auto w-full">
                      {[1,2,3,4,5]?.map((item: any, index: number) => (
                          <div className="flex flex-row gap-3 cursor-pointer bg-slate-200 h-[100px]">
                            <div
                                className={`flex flex-col justify-center items-center bg-gray-100 rounded-lg gap-2 p-2 min-w-[200px] max-w-[200px]`}
                            >
                              <p
                                  className={`text-sm text-low-contrast font-bold text-center min-w-[130px] max-w-[130px] bg-slate-300`}
                              >
                              </p>
                              <p className={`text-sm font-light text-low-contrast text-center bg-gray-300`}>
                              </p>
                            </div>
                            {index < [1,2,3,4,5].length - 1 && (
                                <div className="flex justify-center items-center bg-gray-100">
                                </div>
                            )}
                          </div>
                      ))}
                    </div>

                    <div className="flex flex-col m-2">
                      <div className="flex flex-row justify-between">
                        <p className="font-bold m-2 text-sm  bg-slate-300"></p>
                        <p className="text-sm font-light text-low-contrast m-2 cursor-pointer  bg-slate-300">
                        </p>
                      </div>
                      <div className="flex flex-col gap-2   bg-slate-200 h-[200px]">
                      </div>



                      <div className="mt-2">
                        <div className="flex flex-row justify-between">
                          <div className={`flex flex-row gap-2 bg-slate-200`}>
                            <p className="text-sm text-red-500 mt-1  bg-slate-300"></p>
                            <p className="text-sm text-red-500 mt-1  bg-slate-300"></p>

                          </div>
                          <div className="flex flex-row gap-2">
                          <p className="text-sm text-red-500 mt-1  bg-slate-300"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 mb-4">
                    <p className="text-sm font-light text-low-contrast mb-2 bg-slate-300 ">
                    </p>
                    <div className="flex gap-2 items-center bg-slate-200">
                      <p className="text-high-contrast font-semibold text-sm bg-slate-300">
                      </p>
                      <p className="text-low-contrast text-sm bg-slate-300">
                      </p>
                      <p className="text-high-contrast text-sm bg-slate-300">
                      </p>
                    </div>
                    <div className="flex gap-2 items-center bg-slate-300">
                      <p className="text-high-contrast font-semibold text-sm bg-slate-300">
                      </p>
                      <p className="text-low-contrast text-sm bg-slate-300">
                      </p>
                      <p className="text-high-contrast text-sm bg-slate-300">
                      </p>
                    </div>
                    <div className="flex gap-2 items-center bg-slate-200">
                      <p className="text-high-contrast font-semibold text-sm bg-slate-300">
                      </p>
                      <p className="text-low-contrast text-sm bg-slate-300">
                      </p>
                      <p className="text-high-contrast text-sm bg-slate-300">
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-3 w-full">

                    <div className="flex flex-row ml-auto  gap-1">
                      <p className="h-2 mb-3 rounded-lg w-96 bg-slate-200"></p>
                      <p className="h-2 rounded-lg w-96 bg-slate-200"></p>
                    </div>

                  </div>
                </div>
  );
};
