import React from "react";
import {useTranslation} from "react-i18next";
import {cn} from "../../helpers/classHelper";
import {Button} from "../Common/Button/Button";
import LogOutIcon from "../../assets/icons/log-out.svg?react";
import FileIcon from "../../assets/icons/file-text.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";
import {updateAssignationType} from "../../pages/Dashboard/DashboardPage";
import moment from "moment-timezone";
import {getRelativeDay} from "../../helpers/dateHelper";
import {TaskItemResponse} from "../../types/GETTypes";

export const DashboardServicesTasksItem: React.FC<{
  task: any;
  onAcceptTask: (task: TaskItemResponse | undefined) => void;
  onDeclineTask: (task: TaskItemResponse | undefined) => void;
}> = ({task, onAcceptTask, onDeclineTask}) => {
  const {t} = useTranslation();
  const isAssigned =
    task.status === "COMPLETED" && task.status !== "NOT_ASSIGNED";
  const guest = isAssigned
    ? task?.assigned_operators
      ? task.assigned_operators[0]
      : ""
    : "";
  const next_reservation = task?.started_task_data?.next_reservation;
  const last_reservation = task?.started_task_data?.last_reservation;
  const isDeclinedByMe = task?.assigned_or_not_task_data?.is_declined_by_me;

  return (
    <div
      className={cn(
        `flex items-center p-4 border-t-0 border-b-1 border-x-1 border-element-border hover:cursor-pointer hover:bg-element-background/30`
      )}
    >
      <div className="flex flex-1 items-center justify-between space-x-4">
        <div className="flex w-1/3 items-center space-x-2">
          {/* Rental */}
          <img
            src={task?.rental?.cover!}
            className="rounded-4px w-10 h-10 bg-slate-200 border-1 border-element-border-light"
          />

          <div className="flex flex-col space-y-4">
            <p className="text-sm text-high-contrast">{task?.rental?.name}</p>
            <p className="text-sm text-low-contrast">
              {task?.rental?.address} {task?.rental?.postal_code}{" "}
              {task?.rental?.city}
            </p>
          </div>
        </div>

        <p className="w-1/5 text-sm font-bold text-high-contrast text-center">
          {task.name}
        </p>

        <p className="w-1/5 text-sm font-bold text-high-contrast text-center">
          {t("Dashboard.startingTime")}{" "}
          {moment(task.scheduled_time, "HH:mm").format("HH[h]mm")}
        </p>

        {/* Guest */}
        {guest ? (
          <div className="flex w-1/5 items-center space-x-2">
            <img
              src={guest?.photo!}
              className="w-12 h-12 rounded-full  border-1 border-element-light bg-slate-200"
            />

            <div className="flex space-x-1">
              <p className="text-sm font-bold text-low-contrast">
                {guest?.first_name}
              </p>
              <p className="text-sm font-bold text-low-contrast">
                {guest?.last_name}
              </p>
            </div>
          </div>
        ) : (
          <div className="w-1/5 flex flex-col space-y-2 items-center">
            <span className="text-active text-xs font-bold">
              {t("Dashboard.noneAssignedSingular")}
            </span>
            <div className="flex space-x-2">
              {!isDeclinedByMe && (
                <Button type="alert" onClick={() => onDeclineTask(task)}>
                  {t("Dashboard.refuse")}
                </Button>
              )}
              <Button onClick={() => onAcceptTask(task)}>
                {t("Dashboard.accept")}
              </Button>
            </div>
          </div>
        )}
        {/* Reservation */}
        <div className={cn(`flex items-center space-x-8`)}>
          {last_reservation && (
            <div className="flex flex-col space-y-2">
              <div className="flex items-center gap-1">
                <LogOutIcon className="w-4 h-4 font-light  text-low-contrast" />
                <p className="font-bold text-md text-low-contrast">
                  {t("Dashboard.lastReservation")}
                </p>
              </div>

              <div className="flex flex-col px-2 py-1 min-w-[200px] rounded-4px h-[115px] space-y-2 border-1 border-element-border hover:cursor-pointer bg-element-background/30">
                <div className="flex justify-between">
                  <p className="text-sm text-high-contrast">
                    {last_reservation?.guest_first_name}{" "}
                    {last_reservation?.guest_last_name}
                  </p>
                  {/* <FileIcon className="w-5 h-5" /> */}
                </div>
                <p className="text-sm text-low-contrast">
                  {last_reservation?.guest_phone}
                </p>
                <p className="text-sm text-high-contrast font-bold">
                  {getRelativeDay(last_reservation?.checkin_date)}
                  {last_reservation?.checkin_time
                    ? ` ${t("Dashboard.at")} ` +
                      moment(last_reservation?.checkin_time, "HH:mm").format(
                        "HH [h]"
                      )
                    : ""}
                </p>

                <div className="flex space-x-2 justify-between">
                  <div className="flex space-x-1 items-center w-full">
                    <MoonIcon className="w-5 h-5" />
                    <p className="text-xs text-low-contrast">
                      {t("Global.night", {
                        count: last_reservation?.nights_count ?? 0,
                      })}
                    </p>
                  </div>

                  <div className="flex space-x-1 items-center w-full">
                    <UsersIcon className="w-5 h-5" />
                    <p className="text-xs text-low-contrast">
                      {t("Global.adults", {
                        count: last_reservation?.guests_count ?? 0,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {next_reservation && (
            <div className="flex flex-col space-y-2">
              <div className="flex items-center gap-1">
                <LogOutIcon className="w-4 h-4 font-light  text-low-contrast" />
                <p className="font-bold text-md text-low-contrast">
                  {t("Dashboard.nextReservation")}
                </p>
              </div>

              <div className="flex flex-col px-2 py-1 min-w-[200px] h-[115px] space-y-2 border-1 border-element-border hover:cursor-pointer bg-element-background/30">
                <div className="flex justify-between">
                  <p className="text-sm text-high-contrast">
                    {next_reservation?.guest_first_name}{" "}
                    {next_reservation?.guest_last_name}
                  </p>
                  <FileIcon className="w-5 h-5" />
                </div>
                <p className="text-sm text-low-contrast">
                  {next_reservation?.guest_phone}
                </p>
                <p className="text-sm text-high-contrast font-bold">
                  {getRelativeDay(next_reservation?.checkin_date)}
                  {next_reservation?.checkin_time
                    ? ` ${t("Dashboard.at")} ` +
                      moment(next_reservation?.checkin_time, "HH:mm").format(
                        "HH [h]"
                      )
                    : ""}
                </p>

                <div className="flex space-x-2 justify-between">
                  <div className="flex space-x-1 items-center w-full">
                    <MoonIcon className="w-5 h-5" />
                    <p className="text-xs text-low-contrast">
                      {t("Global.night", {
                        count: next_reservation?.nights_count ?? 0,
                      })}
                    </p>
                  </div>

                  <div className="flex space-x-1 items-center w-full">
                    <UsersIcon className="w-5 h-5" />
                    <p className="text-xs text-low-contrast">
                      {t("Global.adults", {
                        count: next_reservation?.guests_count ?? 0,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
