import {PaymentPolicyDepositPaymentOptionEnum} from "../components/Payment/Payment.type";
import {RentalPricelabsResponse} from "../components/Rental/Services/Pricelabs/Pricelabs.type";
import {SmartLocksEnum} from "../constants/locks";
import {
  AirbnbLoginStatusEnum,
  ExtraAvailableUntil,
  PaymentAccountTypeEnum,
  PaymentReservationDepositAccountOptionEnum,
  PaymentReservationDepositStatusEnum,
  PaymentReservationDepositValueTypeEnum,
  PaymentReservationStatusEnum,
  PlatformEnum,
  ProgressionStepEnum,
  ProgressionStepStatusEnum,
  ProgressionStepSynchronizePhaseEnum,
  ReservationExtraOrderStatusEnum,
  ReservationStatusEnum,
} from "../enums/GETenums";
import {
  Rental,
  RentalDetails,
  RentalInformations,
} from "../pages/RentalPage/RentalPage.type";
import {ValueType} from "./commonTypes";

export type APIResponse = {
  success: boolean;
  message: null | string;
  result: any;
};

export type PlatformResponse = {
  id: number;
  name: keyof typeof PlatformEnum;
  logo: string;
  reservation_reference?: string | null;
  reservation_link?: string | null;
};

export type PhotoListItemResponse = {
  id: number;
  name: string;
  mime_type: string;
  original_url: string;
};

export type PhotoListResponse = {
  cover: string;
  photos: PhotoListItemResponse[];
};

export type RentalPlatformBookingResponse = {
  enabled: number;
  last_connexion_date: string | null;
  hotel_id: string | null;
  room_id: string | null;
  room_name: string | null;
  rate_id: string | null;
  rate_name: string | null;
  price_multiplier_value: number;
  listing_link: string | null;
};

export type RentalPlatformAirbnbListingResponse = {
  listing_id: string | null;
  name: string | null;
  email: string | null;
  airbnb_link: string | null;
};

export type RentalPlatformAirbnbResponse = {
  enabled: number;
  last_connexion_date: string | null;
  airbnb_account_id: string;
  listing_id: string;
  price_multiplier_value: number;
  name?: string | null;
  email?: string | null;
};

export type RentalPlatformIcalendarResponse = {
  id: number;
  enabled: number;
  icalendar_link: string;
  connexion_name: string;
  last_connexion_date: string;
  last_synchronization_date: string;
};

export type CountryResponse = {
  id: number;
  name: string;
};

export type CurrencyResponse = {
  id: number;
  name: string;
  symbol: string;
  code: string;
};

export type I18nLabelResponse = {
  de: string;
  en: string;
  es: string;
  fr: string;
};

export type LanguageResponse = {
  id: number;
  country_ids: string;
  code: string;
  name: string;
};

// * -- REGISTRATION --
// URL Sample : /auth/register?email=test1@test.com
export type RegistrationResponse = {
  current_step: string;
  personnal: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    avatar: string;
    locale: string;
  };
};

export type OperationalType = "owner" | "member";

// URL Sample : /user
export type UserResponse = {
  id: ValueType;
  address: string;
  avatar: string;
  city: string;
  postal_code: string;
  first_name: string;
  last_name: string;
  email: string;
  locale: string;
  progression: number;
  is_synchronizing: boolean;
  photo: string;
  phone: string;
  is_synchro_with_booking: boolean;
  is_synchro_with_airbnb: boolean;
  profile_administrative: UserProfileAdministrativeResponse;
  lead: LeadResponse;
  guest_page_link: string;
  all_postes: {
    id: ValueType;
    name: string;
    created_by: ValueType;
    members: ValueType[];
    workspace_owner: ValueType;
    permissions_selected: {
      id: ValueType;
      name: OperationalManagementRolePermissionNameResponse;
    }[];
    all_rights: ("hide_price" | "hide_sensitive" | "no_edit")[];
    type: string[];
  }[];
  type: OperationalType;
  workspaces: WorkspaceResponse[];
  current_workspaces_id: ValueType[];
};

export type UserProfileAdministrativeResponse = {
  id: number;
  host_type: RentalBillingInfosHostType;
  host_name: string | null;
  tax_identity_number: string | null;
  company_name: string | null;
  siren: string | null;
};

export type LeadResponse = {
  id: ValueType;
  first_name: string;
  last_name: string;
  email: string;
  locale: string;
  phone: string;
  registration_step: number;
  objectives: string;
  other_objective_value: string;
  types: string;
  platforms: string;
};

export type WorkspaceResponse = {
  id: ValueType;
  name: string;
  created_by: string;
};

// * -- PROGRESSION STEPS --
// URL Sample : /user/progression/get-all-steps
export type ProgressionAllStepsResponse = {
  progression_steps: string;
};

export type ProgressionStep =
  (typeof ProgressionStepEnum)[keyof typeof ProgressionStepEnum];

export type ProgressionStepStatus =
  (typeof ProgressionStepStatusEnum)[keyof typeof ProgressionStepStatusEnum];

export type ProgressionStepSynchronize =
  (typeof ProgressionStepSynchronizePhaseEnum)[keyof typeof ProgressionStepSynchronizePhaseEnum];

// URL Sample : /user/progression/get-current-step
export type ProgressionCurrentStepResponse = {
  progression_step: ProgressionStep;
  progression_step_status: ProgressionStepStatus;
  progression_step_pending_progress_percents: number;
  progression_step_pending_total_jobs: number;
  progression_step_pending_processed_jobs: number;
  progression_step_pending_progress_text: ProgressionStepSynchronize;
  progression_step_booking_already_associated: boolean;
};

// * -- GUESTS --
// URL Sample : /reservation-guests
export type GuestListItemResponse = {
  id: ValueType;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  postal_code: string;
  city: string;
  country: string;
  photo: string;
  locale: string | null;
  languages: any | null; // TODO : A vérifier
  notes: string;
  rate: number | null;
};

export type GuestsListResponse = {
  guests: GuestListItemResponse[];
};

// URL Sample : /reservation-guests/1
export type GuestResponse = {
  id: ValueType;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  postal_code: string;
  city: string;
  country?: CountryResponse;
  currency?: CurrencyResponse;
  photo: string;
  locale: string | null;
  languages: any | null;
  notes: string;
  average_rating: number | null;
  total_spend: number;
  total_stay_days: number;
  business_name: string | null;
  invoice_address: string | null;
  siret: string | null;
  guest_type: string;
  note: string | null;
  timezone: string | null;
  currency_id: number;
  country_id: number;
  created_at?: string | null;
  reservations: ReservationResponse[];
};

// * -- RESERVATION --
// URL Sample : /reservations
export type ReservationListItemResponse = {
  id: number;
  rental: RentalResponse;
  platform: PlatformResponse;
  guest: GuestResponse; // ! Peut-être que ce type devra changer quand on bossera les Guests
  status_reservation: number;
  status_payment: number;
  status_deposit: number;
  status_contract: number;
  reservation_date: string;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  adults_count: number;
  children_count: number;
  total_night_price: number;
  total_guest_price: number;
  cleaning_fee: number;
  city_tax: number;
  private_notes: string;
  platform_notes: string;
  background_color: string;
  text_color: string;
  messages_read_count: number;
  messages_unread_count: number;
};

export type ReservationListResponse = {
  reservations: ReservationListItemResponse[];
  total: number;
  total_filtered: number;
  total_request: number;
  total_confirmed_finished: number;
  total_confirmed_progress: number;
  total_confirmed_coming: number;
  total_canceled: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

// URL Sample : /reservations/5
export type ReservationResponse = {
  id: number;
  rental: RentalResponse;
  platform: PlatformResponse;
  guest: GuestResponse;
  status_reservation: ReservationStatusEnum;
  status_payment: PaymentReservationStatusEnum;
  status_deposit: PaymentReservationDepositStatusEnum;
  status_contract: number;
  reservation_date: string;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  adults_count: number;
  children_count: number;
  nights_count: number;
  total_night_price: number;
  total_guest_price: number;
  cleaning_fee: number;
  city_tax: number;
  private_notes: string;
  platform_notes: string;
  background_color: string;
  text_color: string;
  messages_read_count: number;
  messages_unread_count: number;
  payment_details: ReservationPaymentDetails[];
  payment_details_host: ReservationPaymentDetailsHost;
  payment_details_guest: ReservationPaymentDetailsGuest;
  payment_schedule: PaymentReservationScheduleResponse;
  guest_page_link: string;
  guest_page_beds_count: number;
  extra_orders: GuestPageExtraOrder[];
  workspace_owner: number;
  locks: ReservationLockItemResponse[];
  tasks: any;
};

export type ReservationPaymentDetails = {
  id: number;
  reservation_id: number;
  label: string;
  type: string;
  amount: number;
};

export type ReservationPaymentDetailsHost = {
  night: {
    label: string;
    amount: number;
  };
  cleaning_fee: {
    label: string;
    amount: number;
  };
  host_platform_total: {
    label: string;
    amount: number;
  };
  host_platform_commission: {
    label: string;
    amount: number;
  };
  host_platform_transaction_fees: {
    label: string;
    amount: number;
  };
  host_service_fees: {
    label: string;
    amount: number;
  };
  total: {
    label: string;
    value: number;
  };
};

export type ReservationPaymentDetailsGuest = {
  night: {
    label: string;
    amount: number;
  };
  city_tax: {
    label: string;
    amount: number;
  };
  cleaning_fee: {
    label: string;
    amount: number;
  };
  guest_service_fees: {
    label: string;
    amount: number;
  };
  other_fees_total: {
    label: string;
    amount: number;
  };
  total: {
    label: string;
    value: number;
  };
};

export type ReservationLockDeviceItemResponse = {
  id: number;
  device_id: string | null;
  lock_auth: any | null;
  lock_authentication_id: number | null;
  name: string;
  rental_id: ValueType;
  static_code: string;
};

export type ReservationLockItemResponse = {
  id: number;
  checkin: string;
  checkout: string;
  device: ReservationLockDeviceItemResponse | null;
  code: string;
  provider: Lowercase<SmartLocksEnum> | "static";
};

// * -- PAYMENT RESERVATION / PAYMENT DEPOSIT --
// URL Sample :
// - /payment-reservations
// - /payment-deposits
export type PaymentReservationListResponse = {
  payment_reservations: PaymentReservationListItemResponse[];
  total: number;
  total_filtered: number;
  total_unpaid: number;
  total_partially_paid: number;
  total_paid: number;
  total_overdue: number;
  total_canceled: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

export type PaymentReservationListItemResponse = {
  id: number;
  rental: PaymentReservationRentalResponse;
  guest: GuestResponse;
  platform: PlatformResponse;
  payment_schedule: PaymentReservationScheduleResponse;
};

// URL Sample :
// - /payment-reservations/1
// - /payment-deposits/1
export type PaymentReservationResponse = {
  id: number;
  checkin: string; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  checkout: string; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  time_in: string; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  time_out: string; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  nights_count: number; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  adults_count: number; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  children_count: number; // ! Pourquoi ne pas l'ajouter dans la version liste aussi ?
  rental: PaymentReservationRentalResponse;
  guest: GuestResponse;
  platform: PlatformResponse;
  payment_schedule: PaymentReservationScheduleResponse;
};

// URL Sample :
// - /payment-reservations/15/get-payment-link/f65ae7a9-aee1-4bd3-a8cb-36b224288d3e
// - /payment-deposits/1/get-deposit-link/967d924c-fd3c-46e3-bb03-a6f708ff0bed
export type PaymentReservationLinkResponse = {
  payment_schedules: PaymentReservationLinkScheduleResponse;
  payment_schedule_current_item: PaymentScheduleCurrentItem;
  stripe_client_secret: string;
};

// URL Sample :
// - /payment-extras-orders
export type PaymentExtraListResponse = {
  reservation_extras: PaymentExtraListItemResponse[];
  total: number;
  total_filtered: number;
  total_unpaid: number;
  total_partially_paid: number;
  total_paid: number;
  total_overdue: number;
  total_canceled: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

export type PaymentExtraListItemResponse = {
  id: number;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  nights_count: number;
  adults_count: number;
  children_count: number;
  total_guest_price: number;
  rental: RentalResponse;
  guest: GuestResponse;
  platform: PlatformResponse;
  payment_extras_order: GuestPageExtraOrder;
};

export type PaymentScheduleCurrentItem = {
  id: number;
  payment_status: string;
  value_type: string;
  percentage_value: number | null;
  price_value: string;
  trigger: string;
  specific_date: string; // Date string in ISO format
  payment_account_type: string;
  payment_account: GuestPagePaymentAccount;
  payment_link: string;
  payment_success_date: string | null; // Date string in ISO format or null
};

export type PaymentReservationLinkScheduleResponse = {
  id: number;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  nights_count: number;
  adults_count: number;
  children_count: number;
  rental: PaymentReservationRentalResponse;
  guest: GuestResponse;
  platform: PlatformResponse;
  payment_schedule: PaymentReservationScheduleResponse;
};

export type PaymentReservationRentalResponse = {
  name: string;
  address: string;
  postal_code: string;
  city: string;
  cover: string;
  checkin_min_time: string; // ! Est dans le détails mais pas dans la liste
  checkin_max_time: string; // ! Est dans le détails mais pas dans la liste
  checkout_min_time: string; // ! Est dans le détails mais pas dans la liste
  checkout_max_time: string; // ! Est dans le détails mais pas dans la liste
};

export type PaymentReservationDepositValue =
  (typeof PaymentReservationDepositValueTypeEnum)[keyof typeof PaymentReservationDepositValueTypeEnum];

export type PaymentReservationDepositAccountOption =
  (typeof PaymentReservationDepositAccountOptionEnum)[keyof typeof PaymentReservationDepositAccountOptionEnum];

export type PaymentReservationDepositStatus =
  (typeof PaymentReservationDepositStatusEnum)[keyof typeof PaymentReservationDepositStatusEnum];

export type PaymentReservationScheduleResponse = {
  id: number;
  payment_status: PaymentReservationStatusEnum;
  total_price_value: string;
  is_single_payment: number;
  is_refundable: number;
  refund_value: number | null;
  refund_condition: string | null;
  is_deposit_required: number;
  deposit_value_type: PaymentReservationDepositValue | null;
  deposit_value: number | null;
  deposit_payment_option: PaymentReservationDepositAccountOption | null;
  deposit_payment_account_id: number | null;
  deposit_payment_days_delay: number | null;
  deposit_payment_specific_date: string | null;
  deposit_payment_status: PaymentReservationDepositStatus | null;
  deposit_payment_link: string | null;
  deposit_payment_success_date: any; // TODO : A vérifier si réellement encore utilisé
  payments_schedule_items: PaymentReservationScheduleListItemResponse[];
};

export type PaymentReservationScheduleListItemResponse = {
  id: ValueType;
  payment_schedule_id: ValueType;
  payment_status: (typeof PaymentReservationStatusEnum)[keyof typeof PaymentReservationStatusEnum];
  value_type: string;
  percentage_value: number | null;
  price_value: number;
  trigger: string;
  specific_date: string | null;
  payment_account_type: PaymentAccountTypeEnum;
  payment_account: PaymentAccountListItemResponse;
  payment_link: string | null;
  payment_success_date: any; // TODO : A vérifier si réellement encore utilisé (le champ reservation_payment_schedules_items.payment_date n'est pas utiliser)
};

export type PaymentDepositReservationLinkResponse = {
  deposit_schedules: PaymentDepositReservationSchedulesResponse;
  stripe_client_secret: string;
  stripe_account_id: string;
};

export type PaymentDepositReservationSchedulesResponse = {
  id: number;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  nights_count: number;
  adults_count: number;
  children_count: number;
  rental: PaymentDepositReservationRentalResponse;
  guest: PaymentDepositReservationGuestResponse;
  platform: PaymentDepositReservationPlatformResponse;
  payment_schedule: PaymentDepositReservationPaymentScheduleResponse;
};

export type PaymentDepositReservationRentalResponse = {
  name: string;
  address: string;
  postal_code: string;
  city: string;
  cover: string;
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string;
  checkout_max_time: string;
};

export type PaymentDepositReservationGuestResponse = {
  id: number;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  postal_code: string;
  city: string;
  country: string;
  photo: string;
  locale: any;
  languages: any;
  notes: string;
  rate: any;
};

export type PaymentDepositReservationPlatformResponse = {
  id: number;
  name: string;
  logo: string;
  reservation_reference: string;
  reservation_link: string;
};

export type PaymentDepositReservationPaymentScheduleResponse = {
  id: number;
  payment_status: string;
  total_price_value: string;
  is_single_payment: number;
  is_refundable: number;
  refund_value: any;
  refund_condition: any;
  is_deposit_required: number;
  deposit_value_type: string;
  deposit_value: number;
  deposit_payment_option: PaymentPolicyDepositPaymentOptionEnum;
  deposit_payment_account_id: number;
  deposit_payment_days_delay: number;
  deposit_payment_specific_date: string;
  deposit_payment_status: PaymentReservationDepositStatusEnum;
  deposit_payment_link: string;
  deposit_payment_success_date: any;
  payments_schedule_items: PaymentDepositReservationPaymentsScheduleItemResponse[];
};

export type PaymentDepositReservationPaymentsScheduleItemResponse = {
  id: number;
  payment_status: PaymentReservationDepositStatusEnum;
  value_type: string;
  percentage_value: any;
  price_value: string;
  trigger: string;
  specific_date: string;
  payment_account_type: string;
  payment_account: PaymentDepositReservationPaymentAccountResponse;
  payment_link: string;
  payment_success_date: any;
};

export type PaymentDepositReservationPaymentAccountResponse = {
  id: number;
  name: string;
  type: string;
  stripe_account_id: string;
  stripe_account_name: string;
  stripe_account_email: string;
  created_at: string;
  updated_at: string;
};

// * -- RENTAL --
export type RentalLightListItemResponse = {
  id: ValueType;
  user_id: ValueType;
  rental_category_id: ValueType;
  rental_type_id: ValueType;
  renting_type: string;
  management_type: string;
  enabled: number;
  name: string;
  address: string;
  postal_code: string;
  city: string;
  country: CountryResponse;
  guests_max_capacity: number;
  adults_max_capacity: number;
  children_max_capacity: number;
  guests_default_capacity: number;
  bathrooms_count: number;
  bedrooms_count: number;
  beds_count: number;
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string | null;
  checkout_max_time: string | null;
  min_nights_default: number;
  max_nights_default: number;
  external_connexions: RentalExternalConnexionsResponse;
  cover: string;
  last_update: string;
  price_night_min: number | null;
  price_night_default: number | null;
  price_night_added_per_guests: number | null;
  cleaning_default: number | null;
  deposit: any;
  city_tax_default: number | null;
  city_tax_limit: number | null;
  city_tax_percent: number | null;
  city_tax_additional: number | null;
  reservation_daytime_limit: string | null;
  min_days_prior_reservations: number | null;
};

export type RentalListResponse = {
  rentals: RentalLightListItemResponse[];
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

export type RentalResponse = {
  id: ValueType;
  user_id: ValueType;
  details: RentalDetailsResponse;
  tarification: RentalTarificationResponse;
  guest_page: RentalGuestPageResponse;
  services: RentalServicesResponse;
  checklists: RentalChecklistsResponse[];
};

export type RentalDetailsResponse = {
  informations: RentalInformationsResponse;
  bedrooms: RentalBedRoomResponse[];
  external_connexions: RentalExternalConnexionsResponse;
  rules: RentalReservationRulesResponse;
  photos: PhotoListResponse;
  descriptions: RentalDescriptionResponse[];
  equipments: string;
  private_notes: string | null;
  billing_infos: RentalBillingInfosResponse;
  checklists: RentalChecklistsResponse[];
};

export type RentalDescriptionResponse = {
  id: number;
  rental_id: number;
  language_id: number;
  description: string | null;
};

export enum RentalBillingInfosHostType {
  PARTICULAR = "particular",
  PROFESSIONAL = "professional",
}

export type RentalBillingInfosResponse = {
  id: number;
  rental_id: number;
  host_type: RentalBillingInfosHostType;
  host_name: string | undefined;
  tax_identity_number: string | undefined;
  company_name: string | undefined;
  siren: string | undefined;
  address: string | undefined;
  country: string | undefined;
  postal_code: string | undefined;
  city: string | undefined;
  property_registration_number: string | undefined;
};

export type ChecklistType =
  | "cleaning"
  | "checkin"
  | "checkout"
  | "incident"
  | "other";
export type ChecklistRemunerationType = "fixed" | "by_bed";
export type ChecklistStep = {
  step_id: number;
  name: string;
  order: number;
};
export type ChecklistSection = {
  section_id: number;
  name: string;
  section_order: number;
  steps: ChecklistStep[];
};
export type RentalChecklistsResponse = {
  id: number;
  name: string;
  description: string;
  type: ChecklistType;
  remuneration_type: ChecklistRemunerationType;
  amount_fixed: number;
  amount_by_bed: number;
  sections: ChecklistSection[];
  number_of_steps: number;
  rental_id: ValueType;
  photos: PhotoListItemResponse[];
};

export type RentalInformationsResponse = {
  id: number;
  rental_group_id: number;
  rental_category_id: number;
  rental_type_id: number;
  renting_type: string;
  management_type: string;
  enabled: number;
  name: string;
  short_name: string;
  address: string;
  postal_code: string;
  city: string;
  country: CountryResponse;
  guests_max_capacity: number;
  adults_max_capacity: number;
  children_max_capacity: number;
  guests_default_capacity: number;
  bathrooms_count: number;
  bedrooms_count: number;
  beds_count: number;
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string;
  checkout_max_time: string;
  min_nights_default: number;
  max_nights_default: number;
  parent_dependency: RentalLightListItemResponse | null;
  children_dependencies: RentalChildrenDependencyResponse[];
};

export type RentalChildrenDependencyResponse = {
  id: number;
  user_id: number;
  rental_category_id: number;
  rental_type_id: number;
  renting_type: string;
  management_type: string;
  enabled: number;
  name: string;
  address: string;
  postal_code: string;
  city: string;
  country: CountryResponse;
  guests_max_capacity: number;
  adults_max_capacity: number;
  children_max_capacity: number;
  guests_default_capacity: number;
  bathrooms_count: number;
  bedrooms_count: number;
  beds_count: number;
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string;
  checkout_max_time: string;
  min_nights_default: number;
  max_nights_default: number;
  external_connexions: RentalExternalConnexionsResponse;
  cover: string;
  last_update: string;
  price_night_min: number;
  price_night_default: number;
  price_night_added_per_guests: number;
  cleaning_default: number;
  deposit: number | null;
  city_tax_default: number | null;
  city_tax_limit: number | null;
  city_tax_percent: number | null;
  city_tax_additional: number | null;
  reservation_daytime_limit: string | null;
  min_days_prior_reservations: number | null;
};

export type RentalExternalConnexionsResponse = {
  platform_booking: RentalPlatformBookingResponse | null;
  platform_airbnb: RentalPlatformAirbnbResponse | null;
  platform_pricelabs: RentalPricelabsResponse | null;
  platform_icalendar: Array<RentalPlatformIcalendarResponse> | null;
  superhote_icalendar_link: string | null;
};

export type RentalBedRoomResponse = {
  id: ValueType;
  name: string;
  room_type_id: number | null;
  private_room: number;
  private_bathroom: number;
  adults_count: number;
  children_count: number;
  infants_beds_count: number;
  children_beds_count: number;
  double_beds_count: number;
  wall_beds_count: number;
  queen_beds_count: number;
  king_beds_count: number;
  mezannine_beds_count: number;
  sofa_beds_count: number;
  single_beds_count: number;
};

export type RentalReservationRulesResponse = {
  reservation_daytime_limit: string | null;
  min_days_prior_reservations: number | null;
  max_days_prior_reservations: number | null;
  duration_between_reservations: number | null;
  smoking_allowed: number | null;
  infants_allowed: number | null;
  children_allowed: number | null;
  pets_allowed: number | null;
  events_allowed: number | null;
  additional_rules: string | null;
};

export type RentalReservationRulesPayload = {
  reservation_daytime_limit: string;
  min_days_prior_reservations: number;
  max_days_prior_reservations: number;
  duration_between_reservations: number;
  smoking_allowed: number;
  infants_allowed: number;
  children_allowed: number;
  pets_allowed: number;
  events_allowed: number;
  additional_rules: string;
};

export type RentalTarificationResponse = {
  rules: RentalTarificationRulesResponse;
  policies_seasons: RentalPoliciesSeasonsResponse;
  fees_discounts: FeesDiscounts;
};

export type RentalTarificationRulesResponse = {
  rules: RentalTarificationRules2Response;
  price_multipliers: RentalPriceMultipliersResponse;
  payment_ways: RentalPaymentWaysResponse;
};

export type RentalTarificationRules2Response = {
  currency: CurrencyResponse;
  price_night_default: number | null;
  price_night_min: number | null;
  vat_percentage: number | null;
  city_tax_limit: number | null;
  city_tax_default: number | null;
  city_tax_percent: number | null;
  city_tax_additional: number | null;
};

export type RentalPoliciesSeasonsResponse = {
  payment_policies: RentalPaymentPolicyListItemResponse[];
  seasons: RentalPaymentSeasonListItemResponse[];
};

export type RentalPaymentPolicyListItemResponse = {
  id: ValueType;
  name: string;
  description: string;
  is_favorite_policy: number;
  is_single_payment: number;
  is_refundable: number;
  refund_value: number | null;
  refund_condition: string | null;
  is_deposit_required: number;
  deposit_value_type?: string | null;
  deposit_value?: number | null;
  deposit_payment_option?: string | null;
  deposit_payment_account_id?: number | null;
  deposit_payment_days_delay?: number | null;
  payments_policy_items: RentalPaymentPolicyItem[];
};

export type RentalPaymentPolicyItem = {
  id: ValueType;
  payment_policy_id: ValueType;
  value_type: string;
  trigger: string;
  specific_date: string | null;
  payment_account_type: string;
  payment_account_id: number | null;
  created_at: string | null;
  updated_at: string | null;
};

export type RentalPaymentSeasonListItemResponse = {
  id: ValueType;
  rental_id: ValueType;
  name: string;
  start_date: string;
  end_date: string;
  night_price: number;
  is_anunally_recurring: number;
  per_days_prices: string | null;
  per_days_min_nights: string | null;
  per_days_no_checkins: string | null;
  per_days_no_checkouts: string | null;
  created_at: string | null;
  updated_at: string | null;
};

export type FeesDiscounts = {
  additional_fees: RentalAdditionalFeesResponse;
  discounts: RentalDiscountListItemResponse[];
};

export type RentalAdditionalFeesResponse = {
  cleaning_default: number | null;
  linen_default: number | null;
  price_night_added_per_guests: number | null;
  pet_default: number | null;
  infant_bed_default: number | null;
};

export type RentalDiscountListItemResponse = {
  id: number;
  rental_id: number;
  name: string;
  description: string | null;
  value_type: string;
  percentage_value: number | null;
  price_value: number | null;
  discount_code: string | null;
  maximum_days_to_apply: number | null;
  minimum_days_to_apply: number | null;
  minimun_date_to_apply: number | null;
  maximum_date_to_apply: number | null;
  usage_limit: number | null;
  per_person_limit: number | null;
  created_at: string;
  updated_at: string;
};

export type RentalPriceMultipliersResponse = {
  platform_booking: number;
  platform_airbnb: number;
};

export type RentalPaymentMethod = PaymentAccountTypeEnum;

export type RentalPaymentWaysResponse = {
  airbnb_reservation_payment_method: RentalPaymentMethod;
  airbnb_deposit_payment_method: RentalPaymentMethod;
  booking_reservation_payment_method: RentalPaymentMethod;
  booking_payment_policy_id: number | null; // TODO: A vérifier
  booking_deposit_payment_method: RentalPaymentMethod;
};

export type RentalGuestPageResponse = {
  id: ValueType;
  enabled: number;
  contacts: RentalContactListItemResponse[];
  infos_enabled: number;
  instructions_checkin_enabled: number;
  instructions_checkout_enabled: number;
  welcome_guide_enabled: number;
  reviews_enabled: number;
  reviews_texts: RentalReviewsTextsResponse;
  id_card_enabled: number;
  passport_enabled: number;
  selfie_enabled: number;
  cb_enabled: number;
  police_record_enabled: number;
  extras_payment_account_id: RentalExtrasPaymentAccountIdResponse; // ! Bizarre comme nom de type ? id alors que c'est un objet
  extras_enabled: number;
  extras_available: RentalExtraAvailableListItemResponse[]; // TODO : A vérifier si utilisé, je ne l'ai pas trouvé ce champ dans la DB
  cgv: string;
  wifi_enabled: boolean;
  wifi_name: string;
  wifi_password: string;
  instructions: Instruction[];
};

export type RentalSmartlocksResponse = {
  devices: RentalSmartlocksDeviceItemResponse[];
  checkin: string;
  checkout: string;
  manual_codes: RentalSmartlocksManualCodeItemResponse[];
};

export type RentalSmartlocksManualCodeItemResponse = {
  id: number;
  name: string;
  code: string;
};

export type RentalSmartlocksDeviceItemResponse = {
  id: number;
  lock_authentication_id: number;
  name: string;
  checkin: string;
  checkout: string;
  device_id: string | null;
  provider: Lowercase<SmartLocksEnum> | "static";
  provider_static_codes: any[];
  static_code: string | null;
};

export type RentalServicesResponse = {
  locks: RentalSmartlocksResponse;
};

export type RentalContactListItemResponse = {
  email: string;
  phone: string;
  last_name: string;
  first_name: string;
  email_enabled: boolean;
  phone_enabled: boolean;
};

export type RentalReviewsTextsResponse = {
  [key: string]: string;
  en: string;
  fr: string;
  es: string;
  de: string;
} | null;

export type RentalExtrasPaymentAccountIdResponse = {
  id: number;
  name: string;
  type: string;
  stripe_account_id: string;
  stripe_account_name: string;
  stripe_account_email: string;
  created_at: string;
  updated_at: string;
} | null;

interface Currency {
  code: string;
  name: string;
  symbol: string;
}

export type RentalExtraAvailableListItemResponse = {
  id: number;
  available_until: keyof ExtraAvailableUntil;
  confirmation_enabled: number;
  cost_price: number;
  currency: CurrencyResponse;
  created_at: string;
  updated_at: string;
  description: GuestPageLangTrad;
  extra_enabled: number;
  guest_page_id: number;
  maximum_quantity: number;
  name: GuestPageLangTrad;
  photo: PhotoListItemResponse[];
  unit_price: number;
};

// * -- PAYMENT ACCOUNT --
// URL Sample :
// - /payment-accounts
// - /payment-accounts/get-dropdown-list-items
export type PaymentAccountListResponse = {
  payment_accounts: PaymentAccountListItemResponse[];
};

// URL Sample /payment-accounts/2
export type PaymentAccountListItemResponse = {
  id: ValueType;
  name: string;
  type: string;
  stripe_account_id: string;
  stripe_account_name: string;
  stripe_account_email: string;
  created_at: string | null;
  updated_at: string | null;
} | null;

// * -- SMART LOCKS --
// URL Sample: /locks/lock-authentications/connect
export type SmartlocksConnectResponse = {
  id: number;
  provider: Lowercase<SmartLocksEnum>;
  created_at: string;
  updated_at: string;
};

// URL Sample : /locks/rentals/1/locks
// /locks/rentals/1/lock/manual
export type SmartlocksLinkedItemResponse = {
  id: number;
  lock_authentication_id: number;
  checkin: string;
  checkout: string;
  device_id: string;
  name: string;
  static_code: string | null;
  provider: Lowercase<SmartLocksEnum> | "static";
  provider_static_code: any[];
};

// URL Sample: /locks/lock-authentications
export type SmartlocksAccountItemResponse = {
  id: number;
  email: string;
  provider: Lowercase<SmartLocksEnum>;
};

// URL Sample: /locks/lock-authentications/1/devices
export type SmartlocksIdListItemResponse = {
  id: string;
  name: string;
};

// URL Sample: /locks/rentals/1/lock
export type SmartlocksLinkResponse = {
  id: number;
  lock_authentication_id: number;
  device_id: string;
  name: string;
  checkin: string;
  checkout: string;
};

// URL Sample: /locks/rentals/1/lock
export type SmartlocksUnlinkResponse = SmartlocksLinkedItemResponse[];

// URL Sample: /locks/rentals/1/lock
export type SmartlocksUpdateResponse = {
  id: number;
  lock_authentication_id: number;
  name: string;
  code?: string;
  device_id: string;
  checkin: string;
  checkout: string;
};

// * -- RENTAL GROUPS --
// URL Sample : /rental-groups
export type RentalGroupListResponse = {
  rental_groups: RentalGroupListItemResponse[];
  total: number;
  total_rentals: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

export type RentalGroupListItemResponse = {
  id: number;
  user_id: number;
  name: string;
  rentals: RentalLightListItemResponse[];
};

// URL Sample : /rental-groups/1
export type RentalGroupItemResponse = {
  id: ValueType;
  user_id: ValueType;
  rental_category_id: ValueType;
  rental_type_id: ValueType;
  renting_type: string;
  management_type: string;
  parent_dependency_id: any;
  enabled: number;
  name: string;
  short_name: string | null;
  address: string;
  postal_code: string;
  city: string;
  country_id: ValueType;
  guests_max_capacity: number;
  adults_max_capacity: number;
  children_max_capacity: number;
  guests_default_capacity: number;
  bathrooms_count: number;
  equipments: string | null;
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string | null;
  checkout_max_time: string | null;
  min_nights_default: number;
  max_nights_default: number;
  private_notes: string | null;
  custom_sort_order: number;
};

// * -- BOOKING.COM --
export type BookingComResponse = {
  rooms: BookingComRoomResponse[];
  rates: BookingComRateResponse[];
};

export type BookingComRoomResponse = {
  id: string;
  name: string;
};

export type BookingComRateResponse = {
  id: string;
  name: string;
};

// * -- CALENDAR --
// URL Sample : /calendar?from=2024-04-28&to=2024-05-11
export type CalendarResponse = {
  rentals: CalendarRentalResponse[];
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  show_progression_checking_banner: boolean;
};

export type CalendarRentalResponse = {
  id: ValueType;
  name: string;
  photo: string;
  reservations: CalendarReservationResponse[];
  restrictions: CalendarRestrictionResponse[];
};

export type CalendarReservationResponse = {
  id: ValueType;
  rental: RentalLightListItemResponse;
  platform: PlatformResponse;
  guest: GuestResponse;
  status_reservation: number;
  status_payment: string;
  status_deposit: string;
  status_contract: number;
  reservation_date: string;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  adults_count: number;
  children_count: number;
  total_night_price: number;
  total_guest_price: number;
  cleaning_fee: number;
  city_tax: number;
  private_notes: any;
  platform_notes: any;
  background_color: string;
  text_color: string;
  messages_read_count: number;
  messages_unread_count: number;
};

export type CalendarRestrictionResponse = {
  date: string;
  price: number;
  is_available: number;
  unavailability_reason: string;
  min_nights: number;
  max_nights: number;
  no_checkin: number;
  no_checkout: number;
};

// URL Sample : /calendar/get-list-view-data?from=2024-04-11&to=2024-05-15
export type CalendarListResponse = {
  days: CalendarListDayResponse;
};

export type CalendarListDayResponse = {
  [key: string]: {
    checkins?: CalendarListCheckinCheckoutResponse[];
    checkouts?: CalendarListCheckinCheckoutResponse[];
  };
};

export type CalendarListCheckinCheckoutResponse = {
  id: number;
  reservation_date: string;
  checkin: string;
  checkout: string;
  nights_count: number;
  time_in: string;
  time_out: string;
  adults_count: number;
  children_count: number;
  background_color: string;
  text_color: string;
  guest: GuestResponse;
  platform: PlatformResponse;
  rental: RentalResponse;
};

// * -- AIRBNB --
// URL Sample : /airbnb/login/status?email
export type AirbnbLoginStatusResponse = {
  response_code: (typeof AirbnbLoginStatusEnum)[keyof typeof AirbnbLoginStatusEnum];
};

// URL Sample : /airbnb/listings?airbnb_account_id=1
export type AirbnbListingListResponse = {
  listings: AirbnbListingListItemResponse[];
};

export type AirbnbListingListItemResponse = {
  id: number;
  name: string;
};

// * -- Messages --
// URL Sample : /messages
export type MessageConversationListResponse = {
  conversations: MessageConversationListItemResponse[];
  not_read_conversations: number;
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

export type MessageConversationListItemResponse = {
  reservation_id: number;
  status: number;
  guest: MessageGuestResponse;
  rental: MessageRentalResponse;
  reservation: ReservationResponse;
  is_conversation_read: boolean;
  last_message_date: string;
  last_message_preview?: string;
};

export type MessageGuestResponse = {
  // ! Pourquoi pas le même type que Guest comme partout ?
  first_name: string;
  last_name: string;
  photo: string;
};

export type MessageRentalResponse = {
  // ! Pourquoi pas RentalLight ?
  name: string;
};

export type MessageReservationResponse = {
  id: number;
  rental: RentalResponse;
  platform: PlatformResponse;
  guest: GuestResponse;
  status_reservation: number;
  status_payment: string;
  status_deposit: string;
  status_contract: number;
  reservation_date: string;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  adults_count: number;
  children_count: number;
  total_night_price: number;
  total_guest_price: number;
  cleaning_fee: number;
  city_tax: number;
  private_notes: any;
  platform_notes: any;
  background_color: string;
  text_color: string;
  messages_read_count: number;
  messages_unread_count: number;
};

export enum NotificationStatusesEnumResponse {
  PENDING = "pending", // Waiting for the job to be dispatched
  JOB_DISPATCHED = "job_dispatched", // Job has been dispatched in the queue
  PROCESSING = "processing", // Job is being processed by the worker
  COMPLETED = "completed", // Job has been completed
  CANCELLED = "cancelled", // Job has been cancelled
  ERRORED = "errored", // Job has encountered an error
}

export type NotificationStatusesListItemResponse = {
  id: ValueType;
  status: NotificationStatusesEnumResponse;
  uuid: string | null;
  content: {
    title: string | null;
    message: string;
  };
  content_preview: {
    title: string | null;
    message: string;
  };
  notification: {
    id: ValueType;
    name: string;
  };
  channel: string;
  channels: string[];
  ready_at: string; // "2024-06-07 19:00:00";
  sent_at: string | null;
};

// URL Sample : /messages/1
export type MessageListResponse = {
  messages: MessageListItemResponse[];
  notification_statuses: NotificationStatusesListItemResponse[];
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

export type MessageListItemResponse = {
  id: number;
  user_id: number;
  guest_id: number;
  reservation_id: number;
  type: string;
  from: string;
  content: string;
  is_read: number;
  captured_at: string;
  created_at: string;
  photos: PhotoListItemResponse[];
};

// * -- MESSAGE TEMPLATES --
// URL Sample : /messages-template
export type MessageTemplateListResponse = {
  messages_template: MessageTemplateListItemResponse[];
};

// URL Sample : /messages-template/1
export type MessageTemplateListItemResponse = {
  id: number;
  user_id: number;
  title: string;
  message: string;
  photos: MessageTemplatePhotoResponse[];
  rental: number[] | null;
  language: LanguageResponse;
};

export type MessageTemplatePhotoResponse = {
  id: number;
  name: string;
  mime_type: string;
  original_url: string;
  base_64: string;
};

// * -- Shortcode --
// URL Sample : /shortcodes
export type ShortcodeListResponse = {
  [key: string]: ShortcodeListItemResponse[];
};

export type ShortcodeListItemResponse = {
  id: ValueType;
  type: "custom" | "default";
  trigger: string;
  description: string;
  is_generic: boolean;
  generic_content: string;
  rental_contents: {
    rental_id: ValueType | null;
    content: string;
  }[];
};

// * -- Notifications --
// URL Sample : /notifications?limit=10
export type NotificationListResponse = {
  notifications: NotificationListItemResponse[];
  total: number;
  total_filtered: number;
  total_pre_checkin: number;
  total_post_checkin: number;
  total_payments_cautions: number;
  total_post_checkout: number;
  per_page: number;
  current_page: number;
  last_page: number;
};

// URL Sample : /notifications/11
export type NotificationListItemResponse = {
  id: number;
  enabled: boolean;
  name: string;
  trigger: string;
  delay: string;
  contents: NotificationContentListItemResponse[];
  conditions: string[];
  rentals: {id: number; name: string}[];
  channels: NotificationChannelResponse;
  created_at: string;
  updated_at: string;
  workspace_owner: number;
};

export type NotificationContentListItemResponse = {
  language_id: number;
  title: any;
  message: string;
};

export type NotificationChannelResponse = {
  airbnb: "platform" | "email" | "SMS" | null;
  booking: "platform" | "email" | "SMS" | null;
  direct: "platform" | "email" | "SMS" | null;
};

// * -- Operational management --
// URL Sample : /operational-management/postes
export type OperationalManagementRoleListItemResponse = {
  id: ValueType;
  name: string;
  created_by: ValueType;
  members: ValueType[];
  permissions_selected: {
    id: ValueType;
    name: OperationalManagementRolePermissionNameResponse;
  }[];
  all_rights: OperationalManagementRoleRightResponse[];
  type: OperationalManagementRoleTypeResponse;
};

export type OperationalManagementRoleRightResponse =
  | "hide_price"
  | "hide_sensitive"
  | "no_edit";
export type OperationalManagementRoleTypeResponse =
  | "manager"
  | "house_owner"
  | "house_cleaner"
  | "custom";

export type OperationalManagementRolePermissionNameResponse =
  | "dashboard_prestation"
  | "dashboard_reservations"
  | "calendars"
  | "reservations_message"
  | "reservations_payment_and_invoice"
  | "reservations_contrats_and_document"
  | "reservations_contrats_and_document"
  | "reservations_task"
  | "rental_tarification"
  | "rental_service"
  | "rental_document"
  | "rental_checklist"
  | "rental_guest_page"
  | "rental_reservation_browner"
  | "payment_reservations"
  | "payment_cautions"
  | "payment_extras"
  | "payment_mean_of_collection"
  | "payment_invoices"
  | "messages"
  | "automated_messages"
  | "message_dynamics_fields"
  | "operational_management_tasks"
  | "operational_management_team"
  | "operational_management_automation"
  | "operational_management_payment"
  | "analytics";

export type OperationalManagementTeamMemberForUserItemResponse = {
  id: number;
  name: string;
  created_by: string;
};

// URL Sample : /api/operational-management/teams/member/{memberId}
export type OperationalManagementTeamMemberForUserResponse = {
  teams: OperationalManagementTeamMemberForUserItemResponse[];
};

export type OperationalManagementRolePermissionResponse = {
  id: ValueType;
  name: OperationalManagementRolePermissionNameResponse;
};

export type OperationalManagementPositionTypeResponse =
  | "manager"
  | "house_cleaner"
  | "house_owner";

export type OperationalManagementPositionItemResponse = {
  id: number;
  name: string;
  created_by: string;
  members: ValueType[];
  permissions_selected: {
    id: ValueType;
    name: OperationalManagementRolePermissionNameResponse;
  }[];
  all_rights: any;
  type: OperationalManagementPositionTypeResponse;
};

export type OperationalManagementTeamMemberResponse = {
  id: ValueType;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  photo: string | undefined;
  address: string;
  city: string;
  postal_code: string;
  country: {
    id: ValueType;
    name: string;
  };
  postes: OperationalManagementPositionItemResponse[];
  workspaces: {
    id: ValueType;
    name: string;
    created_by: ValueType;
  }[];
  teams: {
    id: string;
    name: string;
  }[];
  all_rentals_access: RentalLightListItemResponse[]; //all rentals in all workspaces
  rentals_access: RentalLightListItemResponse[]; //rentals in the actual workspace
  accepted: number;
};

export type TeamsMembers = {
  id: number;
  name: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  pivot: {
    user_id: string;
    team_id: number;
  };
};
export type TeamsMembersResponse = {
  teams: TeamsMembers[];
};

export type OperationalManagementTeamListItemResponse = {
  id: ValueType;
  name: string;
  owner: {
    name: string | null;
    email: string;
    photo: string | null;
  };
  members: OperationalManagementTeamMemberResponse[];
  created_by: ValueType;
};

export type TaskAssignationType = "OPERATORS" | "TEAMS";

export type TaskType = "checklist" | "description";

export type CreateTaskForm = {
  name: string;
  rental_id: number | ValueType;
  task_type: TaskType;
  type: ChecklistType;
  assignation_type: TaskAssignationType;
  operator_ids: any[];
  operator_team_ids: any[];
  remuneration_type: TaskRemunerationType;
  amount_global: number | string;
  amount_by_bed: number | null;
  scheduled_date: string;
  scheduled_time: string;
  checklist_id: ValueType;
  description: string;
  remark: string;
};

// URL Sample : /guest-page/get-login-screen-details/d9bdc095-ee52-4ecb-bb94-b31bdafe50f8

export type AutomationOperatorItemResponse = {
  id: string;
  team_name?: string;
  first_name?: string;
  last_name?: string;
  photo?: string;
};

// URL Sample : /automations
export type AutomationItemResponse = {
  id: number;
  name: string | null;
  type: ChecklistType;
  checklist: RentalChecklistsResponse;
  description: string | null;
  remarks: string;
  trigger: "checkin" | "checkout";
  assignation_type: "OPERATORS" | "TEAMS";
  assignee_ids: string[];
  enabled: number;
  rental: {
    id: number;
    name: string;
    address: string;
    postal_code: string;
    city: string;
    cover: string;
  };
  operators: AutomationOperatorItemResponse[];
};

// URL Sample : /guest-page/get-login-screen-details/d9bdc095-ee52-4ecb-bb94-b31bdafe50f8
export type GuestPageLoginDetailsResponse = {
  reservation_id: number;
  guest_first_name: string;
  guest_last_name: string;
  rental_cover: string;
  rental_name: string;
  rental_checkin_min_time: string;
  rental_checkin_max_time: string;
  rental_checkout_min_time: string;
  rental_checkout_max_time: string;
  rental_max_beds: number;
  id_card_enabled: number;
  passport_enabled: number;
  selfie_enabled: number;
  cb_enabled: number;
  police_record_enabled: number;
  guest_page_connected: boolean;
  guest_email: string | null;
  guest_phone: string | null;
  guest_locale: string | null;
  enabled?: boolean;
  cgv: string | null;
  languages: LanguageResponse[];
  reservation_time_in: string;
  reservation_time_out: string;
  guest_page_beds_count: number;
};

// URL Sample : /guest-page/connect

export type GuestPageConnectData = {
  reservation_id: number;
  language: string;
  email: string;
  phone: string;
  guest_page_beds_count: number;
  remarks: string;
  time_in?: string;
  time_out?: string;
};

export interface GuestPageLangTrad {
  de: string;
  en: string;
  es: string;
  fr: string;

  [key: string]: string;
}

export interface GuestPageExtra {
  id: number;
  available_until: keyof ExtraAvailableUntil;
  description: GuestPageLangTrad;
  maximum_quantity: number;
  name: GuestPageLangTrad;
  unit_price: number;
  photo?: PhotoListItemResponse[];
  extra_enabled: boolean;
}

export interface GuestPageExtraDetail {
  id: number;
  name: GuestPageLangTrad;
  description: GuestPageLangTrad;
  quantity: number;
  unit_price_value: number;
  total_price_value: number;
  payment_status: ReservationExtraOrderStatusEnum;
  order_date: string;
  validation_date: string | null;
  payment_success_date: string | null;
  refund_date: string | null;
  cancelation_date: string | null;
  photo?: PhotoListItemResponse[];
  extra_enabled?: boolean;
  available_until: keyof ExtraAvailableUntil;
  extra_available?: boolean; // this is only for extra orders switch for available or unavailable
}

export interface GuestPageExtraOrder {
  id: number;
  payment_status: ReservationExtraOrderStatusEnum;
  total_price_value: number;
  order_date: string;
  validation_date: string | null;
  payment_success_date: string | null;
  refund_date: string | null;
  cancelation_date: string | null;
  extras: GuestPageExtraDetail[];
}

export interface GuestPageContact {
  email: string;
  phone: string;
  last_name: string;
  first_name: string;
  email_enabled: boolean;
  phone_enabled: boolean;
  photo?: string;
}
export interface GuestPageContactExtras {
  email: string;
  phone: string;
  last_name: string;
  first_name: string;
  photo?: string;
}

export interface GuestPagePaymentAccount {
  id: number;
  name: string;
  type: string;
  stripe_account_id: string;
  stripe_account_name: string;
  stripe_account_email: string;
  created_at: string;
  updated_at: string;
}

export interface GuestPagePaymentScheduleItem {
  id: number;
  payment_status: PaymentReservationStatusEnum;
  value_type: string;
  percentage_value: number | null;
  price_value: number;
  trigger: string;
  specific_date: string;
  payment_account_type: string;
  payment_account: GuestPagePaymentAccount;
  payment_link: string;
  payment_success_date: string | null;
}

export interface GuestPagePaymentSchedule {
  id: number;
  payment_status: string;
  total_price_value: number;
  is_single_payment: number;
  is_refundable: number;
  refund_value: number | null;
  refund_condition: string | null;
  is_deposit_required: number;
  deposit_value_type: string;
  deposit_value: number;
  deposit_payment_option: string;
  deposit_payment_account_id: number;
  deposit_payment_days_delay: number;
  deposit_payment_specific_date: string;
  deposit_payment_capture_date_limit: string | null;
  deposit_payment_status: PaymentReservationDepositStatusEnum | null;
  deposit_payment_link: string;
  deposit_payment_success_date: string | null;
  payments_schedule_items: GuestPagePaymentScheduleItem[];
}

export interface GuestPageRental {
  name: string;
  address: string;
  postal_code: string;
  city: string;
  cover: string;
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string;
  checkout_max_time: string;
  currency: CurrencyResponse | null;
}

export interface GuestPageGuestConnectData {
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  postal_code: string;
  city: string;
  country: string;
  locale: string | null;
  currency?: CurrencyResponse | null;
}
export interface PaymentDetail {
  id: number;
  reservation_id: number;
  label: string;
  type:
    | "night"
    | "city_tax"
    | "cleaning_fee"
    | "additional_guests_total_price"
    | "pets_fees"
    | "infant_bed_fees"
    | "other";
  amount: number;
}

export type FileInstruction = {
  id: number;
  name: string;
  original_url: string;
  mime_type: string;
};

export type InstructionType =
  | "arrival_instruction"
  | "checkout_procedure"
  | "welcome_guide";
export interface Instruction {
  content: GuestPageLangTrad;
  entry_code: string | null;
  files: FileInstruction[];
  id: number;
  infos_enabled: number;
  key_box_code: string | null;
  language_id: number;
  rental_guest_page_id: number;
  type: InstructionType;
}
export interface GuestPageReviews {
  de: string;
  en: string;
  es: string;
  fr: string;

  [key: string]: string;
}

export interface GuestPageConnectResponse {
  id: number;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  nights_count: number;
  adults_count: number;
  children_count: number;
  platform_name: string;
  rental: GuestPageRental;
  instructions_enabled: boolean;
  instructions_checkin_enabled: boolean;
  instructions_checkout_enabled: boolean;
  welcome_guide_enabled: boolean;
  reviews_enabled: boolean;
  reviews_texts: GuestPageReviews;
  wifi_enabled: boolean;
  wifi_name: string;
  wifi_password: string;
  extras_enabled: boolean;
  instructions: Instruction[];
  extras_available_for_order: GuestPageExtra[];
  extras_order_confirmation_enabled: boolean;
  extras_order_historical: GuestPageExtraOrder[];
  contacts: GuestPageContact[];
  contacts_extras: GuestPageContactExtras[];
  payment_schedule: GuestPagePaymentSchedule;
  payment_details: PaymentDetail[];
  guest: GuestPageGuestConnectData;
  guest_page_review_enabled: boolean;
  guest_page_rating_stars: number;
  guest_page_review_text: string;
  guest_page_wifi_enabled: boolean;
  cgv: string;
}

export interface ValidateExtraResponse {
  extras: GuestPageExtraDetail[];
  stripe_client_secret?: string;
  stripe_account_id: string;
  extras_order_confirmation_enabled: boolean;
  total_price_value: number;
  total_price_value_in_smaller_unit: number; // prices amounts have to be sent to stripe in the currency smaller unit (cents for euros for example)
}

export type ResultOrder = {
  ordered_extras_ids: string;
  ordered_extras_quantities: string;
};

export interface SendExtraData extends ResultOrder {
  reservation_id: string;
  email: string | undefined;
  stripe_confirmation_token?: string;
}

export interface SendExtraOrderPaymentResponse {
  stripe_account_id: string;
  stripe_client_secret: string;
  stripe_payment_status: string;
  stripe_error?: string;
  extras_order_historical: GuestPageExtraOrder[];
}

export type File = {
  id: number;
  name: string;
  original_url: string;
  mime_type: string;
  updated_at?: string;
};
export interface ReservationContractDocs {
  docs: File[];
}

export type AnalyticsPageType =
  | "general"
  | "detail"
  | "evaluation"
  | "object_connected"
  | "contender";

export interface AnalyticsGeneralRevenue {
  gross_revenue: number;
  platform_commissions: number;
  cleaning_fees: number;
  tourist_tax: number;
  net_revenue: number;
}

export interface AnalyticsGeneralOccupancy {
  occupancy_rate: string;
  occupancy_rate_excluding_blocked: string;
  nights_booked: number;
  nights_available: number;
}

export interface AnalyticsGeneralReservationStatistics {
  active_properties: number;
  average_guests_per_reservation: number;
  average_nights_per_reservation: number;
  total_checkins_checkouts: number;
  average_price_per_reservation: number;
  revenue_per_available_room: number;
}

export interface AnalyticsGeneralResponse {
  revenue: AnalyticsGeneralRevenue;
  occupancy: AnalyticsGeneralOccupancy;
  reservation_statistics: AnalyticsGeneralReservationStatistics;
}

export interface AnalyticsDetailedResponse {
  summary: AnalyticsDetailedSummary;
  occupancy_statistics: AnalyticsDetailedOccupancyStatistics;
  revenue_by_rental: AnalyticsDetailedRevenueByRental[];
  monthly_details: AnalyticsDetailedMonthlyDetails[];
  currency: string;
}

export interface AnalyticsDetailedRevenueMetrics {
  monthly_gross_revenue_per_reservation: Record<
    string,
    {paid: number; upcoming: number}
  >;
  total_paid: number;
  total_upcoming: number;
}

export interface AnalyticsDetailsObjectResponse {
  total: number;
  distribution: AnalyticsDetailedDistributionObject;
}

export interface AnalyticsDetailsSimpleResponse {
  total: number;
  distribution: AnalyticsDetailedDistribution;
}

export interface AnalyticsDetailedSummary {
  revenue_metrics: AnalyticsDetailedRevenueMetrics;
  revenue_breakdown: AnalyticsDetailsObjectResponse;
  commissions: AnalyticsDetailsObjectResponse;
  city_taxes: AnalyticsDetailsObjectResponse;
  cleaning_fees: AnalyticsDetailsObjectResponse;
  net_revenue: AnalyticsDetailsObjectResponse;
}

export interface AnalyticsDetailedMonthlyOccupancy {
  monthly_occupancy: Record<string, number>;
  average_total_occupancy: number;
}

export interface AnalyticsDetailedOccupancyStatistics {
  monthly_occupancy: AnalyticsDetailedMonthlyOccupancy;
  nights_booked: AnalyticsDetailsSimpleResponse;
  nights_available: AnalyticsDetailsSimpleResponse;
  cancelled_nights: AnalyticsDetailsSimpleResponse;
  cancellation_rates: AnalyticsDetailsSimpleResponse;
  average_nights_per_reservation: AnalyticsDetailsSimpleResponse;
  average_price_per_reservation: AnalyticsDetailsSimpleResponse;
}

export interface AnalyticsDetailedRevenueByRental {
  name: string;
  address: string;
  revenue: number;
  percentage: number;
  cover_url: string;
}

export interface AnalyticsDetailedMonthlyDetails {
  period: string;
  nights_booked: number;
  nights_available: number;
  occupancy_rate: number;
  commissions: number;
  tourist_tax: number;
  revenue: number;
  net_revenue: number;
}

export interface AnalyticsDetailedDistributionObject {
  superhote: {
    value: number;
    percentage: number;
  };
  airbnb: {
    value: number;
    percentage: number;
  };
  booking: {
    value: number;
    percentage: number;
  };
  vrbo: {
    value: number;
    percentage: number;
  };
  others: {
    value: number;
    percentage: number;
  };
}
export interface AnalyticsDetailedDistribution {
  superhote: number;
  airbnb: number;
  booking: number;
  vrbo: number;
  others: number;
}

export enum DistributionKeyEnum {
  AIRBNB = "airbnb",
  BOOKING = "booking",
  DIRECT = "superhote",
  OTHERS = "others",
}

export enum StatsNameKeysEnum {
  REVENUE_BREAKDOWN = "REVENUE_BREAKDOWN",
  COMMISSIONS = "COMMISSIONS",
  CITY_TAXES = "CITY_TAXES",
  CLEANING_FEES = "CLEANING_FEES",
  REVENUE_NET = "REVENUE_NET",
  NIGHTS_BOOKED = "NIGHTS_BOOKED",
  NIGHTS_AVAILABLE = "NIGHTS_AVAILABLE",
  CANCELLED_NIGHTS = "CANCELLED_NIGHTS",
  CANCELLATION_RATES = "CANCELLATION_RATES",
  AVERAGE_NIGHTS_PER_RESERVATION = "AVERAGE_NIGHTS_PER_RESERVATION",
  AVERAGE_PRICE_PER_RESERVATION = "AVERAGE_PRICE_PER_RESERVATION",
}
export interface DetailsStatsReservationResponse {
  checkin: string;
  checkout: string;
  date: string;
  id: number;
  rental_name: string;
  value: number;
}
export interface DetailsStatsResponse {
  limit: number;
  page: number;
  reservations: DetailsStatsReservationResponse[];
  total_pages: number;
  total_reservations: number;
  total_value: number;
}
// URL Sample : /dashboard

export interface DashboardData {
  reservations: ReservationsDashboard;
  operator_tasks: OperatorTasksDashboard;
}
export interface StatItemDashboard {
  value?: string;
  month?: string;
  year?: number;
  count?: number;
  last_month_comp_percents?: number;
  last_month_comp_value?: number;
}

export interface StatsDashboard {
  saved_time?: StatItemDashboard & {
    display_value: boolean;
  };
  occupancy_rate?: StatItemDashboard;
  reservations_not_paid?: StatItemDashboard;
  deposit_to_collect?: StatItemDashboard;
  extras_orders?: StatItemDashboard;
  tasks_not_assigned?: StatItemDashboard;
  invoice_amount?: StatItemDashboard;
  tasks_to_complete_today?: StatItemDashboard;
}

export interface RecommendationsDashboard {
  automated_messages_enabled: number;
  smart_lock_enabled: number;
  welcome_guide_enabled: number;
}

export interface ReservationsDashboard {
  stats: StatsDashboard;
  next_tasks: TaskListByDateResponse;
  recommendations: RecommendationsDashboard;
}

export interface OperatorTasksDashboard {
  stats: StatsDashboard;
  next_tasks: TaskListByDateResponse;
}

// Define the structure for the tasks of a single day

export type TaskListByDateResponse = {
  current_page: number;
  last_page: number;
  per_page: number;
  tasks: TasksByDateResponse;
  total: number;
  total_assigned: number;
  total_canceled: number;
  total_completed: number;
  total_declined: number;
  total_filtered: number;
  total_not_assigned: number;
  total_started: number;
  [date: string]: any;
};

export type TasksByDateResponse = {
  [date: string]: TaskListDayResponse;
};

export type TaskListDayResponse = {
  total_cleaning_tasks: number;
  total_checkin_tasks: number;
  total_checkout_tasks: number;
  total_incident_tasks: number;
  total_other_tasks: number;
  day_tasks: TaskItemResponse[];
};

export type TaskRemunerationType = "BY_BED" | "FIXED";

export type TaskStatus =
  | "ASSIGNED"
  | "NOT_ASSIGNED"
  | "STARTED"
  | "COMPLETED"
  | "CANCELED"
  | "PARTIALLY_COMPLETED";

export type TaskAssignedTeamItemResponse = {
  id: number;
  name: string;
};

export type TaskItemOperatorItemResponse = {
  id: ValueType;
  first_name: string;
  last_name: string;
  photo: string;
};

export type TaskLastReservation = {
  still_in_progress: boolean;
  guest_first_name: string | null;
  guest_last_name: string | null;
  guest_phone: string | null;
  checkout_date: string | null;
  checkout_time: string | null;
  nights_count: number | null;
  guests_count: number | null;
};
export type TaskNextReservation = {
  still_in_progress: boolean;
  guest_first_name: string | null;
  guest_last_name: string | null;
  guest_phone: string | null;
  checkin_date: string | null;
  checkin_time: string | null;
  nights_count: number | null;
  guests_count: number | null;
  private_notes: string | null;
};

export type TaskItemResponse = {
  id: number;
  rental: RentalLightListItemResponse;
  name: string;
  type: ChecklistType;
  assignation_type: TaskAssignationType;
  assigned_teams: TaskAssignedTeamItemResponse[];
  assigned_operators: TaskItemOperatorItemResponse[];
  remuneration_type: TaskRemunerationType;
  amount_global: string | null;
  amount_by_bed: string | null;
  scheduled_date: string;
  scheduled_time: string;
  description: string | null;
  remark: string | null;
  status: TaskStatus;
  reservation_platform: "Direct" | "Booking.com" | "Airbnb";
  reservation_id: string | null;
  checklist_id: ValueType;
  last_reservation: TaskLastReservation;
  next_reservation: TaskNextReservation;
  assigned_or_not_task_data: {
    is_owned_by_me: boolean;
    is_assigned_to_me: boolean;
    has_multiple_assignation: boolean;
    is_requested_to_me: boolean;
    is_declined_by_me: boolean;
    is_accepted_by_me: boolean;
    remuneration_type: TaskRemunerationType;
    amount_global: number;
    amount_by_bed: number | null;
    checklist_id: ValueType | null;
    checklist_photos: File[] | null;
    checklist_sections: string | null;
    description: string;
    remark: string;
  };
  started_task_data: {
    is_owned_by_me: boolean;
    is_started_by_me: boolean;
    has_multiple_assignation: boolean;
    remark: string;
    checklist_photos: File[] | null;
    checklist_sections: string | null;
    description: string | null;
    last_reservation: TaskLastReservation;
    next_reservation: TaskNextReservation;
  };
  completed_task_data: {
    is_completed_by_me: boolean;
    is_edition_enabled: boolean;
    multiple_assignation: boolean | null;
    simple_assignation: {
      checklist: string | null;
      cleaned_beds_count: number | null;
      cleanness: string;
      photos: File[] | null;
      remarks: string | null;
      validation_date: string;
    };
  };
  canceled_task_data: {
    cancelation_date: string;
    cancelation_remarks: string | null;
    cancelation_reason: string | null;
    has_multiple_assignation: boolean;
    is_canceled_by_owner: boolean;
    is_owned_by_me: boolean;
    canceled_by: {
      id: ValueType;
      first_name: string;
      last_name: string;
      photo: string;
    };
  };
};

export interface ManagementPaymentResponse {
  [date: string]: {
    [operator: string]: {
      summary: {
        total_amount: number;
        total_bed_changed_count: number;
        count: number;
        payment_ids: number[];
        status: "PAID" | "INVOICE_RECEIVED" | "PENDING";
      };
      day_payments: Array<ManagementDayPayment>;
    };
  };
}

export interface ManagementPaymentResponseLite {
  summary: {
    total_amount: number;
    total_bed_changed_count: number;
    count: number;
    payment_ids: number[];
    status: "PAID" | "INVOICE_RECEIVED" | "PENDING";
  };
  day_payments: Array<ManagementDayPayment>;
}

export interface ManagementDayPayment {
  id: number;
  user_id: string;
  operator_id: string;
  name: string;
  rentals: Rental[];
  end_date: string;
  status: string;
  beds_changed_count: number;
  amount: number;
  task_id: string | null;
  created_at: string;
  updated_at: string;
  operator: UserResponse;
}

export interface Payment {
  id: number;
  name: string;
  specific_date: string;
  payment_link: string;
  status: string;
  price: number;
}

export interface Payments {
  [key: string]: Payment | number | string;
  total: number;
  status: string;
}

export interface Deposit {
  deposit_value_type: string;
  deposit_value: number;
  deposit_payment_option: string;
  deposit_payment_days_delay: number;
  deposit_payment_specific_date: string;
  deposit_payment_status: string;
  deposit_payment_link: string;
}

export interface CancelConditions {
  is_refundable: number;
  refund_value: number | null;
  refund_condition: string | null;
}

export interface PaymentsDeposit {
  payments: Payments;
  deposit: Deposit;
  cancel_conditions: CancelConditions;
}

export interface PaymentSummary {
  type: string;
  label: string;
  amount: number;
  quantity: number;
  total_ht: number;
  tva: number;
  total_ttc: number;
}

export interface ReceiptLine {
  label: string;
  amount: number;
  quantity: number;
  total: number;
}

export interface Receipt {
  total: number;
  lines: ReceiptLine[];
}

export interface Receipts {
  night_receipt: Receipt;
  extra_receipt: Receipt;
  full_receipt: Receipt;
}

export interface PaymentReceiptsData {
  metadata: {
    platform: PlatformResponse;
    sender: UserResponse;
    customer: GuestResponse;
  };
  payments_deposit: PaymentsDeposit;
  payment_summaries: PaymentSummary[];
  receipts: Receipts;
}


export type ContactType = "MAIN" | "INVOICE" |
    "RESERVATION" | "CENTER_OF_RESERVATIONS" |
    "SPECIAL_REQUEST" | "DISPONIBILITIE" |
    "PHOTO_AND_DESCRIPTION" | "TARIFICATION" |
    "CONTRACT"

export interface RentalContact {
  id: number;
  contact_type: ContactType;
  firstname: string|null;
  lastname: string|null;
  email: string|null;
  phone: string|null;
}

