import React, {useEffect, useState} from "react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import {useTranslation} from "react-i18next";
import useExtrasStore from "../../../stores/guestPageStore/useExtrasStore";
import {
  GuestPageConnectResponse,
  GuestPageExtra,
  GuestPageExtraDetail,
  PaymentReservationLinkResponse,
  SendExtraOrderPaymentResponse,
} from "../../../types/GETTypes";
import ExtraCardComponent from "../Common/ExtraCardComponent";
import {Loader} from "../../Common/Loader/Loader";
import {Button} from "../../Common/Button/Button";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import AlertCircle from "../../../assets/icons/alert-circle.svg?react";
import {ExtrasOrder} from "../Common/ExtraComponent";
import CartSleeping from "../../../assets/images/cart-sleeping.svg?react";
import {Elements} from "@stripe/react-stripe-js";
import {PaymentReservationCheckoutForm} from "../../../pages/PaymentReservation/PaymentReservationCheckoutForm";
import {
  loadStripe,
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import {
  capitalizeFirstLetter,
  replaceAsteriskWithStrongBalise,
} from "../../../helpers/stringHelper";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import GuestPagePaymentFormSkeleton from "../GuestPagePayment/GuestPagePaymentFormSkeleton";
import GuestPagePaymentForm from "../GuestPagePayment/GuestPagePaymentForm";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import GuestPageConditions from "../Common/GuestPageConditions";
import {CenterModal} from "../../Common/CenterModal/CenterModal";
import {ReservationExtraOrderStatusEnum} from "../../../enums/GETenums";
import {navigateWithParams} from "../../../helpers/urlHelper";

const GuestPageExtraPayment = ({onChangeTab}: GuestPageTab) => {
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  const {guestPageData, storeGuestPageData} = useGuestPageDataStore();
  const {guest, rental, cgv} = guestPageData || {};
  const {currency} = rental || {};

  const {extrasValidateData, storeExtraValidateData, storeExtraOrder} =
    useExtrasStore();
  const {
    total_price_value,
    total_price_value_in_smaller_unit,
    extras,
    extras_order_confirmation_enabled,
  } = extrasValidateData || {};
  const [billingDetails, setBillingDetails] =
    useState<PaymentMethodCreateParams.BillingDetails>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();
  const [loadingPaymentExtraForm, setLoadingPaymentExtraForm] = useState(false);
  const [openCgv, setOpenCgv] = useState(false);
  const initStripeExtraPayment = () => {
    setLoadingPaymentExtraForm(true);
    if (extrasValidateData) {
      if (total_price_value && total_price_value > 0) {
        setStripePromise(
          loadStripe(import.meta.env.VITE_STRIPE_KEY, {
            stripeAccount: extrasValidateData.stripe_account_id,
          })
        );
        setStripeOptions({
          mode: "payment",
          amount: total_price_value_in_smaller_unit ?? 0,
          currency: currency?.code?.toLowerCase() ?? "eur",
          paymentMethodCreation: "manual",
          captureMethod: extras_order_confirmation_enabled
            ? "manual"
            : "automatic",
        });

        if (guest) {
          const {first_name, last_name, city, country} = guest;
          setBillingDetails({
            name: `${capitalizeFirstLetter(first_name)} ${capitalizeFirstLetter(
              last_name
            )}`,
            address: {
              city: capitalizeFirstLetter(city),
              country: capitalizeFirstLetter(country),
            },
          });
        }
      }
    }
    setLoadingPaymentExtraForm(false);
  };
  useEffect(() => {
    initStripeExtraPayment();
  }, []);

  const resetExtraOrder = (
    status: ReservationExtraOrderStatusEnum,
    response?: any
  ) => {
    const result = response as SendExtraOrderPaymentResponse;
    if (guestPageData) {
      storeGuestPageData({
        ...guestPageData,
        extras_order_historical:
          result?.extras_order_historical ??
          guestPageData?.extras_order_historical ??
          [],
      });
    }
    storeExtraOrder([]);
  };

  const closeSuccessMessage = () => {
    navigateWithParams(window.location.href, "tab", "extrasHistorical");
  };

  //Handling remove extra from cart
  const onChangeExtra = (extraOrder: ExtrasOrder, toRemove?: boolean) => {
    // if (toRemove) {
    //   if (extrasPayment) {
    //     setExtrasPayment(
    //       extrasPayment.filter((extra) => extra.id !== extraOrder.id)
    //     );
    //   }
    //   storeExtraOrder(
    //     extrasOrder.filter((extra) => extra.id !== extraOrder.id)
    //   );
    // }
  };

  useEffect(() => {
    if (!openCgv) {
      storeTitle(t("GuestPage.ExtrasPayment.title"));
      storeSubTitle &&
        storeSubTitle(
          nbExtras && nbExtras > 1
            ? t("GuestPage.ExtrasPayment.elementsInCart", {nb: nbExtras})
            : nbExtras === 1
            ? t("GuestPage.ExtrasPayment.elementInCart", {nb: nbExtras})
            : t("GuestPage.ExtrasPayment.elementInCartEmpty")
        );
    } else {
      storeTitle(t("GuestPage.ExtrasPayment.cgv"));
      storeSubTitle && storeSubTitle("");
    }
  }, [openCgv]);

  const nbExtras = extras?.length;
  const isEmpty = extras?.length === 0 || !extras;
  return (
    <div className="flex flex-col md:w-full">
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className="m-4 mt-8"
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() =>
          openCgv ? setOpenCgv(false) : onChangeTab && onChangeTab("extra")
        }
      >
        {t("GuestPage.ExtrasPayment.goBack")}
      </Button>

      <>
        <LayoutBlockComponent
          title={t("GuestPage.ExtrasPayment.title")}
          subTitle={
            nbExtras && nbExtras > 1
              ? t("GuestPage.ExtrasPayment.elementsInCart", {nb: nbExtras})
              : nbExtras === 1
              ? t("GuestPage.ExtrasPayment.elementInCart", {nb: nbExtras})
              : t("GuestPage.ExtrasPayment.elementInCartEmpty")
          }
          blockStyle={{
            titleStyle: "text-[32px] px-0",
            childrenDivStyle:
              "pl-2 md:w-full md:h-full border-b-0 px-0 pb-0 mx-0 md:flex-row md:flex-1 md:justify-between ",
            parentDivStyle: "md:w-full pb-0",
          }}
        >
          <CenterModal
            isVisible={openCgv}
            onClose={() => setOpenCgv(false)}
            title={t("GuestPage.ExtrasPayment.cgv")}
            classNames={{
              divParentModal: "px-12",
              divModal:
                "w-auto md:w-1/3 max-w-3xl max-h-1/2 md:max-h-auto h-1/2 md:h-1/2",
              divContent: "h-full",
            }}
          >
            <div
              className="whitespace-pre-line text-base md:text-lg max-h-96 overflow-y-auto px-4 py-2"
              dangerouslySetInnerHTML={{
                __html: replaceAsteriskWithStrongBalise(cgv ?? ""),
              }}
            />
          </CenterModal>
          <div className="md:flex  md:flex-col md:w-[45%] md:mt-8">
            {Boolean(extras_order_confirmation_enabled) && (
              <div className="flex mb-6 bg-element-border-active/10 p-2 rounded-md w-full flex-row">
                <AlertCircle
                  className={`fill-gray-500 me-2 shrink-0`}
                  width={26}
                  height={26}
                />
                <p className="text-xs font-light text-left text-low-contrast md:text-lg">
                  {t("GuestPage.ExtrasPayment.extraToValidateByHost")}
                </p>
              </div>
            )}
            {isEmpty ? (
              <div className="flex justify-center">
                <CartSleeping className="w-96 h-96" />
              </div>
            ) : (
              extras?.map((extra: GuestPageExtraDetail) => (
                <ExtraCardComponent
                  extra={extra}
                  guest={guest}
                  fromPage={"extraPayment"}
                  onChangeExtra={onChangeExtra}
                />
              ))
            )}
            {!isEmpty && (
              <div className="flex flex-1 text-high-contrast font-bold justify-between items-center">
                <span className="md:text-lg">
                  {t("GuestPage.ExtrasPayment.total")}
                </span>
                <h3 className="text-xxl">
                  {total_price_value} {currency?.symbol ?? "€"}
                </h3>
              </div>
            )}
          </div>
          <div className="my-8 md:w-1/2 md:w-[45%]">
            <GuestPagePaymentForm
              extrasData={extrasValidateData}
              setExtrasData={storeExtraValidateData}
              step="extraForm"
              loading={loadingPaymentExtraForm}
              billingDetails={billingDetails}
              stripePromise={stripePromise}
              stripeOptions={stripeOptions}
              showGoBack={false}
              showReservation={false}
              onSuccess={resetExtraOrder}
              childrenStyle="md:w-full"
              onAction={() => setOpenCgv(true)}
              onCloseSuccess={closeSuccessMessage}
            />
          </div>
        </LayoutBlockComponent>
        {isEmpty && (
          <div className="flex mx-3 items-end">
            <Button
              type="primary"
              buttonClassName="md:h-12"
              textClassName="md:text-xl"
              onClick={() => onChangeTab && onChangeTab("extra")}
            >
              {t("GuestPage.ExtrasPayment.goBackShop")}
            </Button>
          </div>
        )}
      </>
    </div>
  );
};

export default GuestPageExtraPayment;
