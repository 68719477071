/**
 * Page for show payments and
 * ticket information reservation
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { BookingInvoicePageProps } from "./BookingInvoicePage.type";
import { useParams } from "react-router-dom";
import paths from "../../constants/paths";
import { fetchOneReservation } from "../../helpers/reservationHelper";
import { useModal } from "../../hooks/useModal";
import { useTablePage } from "../../hooks/useTablePage";
import {
  Deposit,
  GuestResponse,
  Payment,
  PaymentReceiptsData,
  ReservationResponse,
  UserResponse,
} from "../../types/GETTypes";
import HandIcon from "../../assets/icons/giving-money.svg?react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import FileIcon from "../../assets/icons/file.svg?react";
import NavItem from "../../components/Booking/PaymentsTicket/NavItem/NavItem";
import PaymentsAndCaution from "../../components/Booking/PaymentsTicket/Menu/PaymentsAndCaution";
import Tickets from "../../components/Booking/PaymentsTicket/Menu/Tickets";
import RecapPaymentList from "../../components/Booking/PaymentsTicket/Menu/RecapPaymentList";
import SendTicketModal from "../../components/Booking/PaymentsTicket/Modals/SendTicketModal";
import TicketInformationModal from "../../components/Booking/PaymentsTicket/Modals/TicketInformationModal";
import { usePaymentReceipt } from "../../hooks/api/paymentReceipt";
import { ReceiptType } from "../../types/commonTypes";
import { Card } from "../../components/Common/Card/Card";
import SendReceiptTicket from "../../components/Booking/PaymentsTicket/Modals/SendReceiptTicket";
import SendCautionPayment from "../../components/Booking/PaymentsTicket/Modals/SendCautionPayment";

type MenuType = "payments" | "recap" | "tickets";

export const BookingInvoicePage: React.FC<BookingInvoicePageProps> = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();

  const modalSendTicket = useModal<{
    payment: Payment;
  }>();
  const modalSendReceipt = useModal<{
    receiptType: ReceiptType;
  }>();
  const modalSendCaution = useModal<{
    caution: Deposit;
  }>();

  const modalInfoTicket = useModal<{
    sender: UserResponse;
    customer: GuestResponse;
  }>();

  const [menu, setMenu] = useState<MenuType>("payments");
  const [paymentsReceipts, setPaymentsReceipts] =
    useState<PaymentReceiptsData>();
  const [send, setSend] = useState(false);

  const { getAll } = usePaymentReceipt();

  useEffect(() => {
    if (typeof reservationId === "string") {
      getAll(reservationId).then((response) => {
        setPaymentsReceipts(response);
      });
    }
  }, []);

  const [reservation, setReservation] = useState<
    ReservationResponse | undefined
  >();
  const tableMessages = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
    "messages",
    { combine: true, reverseData: true },
  );

  const handleReservationSuccess = (value: ReservationResponse) => {
    setReservation(value);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(reservationId, handleReservationSuccess, () => {});

      tableMessages.fetch({
        limit: 10,
        sort_field: "captured_at",
        sort_direction: "desc",
      });
    }
  }, [reservationId]);

  const getActions = () => {
    return <div className="flex flex-row gap-2"></div>;
  };

  // Payment & deposit links card
  const sendModal = useModal<{ index: number; isDeposit: boolean }>();

  const [sendSuccessed, setSendSuccessed] = useState<{
    payments: boolean[];
    deposit: boolean;
  }>({ payments: [false, false, false], deposit: false });

  const handleSendPaymentOrDepositLink = (
    data: { index: number; isDeposit: boolean } | undefined | null,
  ) => {
    if (data?.isDeposit) {
      setSendSuccessed({ ...sendSuccessed, deposit: true });
    } else {
      const nextPayments = JSON.parse(JSON.stringify(sendSuccessed?.payments));
      nextPayments[data?.index ?? 0] = true;
      setSendSuccessed({ ...sendSuccessed, payments: nextPayments });
    }

    // Close modal
    sendModal.close();
  };
  return (
    <BookingLayout
      navbarActiveItem="payments"
      rightChildren={getActions()}
      reservation={reservation}
    >
      <section className={"flex px-4"}>
        <nav className={"w-1/4 flex-col flex gap-4"}>
          <NavItem
            Icon={HandIcon}
            isActive={menu === "payments"}
            onClick={() => {
              setMenu("payments");
              setSend(false);
            }}
            text={"Booking.suiviePayment"}
          />
          <NavItem
            Icon={MoneyIcon}
            isActive={menu === "recap"}
            onClick={() => {
              setMenu("recap");
              setSend(false);
            }}
            text={"Booking.recapPayment"}
          />
          <NavItem
            Icon={FileIcon}
            isActive={menu === "tickets"}
            onClick={() => {
              setMenu("tickets");
              setSend(false);
            }}
            text={"Booking.recus"}
          />
        </nav>
        {paymentsReceipts ? (
          <div className={"w-2/4"}>
            <SendTicketModal
              onSubmit={() => {
                setSend(true);
              }}
              modal={modalSendTicket}
              reservationId={reservation?.id}
            />
            <SendReceiptTicket
              onSubmit={() => {
                setSend(true);
              }}
              modal={modalSendReceipt}
              reservationId={reservation?.id}
            />
            <SendCautionPayment
              onSubmit={() => {
                setSend(true);
              }}
              modal={modalSendCaution}
              reservationId={reservation?.id}
            />
            <TicketInformationModal
              modal={modalInfoTicket}
              payments={paymentsReceipts.payment_summaries}
              modalSend={modalSendReceipt}
              reservationId={reservationId as string}
            />

            {menu === "payments" && (
              <>
                {send && (
                  <Card
                    hasBody={false}
                    label={t("Booking.paymentSend")}
                    classNames={{
                      root: "bg-success border-success mb-4 text-white",
                      label: "text-lg font-normal",
                    }}
                  />
                )}
                <PaymentsAndCaution
                  receipts={paymentsReceipts}
                  modalSendTicket={modalSendTicket}
                  modalSendTicketCaution={modalSendCaution}
                  onClick={() => {
                    modalInfoTicket.open({
                      sender: paymentsReceipts.metadata.sender,
                      customer: paymentsReceipts.metadata.customer,
                    });
                  }}
                />
              </>
            )}
            {menu === "recap" && (
              <>
                {send && (
                  <Card
                    hasBody={false}
                    label={t("Booking.receiptSend")}
                    classNames={{
                      root: "bg-success border-success mb-4 text-white",
                      label: "text-lg font-normal",
                    }}
                  />
                )}
                <RecapPaymentList
                  modal={modalSendReceipt}
                  payments={paymentsReceipts.payment_summaries}
                />
              </>
            )}
            {menu === "tickets" && reservationId && (
              <>
                {send && (
                  <Card
                    hasBody={false}
                    label={t("Booking.receiptSend")}
                    classNames={{
                      root: "bg-success border-success mb-4 text-white",
                      label: "text-lg font-normal",
                    }}
                  />
                )}
                <Tickets
                  modal={modalSendReceipt}
                  reservationId={reservationId}
                  receipts={paymentsReceipts.receipts}
                />
              </>
            )}
          </div>
        ) : (
          <div className="flex flex-col w-1/2">
            <p className="font-semibold text-high-contrast rounded bg-slate-200  h-[700px] animate-pulse"></p>
          </div>
        )}
      </section>
    </BookingLayout>
  );
};
