import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { Rental } from "../../../../pages/RentalPage/RentalPage.type";
import {
  RentalLayoutMenuSections,
  RentalLayoutSubMenuSections,
  RentalNavigationMenuItem,
} from "./RentalLayoutNavbar.type";
import useUserStore from "../../../../stores/useUserStore";
import { hasPermissionWithUser } from "../../../../helpers/workspaceHelper";

/**
 * Generates an array of navbar items for the rental layout.
 *
 * @param {RentalLayoutMenuSections} activeItem - The currently active navbar item.
 * @returns {Array<RentalNavigationMenuItem>} An array of navbar item objects, each containing a value, label, and isActive status.
 */
export const useRentalLayoutNavbarItems = (
  activeItem: RentalLayoutMenuSections
): RentalNavigationMenuItem[] => {
  const { t } = useTranslation();
  const userStore = useUserStore();

  const navbarItems = useMemo(() => {
    const items: {
      value: RentalLayoutMenuSections;
      labelKey: string;
    }[] = [{ value: "infos", labelKey: "Rental.infos" }];

    if (hasPermissionWithUser(userStore.user, "rental_tarification"))
      items.push({ value: "prices", labelKey: "Rental.prices" });

    if (hasPermissionWithUser(userStore.user, "rental_document"))
      items.push({ value: "contract", labelKey: "Rental.contract" });

    if (hasPermissionWithUser(userStore.user, "rental_service"))
      items.push({ value: "services", labelKey: "Rental.services" });

    if (hasPermissionWithUser(userStore.user, "rental_document"))
      items.push({ value: "invoice", labelKey: "Rental.invoice" });

    if (hasPermissionWithUser(userStore.user, "rental_checklist"))
      items.push({ value: "checklists", labelKey: "Rental.checklists" });

    if (hasPermissionWithUser(userStore.user, "rental_guest_page"))
      items.push({ value: "traveler", labelKey: "Rental.traveler_page" });

    return items.map((item) => ({
      value: item.value,
      label: t(item.labelKey),
      isActive: activeItem === item.value,
    }));
  }, [activeItem, t]);

  return navbarItems;
};

/**
 * Generates an array of group button items for the rental layout.
 *
 * @param {RentalLayoutSubNavbarActiveItemType} activeSubItem - The currently active sub-navbar item.
 * @returns {Array<RentalNavigationMenuItem>} An array of group button item objects, each containing a value, label, and isActive status.
 */
export const useRentalLayoutNavbarSubItems = (
  activeItem: RentalLayoutMenuSections,
  activeSubItem: RentalLayoutSubMenuSections
): RentalNavigationMenuItem[] => {
  const navbarSubItems = useMemo(() => {
    if (activeItem !== "prices") {
      return [];
    }

    const items: { value: RentalLayoutSubMenuSections; label: string }[] = [
      { value: "pricing_rules", label: "Règles de tarification" },
      { value: "policies_and_seasons", label: "Politiques et saisons" },
      { value: "fees_and_promotions", label: "Frais et promotions" },
    ];

    return items.map((item) => ({
      value: item.value,
      label: item.label,
      isActive: activeSubItem === item.value,
    }));
  }, [activeItem, activeSubItem]);

  return navbarSubItems;
};

/**
 * Custom hook to handle click events on navbar items.
 *
 * @param {ValueType} value - The value associated with the clicked navbar item.
 * @param {Rental | undefined} rental - The rental object, if available.
 * @param {(value: RentalLayoutMenuSections) => void} onActiveItem - Callback function to set the active item.
 */
export const useHandleClickNavbarItem = (
  value: ValueType,
  rental: Rental | undefined,
  onActiveItem: (value: RentalLayoutMenuSections) => void
) => {
  if (!rental) return;

  const casted = value as RentalLayoutMenuSections;

  onActiveItem(casted);
};

/**
 * Custom hook to handle click events on navbar sub-items.
 *
 * @param {ValueType} value - The value associated with the clicked sub-item.
 * @param {Rental | undefined} rental - The rental object, if available.
 * @param {(value: RentalLayoutSubMenuSections) => void} onActiveSubItem - Callback function to set the active sub-item.
 */
export const useHandleClickNavbarSubItem = (
  value: ValueType,
  rental: Rental | undefined,
  onActiveSubItem: (value: RentalLayoutSubMenuSections) => void
) => {
  if (!rental) return;

  const casted = value as RentalLayoutSubMenuSections;

  onActiveSubItem(casted);
};
