import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import {
  RentalAdditionalCostForm,
  RentalAdditionalCostUpdateRequest,
} from "./RentalAdditionalCost.type";
import { RentalAdditionalCostSkeleton } from "./RentalAdditionalCostSkeleton";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import AnimalIcon from "../../../../assets/icons/animal.svg?react";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import BedIcon from "../../../../assets/icons/bed.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import CleanIcon from "../../../../assets/icons/clean.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import SingleBedIcon from "../../../../assets/icons/single-bed.svg?react";
import UsersIcon from "../../../../assets/icons/users.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { RequiredFields, ValueType } from "../../../../types/commonTypes";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const RentalAdditionalCost: React.FC<{
  workspaceOwner: ValueType | undefined;
}> = ({ workspaceOwner }) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const { rentalId } = useParams();
  const {
    loadingState: { loading },
    rental,
    onUpdateRental,
  } = useRentalPageContext();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
  } = useForm<RentalAdditionalCostForm>({
    mode: "all",
  });

  const requiredFields: RequiredFields<RentalAdditionalCostForm> = {
    cleaning_default: true,
    infant_bed_default: true,
    linen_default: true,
    pet_default: true,
    price_night_added_per_guests: true,
    guests_default_capacity: true,
  };

  useEffect(() => {
    console.log(
      "rental fees --------------------- ",
      rental?.tarification?.fees_discounts,
    );
    setValue(
      "cleaning_default",
      rental?.tarification?.fees_discounts?.additional_fees.cleaning_default ??
        0,
    );
    setValue(
      "infant_bed_default",
      rental?.tarification?.fees_discounts?.additional_fees
        .infant_bed_default ?? 0,
    );
    setValue(
      "linen_default",
      rental?.tarification?.fees_discounts?.additional_fees.linen_default ?? 0,
    );
    setValue(
      "pet_default",
      rental?.tarification?.fees_discounts?.additional_fees.pet_default ?? 0,
    );
    setValue(
      "price_night_added_per_guests",
      rental?.tarification?.fees_discounts?.additional_fees
        .price_night_added_per_guests ?? 0,
    );
    setValue(
      "guests_default_capacity",
      rental?.details.informations.guests_default_capacity ?? 0,
    );
  }, [rental?.tarification?.fees_discounts]);

  const handleSave = async () => {
    setValidationLoading(true);
    const data: RentalAdditionalCostUpdateRequest = {
      cleaning_default: String(getValues("cleaning_default")),
      infant_bed_default: String(getValues("infant_bed_default")),
      linen_default: String(getValues("linen_default")),
      pet_default: String(getValues("pet_default")),
      price_night_added_per_guests: String(
        getValues("price_night_added_per_guests"),
      ),
      guests_default_capacity: String(getValues("guests_default_capacity")),
    };
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_ADDITIONAL_FEES
      }`,
      data,
    );

    if (res?.data?.success) {
      setEditMode(!editMode);
      if (rental) {
        onUpdateRental({
          ...rental,
          details: {
            ...rental.details,
            informations: {
              ...rental.details.informations,
              guests_default_capacity: getValues("guests_default_capacity"),
            },
          },
          tarification: {
            ...rental.tarification,
            fees_discounts: {
              additional_fees: {
                cleaning_default: getValues("cleaning_default"),
                infant_bed_default: getValues("infant_bed_default"),
                linen_default: getValues("linen_default"),
                pet_default: getValues("pet_default"),
                price_night_added_per_guests: getValues(
                  "price_night_added_per_guests",
                ),
              },
              discounts: [],
            },
          },
        });
      }
    } else {
      setError(res?.response?.data?.message);
    }
    setValidationLoading(false);
  };

  const isEditDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return editMode && (!isValid || validationLoading);
  };

  watch();

  if (loading) return <RentalAdditionalCostSkeleton />;

  return (
    <Card
      Icon={MoneyIcon}
      label={t("Rental.Pricing.AdditionalCost.title")}
      anchor="additional-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? handleSubmit(handleSave)
          : () => setEditMode(!editMode),
        disabled: isEditDisabled(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {editMode ? (
          <div className="flex flex-col gap-y-3">
            {/* CLEANING DEFAULT */}
            <SettingElement
              Icon={CleanIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostCleaningTitle",
              )}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostCleaningSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostCleaningTooltip",
                  )}
                />
              }
            >
              <div className="w-34">
                <NumberInput
                  required={requiredFields.cleaning_default}
                  disabled={validationLoading}
                  acceptDecimal={false}
                  currency={t("Global.currencySymbol")}
                  register={register("cleaning_default", {
                    required: {
                      value: requiredFields.cleaning_default,
                      message: t(
                        "Rental.Pricing.AdditionalCost.additionalCostCleaningError",
                      ),
                    },
                  })}
                  error={errors.cleaning_default?.message}
                  value={getValues("cleaning_default")}
                />
              </div>
            </SettingElement>

            {/* LINEN DEFAULT */}
            <SettingElement
              Icon={BedIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostLinenTitle",
              )}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostLinenSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostLinenTooltip",
                  )}
                />
              }
            >
              <div className="w-34">
                <NumberInput
                  required={requiredFields.linen_default}
                  disabled={validationLoading}
                  acceptDecimal={false}
                  currency={t("Global.currencySymbol")}
                  register={register("linen_default", {
                    required: {
                      value: requiredFields.linen_default,
                      message: t(
                        "Rental.Pricing.AdditionalCost.additionalCostLinenError",
                      ),
                    },
                  })}
                  error={errors.linen_default?.message}
                  value={getValues("linen_default")}
                />
              </div>
            </SettingElement>

            {/* PRICE NIGHT ADDED PER GUEST */}
            <div>
              <SettingElement
                Icon={UsersIcon}
                title={t(
                  "Rental.Pricing.AdditionalCost.additionalCostGuestTitle",
                )}
                description={t("Rental.Pricing.PricingRules.ToGuestFees", {
                  count: getValues("guests_default_capacity"),
                })}
                labelTooltip={
                  <Tooltip
                    value={t(
                      "Rental.Pricing.AdditionalCost.additionalCostGuestTooltip",
                    )}
                  />
                }
                secondChildren={
                  <div className={"flex justify-end"}>
                    <div className={"flex w-34 flex-col gap-2"}>
                      <p className={"w-[150px] font-bold"}>
                        {t("Rental.Pricing.PricingRules.toGuests")}
                      </p>
                      <NumberInput
                        required={requiredFields.guests_default_capacity}
                        disabled={validationLoading}
                        acceptDecimal={false}
                        currency={"👤"}
                        register={register("guests_default_capacity", {
                          required: {
                            value: requiredFields.guests_default_capacity,
                            message: t(
                              "Rental.Pricing.AdditionalCost.additionalCostGuestError",
                            ),
                          },
                        })}
                        error={errors.guests_default_capacity?.message}
                        value={getValues("guests_default_capacity")}
                      />
                    </div>
                  </div>
                }
              >
                <div className="w-34">
                  <NumberInput
                    required={requiredFields.price_night_added_per_guests}
                    disabled={validationLoading}
                    acceptDecimal={false}
                    currency={t("Global.currencySymbol")}
                    register={register("price_night_added_per_guests", {
                      required: {
                        value: requiredFields.price_night_added_per_guests,
                        message: t(
                          "Rental.Pricing.AdditionalCost.additionalCostGuestError",
                        ),
                      },
                    })}
                    error={errors.price_night_added_per_guests?.message}
                    value={getValues("price_night_added_per_guests")}
                  />
                </div>
              </SettingElement>
            </div>

            {/* PET DEFAULT */}
            <SettingElement
              Icon={AnimalIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostAnimalTitle",
              )}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostAnimalSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostAnimalTooltip",
                  )}
                />
              }
            >
              <div className="w-34">
                <NumberInput
                  required={requiredFields.pet_default}
                  disabled={validationLoading}
                  acceptDecimal={false}
                  currency={t("Global.currencySymbol")}
                  register={register("pet_default", {
                    required: {
                      value: requiredFields.pet_default,
                      message: t(
                        "Rental.Pricing.AdditionalCost.additionalCostAnimalError",
                      ),
                    },
                  })}
                  error={errors.pet_default?.message}
                  value={getValues("pet_default")}
                />
              </div>
            </SettingElement>

            {/* INFANT BED DEFAULT */}
            <SettingElement
              Icon={SingleBedIcon}
              title={t("Rental.Pricing.AdditionalCost.additionalCostCotTitle")}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostCotSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostCotTooltip",
                  )}
                />
              }
            >
              <div className="w-34">
                <NumberInput
                  required={requiredFields.infant_bed_default}
                  disabled={validationLoading}
                  acceptDecimal={false}
                  currency={"€"}
                  register={register("infant_bed_default", {
                    required: {
                      value: requiredFields.infant_bed_default,
                      message: t(
                        "Rental.Pricing.AdditionalCost.additionalCostCotError",
                      ),
                    },
                  })}
                  error={errors.infant_bed_default?.message}
                  value={getValues("infant_bed_default")}
                />
              </div>
            </SettingElement>
          </div>
        ) : (
          <div className="flex flex-col gap-y-3">
            {/* CLEANING DEFAULT */}
            <SettingElement
              Icon={CleanIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostCleaningTitle",
              )}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostCleaningSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostCleaningTooltip",
                  )}
                />
              }
            >
              <span className="text-lg font-bold text-active">
                {rental?.tarification?.fees_discounts?.additional_fees
                  .cleaning_default
                  ? `${
                      rental?.tarification?.fees_discounts?.additional_fees
                        .cleaning_default
                    } ${t("Global.currencySymbol")}`
                  : t("Global.notDefined")}
              </span>
            </SettingElement>

            {/* LINEN DEFAULT */}
            <SettingElement
              Icon={BedIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostLinenTitle",
              )}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostLinenSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostLinenTooltip",
                  )}
                />
              }
            >
              <span className="text-lg font-bold text-active">
                {rental?.tarification?.fees_discounts?.additional_fees
                  .linen_default
                  ? `${
                      rental?.tarification?.fees_discounts?.additional_fees
                        .linen_default
                    } ${t("Global.currencySymbol")}`
                  : t("Global.notDefined")}
              </span>
            </SettingElement>

            {/* PRICE NIGHT ADDED PER GUEST */}
            <SettingElement
              Icon={UsersIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostGuestTitle",
              )}
              description={t("Rental.Pricing.PricingRules.ToGuestFees", {
                count: getValues("guests_default_capacity"),
              })}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostGuestTooltip",
                  )}
                />
              }
            >
              <span className="text-lg font-bold text-active">
                {rental?.tarification?.fees_discounts?.additional_fees
                  .price_night_added_per_guests
                  ? t("Rental.Pricing.AdditionalCost.costByDayLabel", {
                      count:
                        rental?.tarification?.fees_discounts?.additional_fees
                          .price_night_added_per_guests ?? 0,
                    })
                  : t("Global.notDefined")}
              </span>
            </SettingElement>

            {/* PET DEFAULT */}
            <SettingElement
              Icon={AnimalIcon}
              title={t(
                "Rental.Pricing.AdditionalCost.additionalCostAnimalTitle",
              )}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostAnimalSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostAnimalTooltip",
                  )}
                />
              }
            >
              <span className="text-lg font-bold text-active">
                {rental?.tarification?.fees_discounts?.additional_fees
                  .pet_default
                  ? `${
                      rental?.tarification?.fees_discounts?.additional_fees
                        .pet_default
                    } ${t("Global.currencySymbol")}`
                  : t("Global.notDefined")}
              </span>
            </SettingElement>

            {/* INFANT BED DEFAULT */}
            <SettingElement
              Icon={SingleBedIcon}
              title={t("Rental.Pricing.AdditionalCost.additionalCostCotTitle")}
              description={t(
                "Rental.Pricing.AdditionalCost.additionalCostCotSubtitle",
              )}
              labelTooltip={
                <Tooltip
                  value={t(
                    "Rental.Pricing.AdditionalCost.additionalCostCotTooltip",
                  )}
                />
              }
            >
              <span className="text-lg font-bold text-active">
                {rental?.tarification?.fees_discounts?.additional_fees
                  .infant_bed_default
                  ? `+${rental?.tarification?.fees_discounts?.additional_fees.infant_bed_default} ${t("Global.currencySymbol")}`
                  : t("Global.notDefined")}
              </span>
            </SettingElement>
          </div>
        )}
      </div>
    </Card>
  );
};
