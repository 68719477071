import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import {
  PlatformToActive,
  RentalConfirmPlatform,
  RentalPlatformsCardProps,
} from "./Platforms.type";

import { useParams } from "react-router-dom";
import PlugIcon from "../../../../assets/icons/plug.svg?react";
import paths from "../../../../constants/paths";
import { get, post } from "../../../../helpers/APIHelper";
import { capitalizeFirstLetter } from "../../../../helpers/stringHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import useProgressionStore from "../../../../stores/useProgressStore";
import {
  RentalLightListItemResponse,
  RentalPlatformAirbnbListingResponse,
} from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import { PlatformConfirmModal } from "../../../Modal/Airbnb/Connect/PlatformConfirmModal";
import { PlatformConnectSingleRentalModal } from "../../../Modal/Airbnb/Connect/PlatformConnectSingleRentalModal";
import {
  ProgressionBooking,
  ProgressionStatusEnum,
} from "../../../Progression/Progression.type";
import { BookingRulesSyncWaitingModal } from "./BookingRulesSyncWaitingModal";
import { CancelSynchroPlaformModal } from "./CancelSynchroPlatformModal";
import { RentalPlatformsAirbnbSettingsModal } from "./PlatformsAirbnbSettingsModal";
import { RentalPlatformsBookingSettingsModal } from "./PlatformsBookingSettingsModal";
import { RentalPlatformsCardAirbnb } from "./PlatformsCardAirbnb";
import { RentalPlatformsCardBooking } from "./PlatformsCardBooking";
import { RentalPlatformsCardSkeleton } from "./PlatformsCardSkeleton";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";
import { ExternalPlatformsCard } from "./Externals/ExternalPlatformsCard";
import CopyIcon from "../../../../assets/icons/copy.svg?react";
import SuccessIcon from "../../../../assets/icons/check-success.svg?react";
import Paths from "../../../../constants/paths";

export const RentalPlatformsCard: React.FC<RentalPlatformsCardProps> = ({
  externalConnections,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { rentalId } = useParams();
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const {
    loadingState: { loading },
    rental,
    onUpdateRental,
  } = useRentalPageContext();

  const [loadingData, setLoadingData] = useState(false);
  const [header, setHeader] = useState("");

  const [airBnbListingData, setAirBnbListingData] =
    useState<RentalPlatformAirbnbListingResponse>();

  const [isAirbnbSettingsVisible, setAirbnbSettingsVisible] =
    useState<boolean>(false);

  const [isBookingSettingsVisible, setBookingSettingsVisible] =
    useState<boolean>(false);

  const [openDesynchronizeModal, setOpenDesynchronizeModal] = useState(false);
  const [confirmPlatform, setConfirmPlatform] =
    useState<RentalConfirmPlatform>("");

  const [keep_historic_enabled, setKeepHistoricEnabled] = useState(1);

  const [loaderDesynchronize, setLoaderDesynchronize] = useState(false);

  const [error, setError] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const fetchAirBnbAccountData = async () => {
    const isEnabled = externalConnections?.platform_airbnb?.enabled;
    console.log("🚀 ~ fetchAirBnbAccountData ~ isEnabled:", isEnabled);
    if (isEnabled) {
      setLoadingData(true);
      const airbnb_account_id =
        externalConnections?.platform_airbnb?.airbnb_account_id;
      const res = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.AIRBNB.ACCOUNT_LISTING
        }?airbnb_account_id=${airbnb_account_id}&rental_id=${rentalId}`
      );
      if (res?.data?.success) {
        console.log("🚨[fetchAirBnbAccountData] res", res);
        setAirBnbListingData(res?.data?.result);
      }
      setLoadingData(false);
    }
  };

  const fetchAirbnbListings = async () => {
    setLoadingData(true);
    const airbnb_account_id =
      externalConnections?.platform_airbnb?.airbnb_account_id;
    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.AIRBNB.ACCOUNT_LISTING
      }?airbnb_account_id=${airbnb_account_id}&rental_id=${rentalId}`
    );
    if (res?.data?.success) {
      console.log("🚨[fetchAirBnbAccountData] res", res);
      setAirBnbListingData(res?.data?.result);
    }
    setLoadingData(false);
  };

  const handleAirbnbDesynchronize = async () => {
    setLoaderDesynchronize(true);
    const resSynchro = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.AIRBNB.DISABLE_SYNCHRONIZATION
      }`,
      { rental_id: rentalId, keep_historic_enabled }
    );
    if (resSynchro?.data?.success) {
      if (externalConnections?.platform_airbnb) {
        if (rental && externalConnections?.platform_airbnb !== null) {
          onUpdateRental({
            ...rental,
            details: {
              ...rental.details,
              external_connexions: {
                ...rental.details.external_connexions,
                platform_airbnb: {
                  ...externalConnections.platform_airbnb,
                  enabled: 0,
                },
              },
            },
          });
        }
      }
      setOpenDesynchronizeModal(false);
      setAirbnbSettingsVisible(false);
    } else {
      setError(resSynchro?.response?.data?.message);
    }
    setLoaderDesynchronize(false);
  };

  const handleBookingDesynchronize = async () => {
    setLoaderDesynchronize(true);
    const resSynchro = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.BOOKING.DISABLE_SYNCHRONIZATION
      }`,
      { rental_id: rentalId, keep_historic_enabled }
    );
    const resDeconnect = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.BOOKING.DECONNECT_RENTAL}`,
      { rental_id: rentalId }
    );
    setLoaderDesynchronize(false);
    if (resSynchro?.data?.success && resDeconnect?.data?.success) {
      if (externalConnections?.platform_booking) {
        if (rental && externalConnections.platform_booking !== null) {
          onUpdateRental({
            ...rental,
            details: {
              ...rental.details,
              external_connexions: {
                ...rental.details.external_connexions,
                platform_booking: {
                  ...externalConnections.platform_booking,
                  enabled: 0,
                },
              },
            },
          });
        }
      }
      setOpenDesynchronizeModal(false);
      setBookingSettingsVisible(false);
    } else {
      setError(
        resSynchro?.response?.data?.message +
          "\n" +
          resDeconnect?.response?.data?.message
      );
    }
  };

  const handleDesynchronizeByPlatform = () => {
    switch (confirmPlatform) {
      case "booking":
        handleBookingDesynchronize();
        break;
      case "airbnb":
        handleAirbnbDesynchronize();
        break;
      default:
        break;
    }
  };

  const closeConfirmModal = () => {
    setConfirmPlatform("");
    setKeepHistoricEnabled(1);
    setOpenDesynchronizeModal(false);
  };

  const [isBookingConnectModalVisible, setBookingConnectModalVisible] =
    useState(false);
  const [isAirbnbConnectModalVisible, setAirbnbConnectModalVisible] =
    useState(false);
  const [displayBookingConnectModal, setDisplayBookingConnectModal] =
    useState(false);
  const [displayCancelSynchroBooking, setDisplayCancelSynchroBooking] =
    useState(false);
  const [platformToActive, setPlatformToActive] =
    useState<PlatformToActive>("");

  const handleConfirmPlaformModal = (
    airbnb_account_id = null,
    listing_id = null
  ) => {
    console.log("🚨[handleConfirmPlaformModal]");
    console.log(
      "🚨[handleConfirmPlaformModal] airbnb_account_id",
      airbnb_account_id
    );
    console.log("🚨[handleConfirmPlaformModal] listing_id", listing_id);
    setBookingConnectModalVisible(false);
    setDisplayBookingConnectModal(true);
    startSynchronisation(airbnb_account_id, listing_id);
  };
  const handleCloseSynchroModal = () => {
    setDisplayBookingConnectModal(false);
    setDisplayCancelSynchroBooking(false);
  };

  const [currentProgression, setCurrentProgression] =
    useState<ProgressionBooking>({
      progressText: "",
      status: ProgressionStatusEnum.INCOMPLETE,
      progress: null,
    });

  const { triggerRefreshProgression } = useProgressionStore();

  const updateCurrentProgression = (response: any) => {
    const result = response?.data?.result;
    console.log("🚨[updateCurrentProgression] result", result);
    setCurrentProgression({
      status: result?.synchronization_step_status,
      progress: result?.synchronization_step_pending_progress_percents,
      progressText: result?.synchronization_step_pending_progress_text,
    });
  };

  const loopProgressionStatus = () => {
    console.log("START LOOOOOOP", "🚨[loopProgressionStatus]");
    const interval = setInterval(async () => {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${
          platformToActive === "booking" ? "/booking/" : "/airbnb/"
        }${paths.API.GET_PROGRESSION}/${rentalId}`
      );
      updateCurrentProgression(response);
      triggerRefreshProgression();

      if (
        response.data?.success &&
        response.data?.result?.synchronization_step_status ===
          ProgressionStatusEnum.COMPLETE
      ) {
        clearInterval(interval);
        setHeader("success");
        const result = response.data?.result;

        console.log(
          "🚨[loopProgressionStatus] result",
          result.rental.details.external_connexions
        );
        setAirBnbListingData(
          result.rental.details.external_connexions.platform_airbnb
        );
        onUpdateRental({
          ...rental,
          details: {
            ...rental?.details,
            external_connexions: {
              ...result.rental.details.external_connexions,
            },
          },
        });
        console.log("FINISH LOOOOOOP");
      } else {
        setError(response?.response?.data?.message);
      }
    }, 2 * 1000);
  };

  const startSynchronisation = async (airbnb_account_id = null, listing_id:number | null) => {
    console.log("🚨[startSynchronisation]", platformToActive);
    const response = await post(
      `${import.meta.env.VITE_API_URL}${
        platformToActive === "booking" ? "/booking/" : "/airbnb/"
      }${paths.API.START_SYNCHRONIZATION}`,
      {
        rental_id: rental && rental.id,
        airbnb_account_id: airbnb_account_id,
        listing_id: listing_id,
      }
    );
    setHeader("");
    console.log("🚨[startSynchronisation] response");
    if (response?.data?.success) {
      loopProgressionStatus();
    } else {
      setError(response?.response?.data?.message);
    }
  };

  const handleSynchro = () => {
    console.log("🚨[handleSynchro]");
    setBookingConnectModalVisible(true);
    setDisplayCancelSynchroBooking(false);
    setIsVisible(false);
    setHeader("");
  };

  useEffect(() => {
    fetchAirBnbAccountData();
  }, [externalConnections?.platform_airbnb?.enabled]);

  if (loading || loadingData) return <RentalPlatformsCardSkeleton />;

  const handleCancelPlatformModal = () => {
    //console.log('🚨[handleCancelBookingModal]');
    setBookingConnectModalVisible(false);
    setDisplayCancelSynchroBooking(true);
  };

  const handleClickPlatformActive = (platform: PlatformToActive) => {
    console.log("🚨[handleClickPlatformActive]");
    setPlatformToActive(platform);
    setIsVisible(true);
  };

  return (
    <>
      <RentalPlatformsAirbnbSettingsModal
        rentalId={rentalId}
        platformAirbnb={externalConnections?.platform_airbnb!}
        airBnbListingData={airBnbListingData}
        isVisible={isAirbnbSettingsVisible}
        onAirbnbDesynchronize={handleAirbnbDesynchronize}
        onClose={() => setAirbnbSettingsVisible(false)}
        setPlatform={setConfirmPlatform}
        setOpenDesynchronizeModal={setOpenDesynchronizeModal}
      />

      <RentalPlatformsBookingSettingsModal
        rentalId={rentalId}
        platformBooking={externalConnections?.platform_booking!}
        isVisible={isBookingSettingsVisible}
        onBookingDesynchronize={handleBookingDesynchronize}
        onClose={() => setBookingSettingsVisible(false)}
        setPlatform={setConfirmPlatform}
        setOpenDesynchronizeModal={setOpenDesynchronizeModal}
      />

      <CenterModal
        isVisible={openDesynchronizeModal}
        onClose={closeConfirmModal}
        title={t("Rental.Infos.Platforms.ConfirmDesynchronizeModal.title", {
          platform: capitalizeFirstLetter(confirmPlatform),
        })}
      >
        <p className="pb-4 text-sm font-light text-low-contrast">
          {t("Rental.Infos.Platforms.ConfirmDesynchronizeModal.description")}
        </p>
        <p className="pb-4 text-sm font-light text-low-contrast">
          {t("Rental.Infos.Platforms.ConfirmDesynchronizeModal.description2")}
        </p>

        <div className="flex flex-col gap-3 mt-2 mb-4 text-sm font-semibold text-low-contrast">
          <div
            className="flex items-center gap-1 cursor-pointer"
            onClick={() => setKeepHistoricEnabled(0)}
          >
            <SimpleRadio value={keep_historic_enabled === 0} />
            <div className="flex flex-col ml-2">
              <p className="text-high-contrast">
                {t(
                  "Rental.Infos.Platforms.ConfirmDesynchronizeModal.deleteFutureReservations"
                )}
              </p>
              <p>
                {t(
                  "Rental.Infos.Platforms.ConfirmDesynchronizeModal.deleteFutureReservationsDescription",
                  {
                    platform: capitalizeFirstLetter(confirmPlatform),
                  }
                )}
              </p>
            </div>
          </div>

          <div
            className="flex items-center gap-1 mt-2 cursor-pointer"
            onClick={() => setKeepHistoricEnabled(1)}
          >
            <SimpleRadio value={keep_historic_enabled === 1} />
            <div className="flex flex-col ml-2">
              <p className="text-high-contrast">
                {t(
                  "Rental.Infos.Platforms.ConfirmDesynchronizeModal.keepFutureReservations"
                )}
              </p>
              <p>
                {t(
                  "Rental.Infos.Platforms.ConfirmDesynchronizeModal.keepFutureReservationsDescription"
                )}
              </p>
            </div>
          </div>
        </div>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        <div className="flex gap-3">
          <Button
            type="secondary"
            onClick={closeConfirmModal}
            disabled={loaderDesynchronize}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="destructive"
            onClick={handleDesynchronizeByPlatform}
            loading={loaderDesynchronize}
          >
            {t("Rental.Infos.Platforms.desynchronize")}
          </Button>
        </div>
      </CenterModal>
      <Card
        Icon={PlugIcon}
        label={t("Rental.Infos.Platforms.title")}
        anchor="platforms-card"
        button={{
          label: t(
            "Rental.Infos.Platforms." + (isCopied ? "isCopyIcal" : "copyIcal")
          ),
          Icon: isCopied ? SuccessIcon : CopyIcon,
          disabled: isCopied,
          onClick: () => {
            get(
              `${import.meta.env.VITE_API_URL}${Paths.API.RENTAL.FIND_BY_ID(
                rentalId
              )}`
            ).then((response) => {
              const icalendarLink =
                response?.data?.result?.details?.external_connexions
                  ?.superhote_icalendar_link;
              if (icalendarLink) {
                navigator.clipboard.writeText(icalendarLink).then(() => {
                  setIsCopied(true);
                });
              } else {
                post(
                  `${import.meta.env.VITE_API_URL}${
                    Paths.API.ICALENDAR.EXPORT
                  }`,
                  {
                    rental_id: rentalId,
                  }
                ).then((response) => {
                  if (response.data.success) {
                    get(
                      `${
                        import.meta.env.VITE_API_URL
                      }${Paths.API.RENTAL.FIND_BY_ID(rentalId)}`
                    ).then((response) => {
                      const icalendarLink =
                        response?.data?.result?.details?.external_connexions
                          ?.superhote_icalendar_link;
                      if (icalendarLink) {
                        navigator.clipboard
                          .writeText(icalendarLink)
                          .then(() => {
                            setIsCopied(true);
                          });
                      }
                    });
                  }
                });
              }
            });
          },
        }}
      >
        <div className="flex flex-col w-full gap-2">
          <p className="font-semibold text-low-contrast">
            {t("Rental.Infos.Platforms.bookingPlatforms")}
          </p>

          <RentalPlatformsCardAirbnb
            platformAirbnb={externalConnections?.platform_airbnb!}
            airBnbListingData={airBnbListingData}
            disabled={
              !getCurrentCanEdit({ user: userStore.user, workspaceOwner })
            }
            onClick={() =>
              externalConnections?.platform_airbnb?.enabled
                ? setAirbnbSettingsVisible(true)
                : handleClickPlatformActive("airbnb")
            }
          />

          <RentalPlatformsCardBooking
            platformBooking={externalConnections?.platform_booking!}
            disabled={
              !getCurrentCanEdit({ user: userStore.user, workspaceOwner })
            }
            onClick={() =>
              externalConnections?.platform_booking?.enabled
                ? setBookingSettingsVisible(true)
                : handleClickPlatformActive("booking")
            }
          />

          <ExternalPlatformsCard rentalId={rentalId} />

          {/* TODO: [RentalPlatformsCard] Implement VRBO on the next time */}
          {/* <RentalPlatformsCardVRBO rental={rental} /> */}

          {/* TODO: [RentalPlatformsCard] Implement after MVP */}
          {/* <Separator />

          <p className="font-semibold text-low-contrast">
            {t("Rental.Infos.Platforms.ical")}
          </p>

          <Button
            type="secondary"
            RightIcon={LinkIcon}
            onClick={() => navigator.clipboard.writeText("lien_ical")}
          >
            {t("Rental.Infos.Platforms.copyIcal")}
          </Button>
          */}
          <PlatformConfirmModal
            isVisible={isVisible}
            onClose={() => {
              setIsVisible(false);
            }}
            handleConfirm={handleSynchro}
            platformActive={platformToActive}
          />
          <PlatformConnectSingleRentalModal
            isVisible={isBookingConnectModalVisible}
            onClose={handleCancelPlatformModal}
            onFinish={handleConfirmPlaformModal}
            rental={(rental as RentalLightListItemResponse) ?? undefined}
            platformActive={platformToActive}
          />
          <CancelSynchroPlaformModal
            isVisible={displayCancelSynchroBooking}
            onClose={() => {
              setDisplayCancelSynchroBooking(false);
            }}
            handleConfirm={handleSynchro}
            platformActive={platformToActive}
          />

          <BookingRulesSyncWaitingModal
            progression={currentProgression.progress}
            isVisible={displayBookingConnectModal}
            progressText={currentProgression.progressText}
            headerStatus={header}
            close={() => handleCloseSynchroModal()}
          />
        </div>
      </Card>
    </>
  );
};
