import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ListIcon from "../../assets/icons/list.svg?react";
import TableRowsIcon from "../../assets/icons/table-rows.svg?react";
import { Button } from "../../components/Common/Button/Button";
import { CenterModal } from "../../components/Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { GroupButton } from "../../components/Common/GroupButton/GroupButton";
import { GroupButtonItemType } from "../../components/Common/GroupButton/GroupButton.type";
import { TableList } from "../../components/Common/TableList/TableList";
import { TableListGroupButtonItemType } from "../../components/Common/TableList/TableList.type";
import { TableListWithGroup } from "../../components/Common/TableList/TableListWithGroup";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { RentalGroupModal } from "../../components/Modal/Rental/Group/RentalGroupModal";
import { RentalGroupUseMode } from "../../components/Modal/Rental/Group/RentalGroupModal.type";
import { NoRentalRentalList } from "../../components/RentalList/NoRental/NoRentalRentalList";
import { NoRentalGroupRentalList } from "../../components/RentalList/NoRentalGroup/NoRentalGroupRentalList";
import { RentalFilters } from "../../components/RentalList/RentalFilters/RentalFilters";
import { RentalItem } from "../../components/RentalList/RentalTable/RentalItem";
import { RentalItemSkeleton } from "../../components/RentalList/RentalTable/RentalItemSkeleton";
import { RentalTableHeader } from "../../components/RentalList/RentalTable/RentalTableHeader";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { useDeleteRentalGroup } from "../../hooks/api/rentalGroups";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { RentalGroup } from "../RentalPage/RentalPage.type";
import { RentalListPageProps } from "./RentalListPage.type";

export const RentalListPage: React.FC<RentalListPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const [createGroupError, setCreateGroupError] = useState<
    string | undefined
  >();
  const [updateGroupError, setUpdateGroupError] = useState<
    string | undefined
  >();
  const [deleteGroupError, setDeleteGroupError] = useState<
    string | undefined
  >();

  const [isAddRentalGroupVisible, setIsAddRentalGroupVisible] =
    useState<boolean>(false);
  const [modalUseMode, setModalUseMode] =
    useState<RentalGroupUseMode>("create");
  const [currentRentalGroup, setCurrentRentalGroup] = useState<
    RentalGroup | undefined
  >();

  const [isDeleteRentalGroupVisible, setIsDeleteRentalGroupVisible] =
    useState<boolean>(false);

  const [selectedViewType, setSelectedViewType] =
    useState<TableListGroupButtonItemType | null>(null);

  const { pathname } = useLocation();
  useEffect(() => {
    let splitPath = pathname.toLowerCase().split("/");

    if (
      splitPath[splitPath.length - 1] === paths.RENTALS_LIST_VIEW ||
      splitPath[splitPath.length - 1] === paths.RENTALS_GROUP_VIEW
    ) {
      setSelectedViewType(
        splitPath[splitPath.length - 1] as TableListGroupButtonItemType,
      );
    } else {
      setSelectedViewType(
        paths.RENTALS_LIST_VIEW as TableListGroupButtonItemType,
      );
    }
  }, [pathname]);

  // * -- Handle group button view states --
  const [viewTypeGroupButtonItems, setViewTypeGroupButtonItems] = useState<
    GroupButtonItemType[]
  >([
    {
      label: t("RentalList.listView"),
      value: paths.RENTALS_LIST_VIEW,
      Icon: ListIcon,
      isActive: false,
    },
    {
      label: t("RentalList.groupView"),
      value: paths.RENTALS_GROUP_VIEW,
      Icon: TableRowsIcon,
      isActive: false,
    },
  ]);

  // * -- Handle group button view states logic --
  const rentalsTablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    "rentals",
  );

  const rentalGroupsTablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_GROUPS}`,
    "rental_groups",
    { otherMetas: ["total_rentals"] },
  );

  useEffect(() => {
    // * Update view type active button
    setViewTypeGroupButtonItems((prevData) =>
      prevData.map((item) =>
        item.value === selectedViewType
          ? { ...item, isActive: true }
          : { ...item, isActive: false },
      ),
    );

    // * We are using the fetch function depending on the state of selectedViewType
    switch (selectedViewType) {
      case paths.RENTALS_LIST_VIEW:
        rentalsTablePage.fetch({
          sort_field: "custom_sort_order",
        });
      case paths.RENTALS_GROUP_VIEW:
        rentalGroupsTablePage.fetch({});
    }

    if (selectedViewType) {
      window.history.pushState({}, "", `${paths.RENTALS}/${selectedViewType}`);
    }
  }, [selectedViewType]);

  const handleClickRental = (id: ValueType | null) => {
    if (id !== null) {
      navigate(`${paths.RENTALS}/${id}`);
    }
  };

  const handleAddRental = () => {
    navigate(paths.RENTALS_ADD);
  };

  const getGroupViewFiltersNode = () => {
    return (
      <RentalFilters
        rentals={rentalsTablePage.data}
        onAddRentalGroup={handleRentalGroupModal}
        onAddRental={handleAddRental}
        onSearchText={rentalGroupsTablePage.handleChangeSearch}
      />
    );
  };

  const getListViewFiltersNode = () => {
    return (
      <RentalFilters
        rentals={rentalsTablePage.data}
        onAddRental={handleAddRental}
        onSearchText={rentalsTablePage.handleChangeSearch}
      />
    );
  };

  // * Handle group button view type
  const handleClickButtonViewType = (value: ValueType) => {
    setSelectedViewType(value as TableListGroupButtonItemType);
  };

  const getGroupButtonViewTypeNode = () => {
    return (
      <div className="mb-4">
        <GroupButton
          isFetching={rentalsTablePage.loading}
          items={viewTypeGroupButtonItems}
          width="fit"
          onClick={handleClickButtonViewType}
        />
      </div>
    );
  };

  const handleRentalGroupModal = (
    useMode: RentalGroupUseMode = "create",
    isVisible: boolean = false,
    rentalGroup: RentalGroup | undefined,
  ) => {
    if (rentalGroup?.id !== 0) {
      setModalUseMode(useMode);
      setIsAddRentalGroupVisible(isVisible);
      setCurrentRentalGroup(rentalGroup);
    }
  };

  const handleTriggerRentalGroup = (
    isVisible: boolean = false,
    rentalGroup: RentalGroup | undefined,
  ) => {
    if (rentalGroup?.id !== 0) {
      setIsDeleteRentalGroupVisible(isVisible);
      setCurrentRentalGroup(rentalGroup);
    }
  };

  const handleRentalOrder = async (nextRentalIds: string) => {
    await post(
      `${import.meta.env.VITE_API_URL}${paths.API.UPDATE_ORDER_RENTALS}`,
      {
        rental_ids: nextRentalIds,
      },
    );
  };

  return (
    <>
      {isAddRentalGroupVisible ? (
        <RentalGroupModal
          isVisible={isAddRentalGroupVisible}
          rentalGroup={currentRentalGroup}
          useMode={modalUseMode}
          onTriggerRemoveRentalGroup={() => setIsDeleteRentalGroupVisible(true)}
          onClose={() => setIsAddRentalGroupVisible(false)}
          onCreateGroupError={(message: string | undefined) =>
            setCreateGroupError(message)
          }
          onUpdateGroupError={(message: string | undefined) =>
            setUpdateGroupError(message)
          }
        />
      ) : null}

      <CenterModal
        isVisible={isDeleteRentalGroupVisible}
        onClose={() => setIsDeleteRentalGroupVisible(false)}
      >
        <div className="flex flex-col gap-5">
          <p className="text-xl font-extrabold">
            {t("RentalList.deleteRentalGroupTitle")}
          </p>
          <p className="text-sm text-low-contrast">
            {t("RentalList.deleteRentalGroupSubTitle")}
          </p>
          <div className="flex gap-3">
            <Button
              type="secondary"
              disabled={validationLoading}
              onClick={() => setIsDeleteRentalGroupVisible(false)}
            >
              {t("Global.cancel")}
            </Button>
            <Button
              type="destructive"
              loading={validationLoading}
              onClick={() =>
                useDeleteRentalGroup(
                  currentRentalGroup?.id,
                  () => navigate(0),
                  (message: string | undefined) => setCreateGroupError(message),
                  () => {
                    setDeleteGroupError(undefined);
                    setValidationLoading(true);
                  },
                  () => {
                    setValidationLoading(false);
                  },
                )
              }
            >
              {t("Global.removeWithConfirmation")}
            </Button>
          </div>
        </div>
      </CenterModal>

      <MainLayout title={t("RentalList.title")} sidebarActiveItem="rentals">
        <>
          <ErrorMessage>{createGroupError}</ErrorMessage>
          <ErrorMessage>{updateGroupError}</ErrorMessage>
          <ErrorMessage>{deleteGroupError}</ErrorMessage>
          {selectedViewType === paths.RENTALS_LIST_VIEW && (
            <TableList
              i18nElement="Global.rentals"
              Filters={getListViewFiltersNode()}
              ViewTypeGroupButton={getGroupButtonViewTypeNode()}
              Header={RentalTableHeader}
              Item={RentalItem}
              Skeleton={RentalItemSkeleton}
              NoData={<NoRentalRentalList />}
              tablePage={rentalsTablePage}
              draggableItem={true}
              onChangeOrder={handleRentalOrder}
              onClick={handleClickRental}
            />
          )}

          {selectedViewType === paths.RENTALS_GROUP_VIEW && (
            <TableListWithGroup
              i18nElement="Global.rentals"
              Filters={getGroupViewFiltersNode()}
              ViewTypeGroupButton={getGroupButtonViewTypeNode()}
              Header={RentalTableHeader}
              Item={RentalItem}
              Skeleton={RentalItemSkeleton}
              NoData={
                <NoRentalGroupRentalList
                  onAdd={() => setIsAddRentalGroupVisible(true)}
                />
              }
              tablePage={rentalGroupsTablePage}
              nbCols={6}
              onClick={handleClickRental}
              onTriggerUpdateGroup={handleRentalGroupModal}
              onTriggerRemoveGroup={handleTriggerRentalGroup}
            />
          )}
        </>
      </MainLayout>
    </>
  );
};
