// * -- PROGRESSION STEP --

export enum ProgressionStepEnum {
  STEP_CONNECT_AIRBNB = "STEP_CONNECT_AIRBNB",

  STEP_CONNECT_BOOKING = "STEP_CONNECT_BOOKING",

  STEP_CREATE_RENTAL = "STEP_CREATE_RENTAL",

  STEP_SYNCHRONIZE = "STEP_SYNCHRONIZE",

  STEP_CHECK_CALENDAR = "STEP_CHECK_CALENDAR",

  STEP_CREATE_MESSAGES = "STEP_CREATE_MESSAGES",

  STEP_CREATE_TEAM = "STEP_CREATE_TEAM",

  STEP_GIFT = "STEP_GIFT",
}

export enum ProgressionStepStatusEnum {
  INCOMPLETE = "INCOMPLETE",

  PENDING = "PENDING",

  COMPLETE = "COMPLETE",

  ERROR = "ERROR",
}

export enum ProgressionStepSynchronizePhaseEnum {
  STEP_SYNCHRONIZE_PHASE_AIBNB_IMPORT_RESERVATIONS = "STEP_SYNCHRONIZE_PHASE_AIBNB_IMPORT_RESERVATIONS",

  STEP_SYNCHRONIZE_PHASE_BOOKING_IMPORT_RESERVATIONS = "STEP_SYNCHRONIZE_PHASE_BOOKING_IMPORT_RESERVATIONS",

  STEP_SYNCHRONIZE_PHASE_SEND_RESTRICTIONS = "STEP_SYNCHRONIZE_PHASE_SEND_RESTRICTIONS",
}

// * -- RESERVATION --

export enum ReservationStatusEnum {
  REQUEST = 0,

  CONFIRMED_FINISHED = 1,

  CONFIRMED_PROGRESS = 2,

  CONFIRMED_COMING = 3,

  CANCELED = 4,

  CANCELED_BY_TRAVELER = 5,

  PRE_APPROVED = 6,
}

export enum ReservationStatusFilterEnum {
  CANCEL = 0,

  CONFIRM_COMING = 2,

  CONFIRM_IN_PROGRESS = 1,

  ENDED = 3,

  REQUEST = 2,

  CANCEL_BY_TRAVELER = 5,

  PRE_APPROVED = 6,
}

export enum PlatformEnum {
  AIRBNB = 1,

  BOOKING = 2,

  DIRECT = 54,

  ABRITEL = 3,

  PRICELABS = 4,
  ICALENDAR = 73,
}

export enum PlatformNameEnum {
  AIRBNB = "AIRBNB.COM",
  BOOKING = "BOOKING.COM",
  PRICELABS = "PRICELABS",
  ICALENDAR = "ICAL",
}

// * -- PAYMENT RESERVATION --

export enum PaymentReservationStatusEnum {
  UNPAID = "UNPAID", // payment is not yet processed

  OVERDUE = "OVERDUE", // payment date is passed

  CANCELED = "CANCELED", // payment is canceled (if reservation is canceled for example)

  PARTIALLY_PAID = "PARTIALLY_PAID", 
  // at least one payment of a schedule remains unpaid

  PAID = "PAID", // payment is complete

  REFUNDED = "REFUNDED", // payment is refunded (manually by the user)
}

export enum PaymentReservationDepositValueTypeEnum {
  PERCENT = "PERCENT",
  FIXED = "FIXED",
}

export enum PaymentReservationDepositAccountOptionEnum {
  PRE_AUTHORISATION = "PRE_AUTHORISATION",

  CARD_PAYMENT_TO_REFUND = "CARD_PAYMENT_TO_REFUND",

  BANK_TRANSFER_TO_REFUND = "BANK_TRANSFER_TO_REFUND",

  BANK_CHECK_OR_CASH_AT_ARRIVAL = "BANK_CHECK_OR_CASH_AT_ARRIVAL",
}

export enum PaymentReservationDepositStatusEnum {
  UNPAID = "UNPAID", // deposit is not yet processed

  OVERDUE = "OVERDUE", // deposit payment date is passed

  CANCELED = "CANCELED", // deposit is canceled (if reservation is canceled for example)

  PRE_AUTHORIZED = "PRE_AUTHORIZED", // deposit is pre-authorized but not captured

  RELEASED = "RELEASED", // deposit pre-authorization is released (automatically after delay 7 or 30 days)

  CAPTURED = "CAPTURED", // deposit pre-authorization is captured (manually by the user)

  PAID = "PAID", // deposit is captured (and refunded manually after reservation)

  REFUNDED = "REFUNDED", // deposit is refunded (manually by the user after reservation)

  NOT_REFUNDED = "NOT_REFUNDED", // deposit is not refunded (manually by the user after reservation)

  MANAGED_BY_PLATFORM = "MANAGED_BY_PLATFORM", // deposit is managed by reservation platform (for instance airbnb or booking)

  NOT_REQUIRED = "NOT_REQUIRED", // deposit is not required in payment policy
}

// * -- PAYMENT ACCOUNT --

export enum PaymentAccountTypeEnum {
  STRIPE = "stripe",
  IBAN = "iban",
  BANK_TRANSFER = "bank_transfer",
  BANK_CHECK = "bank_check",
  CASH = "cash",
  BY_PLATFORM = "by_platform",
  BY_SUPERHOTE = "by_superhote",
}

export enum WelcomeStepEnum {
  BY_SEARCH = "Recherche en ligne",
  BY_REFERENCE = "Référence d'un ami ou d'un collègue",
  BY_ADVERTISING = "Publicité en ligne",
  BY_SOCIAL_NETWORK = "Réseaux sociaux",
  BY_OTHER = "Autre",
  BY_EVENT = "Lors d'un événement ou salon professionnel",
  BY_WORD_OF_MOUTH = "Bouche à oreille",
  BY_EMAIL = "Par e-mail via la newsletter",
}

export enum AirbnbLoginStatusEnum {
  LOGGED_IN = "LOGGED_IN",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  AIRLOCK_VALIDATION = "AIRLOCK_VALIDATION",
  HARD_BLOCK = "HARD_BLOCK",
  MFA_REQUIRED = "MFA_REQUIRED",
  MFA_NOT_AVAILABLE = "MFA_NOT_AVAILABLE",
  SH_ALREADY_LINKED = "SH_ALREADY_LINKED",
  SOCIAL_LINKED = "SOCIAL_LINKED",
  AIRLOCK_V2_ERROR = "AIRLOCK_V2_ERROR",
  UNMANAGED = "UNMANAGED",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED",
  MFA_CODE_SENT = "MFA_CODE_SENT",
  MFA_CODE_SUCCESS = "MFA_CODE_SUCCESS",
  MFA_CODE_FAILED = "MFA_CODE_FAILED",
  MFA_CODE_SENT_FAILED = "MFA_CODE_SENT_FAILED",
  MFA_CODE_EXPIRED = "MFA_CODE_EXPIRED",
  SH_VALIDATION_WAITING = "SH_VALIDATION_WAITING",
  SH_VALIDATION_DONE = "SH_VALIDATION_DONE",
}

// * -- CALENDAR --
export enum CalendarRestrictionUnavailabilityReasonEnum {
  HOLIDAYS = "HOLIDAYS",
  CONSTRUCTION = "CONSTRUCTION",
  HOUSEWORK = "HOUSEWORK",
  CLOSED = "CLOSED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

// * -- EXTRA GUEST PAGE
export enum ExtraAvailableUntil {
  CHECKOUT = "CHECKOUT",
  "CHECKOUT-1" = "CHECKOUT-1",
  "CHECKOUT-2" = "CHECKOUT-2",
  "CHECKOUT-3" = "CHECKOUT-3",
  "CHECKOUT-4" = "CHECKOUT-4",
  "CHECKOUT-5" = "CHECKOUT-5",
  "CHECKOUT-6" = "CHECKOUT-6",
}

export enum ReservationExtraOrderStatusEnum {
  PRE_AUTHORIZED = "PRE_AUTHORIZED",
  UNPAID = "UNPAID",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  CANCELED = "CANCELED",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  OVERDUE = "OVERDUE",
}

export enum TaskTypeEnum {
  CLEANING = "CLEANING",
  CHECKIN = "CHECKIN",
  CHECKOUT = "CHECKOUT",
  INCIDENT = "INDICENT",
  OTHER = "OTHER",
}
