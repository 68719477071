import React, {ReactNode, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {NoPaymentReservation} from "../PaymentReservationList/NoPaymentReservation";
import {PaymentExtrasListPageSkeleton} from "./PaymentExtrasListPageSkeleton";
import {
  PaymentReservationFiltersTotals,
  PaymentReservationStatusFilterButton,
} from "../../components/Payment/PaymentReservationList/Filters/PaymentReservationFilters.type";
import {PaymentReservationFilters} from "../../components/Payment/PaymentReservationList/Filters/PaymentReservationFilters";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {TableList} from "../../components/Common/TableList/TableList";
import {PaymentExtrasListHeader} from "../../components/Payment/ExtrasList/PaymentExtrasListHeader";
import {PaymentExtrasListItem} from "../../components/Payment/ExtrasList/PaymentExtrasListItem";
import ExtraOrderModal from "../../components/Reservation/ExtrasOrder/Modal/ExtraOrderModal";
import {
  GuestPageExtraOrder,
  PaymentExtraListItemResponse,
} from "../../types/GETTypes";
import {ValueType} from "../../types/commonTypes";
import {PaymentExtrasReservationFilters} from "../../components/Payment/ExtrasList/Filters/PaymentExtrasReservationFilters";
import {
  PaymentReservationExtrasFiltersTotals,
  PaymentReservationExtrasStatusFilterButton,
} from "../../components/Payment/ExtrasList/Filters/PaymentReservationFilters.type";
import {useLocation, useNavigate} from "react-router-dom";

export const PaymentExtrasListPage: React.FC<{}> = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentActiveButtonFilter, setCurrentActiveButtonFilter] =
    useState<PaymentReservationExtrasStatusFilterButton>("ALL");

  const [paymentReservationExtraModal, setPaymentReservationExtraModal] =
    useState<PaymentExtraListItemResponse>();

  const [openExtraOrderModal, setOpenExtraOrderModal] = useState(false);

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.EXTRAS_ORDERS}`,
    "reservation_extras",
    {
      otherMetas: [
        "total",
        "total_canceled",
        "total_filtered",
        "total_paid",
        "total_partially_paid",
        "total_pre_authorization",
        "total_unpaid",
      ],
    }
  );

  const {currentOtherMetas, data, setData} = tablePage;

  const reservations = (data && data?.length > 0 ? data : []) as PaymentExtraListItemResponse[];

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.extraList")}
      </p>
    );
  };

  const getFiltersNode = (): ReactNode => {
    const totals: PaymentReservationExtrasFiltersTotals = {
      unpaid:
        currentOtherMetas.find((meta) => meta.key === "total_unpaid")?.value ??
        "0",
      partiallyPaid:
        currentOtherMetas.find((meta) => meta.key === "total_partially_paid")
          ?.value ?? "0",
      preAuthorized:
        currentOtherMetas.find((meta) => meta.key === "total_pre_authorization")
          ?.value ?? "0",
      paid:
        currentOtherMetas.find((meta) => meta.key === "total_paid")?.value ??
        "0",
      canceled:
        currentOtherMetas.find((meta) => meta.key === "total_canceled")
          ?.value ?? "0",
      all: currentOtherMetas.find((meta) => meta.key === "total")?.value ?? "0",
    };
    return (
      <PaymentExtrasReservationFilters
        onSearchText={tablePage.handleChangeSearch}
        totals={totals}
        currentActiveButtonFilter={currentActiveButtonFilter}
        onFilterButtonClick={(value) =>
          setCurrentActiveButtonFilter(
            value as PaymentReservationExtrasStatusFilterButton
          )
        }
        tablePage={tablePage}
      />
    );
  };

  const handleOpenExtraFromUrl = () => {
    const checkUrl = (url: string) => {
      if (!url) {
        return {isValid: false, params: ""};
      }
      try {
        const parsedUrl = new URL(url, window.location.origin); // Parse the URL
        if (parsedUrl.pathname !== paths.PAYMENTS_EXTRAS) {
          return {isValid: false, params: ""};
        }
        const openParam = parsedUrl.searchParams.get("openExtra");
        return {
          isValid: openParam && openParam.trim() !== "",
          params: openParam,
        };
      } catch (error) {
        return {isValid: false, params: ""};
      }
    };

    const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
    const {isValid, params} = checkUrl(currentUrl);
    if (isValid) {
      if (params) {
        window.history.pushState({}, "", location.pathname);
        setTimeout(() => {
          openModal(Number(params));
        }, 500);
      }
    }
  };

  const openModal = (paymentReservationId: ValueType) => {
    const reservation = reservations.find(
      (row) => row.id === paymentReservationId
    );
    if (reservation) {
      setPaymentReservationExtraModal(reservation);
      setOpenExtraOrderModal(true);
    }
  };

  const closeModal = () => {
    setPaymentReservationExtraModal(undefined);
    setOpenExtraOrderModal(false);
  };

  const onSuccess = (resultReservation: any) => {
    closeModal();
    fetch();
  };

  const fetch = () => {
    if (currentActiveButtonFilter !== "ALL") {
      tablePage.handleFilterStatus(currentActiveButtonFilter);
    } else {
      tablePage.fetch({});
    }
  };

  useEffect(() => {
    fetch();
  }, [currentActiveButtonFilter]);

  useEffect(() => {
    if (tablePage.data && tablePage?.data?.length > 0) {
      handleOpenExtraFromUrl();
    }
  }, [tablePage?.data]);

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="extras"
      >
        <ExtraOrderModal
          isVisible={openExtraOrderModal}
          closeModal={closeModal}
          extraOrderModal={paymentReservationExtraModal?.payment_extras_order}
          reservation={paymentReservationExtraModal}
          page="payment"
          onSuccess={onSuccess}
        />
        <TableList
          i18nElement="Global.payment"
          Filters={getFiltersNode()}
          filterTotal={
            Number(
              currentOtherMetas.find((meta) => meta.key === "total")?.value
            ) ?? 0
          }
          Header={PaymentExtrasListHeader}
          tablePage={tablePage}
          Item={PaymentExtrasListItem}
          Skeleton={PaymentExtrasListPageSkeleton}
          NoData={
            <NoPaymentReservation
              title={t("Global.noPaiementExtraTitle")}
              description={t("Global.noPaiementExtraContent")}
            />
          }
          onClick={openModal}
        />
      </MainLayout>
    </>
  );
};
