import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import paths from "../../../../constants/paths";
import { useFetchPaymentAccounts } from "../../../../hooks/api/paymentAccounts";
import {
  useCreatePaymentPolicy,
  useDeletePaymentPolicy,
  useUpdateFavoritePaymentPolicy,
  useUpdatePaymentPolicy,
} from "../../../../hooks/api/paymentPolicies";
import { RequiredFields } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { Switch } from "../../../Common/Switch/Switch";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  PaymentAccount,
  PaymentPolicyForm,
  PaymentPolicyPayload,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyResponse,
  PaymentPolicyValueTypeEnum,
} from "../../../Payment/Payment.type";
import { PaymentScheduleAndConditions } from "../../../Payment/ScheduleAndConditions/ScheduleAndConditions";

import {
  useConvertValuesToPaymentPolicyPayload,
  useHasStripeAccount,
  usePaymentPolicyModalInit,
  usePaymentPolicyModalInitStates,
} from "./PaymentPolicy.hooks";
import { RentalPaymentPolicyModalProps } from "./PaymentPolicy.type";

export const RentalPaymentPolicyModal: React.FC<
  RentalPaymentPolicyModalProps
> = ({
  mode,
  title,
  isVisible,
  formData,
  rentalId,
  onClose,
  onCreate,
  onUpdate,
  onDelete,
  onUpdateFavoritePaymentPolicy,
}) => {
  const { t } = useTranslation();

  const requiredFields: RequiredFields<PaymentPolicyForm> = {
    name: true,
    description: true,
    is_favorite_policy: true,
    is_single_payment: true,
    payments_policy_items: true,
    is_refundable: true,
    refund_value: false,
    refund_condition: false,
    is_deposit_required: true,
    deposit_value: false,
    deposit_payment_option: false,
    deposit_payment_account_id: false,
    deposit_payment_days_delay: false,
  };

  const form = useForm<PaymentPolicyForm>({
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      is_favorite_policy: false,
      is_single_payment: true,
      payments_policy_items: [
        {
          valueType: PaymentPolicyValueTypeEnum.PERCENT,
          value: 100,
          trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
          paymentAccountId: null,
        },
      ],
      is_refundable: false,
      refund_value: null,
      refund_condition: null,
      is_deposit_required: true,
      deposit_value: 100,
      deposit_payment_option: null,
      deposit_payment_account_id: null,
      deposit_payment_days_delay: 1,
    },
  });

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = form;

  const {
    fields: paymentFields,
    append: paymentAppend,
    update: paymentUpdate,
    remove: paymentRemove,
  } = useFieldArray({
    control,
    name: "payments_policy_items",
  });

  // * STATES INITIALIZATION
  const initStates = usePaymentPolicyModalInitStates();
  const [apiError, setApiError] = initStates.apiErrorState;
  const [paymentError, setPaymentError] = initStates.paymentErrorState;
  const [validationLoading, setValidationLoading] =
    initStates.validationLoadingState;
  const [
    validationLoadingDeletePaymentPolicy,
    setValidationLoadingDeletePaymentPolicy,
  ] = initStates.validationLoadingDeletePaymentPolicyState;
  const [
    isVisiblePaymentPolicyDeleteModal,
    setIsVisiblePaymentPolicyDeleteModal,
  ] = initStates.isVisiblePaymentPolicyDeleteModalState;

  // * COMPONENT INITIALIZATION
  usePaymentPolicyModalInit(formData, t, form);

  // * Fetch payment accounts
  const [paymentAccounts, setPaymentAccounts] = initStates.paymentAccountsState;
  const [paymentAccountsError, setPaymentAccountsError] =
    initStates.paymentAccountsErrorState;

  useEffect(() => {
    useFetchPaymentAccounts(
      (paymentAccounts: PaymentAccount[]) =>
        setPaymentAccounts(paymentAccounts),
      (message: string | undefined) => setPaymentAccountsError(message),
      () => {
        setApiError(undefined);
        setValidationLoading(true);
      },
      () => setValidationLoading(false)
    );
  }, []);

  const handleUpdateFavoritePaymentPolicy = () => {
    if (mode === "create") {
      setValue("is_favorite_policy", !getValues("is_favorite_policy"));
    } else if (mode === "update" && formData) {
      useUpdateFavoritePaymentPolicy(
        rentalId,
        formData.id,
        (
          paymentPolicyId: number | undefined,
          paymentPolicies: PaymentPolicyResponse[]
        ) => onUpdateFavoritePaymentPolicy(paymentPolicyId, paymentPolicies),
        (message: string | undefined) => setApiError(message),
        () => {
          setApiError(undefined);
          setValidationLoading(true);
        },
        () => setValidationLoading(false)
      );
    }
  };

  // * Add or Update payment policy
  const handleAddOrUpdatePaymentPolicy = () => {
    const paymentPolicy: PaymentPolicyPayload =
      useConvertValuesToPaymentPolicyPayload(getValues, paymentFields);

    if (mode === "create") {
      useCreatePaymentPolicy(
        rentalId,
        paymentPolicy,
        (paymentPolicies: PaymentPolicyResponse[]) => onCreate(paymentPolicies),
        (message: string | undefined) => setApiError(message),
        () => {
          setApiError(undefined);
          setValidationLoading(true);
        },
        () => setValidationLoading(false)
      );
    }

    if (mode === "update" && formData) {
      useUpdatePaymentPolicy(
        rentalId,
        formData.id,
        paymentPolicy,
        (paymentPolicies: PaymentPolicyResponse[]) => onUpdate(paymentPolicies),
        (message: string | undefined) => setApiError(message),
        () => {
          setApiError(undefined);
          setValidationLoading(true);
        },
        () => setValidationLoading(false)
      );
    }
  };

  // * Delete payment policy
  const handleDeletePaymentPolicy = async () => {
    if (formData) {
      useDeletePaymentPolicy(
        rentalId,
        formData.id,
        (paymentPolicies: PaymentPolicyResponse[]) => onDelete(paymentPolicies),
        (message: string | undefined) => setApiError(message),
        () => {
          setApiError(undefined);
          setValidationLoadingDeletePaymentPolicy(true);
        },
        () => {
          setValidationLoadingDeletePaymentPolicy(false);
          setIsVisiblePaymentPolicyDeleteModal(false);
        }
      );
    }
  };

  watch();

  return (
    <RightModal
      title={title}
      isVisible={isVisible}
      bottomHeaderNode={
        <div className="flex items-center space-x-3">
          <p className="font-bold text-low-contrast text-md">
            {t("Rental.Pricing.PaymentPolicy.favoritePolicyLabel")}
          </p>

          <Switch
            value={
              mode === "create"
                ? getValues("is_favorite_policy")
                : Boolean(formData?.is_favorite_policy) ?? false
            }
            disabled={
              mode === "update" &&
              Boolean(formData?.is_default_policy) &&
              Boolean(formData?.is_favorite_policy)
            }
            onClick={handleUpdateFavoritePaymentPolicy}
          />
        </div>
      }
      onClose={!validationLoading ? onClose : () => {}}
    >
      {paymentAccountsError ? (
        <div className="w-full">
          <ErrorMessage>{paymentAccountsError}</ErrorMessage>
        </div>
      ) : (
        <>
          {/* PAYMENT POLICY DELETE MODAL */}
          <CenterModal
            isVisible={isVisiblePaymentPolicyDeleteModal}
            onClose={() => {
              if (!validationLoadingDeletePaymentPolicy) {
                setIsVisiblePaymentPolicyDeleteModal(false);
              }
            }}
          >
            <div className="flex flex-col gap-5">
              <p className="text-xl font-extrabold">
                {t("RentalList.deleteRentalGroupTitle")}
              </p>
              <p className="text-sm text-low-contrast">
                {t("RentalList.deleteRentalGroupSubTitle")}
              </p>
              <div className="flex gap-3">
                <Button
                  type="secondary"
                  disabled={validationLoadingDeletePaymentPolicy}
                  onClick={() => setIsVisiblePaymentPolicyDeleteModal(false)}
                >
                  {t("Global.cancel")}
                </Button>
                <Button
                  type="destructive"
                  onClick={handleDeletePaymentPolicy}
                  loading={validationLoadingDeletePaymentPolicy}
                >
                  {t("Global.removeWithConfirmation")}
                </Button>
              </div>
            </div>
          </CenterModal>

          <div className="flex flex-col w-full gap-y-3">
            <ErrorMessage>{apiError}</ErrorMessage>
            <div className="flex flex-col flex-1 overflow-y-auto gap-y-3">
              {/* NO STRIPE ACCOUNTS CONNECTED */}
              {!useHasStripeAccount(paymentAccounts) ? (
                <div className="flex p-4 space-x-3 rounded-lg bg-element-background">
                  <div className="flex flex-col justify-start">
                    <InformationIcon />
                  </div>
                  <div className="flex flex-col justify-start space-y-3">
                    <p className="text-base font-light text-low-contrast">
                      {t(
                        "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.stripeNotConnectedTitle"
                      )}
                    </p>
                    <Link to={paths.PAYMENTS_ACCOUNTS} target="_blank">
                      <div className="w-max">
                        <Button>
                          {t(
                            "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.stripeNotConnectedButtonLabel"
                          )}
                        </Button>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              <div>
                <TextInput
                  register={register("name", {
                    required: {
                      value: requiredFields.name,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Pricing.PaymentPolicy.namePolicyLabel"
                        ),
                      }),
                    },
                  })}
                  disabled={Boolean(formData?.is_default_policy)}
                  required={requiredFields.name}
                  label={t("Rental.Pricing.PaymentPolicy.namePolicyLabel")}
                  placeholder={t(
                    "Rental.Pricing.PaymentPolicy.namePolicyPlaceholder"
                  )}
                  value={getValues("name")}
                  error={errors?.name?.message}
                />
              </div>

              <TextAreaInput
                register={register("description", {
                  required: {
                    value: requiredFields.description,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t(
                        "Rental.Pricing.PaymentPolicy.descriptionPolicyLabel"
                      ),
                    }),
                  },
                })}
                required={requiredFields.description}
                disabled={Boolean(formData?.is_default_policy)}
                label={t("Rental.Pricing.PaymentPolicy.descriptionPolicyLabel")}
                value={getValues("description")}
                resize={{
                  y: true,
                  x: false,
                }}
                placeholder={t(
                  "Rental.Pricing.PaymentPolicy.descriptionPolicyPlaceholder"
                )}
                error={errors?.description?.message}
              />

              <Separator />

              <PaymentScheduleAndConditions
                useMode="rental"
                form={form}
                formData={formData}
                nonModifiable={Boolean(formData?.is_default_policy)}
                requiredFields={requiredFields}
                paymentPolicyFields={paymentFields}
                paymentPolicyAppend={paymentAppend}
                paymentPolicyUpdate={paymentUpdate}
                paymentPolicyRemove={paymentRemove}
                paymentAccounts={paymentAccounts}
                onError={(error: string | undefined) => setPaymentError(error)}
              />
            </div>

            {/* Cancel | Submit button */}
            <div className="flex flex-col">
              <ErrorMessage errorType="FORM">{paymentError}</ErrorMessage>
              <div className="flex gap-3 pt-4 mt-3 border-t-1 border-element-border">
                <Button
                  type="secondary"
                  onClick={!validationLoading ? onClose : () => {}}
                  disabled={validationLoading}
                >
                  {t("Global.cancel")}
                </Button>
                {formData?.id !== 1 ? (
                  <>
                    {mode === "create" ? (
                      <Button
                        onClick={handleSubmit(handleAddOrUpdatePaymentPolicy)}
                        disabled={
                          !isValid ||
                          Object.keys(errors).length !== 0 ||
                          validationLoading
                        }
                        loading={validationLoading}
                      >
                        {t("Rental.Pricing.PaymentPolicy.validateButtonLabel")}
                      </Button>
                    ) : null}

                    {mode === "update" ? (
                      <>
                        <Button
                          type="destructive"
                          onClick={() =>
                            setIsVisiblePaymentPolicyDeleteModal(true)
                          }
                          disabled={validationLoading}
                        >
                          {t("Global.remove")}
                        </Button>
                        <Button
                          onClick={handleSubmit(handleAddOrUpdatePaymentPolicy)}
                          disabled={
                            !isValid ||
                            Object.keys(errors).length !== 0 ||
                            validationLoading
                          }
                          loading={validationLoading}
                          RightIcon={CheckIcon}
                        >
                          {t("Global.record")}
                        </Button>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </RightModal>
  );
};
