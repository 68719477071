import { t } from "i18next";
import React from "react";
import DotVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import TrashIcon from "../../../assets/icons/trash-active.svg?react";
import { AutomationItemResponse } from "../../../types/GETTypes";
import { AvatarGroup } from "../../Common/AvatarGroup/AvatarGroup";
import { Button } from "../../Common/Button/Button";
import { CheckBox } from "../../Common/CheckBox/CheckBox";
import { Switch } from "../../Common/Switch/Switch";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import { useFormattedAutomationType } from "../ManagementAutomationList.hooks";

export const ManagementAutomationItem: React.FC<TableListItemProps> = ({
  data,
  isSelected,
  onClick = () => {},
  onClickAction = () => {},
}) => {
  const automation: AutomationItemResponse = data;
  const { id } = data as AutomationItemResponse;

  const getTrigger = () => {
    if (automation.trigger === "checkin") {
      return t("Automation.List.TriggerOptions.checkin");
    } else if (automation.trigger === "checkout") {
      return t("Automation.List.TriggerOptions.checkout");
    }
  };

  return (
    <tr className="cursor-pointer select-none hover:bg-element-background/40 outline-active">
      {/* Checkbox selection */}
      <td className="w-4 py-2 text-sm ps-4 pe-2 border-t-1 border-element-border">
        <CheckBox
          onChange={() => onClickAction(id, "select")}
          value={isSelected}
        />
      </td>

      {/* task */}
      <td
        className="px-2 py-2 text-sm border-t-1 border-element-border"
        onClick={() => onClick(id)}
      >
        <div className="flex flex-col space-y-1">
          <p className="text-sm text-low-contrast">
            {t("Automation.List.scheduleTask")}
          </p>
          <p className="text-base font-semibold leading-normal tracking-wide text-high-contrast">
            {automation.type === "other"
              ? automation.name
              : useFormattedAutomationType(automation.type)}
          </p>
        </div>
      </td>

      {/* trigger */}
      <td
        className="px-2 py-2 text-sm border-t-1 border-element-border"
        onClick={() => onClick(id)}
      >
        <div className="flex flex-col space-y-1">
          <p className="text-sm text-low-contrast">
            {t("Automation.List.trigger")}
          </p>
          <p className="text-base font-semibold leading-normal tracking-wide text-high-contrast">
            {getTrigger()}
          </p>
        </div>
      </td>

      {/* concerned rental */}
      <td
        className="flex flex-row items-center py-4 space-x-4 text-sm border-t-1 border-element-border ps-4 pe-2"
        onClick={() => onClick(id)}
      >
        <div className="relative">
          {automation.rental.cover ? (
            <div className="object-fill w-10 h-10 overflow-hidden rounded-4px border-1 border-element-border/50">
              <img
                src={automation.rental.cover}
                className="object-fill w-10 h-10"
              />
            </div>
          ) : (
            <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
          )}
        </div>
        <div className="flex flex-col">
          <p className="w-1/2 font-semibold truncate text-high-contrast">
            {automation.rental.name}
          </p>
          <p className="font-light text-low-contrast">
            {`${automation.rental.address} ${automation.rental.postal_code} ${automation.rental.city}`}
          </p>
        </div>
      </td>

      {/* assignees */}
      <td
        className="py-2 text-sm border-t-1 border-element-border"
        onClick={() => onClick(id)}
      >
        <div className="flex items-center justify-center">
          {automation.assignation_type === "OPERATORS" ? (
            <AvatarGroup
              maxDisplay={2}
              avatars={automation.operators.map((operator) => {
                return {
                  name: `${operator.first_name} ${operator.last_name}`,
                  photoSrc: operator.photo ?? "",
                };
              })}
            />
          ) : null}

          {automation.assignation_type === "TEAMS" &&
          automation.operators.length > 0 ? (
            <p className="text-base font-semibold">
              {automation.operators
                .map((operator) => operator.team_name)
                .join(", ")}
            </p>
          ) : null}

          {automation.operators.length === 0 ? (
            <p className="text-base font-semibold">{t("Global.notDefined")}</p>
          ) : null}
        </div>
      </td>

      {/* State */}
      <td
        className="py-2 border-t-1 border-element-border"
        onClick={() => onClickAction(id, "edit")}
      >
        <div className="flex items-center justify-center">
          <div
            onClick={(event: any) => {
              event.stopPropagation();
              if (Boolean(automation.enabled)) {
                onClickAction(id, "deactivate");
              } else {
                onClickAction(id, "activate");
              }
            }}
          >
            <Switch value={Boolean(automation.enabled)} />
          </div>
        </div>
      </td>

      {/* Actions button */}
      <td className="py-2 pe-4 border-t-1 border-element-border">
        <div className="flex items-center justify-end">
          <div className="w-max">
            <Button
              type="secondary"
              dropItems={[
                {
                  Icon: TrashIcon,
                  label: t("Global.remove"),
                  value: "remove",
                  isRed: true,
                },
              ]}
              onClick={(value: string) => onClickAction(id, value)}
            >
              <DotVerticalIcon />
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};
