/**
 * Card Form for add contact in rentals
 * (reservation contact, invoice reservation contact ...)
 */

import React, { useEffect, useState } from "react";

import { Card } from "../../../Common/Card/Card";
import { useTranslation } from "react-i18next";
import { Separator } from "../../../Common/Separator/Separator";
import { FlexCol } from "../../../Common/FlexComponents/FlexCol";
import { HelpText } from "../../../Common/TextComponents/HelpText";
import { TitleText } from "../../../Common/TextComponents/TitleText";
import { FlexItemCenter } from "../../../Common/FlexComponents/FlexItemCenter";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { PhoneInput } from "../../../Common/PhoneInput/PhoneInput";
import { FlexCenterBetween } from "../../../Common/FlexComponents/FlexCenterBetween";
import { Button } from "../../../Common/Button/Button";
import { useRentalContact } from "../../../../hooks/api/rentalContact";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

import { Rental } from "../../../../pages/RentalPage/RentalPage.type";
import { ContactType, RentalContact } from "../../../../types/GETTypes";

// Icons
import ContactIcon from "../../../../assets/icons/contacts.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import AddIcon from "../../../../assets/icons/plus.svg?react";
import ArrowLeft from "../../../../assets/icons/arrow-left.svg?react";
import TrashIcon from "../../../../assets/icons/trash.svg?react";

export interface ContactCardProps {
  rental: Rental;
}

interface FormInterface {
  main: null | RentalContact;
  invoice: null | RentalContact;
  reservationContact: null | RentalContact;
  reservationCenterContact: null | RentalContact;
  specialRequestsContact: null | RentalContact;
  availabilityContact: null | RentalContact;
  photoAndDescriptionContact: null | RentalContact;
  pricingContact: null | RentalContact;
  contractContact: null | RentalContact;
}

export const ContactCardInfo = ({ rental }: ContactCardProps) => {
  const { getContacts, createContact, deleteContactTypeIfExist } =
    useRentalContact(rental.id);

  const [form, setForm] = useState<FormInterface>({
    main: null,
    invoice: null,
    reservationContact: null,
    reservationCenterContact: null,
    specialRequestsContact: null,
    availabilityContact: null,
    photoAndDescriptionContact: null,
    pricingContact: null,
    contractContact: null,
  });

  const [editCard, setEditCard] = useState(false);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const [contactsCache, setContactsCache] = useState<RentalContact[]>([]);

  /**
   * Function for load all data
   * with contact for rental
   */
  const loadAllData = () => {
    getContacts()
      .then((contacts) => {
        setContactsCache(contacts);
        contacts.forEach((contact) => {
          const formValue = contactTypeToForm(contact.contact_type);
          setForm((prevForm) => ({
            ...prevForm,
            [formValue]: {
              firstname: contact.firstname,
              lastname: contact.lastname,
              email: contact.email,
              phone: contact.phone,
              contact_type: contact.contact_type,
            },
          }));
        });
      })
      .finally(() => {
        console.log(form);
      });
  };

  /**
   * Use effect loader data
   */
  useEffect(() => {
    setError("");
    loadAllData();
  }, [editCard]);

  /**
   * Function called when the "save" button is clicked,
   * and saves all data while carefully checking the cache
   * to avoid unnecessary API calls.
   */
  const handleSaveData = async () => {
    const keys = Object.keys(form);

    for (let index = 0; index < keys.length; index++) {
      const formValueKey = keys[index];
      const contactType = formToContactType(formValueKey);
      // @ts-ignore
      const item: RentalContact = form[formValueKey];

      if (!item) {
        const found = contactsCache.some(
          (contactCache) => contactCache.contact_type === contactType,
        );
        if (found) {
          await deleteContactTypeIfExist(contactType);
        }
      } else {
        const existingContact = contactsCache.find(
          (contactCache) => contactCache.contact_type === contactType,
        );
        if (existingContact) {
          const hasChanged =
            existingContact.firstname !== item.firstname ||
            existingContact.lastname !== item.lastname ||
            existingContact.email !== item.email ||
            existingContact.phone !== item.phone;

          if (hasChanged) {
            await createContact(
              contactType,
              item.firstname,
              item.lastname,
              item.email,
              item.phone,
            );
          }
        } else {
          await createContact(
            contactType,
            item.firstname,
            item.lastname,
            item.email,
            item.phone,
          );
        }
      }
    }
  };

  const formToContactType = (type: string): ContactType => {
    switch (type) {
      case "main":
        return "MAIN";
      case "invoice":
        return "INVOICE";
      case "reservationContact":
        return "RESERVATION";
      case "reservationCenterContact":
        return "CENTER_OF_RESERVATIONS";
      case "specialRequestsContact":
        return "SPECIAL_REQUEST";
      case "availabilityContact":
        return "DISPONIBILITIE";
      case "photoAndDescriptionContact":
        return "PHOTO_AND_DESCRIPTION";
      case "pricingContact":
        return "TARIFICATION";
      case "contractContact":
        return "CONTRACT";
      default:
        return "MAIN";
    }
  };

  const contactTypeToForm = (type: ContactType): string => {
    switch (type) {
      case "MAIN":
        return "main";
      case "INVOICE":
        return "invoice";
      case "RESERVATION":
        return "reservationContact";
      case "CENTER_OF_RESERVATIONS":
        return "reservationCenterContact";
      case "SPECIAL_REQUEST":
        return "specialRequestsContact";
      case "DISPONIBILITIE":
        return "availabilityContact";
      case "PHOTO_AND_DESCRIPTION":
        return "photoAndDescriptionContact";
      case "TARIFICATION":
        return "pricingContact";
      case "CONTRACT":
        return "contractContact";
      default:
        return "main";
    }
  };

  const { t } = useTranslation();
  const title = t("Global.contacts");
  const edit = t("Global.edit");
  const save = t("Global.save");
  const mainContact = t("Rentals.mainContact");
  const invoiceContact = t("Rentals.invoiceContact");
  const helpText = t("Rentals.helpText");
  const name = t("Rentals.name");
  const lastname = t("Rentals.lastname");
  const email = t("Rentals.email");
  const tel = t("Rentals.tel");
  const reservationContact = t("Rentals.reservationContact");
  const reservationCenterContact = t("Rentals.reservationCenterContact");
  const specialRequestsContact = t("Rentals.specialRequestsContact");
  const availabilityContact = t("Rentals.availabilityContact");
  const photoAndDescriptionContact = t("Rentals.photoAndDescriptionContact");
  const pricingContact = t("Rentals.pricingContact");
  const contractContact = t("Rentals.contractContact");
  const addContact = t("Rentals.addContact");
  const cancel = t("Global.cancel");

  const handleClickAddContact = (contactType: ContactType) => {
    setForm((prevForm) => ({
      ...prevForm,
      [contactTypeToForm(contactType)]: {
        name: "",
        lastName: "",
        phone: "",
        email: "",
        contact_type: contactType,
      },
    }));
  };

  const handleRemoveContact = (contactType: ContactType) => {
    setForm((prevForm) => ({
      ...prevForm,
      [contactTypeToForm(contactType)]: null,
    }));
  };

  const addContactsButtonsItems = [
    {
      title: mainContact,
      onClick: () => handleClickAddContact("MAIN"),
    },
    {
      title: invoiceContact,
      onClick: () => handleClickAddContact("INVOICE"),
    },
    {
      title: reservationContact,
      onClick: () => handleClickAddContact("RESERVATION"),
    },
    {
      title: reservationCenterContact,
      onClick: () => handleClickAddContact("CENTER_OF_RESERVATIONS"),
    },
    {
      title: specialRequestsContact,
      onClick: () => handleClickAddContact("SPECIAL_REQUEST"),
    },
    {
      title: availabilityContact,
      onClick: () => handleClickAddContact("DISPONIBILITIE"),
    },
    {
      title: photoAndDescriptionContact,
      onClick: () => handleClickAddContact("PHOTO_AND_DESCRIPTION"),
    },
    {
      title: pricingContact,
      onClick: () => handleClickAddContact("TARIFICATION"),
    },
    {
      title: contractContact,
      onClick: () => handleClickAddContact("CONTRACT"),
    },
  ];

  return (
    <Card
      Icon={ContactIcon}
      label={title}
      anchor={"contact"}
      button={{
        label: editCard ? save : edit,
        Icon: editCard ? CheckIcon : EditIcon,
        onClick: () => {
          if (editCard) {
            setloading(true);
            handleSaveData()
              .then(() => {
                setEditCard(!editCard);
              })
              .catch((error) => {
                setError(error.message);
              })
              .finally(() => {
                setloading(false);
              });
          } else {
            setEditCard(!editCard);
          }
        },
        type: editCard ? "primary" : "secondary",
        loading: loading,
      }}
      secondaryButton={{
        label: cancel,
        LeftIcon: ArrowLeft,
        onClick: () => setEditCard(!editCard),
        type: "secondary",
        visible: editCard,
        disabled: loading,
      }}
    >
      {/*Card Edit Mode Off*/}
      <FlexCol visible={!editCard}>
        {Object.values(form).map((item, index) => {
          console.log(item);
          return (
            item && (
              <>
                <FlexCol gap={10}>
                  <HelpText>{addContactsButtonsItems[index]?.title}</HelpText>
                  <p className={"font-bold"}>
                    {item.firstname} {item.lastname}
                  </p>
                  <p className={"font-bold text-gray-500"}>
                    {item.email}
                    {" - "}
                    {item.phone}
                  </p>
                </FlexCol>
                <Separator accent={"dark"} />
              </>
            )
          );
        })}
      </FlexCol>

      {/*Card Edit Mode On*/}
      <FlexCol gap={20} visible={editCard}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <HelpText>{helpText}</HelpText>
        {Object.values(form).map((item: RentalContact, index) =>
          !item ? (
            <>
              <FlexCenterBetween>
                <TitleText>{addContactsButtonsItems[index]?.title}</TitleText>
                <div>
                  <Button
                    RightIcon={AddIcon}
                    type={"secondary"}
                    onClick={addContactsButtonsItems[index]?.onClick}
                  >
                    {addContact}
                  </Button>
                </div>
              </FlexCenterBetween>
            </>
          ) : (
            <>
              <FlexCenterBetween>
                <TitleText>{addContactsButtonsItems[index]?.title}</TitleText>
                <div>
                  <Button
                    type={"secondary"}
                    RightIcon={TrashIcon}
                    onClick={() => handleRemoveContact(item.contact_type)}
                  />
                </div>
              </FlexCenterBetween>
              <FlexCol gap={10}>
                <FlexItemCenter gap={20}>
                  <TextInput
                    label={name}
                    placeholder={"Lisa"}
                    value={item.firstname ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        const formValue = contactTypeToForm(item.contact_type);
                        return {
                          ...prevForm,
                          [formValue]: {
                            // @ts-ignore
                            ...prevForm[formValue],
                            firstname: text,
                          },
                        };
                      });
                    }}
                  />
                  <TextInput
                    label={lastname}
                    placeholder={"Montano"}
                    value={item.lastname ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        const formValue = contactTypeToForm(item.contact_type);
                        return {
                          ...prevForm,
                          [formValue]: {
                            // @ts-ignore
                            ...prevForm[formValue],
                            lastname: text,
                          },
                        };
                      });
                    }}
                  />
                </FlexItemCenter>
                <FlexItemCenter gap={20}>
                  <TextInput
                    type={"text"}
                    label={email}
                    placeholder={"lisa.montano@gmail.com"}
                    value={item.email ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        const formValue = contactTypeToForm(item.contact_type);
                        return {
                          ...prevForm,
                          [formValue]: {
                            // @ts-ignore
                            ...prevForm[formValue],
                            email: text,
                          },
                        };
                      });
                    }}
                  />
                  <PhoneInput
                    label={tel}
                    placeholder={"06 32 32 41 89"}
                    value={item.phone ?? ""}
                    onChangeText={(text: string) => {
                      setForm((prevForm) => {
                        const formValue = contactTypeToForm(item.contact_type);
                        return {
                          ...prevForm,
                          [formValue]: {
                            // @ts-ignore
                            ...prevForm[formValue],
                            phone: text,
                          },
                        };
                      });
                    }}
                  />
                </FlexItemCenter>
              </FlexCol>
            </>
          ),
        )}
      </FlexCol>
    </Card>
  );
};
