import React, { useEffect, useState } from "react";
import { useOperationalManagementMember } from "../../../hooks/api/operationalManagement";
import { OperationalManagementTeamMemberResponse } from "../../../types/GETTypes";
import { Loader } from "../Loader/Loader";
import { InputMultiSelect } from "../InputMultiSelect/InputMultiSelect";
import CloseIcon from "../../../assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import { PrestaInputProps } from "./PrestaInput.type";
import { cn } from "../../../helpers/classHelper";

/**
 * PrestaInput Component
 * This component displays a multi-select input where you can select multiple team members.
 * Selected members are displayed above the input with the ability to remove them using a close button.
 * The component handles data fetching and displays a loader while fetching the data.
 *
 * Be careful ! This component is an overlay of the InputMultiSelect component.
 *
 * @param {string} className - Additional classes for styling.
 * @param {string|null} error - An error message to display.
 * @param onChange
 * @param membersId
 */
export const PrestaInput = ({ className = "", error = "", onChange, membersId = []}: PrestaInputProps) => {
    // State to store the list of members fetched from the API
    const [members, setMembers] = useState<OperationalManagementTeamMemberResponse[] | null>(null);

    // State to store the ids of the selected members
    const [ids, setIds] = useState<number[]>(membersId);

    const { t } = useTranslation();

    // Hook to fetch the team members data from the API
    const managementMember = useOperationalManagementMember({
        onSuccess: (data) => {
            // @ts-ignore
            setMembers(data);
        },
        onError: () => {

        }
    });

    // Fetch the members when the component is mounted
    useEffect(() => {
        managementMember.getAll();  // Trigger API call to fetch all members
    }, [members]);

    /**
     * Handle member selection from the InputMultiSelect component.
     * Updates the state with the newly selected members.
     *
     * @param selectedValue - The selected members' ids.
     */
    const handleSelect = (selectedValue: any) => {
        setIds(selectedValue);  // Update the selected member ids
        onChange(selectedValue);
    };

    /**
     * Removes a member from the selected members list.
     * This function uses the splice method to remove the member at a specific index.
     *
     * @param index - The index of the member to remove from the list.
     */
    const removeMemberSelect = (index: number) => {
        const newIds = ids.filter((_, i) => i !== index);  // Remove the member at the given index
        setIds(newIds);  // Update the state with the new list of selected members
        onChange(newIds);
    };

    // If members are not fetched yet, show the loader


    return (
        <>
            <div className={cn("relative", className)}>
                {/* Display selected members above the input */}
                <div
                    className={cn("absolute z-10 flex items-center gap-2 h-full pl-1", error ? "-translate-y-0.5" : "mt-3")}
                >
                    {ids && (
                        <>
                            {/* Display the first two selected members */}
                            {ids.slice(0, 2).map((value, index) => {
                                const member = members?.find(m => m.id === value) ?? null;  // Find the member by id
                                return member ? (
                                    <div key={value} className="p-1 bg-gray-100 rounded-md flex items-center gap-2 px-2">
                                        <img alt="operator photo" src={member.photo} height={20} width={20} className="rounded-full" />
                                        {member.first_name} {member.last_name}
                                        <CloseIcon
                                            className="cursor-pointer"
                                            onClick={() => removeMemberSelect(index)}  // Call the remove function on click
                                        />
                                    </div>
                                ) : null;
                            })}

                            {/* Display '...' if there are more than 2 selected members */}
                            {ids.length > 2 && (
                                <div className="p-1 bg-gray-100 rounded-xl flex items-center gap-2 px-2">
                                    ...
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/* Multi-select input for selecting members */}
                <InputMultiSelect
                    label={t('Management.Payments.personToPaid')}
                    selectedValues={ids}
                    items={(members?.map(member => ({
                        label: `${member.first_name} ${member.last_name}`,
                        value: member.id
                    }))) ?? []}
                    onSelect={handleSelect}
                    error={error}
                />
            </div>
        </>
    );
};