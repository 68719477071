import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";

import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import {PaymentReservationResponse, PaymentReservationScheduleListItemResponse,} from "../../../../types/GETTypes";
import moment from "moment";
import {getPaymentStatusBadge} from "../../../../helpers/reservationHelper";
import {hiddenDevice} from "../../../../helpers/calendarHelper";

export const PaymentReservationListMulti: React.FC<{
  paymentReservation: PaymentReservationResponse;
  onTransactionDetailClick: (
    paymentReservationId: number,
    paymentSchedule: PaymentReservationScheduleListItemResponse
  ) => void;
}> = ({ paymentReservation, onTransactionDetailClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="font-bold text-low-contrast">
        {t("Payments.PaymentReservationList.transactionDetailsLabel")}
      </p>

      <div className="flex flex-col space-y-3">
        {paymentReservation?.payment_schedule.payments_schedule_items.map(
          (ps) => (
            <div className="flex items-center justify-between" key={ps.id}>
              <p className="text-base text-low-contrast">
                {ps.specific_date
                  ? `${t("Payments.PaymentReservationList.onDate", {
                      date: moment(ps.specific_date).format("DD/MM/YYYY"),
                    })} ${
                      ps.percentage_value ? `(${ps.percentage_value}%)` : ""
                    }`
                  : t("Global.notDefined")}
              </p>
              <div className="flex items-center space-x-3">
                {getPaymentStatusBadge(ps.payment_status)}
                <p className="font-bold text-high-contrast">{`${
                  typeof ps.price_value === "number"
                    ? ps.price_value?.toFixed(2)
                    : ps.price_value
                }${hiddenDevice(ps.price_value,t("Global.currencySymbol"))}`}</p>
                <Button
                  RightIcon={ArrowRightIcon}
                  type="secondary"
                  onClick={() =>
                    onTransactionDetailClick(paymentReservation?.id, ps)
                  }
                />
              </div>
            </div>
          )
        )}
      </div>

      <div className="flex items-center justify-between">
        <p className="text-base text-high-contrast">{t("Global.total")}</p>
        <p className="text-base font-bold text-high-contrast">
            {`
                ${typeof paymentReservation?.payment_schedule?.total_price_value === "number"
                        ? Number(paymentReservation.payment_schedule.total_price_value).toFixed(2)
                        : paymentReservation?.payment_schedule?.total_price_value
                }
                ${hiddenDevice(paymentReservation?.payment_schedule?.total_price_value, t("Global.currencySymbol"))}
            `}
        </p>
      </div>
    </>
  );
};
