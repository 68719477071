import React, { ReactNode } from "react";

export interface FlexColProps {
    children?: ReactNode;
    gap?: number;
    className?: string;
    visible?: boolean;
    id?: string;
}

export const FlexCol: React.FC<FlexColProps> = ({
    children,
    gap = 2,
    className = "",
    visible = true,
    id = "",
}) => {
    return (
        <section
            id={id}
            className={`flex flex-col ${className} ${!visible && "hidden"}`}
            style={{ gap: `${gap}px` }}
        >
            {children}
        </section>
    );
};
