import { Card } from "../../../Common/Card/Card";
import React from "react";
import { useTranslation } from "react-i18next";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import SendIcon from "../../../../assets/icons/send.svg?react";
import { getFormattedPrice } from "../../../../helpers/currencyHelper";
import { RecapPaymentListProps } from "../../../../pages/BookingInvoice/BookingInvoicePage.type";

export default function RecapPaymentList({
  modal,
  payments,
}: RecapPaymentListProps) {
  const { t } = useTranslation();

  const getTotalHT = (): number => {
    let amount = 0;
    payments.map((payment) => {
      amount += payment.total_ht;
    });
    return amount;
  };

  const getTotalTTC = (): number => {
    let amount = 0;
    payments.map((payment) => {
      amount += payment.total_ttc;
    });
    return amount;
  };

  return (
    <Card
      Icon={MoneyIcon}
      label={t("Booking.recapPayment")}
      classNames={{
        label: "text-lg",
        body: "p-0",
      }}
      button={{
        label: "Envoyer",
        Icon: SendIcon,
        onClick: () => {
          modal.open({
            receiptType: "ALL",
          });
        },
      }}
    >
      <table className="w-full table-auto">
        <thead>
          <tr className={"bg-gray-100"}>
            <th className="px-4 py-2">
              <div className={"items-center flex gap-1"}>{t("Intitulé")}</div>
            </th>
            <th className="px-4 py-2">
              <div className={"items-center flex gap-1"}>{t("Montant")}</div>
            </th>
            <th className="px-4 py-2">
              <div className={"items-center flex gap-1"}>{t("Quantité")}</div>
            </th>
            <th className="px-4 py-2">
              <div className={"items-center flex gap-1"}>{t("Total HT")}</div>
            </th>
            <th className="px-4 py-2">
              <div className={"items-center flex gap-1"}>{t("TVA")}</div>
            </th>
            <th className="px-4 py-2">
              <div className={"items-center flex gap-1"}>{t("Total TTC")}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr className={"font-bold"}>
              <td className="px-4 py-4 text-gray-500">{payment.label}</td>
              <td className="px-4 py-2">
                {getFormattedPrice({ price: payment.amount, decimals: 2 })}
              </td>
              <td className="px-4 py-2">{payment.quantity}</td>
              <td className="px-4 py-2">
                {getFormattedPrice({ price: payment.total_ht, decimals: 2 })}
              </td>
              <td className="px-4 py-2">{payment.tva} %</td>
              <td className="px-4 py-2">
                {getFormattedPrice({ price: payment.total_ttc, decimals: 2 })}
              </td>
            </tr>
          ))}

          <tr className={"font-bold"}>
            <td className="px-4 py-4">Total (EUR)</td>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2">
              {getFormattedPrice({ price: getTotalHT(), decimals: 2 })}
            </td>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2 text-red-500">
              {getFormattedPrice({ price: getTotalTTC(), decimals: 2 })}
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}
