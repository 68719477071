import moment from "moment";
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {Trans, useTranslation} from "react-i18next";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import {cn} from "../../../helpers/classHelper";
import {isCustomStringType} from "../../../helpers/validationHelper";
import {TextType} from "../../../types/commonTypes";
import {Button} from "../../Common/Button/Button";
import {CalendarInput} from "../../Common/CalendarInput/CalendarInput";
import {CalendarInputValue} from "../../Common/CalendarInput/CalendarInput.type";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import {NumberInput} from "../../Common/NumberInput/NumberInput";
import {Separator} from "../../Common/Separator/Separator";
import {SimpleRadio} from "../../Common/SimpleRadio/SimpleRadio";

import {PaymentAccountTypeEnum} from "../../../enums/GETenums";
import {
  useAppendPaymentPolicy,
  useDepositPaymentOptionItems,
  useGetTotalValue,
  usePaymentAccountsOptionItems,
  usePaymentPolicyItem,
  usePaymentPolicyValidation,
  useTriggerPaymentPolicyItems,
} from "../../Rental/Pricing/PaymentPolicy/PaymentPolicy.hooks";
import {
  PaymentPolicyDepositPaymentOption,
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyDepositPaymentOptionItems,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyPaymentTriggerItem,
  PaymentPolicyRefundCondition,
  PaymentPolicyRefundConditionEnum,
  PaymentPolicyRefundConditionItems,
  PaymentPolicyValueTypeEnum,
} from "../Payment.type";
import {
  PaymentScheduleAndConditionsInfoTextItems,
  PaymentScheduleAndConditionsProps,
} from "./ScheduleAndConditions.type";

export const PaymentScheduleAndConditions: React.FC<
  PaymentScheduleAndConditionsProps
> = ({
  useMode,
  reservation,
  form,
  formData,
  nonModifiable,
  paymentPolicyFields,
  paymentPolicyAppend,
  paymentPolicyUpdate,
  paymentPolicyRemove,
  paymentAccounts,
  requiredFields,
  totalPrice,
  infoTextItems,
  onError,
}) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = form;

  const [date, setDate] = useState("");

  const [valueType, setValueType] = useState<PaymentPolicyValueTypeEnum>(
    PaymentPolicyValueTypeEnum.PERCENT
  );
  const [totalValue, setTotalValue] = useState<number>(0);
  const [amountPaymentItems, setAmountPaymentItems] = useState<
    ([number, ...number[]] & { length: 1 | 2 | 3 }) | undefined
  >();

  const MAX_CHECKIN_DIFF_DAYS = 3;
  const today = moment(new Date());
  const checkinDiffDays = moment(reservation?.general?.checkin).diff(
    today,
    "days"
  );

  /**
   * We set the value to 0 when it's a single payment
   * otherwise, if it's a multiple payment, we set the value to the sum of all payments.
   */
  useEffect(() => {
    if (getValues("is_single_payment")) {
      setTotalValue(100);
    } else if (!getValues("is_single_payment")) {
      setTotalValue(useGetTotalValue(getValues("payments_policy_items")));
    }
  }, [getValues("is_single_payment"), getValues("payments_policy_items")]);

  usePaymentPolicyValidation(
    useMode,
    form,
    totalValue,
    valueType,
    totalPrice,
    t,
    onError
  );

  /**
   * Function for calculating the maximum
   * number of days to add caution delay
   */
  const getMaxValueForCautionDayPayment = (): number => {
    let checkinDate = moment(reservation?.general?.checkin).startOf('day');
    let todayDate = moment().startOf('day');
    return checkinDate.diff(todayDate, 'days');
  };

  // * -- DEPOSIT --
  const getDepositPaymentTypeInfo = (): TextType => {
    switch (getValues("deposit_payment_option")) {
      case PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION:
        return t("Payments.preAuthorisationInfo");
      case PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND:
        return t("Payments.cbRefundInfo");
      case PaymentPolicyDepositPaymentOptionEnum.BANK_TRANSFER_TO_REFUND:
        return t("Payments.sepaRefundInfo");
      default:
        return "";
    }
  };

  const hasStripeAccount: boolean = Boolean(
    paymentAccounts.filter(
      (v) => v.type.toLowerCase() === PaymentAccountTypeEnum.STRIPE
    ).length > 0
  );

  // * TYPES CHECKING
  const isPaymentPolicyRefundCondition = (
    value: string
  ): value is PaymentPolicyRefundCondition =>
    PaymentPolicyRefundConditionItems.includes(value);

  const isDepositPaymentOption = (
    value: string
  ): value is PaymentPolicyDepositPaymentOption =>
    PaymentPolicyDepositPaymentOptionItems.includes(value);

  // * Filled the form fields with data for update payment policies
  useEffect(() => {
    if (formData) {
      console.log("form data : ", formData.deposit_value);
      setValue(
        "is_single_payment",
        checkinDiffDays < MAX_CHECKIN_DIFF_DAYS && useMode === "reservation"
          ? true
          : Boolean(formData.is_single_payment)
      );

      // * SET REFUND VALUES
      setValue(
        "is_refundable",
        checkinDiffDays < MAX_CHECKIN_DIFF_DAYS && useMode === "reservation"
          ? false
          : Boolean(formData.is_refundable)
      );
      setValue("refund_value", formData.refund_value);
      setValue(
        "refund_condition",
        isCustomStringType<PaymentPolicyRefundCondition>(
          formData.refund_condition,
          isPaymentPolicyRefundCondition
        )
          ? formData.refund_condition
          : PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
      );

      // * SET DEPOSIT VALUES
      setValue(
        "is_deposit_required",
        useMode === "reservation" ? true : Boolean(formData.is_deposit_required)
      );

      setValue(
        "deposit_value",
        formData.deposit_value ?? form.getValues("deposit_value")
      );
      setValue(
        "deposit_payment_option",
        isCustomStringType<PaymentPolicyDepositPaymentOption>(
          formData.deposit_payment_option,
          isDepositPaymentOption
        )
          ? formData.deposit_payment_option
          : hasStripeAccount
          ? PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
          : PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
      );
      setValue(
        "deposit_payment_account_id",
        formData.deposit_payment_account_id
      );
      setValue(
        "deposit_payment_days_delay",
        formData.deposit_payment_days_delay
      );
      setValue("is_favorite_policy", Boolean(formData.is_favorite_policy));
    } else {
      reset();
    }
  }, [formData]);

  const handleChangeIsRefundable = (e: any) => {
    const isRefundable: boolean = e.target.value;
    if (isRefundable) {
      if (formData) {
        setValue("refund_value", formData.refund_value ?? 1);
        setValue(
          "refund_condition",
          isCustomStringType<PaymentPolicyRefundCondition>(
            formData.refund_condition,
            isPaymentPolicyRefundCondition
          )
            ? formData.refund_condition
            : PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
        );
      } else {
        setValue("refund_value", 1);
        setValue(
          "refund_condition",
          PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
        );
      }
    } else {
      setValue("refund_value", null);
      setValue("refund_condition", null);
    }
  };

  const handleChangeIsDepositRequired = (e: any) => {
    const isDepositRequired: boolean = e.target.value;
    if (isDepositRequired) {
      if (formData) {
        setValue(
          "deposit_value",
          formData.deposit_value ?? form.getValues("deposit_value")
        );
        setValue(
          "deposit_payment_option",
          isCustomStringType<PaymentPolicyDepositPaymentOption>(
            formData.deposit_payment_option,
            isDepositPaymentOption
          ) && hasStripeAccount
            ? formData.deposit_payment_option
            : PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
        );
        setValue(
          "deposit_payment_account_id",
          formData.deposit_payment_account_id
        );
        setValue(
          "deposit_payment_days_delay",
          formData.deposit_payment_days_delay
        );
      } else {
        setValue("deposit_value", form.getValues("deposit_value"));
        setValue(
          "deposit_payment_option",
          hasStripeAccount
            ? PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
            : PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
        );
        setValue("deposit_payment_account_id", paymentAccounts[0].id);
        setValue("deposit_payment_days_delay", 1);
      }
    } else {
      setValue("deposit_value", null);
      setValue("deposit_payment_option", null);
      setValue("deposit_payment_account_id", null);
      setValue("deposit_payment_days_delay", null);
    }
  };

  // * HANDLE PAYMENTS POLICY ITEMS VALUES
  useEffect(() => {
    if (formData) {
      if (
        formData.payments_policy_items &&
        formData.payments_policy_items.length > 0
      ) {
        paymentPolicyRemove();

        if (formData.is_single_payment || getValues("is_single_payment")) {
          if (useGetTotalValue(getValues("payments_policy_items")) !== 100) {
            paymentPolicyAppend({
              trigger: formData.payments_policy_items[0]
                .trigger as PaymentPolicyPaymentTriggerItem,
              valueType: PaymentPolicyValueTypeEnum.PERCENT,
              value: 100,
              specificDate: moment(formData.payments_policy_items[0].specific_date, 'DD/MM/YYYY').toDate(),
              paymentAccountId:
                formData.payments_policy_items[0].payment_account?.id ?? null,
            });

            setAmountPaymentItems([100]);
          }
        } else if (
          !formData.is_single_payment ||
          !getValues("is_single_payment")
        ) {
          paymentPolicyAppend(
            formData.payments_policy_items.map((v) => {
              form.setValue('payments_policy_items.0.specificDate', moment(v.specific_date).toDate())
              return {
                trigger: v.trigger as PaymentPolicyPaymentTriggerItem,
                valueType: v.value_type as PaymentPolicyValueTypeEnum,
                value: v.value,
                specificDate: moment(v.specific_date).toDate(),
                paymentAccountId: v.payment_account?.id ?? null,
              };
            })
          );
        }
      }
    } else {
      if (getValues("payments_policy_items").length === 0) {
        paymentPolicyAppend({
          trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
          valueType: PaymentPolicyValueTypeEnum.PERCENT,
          value: 100,
          specificDate: new Date(),
          paymentAccountId: null,
        });

        setAmountPaymentItems([100]);
      }
    }
  }, [formData, getValues("is_single_payment")]);

  //  * TODO: [ScheduleAndConditions] Implement the total amount by payment items
  // useEffect(() => {
  //   console.log("update pp : ", getValues("payments_policy_items"));
  //   if (getValues("payments_policy_items").length > 0) {
  //     console.log(
  //       "-----------------------______________________________________----------------------------"
  //     );
  //     console.log("total price: ", totalPrice);
  //     getValues("payments_policy_items").forEach((v) => {
  //       setAmountPaymentItems((prevValue) => {
  //         if (prevValue) {
  //           let newValue = [...prevValue];

  //           if (v.valueType === PaymentPolicyValueTypeEnum.FIXED) {
  //             console.log("fixed : ", v.value);
  //             newValue = [...newValue, v.value];
  //           } else if (
  //             v.valueType === PaymentPolicyValueTypeEnum.PERCENT &&
  //             totalPrice
  //           ) {
  //             console.log("percent : ", (totalPrice * v.value) / 100);
  //             newValue = [...newValue, (totalPrice * v.value) / 100];
  //           }

  //           if (newValue.length <= 3) {
  //             return newValue as [number, ...number[]] & {
  //               length: 1 | 2 | 3;
  //             };
  //           }
  //         }
  //         return [v.value];
  //       });
  //     });
  //   }
  // }, [getValues("payments_policy_items")]);

  // useEffect(() => {
  //   if (amountPaymentItems) {
  //     console.log("amountPaymentItems : ", amountPaymentItems);
  //     console.log(
  //       "-----------------------______________________________________----------------------------"
  //     );
  //   }
  // }, [amountPaymentItems]);

  useEffect(() => {
    console.log("payment accounts : ", paymentAccounts);
    if (paymentAccounts && paymentAccounts.length > 0) {
      if (getValues("is_single_payment")) {
        if (getValues("payments_policy_items.0.paymentAccountId") === null) {
          setValue(
            "payments_policy_items.0.paymentAccountId",
            paymentAccounts[0].id
          );
        }
      } else if (!getValues("is_single_payment")) {
        getValues("payments_policy_items").forEach((pp, index) => {
          if (pp.paymentAccountId === null) {
            setValue(
              `payments_policy_items.${index}.paymentAccountId`,
              paymentAccounts[index].id
            );
          }
        });
      }

      if (
        getValues("deposit_payment_account_id")! > 0 ||
        getValues("deposit_payment_account_id") === null
      ) {
        setValue("deposit_payment_account_id", paymentAccounts[0].id);
      }
    }
  }, [getValues("is_single_payment"), paymentAccounts]);

  // * -- INFO TEXT ITEMS --
  type PaymentScheduleAndConditionsInfoTextItemsOutput = Omit<
    PaymentScheduleAndConditionsInfoTextItems,
    "trigger" | "refund" | "cbRefund"
  > & {
    trigger?: {
      i18nKey: string | undefined;
      i18nValues?: {
        date: string | undefined;
      };
    };
    refund?: {
      i18nKey: string | undefined;
      i18nValues?: { date: string | undefined; amount: string | undefined };
    };
    cbRefund?: {
      i18nKey: string | undefined;
      i18nValues?: {
        date: string | undefined;
      };
    };
  };

  const [defaultInfoTextItems, setDefaultInfoTextItems] = useState<
    PaymentScheduleAndConditionsInfoTextItems | undefined
  >();
  const [currentInfoTextItems, setCurrentInfoTextItems] = useState<
    PaymentScheduleAndConditionsInfoTextItemsOutput | undefined
  >();

  useEffect(() => {
    if (infoTextItems) {
      if (defaultInfoTextItems === undefined) {
        setDefaultInfoTextItems(infoTextItems);
      }
    }
  }, [infoTextItems]);

  // * Handle trigger info text
  useEffect(() => {
    let triggerValue = getValues("payments_policy_items.0.trigger");
    if (triggerValue) {
      if (defaultInfoTextItems) {
        setCurrentInfoTextItems((prevValue) => {
          let subtractDays: number | undefined = undefined;
          if (triggerValue === PaymentPolicyPaymentTriggerEnum.AT_RESERVATION) {
            subtractDays = undefined;
          } else if (
            triggerValue ===
            PaymentPolicyPaymentTriggerEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
          ) {
            subtractDays = 1;
          } else if (
            triggerValue ===
            PaymentPolicyPaymentTriggerEnum.FIVE_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 5;
          } else if (
            triggerValue ===
            PaymentPolicyPaymentTriggerEnum.SEVEN_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 7;
          } else if (
            triggerValue ===
            PaymentPolicyPaymentTriggerEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 14;
          } else if (
            triggerValue ===
            PaymentPolicyPaymentTriggerEnum.THIRTY_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 30;
          } else if (
            triggerValue ===
            PaymentPolicyPaymentTriggerEnum.SIXTY_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 60;
          }

          let triggerObject;

          if (triggerValue === PaymentPolicyPaymentTriggerEnum.AT_CHECKIN) {
            triggerObject = {
              ...defaultInfoTextItems.trigger,
              i18nValues: {
                ...defaultInfoTextItems.trigger?.i18nValues,
                date: moment(reservation?.general?.checkin).format("DD MMMM"),
              },
            };
          } else {
            triggerObject = {
              ...defaultInfoTextItems.trigger,
              i18nValues: {
                ...defaultInfoTextItems.trigger?.i18nValues,
                date: moment(defaultInfoTextItems.trigger?.i18nValues?.date)
                  .subtract(subtractDays, "days")
                  .format("DD MMMM"),
              },
            };
          }

          if (prevValue) {
            return {
              ...prevValue,
              trigger: triggerObject,
            };
          }

          return {
            trigger: triggerObject,
          };
        });
      }
    }
  }, [defaultInfoTextItems, getValues("payments_policy_items.0.trigger")]);

  // * Handle refund info text
  useEffect(() => {
    const refundValue: number | null = getValues("refund_value");
    const refundCondition: PaymentPolicyRefundCondition | null =
      getValues("refund_condition");

    if (refundValue || refundCondition) {
      if (defaultInfoTextItems) {
        setCurrentInfoTextItems((prevValue) => {
          let subtractDays: number | undefined = undefined;
          if (
            refundCondition === PaymentPolicyRefundConditionEnum.AT_RESERVATION
          ) {
            subtractDays = undefined;
          } else if (
            refundCondition ===
            PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
          ) {
            subtractDays = 1;
          } else if (
            refundCondition ===
            PaymentPolicyRefundConditionEnum.FIVE_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 5;
          } else if (
            refundCondition ===
            PaymentPolicyRefundConditionEnum.SEVEN_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 7;
          } else if (
            refundCondition ===
            PaymentPolicyRefundConditionEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 14;
          } else if (
            refundCondition ===
            PaymentPolicyRefundConditionEnum.THIRTY_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 30;
          } else if (
            refundCondition ===
            PaymentPolicyRefundConditionEnum.SIXTY_DAYS_BEFORE_ARRIVAL
          ) {
            subtractDays = 60;
          }

          const amount = Number(
            defaultInfoTextItems?.refund?.i18nValues?.amount
          );
          const date = defaultInfoTextItems?.refund?.i18nValues?.date;
          let refundObject = {};

          if (refundValue) {
            refundObject = {
              ...defaultInfoTextItems.refund,
              i18nValues: {
                ...defaultInfoTextItems.trigger?.i18nValues,
                date: moment(date)
                  .subtract(subtractDays, "days")
                  .format("DD MMMM"),
                amount: `${((amount * refundValue) / 100)
                  .toFixed(2)
                  .replace(".", ",")} ${t("Global.currencySymbol")}`,
              },
            };
          }

          if (prevValue) {
            return {
              ...prevValue,
              refund: refundObject,
            };
          }

          return {
            refund: refundObject,
          };
        });
      }
    }
  }, [
    defaultInfoTextItems,
    getValues("refund_value"),
    getValues("refund_condition"),
  ]);

  // * Handle CB refund info text
  useEffect(() => {
    const depositPaymentOption = getValues("deposit_payment_option");
    const depositPaymentDaysDelay = getValues("deposit_payment_days_delay");

    if (depositPaymentOption || depositPaymentDaysDelay) {
      if (defaultInfoTextItems) {
        setCurrentInfoTextItems((prevValue) => {
          const date: Date | undefined =
            defaultInfoTextItems?.cbRefund?.i18nValues?.date;
          let key: string | undefined = undefined;
          let values: {} | undefined = {};

          if (
            depositPaymentOption ===
            PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND
          ) {
            key = "Payments.depositPaymentTimeDelayCBRefundInfo";

            values = {
              date: moment(date)
                .subtract(depositPaymentDaysDelay, "days")
                .format("DD MMMM YYYY"),
            };
          } else if (
            depositPaymentOption ===
              PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION ||
            depositPaymentOption ===
              PaymentPolicyDepositPaymentOptionEnum.BANK_TRANSFER_TO_REFUND ||
            depositPaymentOption ===
              PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
          ) {
            key = undefined;
          }

          const cbRefundObject = {
            i18nKey: key,
            i18nValues: values,
          };

          if (prevValue) {
            return {
              ...prevValue,
              cbRefund: cbRefundObject,
            };
          }

          return {
            cbRefund: cbRefundObject,
          };
        });
      }
    }
  }, [
    defaultInfoTextItems,
    getValues("deposit_payment_option"),
    getValues("deposit_payment_days_delay"),
  ]);

  return (
    <div className="flex flex-col gap-y-3">
      {/* -- PAYMENT SCHEDULE -- */}
      <p className="text-lg font-semibold leading-normal tracking-wide text-high-contrast">
        {t("Payments.paymentScheduleLabel")}
      </p>

      {checkinDiffDays < MAX_CHECKIN_DIFF_DAYS && useMode === "reservation" && (
        <p className="text-error">{t("Payments.singlePaymentError")}</p>
      )}

      <div className="flex space-x-6">
        <Controller
          control={control}
          name="is_single_payment"
          render={({ field: { onChange, value } }) => (
            <>
              <SimpleRadio
                label={t("Payments.singlePaymentLabel")}
                disabled={nonModifiable && useMode === "rental"}
                classNames={{
                  label: [
                    value ? "text-active" : "text-low-contrast",
                    "tracking-wide",
                  ],
                }}
                onClick={() => onChange(true)}
                value={value}
              />
              <SimpleRadio
                label={t("Payments.multiplePaymentsLabel")}
                disabled={
                  (nonModifiable && useMode === "rental") ||
                  (checkinDiffDays < MAX_CHECKIN_DIFF_DAYS &&
                    useMode === "reservation")
                }
                classNames={{
                  label: [
                    !value ? "text-active" : "text-low-contrast",
                    "tracking-wide",
                  ],
                }}
                onClick={() => onChange(false)}
                value={!value}
              />
            </>
          )}
        />
      </div>

      {getValues("is_single_payment") ? (
        <div className="flex flex-col gap-y-3">
          <div className="flex space-x-3">
            <div className="flex-1">
              <InputSelect
                register={register(`payments_policy_items.0.trigger`, {
                  required: {
                    value: requiredFields.payments_policy_items,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.paymentTriggerLabel"),
                    }),
                  },
                })}
                required={requiredFields.payments_policy_items}
                disabled={nonModifiable && useMode === "rental"}
                label={t("Payments.paymentTriggerLabel")}
                infoTextI18nKey={currentInfoTextItems?.trigger?.i18nKey}
                infoTextI18nValues={{
                  date: moment(
                      getValues("payments_policy_items.0.specificDate")
                  ).format("DD MMMM")
                }}
                items={useTriggerPaymentPolicyItems(useMode, true)}
                selectedValue={getValues(`payments_policy_items.0.trigger`)}
              />
            </div>
            {getValues("payments_policy_items.0.trigger") ===
            PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE ? (
              <div className="flex-1">
                <CalendarInput
                  anchor={"end"}
                  required={requiredFields.payments_policy_items}
                  register={register("payments_policy_items.0.specificDate", {
                    required: {
                      value: requiredFields.payments_policy_items,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t("Payments.specificDate"),
                      }),
                    },
                  })}
                  classNames={{
                    button:
                      "h-9 bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm",
                  }}
                  disabled={nonModifiable && useMode === "rental"}
                  dateType="default"
                  label={t("Payments.specificDate")}
                  onChangeDate={(value: CalendarInputValue) => {
                    setValue(
                      "payments_policy_items.0.specificDate",
                      value as Date
                    );
                    setDate(value?.toString() ?? "")
                    setCurrentInfoTextItems((prevValue) => {
                      let triggerObject = undefined;

                      if (prevValue && prevValue.trigger) {
                        triggerObject = {
                          ...prevValue.trigger,
                          i18nValues: {
                            date: moment(
                              getValues("payments_policy_items.0.specificDate")
                            ).format("DD MMMM"),
                          },
                        };

                        return {
                          ...prevValue,
                          trigger: triggerObject,
                        };
                      }

                      return {
                        trigger: triggerObject,
                      };
                    });
                  }}
                  isExcludeDate={(date: Date) =>
                    Boolean(moment(date).isBefore(moment(), "day"))
                  }
                  value={getValues("payments_policy_items.0.specificDate")}
                />
              </div>
            ) : (
              <div className="flex-1">
                <InputSelect
                  required={requiredFields.payments_policy_items}
                  register={register(
                    "payments_policy_items.0.paymentAccountId",
                    {
                      required: {
                        value: requiredFields.payments_policy_items,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("Payments.meansOfPaymentLabel"),
                        }),
                      },
                    }
                  )}
                  disabled={nonModifiable && useMode === "rental"}
                  label={t("Payments.meansOfPaymentLabel")}
                  items={usePaymentAccountsOptionItems(paymentAccounts)}
                  selectedValue={
                    getValues("payments_policy_items.0.paymentAccountId") ?? ""
                  }
                />
              </div>
            )}
          </div>
          {getValues("payments_policy_items.0.trigger") ===
          PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE ? (
            <div className="flex space-x-3">
              <div className="flex-1">
                <InputSelect
                  required={requiredFields.payments_policy_items}
                  register={register(
                    "payments_policy_items.0.paymentAccountId",
                    {
                      required: {
                        value: requiredFields.payments_policy_items,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("Payments.meansOfPaymentLabel"),
                        }),
                      },
                    }
                  )}
                  disabled={nonModifiable && useMode === "rental"}
                  label={t("Payments.meansOfPaymentLabel")}
                  items={usePaymentAccountsOptionItems(paymentAccounts)}
                  selectedValue={
                    getValues("payments_policy_items.0.paymentAccountId") ?? ""
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <>
          {paymentPolicyFields.length > 0
            ? paymentPolicyFields.map((field, index: number) => (
                <div key={field.id}>
                  {usePaymentPolicyItem(
                    index,
                    useMode,
                    form,
                    requiredFields,
                    nonModifiable,
                    paymentPolicyUpdate,
                    paymentPolicyRemove,
                    paymentAccounts,
                    totalPrice,
                    [amountPaymentItems, setAmountPaymentItems],
                    setTotalValue,
                    [valueType, setValueType]
                  )}
                </div>
              ))
            : null}

          {paymentPolicyFields.length < 3 ? (
            <div>
              <Button
                type="secondary"
                RightIcon={PlusIcon}
                disabled={
                  (useMode === "rental" && totalValue >= 100) ||
                  (useMode === "reservation" &&
                    ((valueType === PaymentPolicyValueTypeEnum.PERCENT &&
                      totalPrice &&
                      totalValue >= 100) ||
                      (valueType === PaymentPolicyValueTypeEnum.FIXED &&
                        totalPrice &&
                        totalValue >= totalPrice))) ||
                  (nonModifiable && useMode === "rental")
                }
                onClick={() =>
                  useAppendPaymentPolicy(
                    useMode,
                    paymentPolicyAppend,
                    getValues,
                    totalPrice,
                    valueType,
                    [totalValue, setTotalValue],
                    paymentAccounts
                  )
                }
              >
                {t(
                  "Rental.Pricing.PaymentPolicy.CreateOrUpdateModal.addAnotherPayment"
                )}
              </Button>
            </div>
          ) : null}
        </>
      )}

      <Separator />

      {/* -- REFUND -- */}
      <p className="text-lg font-semibold leading-normal tracking-wide text-high-contrast">
        {t("Payments.cancellationConditionsLabel")}
      </p>

      {checkinDiffDays < MAX_CHECKIN_DIFF_DAYS && useMode === "reservation" && (
        <p className="text-error">
          {t("Payments.cancellationConditionsError")}
        </p>
      )}

      <div className="flex space-x-6">
        <Controller
          control={control}
          name="is_refundable"
          rules={{
            onChange: (e) => handleChangeIsRefundable(e),
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <SimpleRadio
                label={t("Payments.nonRefundableLabel")}
                disabled={nonModifiable && useMode === "rental"}
                classNames={{
                  label: [
                    !value ? "text-active" : "text-low-contrast",
                    "tracking-wide",
                  ],
                }}
                onClick={() => onChange(false)}
                value={!value}
              />
              <SimpleRadio
                label={t("Payments.refundableLabel")}
                disabled={
                  (nonModifiable && useMode === "rental") ||
                  (checkinDiffDays < MAX_CHECKIN_DIFF_DAYS &&
                    useMode === "reservation")
                }
                classNames={{
                  label: [
                    value ? "text-active" : "text-low-contrast",
                    "tracking-wide",
                  ],
                }}
                onClick={() => onChange(true)}
                value={value}
              />
            </>
          )}
        />
      </div>

      {getValues("is_refundable") ? (
        <div className="flex flex-col gap-x-2">
          <div
            className={cn(`flex items-center space-x-3`, {
              "items-start": errors.refund_value?.message,
            })}
          >
            <div className="flex-1">
              <NumberInput
                register={register("refund_value", {
                  required: {
                    value: requiredFields.refund_value,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.refundPercentageLabel"),
                    }),
                  },
                  validate: (value) =>
                    Boolean(value) ||
                    t("Payments.refundPercentageValueError", {
                      maxValue: 100,
                    }).toString(),
                })}
                disabled={nonModifiable && useMode === "rental"}
                required={requiredFields.refund_value}
                placeholder={t("Payments.refundPercentagePlaceholder")}
                label={t("Payments.refundPercentageLabel")}
                max={100}
                value={getValues("refund_value")}
                currency="%"
                error={errors.refund_value?.message}
              />
            </div>
            <div className="flex-1">
              <InputSelect
                register={register("refund_condition", {
                  required: {
                    value: requiredFields.refund_condition,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.refundConditionLabel"),
                    }),
                  },
                })}
                required={requiredFields.refund_condition}
                disabled={nonModifiable && useMode === "rental"}
                label={t("Payments.refundConditionLabel")}
                items={[
                  {
                    label: t(
                      "Payments.RefundConditions.TwentyFourHoursBeforeArrival"
                    ),
                    value:
                      PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL,
                  },
                  {
                    label: t("Payments.RefundConditions.FiveDaysBeforeArrival"),
                    value:
                      PaymentPolicyRefundConditionEnum.FIVE_DAYS_BEFORE_ARRIVAL,
                  },
                  {
                    label: t(
                      "Payments.RefundConditions.SevenDaysBeforeArrival"
                    ),
                    value:
                      PaymentPolicyRefundConditionEnum.SEVEN_DAYS_BEFORE_ARRIVAL,
                  },
                  {
                    label: t(
                      "Payments.RefundConditions.FourteenDaysBeforeArrival"
                    ),
                    value:
                      PaymentPolicyRefundConditionEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL,
                  },
                  {
                    label: t(
                      "Payments.RefundConditions.ThirtyDaysBeforeArrival"
                    ),
                    value:
                      PaymentPolicyRefundConditionEnum.THIRTY_DAYS_BEFORE_ARRIVAL,
                  },
                  {
                    label: t("Payments.RefundConditions.SixtyBeforeArrival"),
                    value:
                      PaymentPolicyRefundConditionEnum.SIXTY_DAYS_BEFORE_ARRIVAL,
                  },
                ]}
                selectedValue={
                  getValues("refund_condition") ??
                  PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
                }
              />
            </div>
          </div>
          <p className="mt-2 text-low-contrast">
            {currentInfoTextItems?.refund?.i18nKey &&
            currentInfoTextItems.refund.i18nValues ? (
              <Trans
                i18nKey={currentInfoTextItems?.refund?.i18nKey}
                values={currentInfoTextItems?.refund?.i18nValues}
              />
            ) : null}
          </p>
        </div>
      ) : null}

      <Separator />

      {/* -- DEPOSIT -- */}
      <p className="text-lg font-semibold leading-normal tracking-wide text-high-contrast">
        {t("Payments.depositLabel")}
      </p>

      <div className="flex space-x-6">
        <Controller
          control={control}
          name="is_deposit_required"
          rules={{
            onChange: (e) => handleChangeIsDepositRequired(e),
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <SimpleRadio
                label={t("Payments.notMandatoryLabel")}
                disabled={nonModifiable && useMode === "rental"}
                classNames={{
                  label: [
                    !value ? "text-active" : "text-low-contrast",
                    "tracking-wide",
                  ],
                }}
                onClick={() => onChange(false)}
                value={!value}
              />
              <SimpleRadio
                label={t("Payments.mandatoryLabel")}
                disabled={nonModifiable && useMode === "rental"}
                classNames={{
                  label: [
                    value ? "text-active" : "text-low-contrast",
                    "tracking-wide",
                  ],
                }}
                onClick={() => onChange(true)}
                value={value}
              />
            </>
          )}
        />
      </div>

      {getValues("is_deposit_required") ? (
        <>
          <InputSelect
            register={register("deposit_payment_option", {
              required: {
                value: requiredFields.deposit_payment_option,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Payments.depositPaymentOptionLabel"),
                }),
              },
            })}
            required={requiredFields.deposit_payment_option}
            disabled={nonModifiable && useMode === "rental"}
            label={t("Payments.depositPaymentOptionLabel")}
            items={useDepositPaymentOptionItems(paymentAccounts)}
            selectedValue={
              getValues("deposit_payment_option") ??
              PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
            }
          />

          <p className="text-xs leading-normal tracking-wide text-low-contrast">
            {getDepositPaymentTypeInfo()}
          </p>

          <div
            className={cn(`flex items-center space-x-3`, {
              "items-start": errors.deposit_value?.message,
            })}
          >
            <div className="flex-1">
              <NumberInput
                register={register("deposit_value", {
                  required: {
                    value: requiredFields.deposit_value,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Payments.depositAmountLabel"),
                    }),
                  },
                  validate: (value) =>
                    Boolean(value) ||
                    t("Payments.depositAmountValueError", {
                      maxValue: 100000,
                      currency: t("Global.currencySymbol"),
                    }).toString(),
                })}
                disabled={nonModifiable && useMode === "rental"}
                label={t("Payments.depositAmountLabel")}
                placeholder={t("Payments.depositAmountPlaceholder")}
                required={requiredFields.deposit_value}
                max={100000}
                value={getValues("deposit_value")}
                currency={t("Global.currencySymbol")}
                error={errors.deposit_value?.message}
              />
            </div>
            {getValues("deposit_payment_option") !==
              PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL &&
            hasStripeAccount ? (
              <div className="flex-1">
                <InputSelect
                  register={register("deposit_payment_account_id", {
                    required: {
                      value: requiredFields.deposit_payment_account_id,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t("Payments.accountChoice"),
                      }),
                    },
                  })}
                  required={requiredFields.deposit_payment_account_id}
                  disabled={nonModifiable && useMode === "rental"}
                  label={t("Payments.accountChoice")}
                  items={usePaymentAccountsOptionItems(paymentAccounts, true)}
                  selectedValue={getValues("deposit_payment_account_id") ?? ""}
                />
              </div>
            ) : null}
          </div>

          {getValues("deposit_payment_option") ===
          PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION ? (
            <div className="flex flex-col gap-y-0.5">
              {reservation?.general?.checkin && reservation.general.checkout ? (
                <p className="text-xs leading-normal tracking-wide text-low-contrast">
                  <Trans
                    i18nKey="Payments.depositInfo1"
                    components={{
                      strong: <span className="font-bold"></span>,
                    }}
                    values={{
                      startDate: moment(reservation.general.checkin)
                        .format("DD MMMM YYYY")
                        .toString(),
                      endDate: moment(reservation.general.checkin)
                        .add(7, "days")
                        .format("DD MMMM YYYY")
                        .toString(),
                    }}
                  />
                </p>
              ) : null}

              <p className="text-xs leading-normal tracking-wide text-low-contrast">
                {t("Payments.depositInfo2")}
              </p>
            </div>
          ) : null}

          {/* DEPOSIT PAYMENT DAYS DELAY */}
          {getValues("deposit_payment_option") ===
          PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND ? (
            <div className="flex items-start gap-x-3">
              <div className="flex-1">
                <NumberInput
                  register={register("deposit_payment_days_delay", {
                    required: {
                      value: requiredFields.deposit_payment_days_delay,
                      message: t("Global.Errors.requiredField", {
                        fieldName:
                          getValues("deposit_payment_option") ===
                          PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
                            ? t(
                                "Payments.depositPaymentTimeDelayPreAuthorisationInfoLabel"
                              )
                            : t("Payments.depositPaymentTimeDelayLabel"),
                      }),
                    },
                  })}
                  disabled={nonModifiable && useMode === "rental"}
                  required={requiredFields.deposit_payment_days_delay}
                  label={
                    getValues("deposit_payment_option") ===
                    PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
                      ? t(
                          "Payments.depositPaymentTimeDelayPreAuthorisationInfoLabel"
                        )
                      : t("Payments.depositPaymentTimeDelayLabel")
                  }
                  infoTextI18nKey={currentInfoTextItems?.cbRefund?.i18nKey}
                  infoTextI18nValues={
                    currentInfoTextItems?.cbRefund?.i18nValues
                  }
                  min={1}
                  max={getMaxValueForCautionDayPayment()}
                  value={getValues("deposit_payment_days_delay")}
                />
              </div>
              <p className="mt-8 text-low-contrast">
                {t("Payments.daysBeforeArrival")}
              </p>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
