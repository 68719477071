import {useTranslation} from "react-i18next";
import {CalendarRestrictionUnavailabilityReasonEnum} from "../enums/GETenums";
import moment from "moment";

export const displayUnavailabilityReason = (
  reason: CalendarRestrictionUnavailabilityReasonEnum
) => {
  const { t } = useTranslation();

  switch (reason) {
    case CalendarRestrictionUnavailabilityReasonEnum.HOLIDAYS:
      return t("Calendar.UnavailabilityReason.holidays");
    case CalendarRestrictionUnavailabilityReasonEnum.CONSTRUCTION:
      return t("Calendar.UnavailabilityReason.construction");
    case CalendarRestrictionUnavailabilityReasonEnum.HOUSEWORK:
      return t("Calendar.UnavailabilityReason.housework");
    case CalendarRestrictionUnavailabilityReasonEnum.CLOSED:
      return t("Calendar.UnavailabilityReason.closed");
    case CalendarRestrictionUnavailabilityReasonEnum.NOT_AVAILABLE:
      return t("Calendar.UnavailabilityReason.unavailable");
    default:
      return t("Calendar.UnavailabilityReason.unavailable");
  }
};

type ScheduleForm = {
  content?: string;
  scheduleDate: Date;
  scheduleTime: string;
};

export const validateDate = (
  values: ScheduleForm
): {
  isValid: boolean;
  reason: "day" | "time" | "" | "missing";
  value: string;
} => {
  const now = new Date();
  const today = moment().startOf("day").toDate();
  const scheduleDate = moment(values.scheduleDate).startOf("day").toDate();
  const scheduleTime = values.scheduleTime;

  // Check if empty schedule date or time
  if (!scheduleDate || !scheduleTime) {
    return {
      isValid: false,
      reason: "missing",
      value: "",
    };
  }

  // Check if the schedule date is before today
  if (scheduleDate < today) {
    return {
      isValid: false,
      reason: "day",
      value: moment(scheduleDate).format("DD/MM/YYYY"),
    };
  }

  if (scheduleDate.getTime() === today.getTime()) {
    const scheduleDateTime = moment(
      `${moment(scheduleDate).format("YYYY-MM-DD")} ${scheduleTime}`
    ).toDate();
    if (scheduleDateTime <= now) {
      return {
        isValid: false,
        reason: "time",
        value: moment(scheduleDateTime).format("HH:mm"), // Format the time
      };
    }
  }
  return { isValid: true, reason: "", value: "" };
};

export const hiddenDevice = (data: string, device: string) => {
  if (data === "*****") {
    return "*";
  } else {
    return device;
  }
};
