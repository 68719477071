import React from "react";
import { PageMenu } from "../../../Common/PageMenu/PageMenu";

import SendMoneyIcon from "../../../../assets/icons/send-money.svg?react";
import SendMoneyHighIcon from "../../../../assets/icons/send-money-high.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import MoneyHighIcon from "../../../../assets/icons/money-high.svg?react";
import TrendingUpIcon from "../../../../assets/icons/trending-up.svg?react";
import TrendingUpHighIcon from "../../../../assets/icons/trending-up-high.svg?react";

export const RentalPricingMenu = () => {
  return (
    <PageMenu
      items={[
        {
          Icon: SendMoneyIcon,
          IconActive: SendMoneyHighIcon,
          anchor: "pricing-card",
          labelI18n: "Rental.Pricing.PricingRules.title",
        },
        {
          Icon: MoneyIcon,
          IconActive: MoneyHighIcon,
          anchor: "additional-card",
          labelI18n: "Rental.Pricing.AdditionalCost.title",
        },
        {
          Icon: SendMoneyIcon,
          IconActive: SendMoneyHighIcon,
          anchor: "policies-card",
          labelI18n: "Rental.Pricing.PaymentPolicy.title",
        },
        {
          Icon: TrendingUpIcon,
          IconActive: TrendingUpHighIcon,
          anchor: "rate-card",
          labelI18n: "Rental.Pricing.PriceMultipliers.title",
        },
      ]}
    />
  );
};
