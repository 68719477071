import { t } from "i18next";
import { ChecklistType } from "../../types/GETTypes";

export const useFormattedAutomationType = (
  automationType: ChecklistType
): string => {
  let value = t("Global.notDefined");

  if (automationType === "cleaning") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.cleaning"
    );
  } else if (automationType === "checkin") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.checkin"
    );
  } else if (automationType === "checkout") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.checkout"
    );
  } else if (automationType === "incident") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.incident"
    );
  } else if (automationType === "other") {
    value = t(
      "Automation.AddOrUpdateModal.Steps.Informations.AutomationTypeOptions.other"
    );
  }

  return value;
};
