import React, { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CalendarDay } from "../../../pages/CalendarList/CalendarListPage.type";
import { CalendarInput } from "../CalendarInput/CalendarInput";
import moment from "moment";
import {
  useChangeEndDateInputCalendar,
  useChangeStartDateInputCalendar,
} from "../CalendarInput/CalendarInput.hooks";
import {
  CalendarInputValue,
  CalendarInputValueDefault,
  CalendarInputValueRange,
} from "../CalendarInput/CalendarInput.type";
import { InputSelect } from "../InputSelect/InputSelect";
import { useHandleChangeDates } from "./DateFilter.hooks";
import { CustomDateRange, DateFilterProps } from "./DateFilter.type";
import { formatDate } from "@storybook/blocks";

export const DateFilter: React.FC<DateFilterProps> = ({
  onDatesChanged,
  onDateRangePresetChanged,
  onLoading,
  onError,
  customDateRanges,
  defaultPreset = "next_seven_days",
  combineCustomAndDefault = true,
}) => {
  const { t } = useTranslation();

  const today: Date = new Date();

  const defaultDateRanges: CustomDateRange[] = [
    {
      label: t("Global.today"),
      value: "today",
      startDate: () => today,
      endDate: () => today,
    },
    {
      label: t("Components.DateFilter.nextSevenDays"),
      value: "next_seven_days",
      startDate: () => today,
      endDate: () => moment(today).add(6, "days").toDate(),
    },
    {
      label: t("Components.DateFilter.lastFourWeeks"),
      value: "last_four_weeks",
      startDate: () => moment(today).add(-4, "weeks").toDate(),
      endDate: () => today,
    },
    {
      label: t("Components.DateFilter.lastTreeMonths"),
      value: "last_three_months",
      startDate: () => moment(today).add(-3, "months").toDate(),
      endDate: () => today,
    },
    {
      label: t("Components.DateFilter.lastTwelveMonths"),
      value: "last_twelve_months",
      startDate: () => moment(today).add(-12, "months").toDate(),
      endDate: () => today,
    },
    {
      label: t("Components.DateFilter.custom"),
      value: "custom_range",
      startDate: () => moment(today).add(-7, "days").toDate(),
      endDate: () => today,
    },
  ];

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Combiner les plages de dates personnalisées et les plages par défaut
  const dateRanges = combineCustomAndDefault
    ? customDateRanges
      ? [...customDateRanges, ...defaultDateRanges]
      : defaultDateRanges
    : customDateRanges || defaultDateRanges;

  const initialRange = dateRanges.find(
    (range) => range.value === defaultPreset,
  );
  const [dates, setDates] = useState<CalendarInputValue>([
    initialRange?.startDate() || null,
    initialRange?.endDate() || null,
  ]);
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [days, setDays] = useState<CalendarDay | undefined>();
  const [isDateRangePreset, setIsDateRangePreset] = useState<boolean>(true);
  const [dateRangePreset, setDateRangePreset] = useState(defaultPreset);

  const handleDateRangePresetChange = useCallback(
    (value: ValueType) => {
      const selectedRange = dateRanges.find((range) => range.value === value);

      if (selectedRange) {
        setDates([selectedRange.startDate(), selectedRange.endDate()]);
        setIsDateRangePreset(true);
        setDateRangePreset(value as string);

        if (onDateRangePresetChanged) {
          onDateRangePresetChanged(value as string, true);
        }

        if (onDatesChanged) {
          onDatesChanged(
            [
              selectedRange.startDate(),
              selectedRange.endDate(),
            ] as CalendarInputValueRange,
            nbRangeDate,
            days,
          );
        }
      }
    },
    [dateRanges, days, nbRangeDate, onDateRangePresetChanged, onDatesChanged],
  );

  const handleDatesChange = useCallback(
    (value: CalendarInputValue) => {
      setDates(value);
      setIsDateRangePreset(false);

      useHandleChangeDates(
        value as CalendarInputValueRange,
        setNbRangeDate,
        setDays,
        onError,
        () => onLoading && onLoading(true),
        () => onLoading && onLoading(false),
      );

      if (onDatesChanged) {
        setDateRangePreset("custom_range");
        onDatesChanged(value as CalendarInputValueRange, nbRangeDate, days);
      }
    },
    [days, nbRangeDate, onDatesChanged, onError, onLoading],
  );

  return (
    <div className="flex items-center w-full">
      <InputSelect
        classNames={{
          button: "rounded-e-none",
        }}
        maxItemWidth={true}
        items={dateRanges.map((range) => ({
          label: range.label,
          value: range.value,
        }))}
        selectedValue={dateRangePreset}
        onSelect={handleDateRangePresetChange}
      />
      <CalendarInput
        classNames={{
          button: "border-s-0 rounded-md rounded-s-none bg-white",
        }}
        height="full"
        dateType={"range"}
        value={dates}
        onChangeStartDateInput={(date: string) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            const rangeOne = moment(date).toDate();
            // @ts-ignore
            const rangeTwo = dates[1] ?? moment(date).toDate();
            handleDatesChange([rangeOne, rangeTwo]);
          }, 2000);

          setDateRangePreset("custom_range");
          useChangeStartDateInputCalendar(
            date,
            [dates, setDates],
            setIsDateRangePreset,
          );
        }}
        onChangeEndDateInput={(date: string) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            // @ts-ignore
            const rangeOne = dates[0] ?? moment(date).toDate();
            const rangeTwo = moment(date).toDate();
            handleDatesChange([rangeOne, rangeTwo]);
          }, 2000);

          setDateRangePreset("custom_range");
          useChangeEndDateInputCalendar(
            date,
            [dates, setDates],
            setIsDateRangePreset,
          );
        }}
        onChangeDate={handleDatesChange}
      />
    </div>
  );
};
