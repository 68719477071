import React from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "../../../types/commonTypes";
import { useLocation } from "react-router-dom";
import { cn } from "../../../helpers/classHelper";

export const PageMenu: React.FC<{
  items: {
    anchor: string;
    labelI18n: string;
    Icon: IconType;
    IconActive: IconType;
  }[];
}> = ({ items }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="flex flex-col gap-4">
      {items?.map((item, index) => {
        const isActive = () => {
          if (location.hash === `#${item.anchor}`) return true;

          if (location.hash === "" && index === 0) return true;
        };

        return (
          <div key={item.anchor} className="flex items-start gap-2">
            {isActive() ? (
              <item.IconActive className="w-4 h-4 shrink-0 mt-0.5" />
            ) : (
              <item.Icon className="w-4 h-4 shrink-0 mt-0.5" />
            )}

            <p
              className={cn(
                isActive()
                  ? "font-semibold text-high-contrast"
                  : "font-light text-low-contrast"
              )}
            >
              <a href={`#${item.anchor}`}>{t(item.labelI18n)}</a>
            </p>
          </div>
        );
      })}
    </div>
  );
};
