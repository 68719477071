import {
  RentalInformationsPayload,
  RentalLocalisationPayload,
} from "../../components/Rental/Infos/Informations/InformationsCard.type";
import {RentalPricingRulesRequest} from "../../components/Rental/Pricing/PricingRules/PricingRules.type";
import paths from "../../constants/paths";
import {get, post} from "../../helpers/APIHelper";
import {RentalInformations, RentalRules2,} from "../../pages/RentalPage/RentalPage.type";
import {RentalResponse} from "../../types/GETTypes";

export const useRentalList = async (
  onSuccess: (data: any) => void,
  onError: (message: string | undefined | null) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.rentals);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};


/*
    Displays a list of all rental access without pagination.
 */
export const useAllRentalAccessList = async (
    onSuccess: (data: any) => void,
    onError: (message: string | undefined | null) => void,
    onStart?: () => void,
    onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.rentals_access);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates rental information by making an HTTP POST request.
 *
 * @param {string | undefined} rentalId - The ID of the rental to be updated.
 * @param {RentalInformationsPayload} data - The data to be sent in the request.
 * @param {(rentalInformations: RentalInformations) => void} onSuccess - Callback function called when the request is successful.
 * @param {(message: string | undefined) => void} onError - Callback function called when there is an error.
 * @param {() => void} [onStart] - Optional callback function called at the start of the request.
 * @param {() => void} [onEnd] - Optional callback function called at the end of the request, regardless of success or failure.
 *
 * @returns {Promise<void>} - A promise that resolves when the request is complete.
 */
export const useRentalInformationsUpdate = async (
  rentalId: string | undefined,
  data: RentalInformationsPayload,
  onSuccess: (rentalInformations: RentalInformations) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  // Call onStart callback if provided
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    // Commented-out code for potential future use
    // if (getValues("parentRental") !== 0) {
    //   data.parent_dependency_id = getValues("parentRental");
    // }

    // const childrenDependenciesString: string = childRentalFields
    //   .map((item) => (item.value !== 0 ? item.value : ""))
    //   .join(",");

    // if (childrenDependenciesString !== "") {
    //   data.children_dependencies_ids = childrenDependenciesString;
    // }

    // Make the HTTP POST request to update rental information
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.INFORMATIONS_DETAILS
      }`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.response?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    console.log("catch");
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the rental pricing rules for a given rental ID.
 *
 * @param {string | undefined} rentalId - The ID of the rental to update.
 * @param {RentalPricingRulesRequest} data - The data containing the new pricing rules.
 * @param {(pricingRules: RentalRules2) => void} onSuccess - Callback function to be called on successful update.
 * @param {(message: string | undefined) => void} onError - Callback function to be called on error.
 * @param {() => void} [onStart] - Optional callback function to be called at the start of the update process.
 * @param {() => void} [onEnd] - Optional callback function to be called at the end of the update process.
 * @returns {Promise<void>} - A promise that resolves when the update process is complete.
 */
export const useRentalPricingRulesUpdate = async (
  rentalId: string | undefined,
  data: RentalPricingRulesRequest,
  onSuccess: (pricingRules: RentalRules2) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_PRICING_RULES
      }`,
      data
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.tarification.rules.rules);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useRentalLocalisationUpdate = async (
  rentalId: string | undefined,
  data: RentalLocalisationPayload,
  onSuccess: (rentalInformations: RentalInformations) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.INFORMATIONS_LOCALISATION
      }`,
      data
    );

    // Handle the response
    if (res?.data?.success) {
      onSuccess(res.data?.result);
    } else {
      onError(res?.data?.message ?? res.message);
    }
  } catch (error: any) {
    // Handle any errors that occur during the request
    onError(error.message);
  } finally {
    // Call onEnd callback if provided
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};




export const useGetRental = async (
    rentalId: string | undefined
): Promise<RentalResponse> => {
  if (!rentalId) {
    throw new Error("Rental ID is required");
  }

  const res = await get(`${import.meta.env.VITE_API_URL}${paths.API.RENTAL.FIND_BY_ID(rentalId)}`);

  if (res?.data?.success) {
    return res.data.result;
  } else {
    throw new Error(res?.data?.message ?? "An unexpected error occurred");
  }
};
