import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import DoubleBedIcon from "../../../../assets/icons/double-bed.svg?react";
import UsersIcon from "../../../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../../../assets/icons/emoji-happy.svg?react";
import HomeGreenIcon from "../../../../assets/icons/home-success.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import UsersWarningIcon from "../../../../assets/icons/users-warning.svg?react";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { UpsertRoomModal } from "../../../AddRental/Rooms/UpsertRoomModal";
import { AddRentalRoomType } from "../../../AddRental/AddRental.type";
import { AddRentalRoomsNoRoom } from "../../../AddRental/Rooms/NoRooms";
import { del, patch, post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";

export const RentalBedroomsCard: React.FC<{
  loading: boolean;
  bedrooms: AddRentalRoomType[] | undefined;
}> = ({ loading, bedrooms }) => {
  const [error, setError] = useState<string | null>(null);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const { rentalId } = useParams();
  const [currentRooms, setCurrentRooms] = useState<AddRentalRoomType[]>([]);
  const [isAddRoomVisible, setIsAddRoomVisible] = useState<boolean>(false);
  const [currentRoomIndexToEdit, setCurrentRoomIndexToEdit] = useState<
    number | undefined
  >(undefined);
  const [currentRoomToEdit, setCurrentRoomToEdit] = useState<
    AddRentalRoomType | undefined
  >(undefined);

  useEffect(() => {
    if (bedrooms) {
      setCurrentRooms(bedrooms ?? []);
    }
  }, [bedrooms]);

  const handleAddRoomVisible = () => {
    setIsAddRoomVisible(true);
  };

  const handleAddRoom = async (values: AddRentalRoomType) => {
    setIsAddRoomVisible(false);
    setError(null);
    setValidationLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_BEDROOM
      }`,
      values
    );

    if (res.data.success) {
      setCurrentRooms([...currentRooms, res.data.result]);
      setCurrentRoomToEdit(undefined);
      setCurrentRoomIndexToEdit(undefined);
    } else {
      setError(res.data.message);
    }
    setValidationLoading(false);
  };

  const handleEditRoom = async (values: AddRentalRoomType) => {
    setIsAddRoomVisible(false);
    setError(null);
    setValidationLoading(true);

    const res = await patch(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_BEDROOM
      }/${currentRooms[currentRoomIndexToEdit!].id}`,
      values
    );

    if (res.data.success) {
      let nextRooms = currentRooms;
      nextRooms[currentRoomIndexToEdit!] = values;
      setCurrentRooms(nextRooms);
      setCurrentRoomToEdit(undefined);
      setCurrentRoomIndexToEdit(undefined);
    } else {
      setError(res.data.message);
    }
    setValidationLoading(false);
  };

  const handleRemoveRoom = async () => {
    setIsAddRoomVisible(false);
    setError(null);
    setValidationLoading(true);

    const res = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_BEDROOM
      }/${currentRooms[currentRoomIndexToEdit!].id}`
    );

    if (res.data.success) {
      let nextRooms = currentRooms;
      nextRooms.splice(currentRoomIndexToEdit!, 1);
      setCurrentRooms(nextRooms);
      setCurrentRoomToEdit(undefined);
      setCurrentRoomIndexToEdit(undefined);
    } else {
      setError(res.data.message);
    }
    setValidationLoading(false);
  };

  const handleCloseAddRoomModal = () => {
    setIsAddRoomVisible(false);
    setCurrentRoomToEdit(undefined);
    setCurrentRoomIndexToEdit(undefined);
  };

  const handleClickRoom = (index: number) => {
    setCurrentRoomToEdit(currentRooms[index]);
    setCurrentRoomIndexToEdit(index);
    setIsAddRoomVisible(true);
  };

  if (loading) return null;

  return (
    <>
      <UpsertRoomModal
        isVisible={isAddRoomVisible}
        onClose={handleCloseAddRoomModal}
        isFirstRoom={currentRooms?.length === 0 || currentRoomIndexToEdit === 0}
        room={currentRoomToEdit}
        onAdd={handleAddRoom}
        onEdit={handleEditRoom}
        onRemove={handleRemoveRoom}
        nbRooms={currentRooms.length + 1}
      />

      <Card
        loading={loading}
        Icon={DoubleBedIcon}
        label={t("Rental.Infos.Bedrooms.title")}
        anchor="bedrooms-card"
        secondaryButton={{
          label: t("AddRental.Rooms.noRoomButton"),
          Icon: PlusIcon,
          onClick: handleAddRoomVisible,
        }}
      >
        <div className="flex flex-col gap-y-4">
          <>
            {!currentRooms ||
              (currentRooms?.length === 0 && (
                <AddRentalRoomsNoRoom onAdd={handleAddRoomVisible} />
              ))}

            {currentRooms?.length > 0 && (
              <div className="flex flex-col gap-2">
                {currentRooms.map((room, index) => (
                  <div
                    className="p-4 cursor-pointer bg-element-background border-1 border-element-border rounded-6px"
                    onClick={() => handleClickRoom(index)}
                    key={index}
                  >
                    <p className="font-semibold text-high-contrast">
                      {room.name}
                    </p>

                    <div className="flex flex-row mt-1">
                      {/* Beds */}
                      <div className="flex flex-col font-light w-52 text-low-contrast">
                        <p>
                          {room.king_beds_count > 0 &&
                            t("Global.nbKingBeds", {
                              count: room.king_beds_count,
                            })}
                        </p>
                        <p>
                          {room.queen_beds_count > 0 &&
                            t("Global.nbQueenBeds", {
                              count: room.queen_beds_count,
                            })}
                        </p>
                        <p>
                          {room.double_beds_count > 0 &&
                            t("Global.nbDoubleBeds", {
                              count: room.double_beds_count,
                            })}
                        </p>
                        <p>
                          {room.single_beds_count > 0 &&
                            t("Global.nbSingleBeds", {
                              count: room.single_beds_count,
                            })}
                        </p>
                        <p>
                          {room.children_beds_count > 0 &&
                            t("Global.nbChildrenBeds", {
                              count: room.children_beds_count,
                            })}
                        </p>
                        <p>
                          {room.infants_beds_count > 0 &&
                            t("Global.nbInfantsBeds", {
                              count: room.infants_beds_count,
                            })}
                        </p>
                        <p>
                          {room.sofa_beds_count > 0 &&
                            t("Global.nbSofaBeds", {
                              count: room.sofa_beds_count,
                            })}
                        </p>
                        <p>
                          {room.wall_beds_count > 0 &&
                            t("Global.nbWallBeds", {
                              count: room.wall_beds_count,
                            })}
                        </p>
                        <p>
                          {room.mezannine_beds_count > 0 &&
                            t("Global.nbMezzanineBeds", {
                              count: room.mezannine_beds_count,
                            })}
                        </p>
                      </div>

                      {/* People */}
                      <div className="flex flex-1 gap-1 font-light text-low-contrast">
                        <UsersIcon className="w-4 h-4" />
                        <p>
                          {t("Global.adults", { count: room.adults_count })}
                        </p>
                        <EmojiHappyIcon className="w-4 h-4" />
                        <p>
                          {t("Global.children", { count: room.children_count })}
                        </p>
                      </div>

                      {/* Room type */}
                      <div>
                        {room.private_room && (
                          <div className="flex items-center gap-1 p-2 font-semibold bg-element-background-success text-success rounded-6px">
                            <HomeGreenIcon className="w-4 h-4" />
                            {t("AddRental.Rooms.private")}
                          </div>
                        )}

                        {!room.private_room && (
                          <div className="flex items-center gap-1 p-2 font-semibold bg-element-background-warning text-warning rounded-6px">
                            <UsersWarningIcon className="w-4 h-4" />
                            {t("AddRental.Rooms.shared")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <ErrorMessage>{error}</ErrorMessage>
          </>
        </div>
      </Card>
    </>
  );
};
