import React from "react";
import { IconType, TextType } from "../../../types/commonTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { NumberInput } from "../../Common/NumberInput/NumberInput";

export const AddReservationPricesFees: React.FC<{
  Icon: IconType;
  label: TextType;
  value: number;
  error?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
  currency?: string;
}> = ({ Icon, label, value, error, disabled, onChange, currency = "€" }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <Icon className="w-5 h-5" />

        <p className="flex-1 font-semibold text-high-contrast">{label}</p>

        <div className="pr-2 w-52">
          <NumberInput
            size="large"
            currency={currency}
            value={value}
            min={0}
            error={error}
            onChangeText={(nextValue) => onChange(Number(nextValue))}
            disabled={disabled}
          />
        </div>
      </div>

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
