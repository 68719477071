/**
 * Modal for show list in ticket and send action or
 * download action.
 */

import React, { useState } from "react";

import { RightModal } from "../../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { getFormattedPrice } from "../../../../helpers/currencyHelper";

import SendIcon from "../../../../assets/icons/send-white.svg?react";
import DownloadIcon from "../../../../assets/icons/download.svg?react";
import { Button } from "../../../Common/Button/Button";
import paths from "../../../../constants/paths";
import { get } from "../../../../helpers/APIHelper";
import { TicketInformationModalProps } from "../../../../pages/BookingInvoice/BookingInvoicePage.type";

export default function TicketInformationModal({
  modal,
  payments,
  modalSend,
  reservationId,
}: TicketInformationModalProps) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const sender = modal.data?.sender;
  const customer = modal.data?.customer;

  const handleTicketAction = () => {
    const url = `${import.meta.env.VITE_API_URL}${paths.API.RESERVATION.DOWNLOAD_RECEIPT(reservationId as string, "ALL")}`;

    setLoading(true);

    get(url, {
      responseType: "blob",
    })
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "ticket.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération du fichier PDF :", error);
      })
      .finally(() => {
        console.log("Action terminée.");

        setLoading(false);
      });
  };

  const getTotalHT = (): number => {
    let amount = 0;
    payments.map((payment) => {
      amount += payment.total_ht;
    });
    return amount;
  };

  const getTotalTTC = (): number => {
    let amount = 0;
    payments.map((payment) => {
      amount += payment.total_ttc;
    });
    return amount;
  };

  return (
    <RightModal
      title={t("Booking.ticket")}
      isVisible={modal.isVisible}
      onClose={() => {
        modal.close();
      }}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      <section className={"flex flex-col justify-between w-full"}>
        <div>
          <div className={"flex justify-between py-2"}>
            <div className={"flex justify-start"}>
              <div className={"flex"}>
                <img src={sender?.avatar} className={"w-16 h-16"} />
                <div className={"flex flex-col text-gray-500"}>
                  <h1 className={"text-black font-bold"}>
                    {sender?.first_name + " " + sender?.last_name}
                  </h1>
                  <p>{sender?.address}</p>
                  <p>
                    {sender?.postal_code} {sender?.city}
                  </p>
                </div>
              </div>
            </div>
            <div className={"flex justify-end mt-[50px] text-end w-[150px]"}>
              <div className={"flex"}>
                <div className={"flex flex-col text-gray-500"}>
                  <h1 className={"text-black font-bold"}>
                    {customer?.first_name + " " + customer?.last_name}
                  </h1>
                  <p>{customer?.address}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={"rounded-lg border border-gray-300"}>
            <table className="w-full table-auto text-[11.7px]">
              <thead>
                <tr className={"rounded-lg border-b-2 border-gray-300"}>
                  <th className="px-2 bg-gray-100  py-2">
                    <div className={"items-center flex gap-1"}>
                      {t("Intitulé")}
                    </div>
                  </th>
                  <th className="px-2 bg-gray-100 ">
                    <div className={"items-center flex gap-1"}>
                      {t("Montant")}
                    </div>
                  </th>
                  <th className="px-2 bg-gray-100 ">
                    <div className={"items-center flex gap-1"}>
                      {t("Quantité")}
                    </div>
                  </th>
                  <th className="px-2 bg-gray-100 ">
                    <div className={"items-center flex gap-1"}>
                      {t("Total HT")}
                    </div>
                  </th>
                  <th className="px-2 bg-gray-100 ">
                    <div className={"items-center flex gap-1"}>{t("TVA")}</div>
                  </th>
                  <th className="px-2 bg-gray-100 ">
                    <div className={"items-center flex gap-1"}>
                      {t("Total TTC")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <tr className={"font-bold"}>
                    <td className="px-2 py-2 text-gray-500">{payment.label}</td>
                    <td className="px-2 ">
                      {getFormattedPrice({
                        price: payment.amount,
                        decimals: 2,
                      })}
                    </td>
                    <td className="px-2">{payment.quantity}</td>
                    <td className="px-2">
                      {getFormattedPrice({
                        price: payment.total_ht,
                        decimals: 2,
                      })}
                    </td>
                    <td className="px-2">{payment.tva} %</td>
                    <td className="px-2">
                      {getFormattedPrice({
                        price: payment.total_ttc,
                        decimals: 2,
                      })}
                    </td>
                  </tr>
                ))}

                <tr className={"font-bold border-t-2 text-xs"}>
                  <td className="px-2 py-4">Total (EUR)</td>
                  <td className="px-2"></td>
                  <td className="px-2"></td>
                  <td className="px-2">
                    {getFormattedPrice({ price: getTotalHT(), decimals: 2 })}
                  </td>
                  <td className="px-2"></td>
                  <td className="px-2">
                    {getFormattedPrice({ price: getTotalTTC(), decimals: 2 })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={"flex justify-between items-center gap-2"}>
          {!loading && (
            <Button
              type={"secondary"}
              RightIcon={DownloadIcon}
              onClick={() => {
                handleTicketAction();
              }}
            >
              {t("Booking.downloadTicket")}
            </Button>
          )}
          <Button
            loading={loading}
            RightIcon={SendIcon}
            onClick={() => {
              modal.close();
              modalSend.open({
                receiptType: "ALL",
              });
            }}
          >
            {t("Global.send")}
          </Button>
        </div>
      </section>
    </RightModal>
  );
}
