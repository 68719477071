import React, { useEffect, useState } from "react";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import useProgressionStore from "../../../stores/useProgressStore";
import useUserStore from "../../../stores/useUserStore";
import { Separator } from "../../Common/Separator/Separator";
import { Sidebar } from "../../Common/Sidebar/Sidebar";
import { Title } from "../../Common/Title/Title";
import { MainLayoutProps } from "./MainLayout.type";
import { WorkspaceListSelect } from "./WorkspaceListSelect";

const randomStr = (): string => Math.random().toString(36).substr(2, 9);

export const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  ariane,
  sidebarActiveItem,
  sidebarActiveSubItem,
  withWorkspaceFilter = true,
  children,
  onSidebarOpen,
  userData,
  id = randomStr()
}) => {
  const [isSidebarClosed, setIsSidebarClosed] = useState<boolean>(false);
  const userStore = useUserStore();

  const { user } = useCheckAuth();

  const { refreshProgression } = useProgressionStore();

  const handleSidebarToggle = (value: boolean) => {
    setIsSidebarClosed(value);
    localStorage.setItem("sidebar_closed", value.toString());
    onSidebarOpen && onSidebarOpen(value);
  };

  useEffect(() => {
    const isClosed = localStorage.getItem("sidebar_closed");
    if (isClosed) {
      setIsSidebarClosed(isClosed === "true");
    }
  }, []);

  useEffect(() => {}, [refreshProgression]);

  useEffect(() => {
    if (user) {
      userStore.storeUser(user);
    }
  }, [user]);

  return (
    <div className="flex justify-center w-full h-screen min-h-svh">
      <div className="flex flex-row flex-1 h-full">
        <Sidebar
          activeItem={sidebarActiveItem}
          activeSubItem={sidebarActiveSubItem}
          user={userData ? userData : user}
          isClosed={isSidebarClosed}
          onToggle={handleSidebarToggle}
        />
        {/* Body */}
        <div id={id} className="flex flex-1 min-w-[777px] h-full py-4 px-8 flex-col text-sm overflow-y-scroll">
          <div className="flex justify-between">
            <div className="flex flex-col">
              {ariane}
              <Title>{title}</Title>
            </div>

            {withWorkspaceFilter && <WorkspaceListSelect user={user} />}
          </div>

          <Separator />

          {children}
        </div>
      </div>
    </div>
  );
};
