import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../../helpers/classHelper";
import { useOnKeyPress } from "../../../hooks/useOnKeyPress";
import { useOutsideDetector } from "../../../hooks/useOutsideDetector";
import { ValueType } from "../../../types/commonTypes";
import { Loader } from "../Loader/Loader";
import { ButtonProps } from "./Button.type";

export const Button: React.FC<ButtonProps> = ({
  children,
  loading = false,
  LeftIcon = null,
  RightIcon = null,
  type = "primary",
  disabled = false,
  size = "normal",
  dropItems,
  anchorSide = "right",
  keyToSubmit,
  onClick,
  displayLargeBtn = true,
  className = "",
  buttonClassName = "",
  textClassName = "",
  borderButton = "border-1 border-black/12",
  typeButton = undefined,
  divParentClassName = ""
}) => {
  const [isDropOpen, setDropOpen] = useState<boolean>(false);

  // const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  // useEffect(() => {
  //   if (isTooltipVisible && tooltipMode === "click") {
  //     setTimeout(() => setTooltipVisible(false), 1000);
  //   }
  // }, [isTooltipVisible]);

  const getPrimaryBgColors = () => {
    return `
    from-button-primary-default-top 
    to-button-primary-default-bottom
    ${
      !disabled
        ? `
        hover:from-button-primary-hover-top      
        hover:to-button-primary-hover-bottom
        active:from-button-primary-active-top 
        active:to-button-primary-active-bottom
        focus:from-button-primary-focus-top 
        focus:to-button-primary-focus-bottom`
        : ""
    }`;
  };

  const getSecondaryBgColors = () => {
    return `
    from-button-secondary-default-top 
    to-button-secondary-default-bottom
    ${
      !disabled
        ? `
        hover:from-button-secondary-hover-top      
        hover:to-button-secondary-hover-bottom
        active:from-button-secondary-active-top 
        active:to-button-secondary-active-bottom
        focus:from-button-secondary-focus-top 
        focus:to-button-secondary-focus-bottom`
        : ""
    }`;
  };

  const getDestructiveBgColors = () => {
    return `
    from-button-destructive-default-top 
    to-button-destructive-default-bottom
    ${
      !disabled
        ? `
        hover:from-button-destructive-hover-top      
        hover:to-button-destructive-hover-bottom
        active:from-button-destructive-active-top 
        active:to-button-destructive-active-bottom
        focus:from-button-destructive-focus-top 
        focus:to-button-destructive-focus-bottom`
        : ""
    }`;
  };

  const getAlertBgColors = () => {
    return `
    from-button-alert-default-top 
    to-button-alert-default-bottom
    ${
      !disabled
        ? `
        hover:from-button-alert-hover-top      
        hover:to-button-alert-hover-bottom
        active:from-button-alert-active-top 
        active:to-button-alert-active-bottom
        focus:from-button-alert-focus-top 
        focus:to-button-alert-focus-bottom`
        : ""
    }`;
  };

  const getBgColors = () => {
    switch (type) {
      case "primary":
        return getPrimaryBgColors();
      case "secondary":
        return getSecondaryBgColors();
      case "destructive":
        return getDestructiveBgColors();
      case "alert":
        return getAlertBgColors();
      default:
        return getPrimaryBgColors();
    }
  };

  const getTextColor = () => {
    switch (type) {
      case "primary":
        return "text-inverted";
      case "secondary":
        return "text-high-contrast";
      case "destructive":
        return "text-inverted";
      case "alert":
        if (disabled) {
          return "text-gray-900";
        }
        return "hover:text-inverted active:text-inverted focus:text-inverted text-error";
      default:
        return "text-inverted";
    }
  };

  const getBorder = () => {
    switch (type) {
      case "alert":
        return "border-error";
      default:
        return "";
    }
  };

  const opacity = disabled ? "opacity-64" : "";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const [buttonStyle, setButtonStyle] = useState<{
    padding: string;
    textSize: string;
    dropTop: string;
    height: string;
  }>({
    padding: "px-3",
    textSize: "text-base",
    dropTop: "top-10",
    height: "h-9",
  });

  // * -- Updated button style from according size props --
  useEffect(() => {
    if (size !== undefined) {
      setButtonStyle((prevValue) => {
        if (size === "small") {
          return {
            padding: "px-3",
            textSize: "text-sm",
            dropTop: "top-9",
            height: "h-8",
          };
        }

        if (size === "normal") {
          return {
            padding: "px-3",
            textSize: "text-base",
            dropTop: "top-10",
            height: "h-9",
          };
        }

        if (size === "auto") {
          return {
            padding: "p-3",
            textSize: "text-base",
            dropTop: "top-10",
            height: "h-auto",
          };
        }

        return prevValue;
      });
    }
  }, [size]);

  const handleClick = () => {
    if (!disabled && !dropItems && typeof onClick === "function") {
      onClick();
    } else if (!disabled && dropItems) {
      setDropOpen(!isDropOpen);
    }

    // if (tooltip) {
    //   setTooltipVisible(!isTooltipVisible);
    // }
  };

  const displayLargeButton = displayLargeBtn
    ? "flex flex-1 relative group/tooltip"
    : className;

  const handleClickItem = (value: ValueType) => {
    if (typeof onClick === "function") {
      onClick(value);
      setDropOpen(false);
    }
  };

  const ref = useRef(null);
  const handleClickOutside = () => {
    setDropOpen(false);

    // if (tooltip) {
    //   setTooltipVisible(!isTooltipVisible);
    // }
  };

  useOutsideDetector(ref, handleClickOutside);

  if (keyToSubmit && onClick) {
    useOnKeyPress(onClick, keyToSubmit);
  }

  if (loading) {
    return (
      <div
        className={`flex flex-1 justify-center items-center p-4 ${buttonStyle.height}`}
      >
        <Loader />
      </div>
    );
  } else
    return (
      <div className={cn(`${displayLargeButton} ${buttonStyle.height}`,divParentClassName)} ref={ref}>
        <button
          onClick={handleClick}
          role="button"
          type={
            typeButton
              ? typeButton
              : type === "destructive" ||
                type === "secondary" ||
                type === "alert"
              ? "button"
              : "submit"
          }
          className={cn(
            `
          flex-1
          bg-gradient-to-b 
          ${getTextColor()}
          ${getBgColors()}
          ${getBorder()}
          ${opacity}
          ${cursor}
          ${buttonStyle.padding}
          ${buttonStyle.height}
          ${borderButton}
          focus:outline-focus
          rounded-6px font-semibold shadow-sm
          `,
            buttonClassName
          )}
        >
          <div className="flex flex-row items-center justify-center space-x-2">
            {LeftIcon && <LeftIcon className="w-4 h-4 text-icon-default " />}
            {children && (
              <div className={cn(`${buttonStyle.textSize}`, textClassName)}>
                {children}
              </div>
            )}
            {RightIcon && <RightIcon className="w-4 h-4 text-icon-default" />}
          </div>
        </button>

        {/* TODO: [BUTTON] Finish tooltip implementation */}
        {/* {!disabled && tooltip ? (
          <>
            {tooltipMode === "click" && isTooltipVisible ? (
              <div
                className={`absolute top-10 left-1/2 -translate-x-1/2 flex flex-col flex-wrap items-center transition-all duration-300 ease-in tooltip w-44`}
              >
                <span className="relative z-10 p-2 text-xs font-bold leading-none text-white rounded shadow-lg select-none bg-element-background-solid">
                  {tooltip}
                </span>
              </div>
            ) : null}

            {tooltipMode === "hover" ? (
              <div
                className={`absolute top-10 left-1/2 -translate-x-1/2 flex flex-col flex-wrap items-center transition-all duration-300 ease-in tooltip invisible opacity-0 group-hover/tooltip:visible group-hover/tooltip:opacity-100 w-44`}
              >
                <span className="relative z-10 p-2 text-xs font-bold leading-none text-white rounded shadow-lg select-none bg-element-background-solid">
                  {tooltip}
                </span>
              </div>
            ) : null}
          </>
        ) : null} */}

        {!disabled && keyToSubmit ? (
          <p className="absolute right-0 text-gray-500 text-xxs -bottom-5">
            {t("Global.infoTextSubmitButton", {
              key: t(`Global.Keys.${keyToSubmit}`),
            })}
          </p>
        ) : null}

        {dropItems && isDropOpen ? (
          <div
            className={`absolute ${buttonStyle.dropTop} ${
              anchorSide === "left" || anchorSide === "right"
                ? `${anchorSide}-0`
                : "left-0 right-0"
            } bg-white z-10 text-sm font-semibold text-high-contrast border-1 border-element-border rounded-6px cursor-pointer overflow-hidden`}
          >
            {dropItems?.map((item) => (
              <div
                key={item.value}
                className="p-3 hover:bg-element-background-selection-subtle min-w-[150px] flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0"
                onClick={() => handleClickItem(item.value)}
              >
                {item.Icon && <item.Icon className="w-4 h-4" />}
                <p className={`${item.isRed && "text-[#d0242e]"}`}>
                  {item.label}
                </p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
};
