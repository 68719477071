import React from "react";
import {useTranslation} from "react-i18next";
import {PaymentReservationResponse} from "../../../../types/GETTypes";
import {getPlatformIcon} from "../../../../helpers/platformHelper";
import {Rating} from "../../../Common/Rating/Rating";
import moment from "moment";

import EmojiHappyIcon from "../../../../assets/icons/emoji-happy.svg?react";
import EyeOffIcon from "../../../../assets/icons/eye-off.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import MoonIcon from "../../../../assets/icons/moon-2.svg?react";
import StarIcon from "../../../../assets/icons/star.svg?react";
import UsersIcon from "../../../../assets/icons/users.svg?react";
import {hiddenDevice} from "../../../../helpers/calendarHelper";

export const PaymentReservationListModalGeneral: React.FC<{
  paymentReservation: PaymentReservationResponse | undefined;
  loading: boolean;
}> = ({ paymentReservation, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="font-bold text-low-contrast">
        {t("Payments.PaymentReservationList.contextLabel")}
      </p>

      <div>
        <p className="font-bold text-high-contrast">
          {t("Payments.PaymentReservationList.guestLabel")}
        </p>
        <div className="flex flex-row items-center justify-between py-2 space-x-3 pe-2">
          <div className="flex items-center space-x-4">
            <div className="relative">
              {/* LOGO */}
              {paymentReservation?.platform ? (
                <div
                  className="absolute z-10"
                  style={{ top: 22, left: 26, width: 16, height: 16 }}
                >
                  {getPlatformIcon(paymentReservation?.platform, "small")}
                </div>
              ) : (
                <div
                  className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                  style={{ top: 22, left: 26, width: 16, height: 16 }}
                />
              )}

              {/* PHOTO */}
              <img
                src={paymentReservation?.guest.photo}
                alt="Guest thumbnail"
                className="w-10 h-10 rounded-full border-1 border-element-border/50"
              />
            </div>

            <div>
              {/* FIRSTNAME & LASTNAME */}
              <>
                <p className="font-semibold text-high-contrast">
                  {`${paymentReservation?.guest.first_name} ${paymentReservation?.guest.last_name}`}
                </p>

                {!paymentReservation?.guest.first_name &&
                  !paymentReservation?.guest.last_name && (
                    <div className="flex items-center space-x-2">
                      <EyeOffIcon width={15} height={15} />
                      <p className="text-low-contrast">
                        {t("Booking.Guest.hiddenInformation")}
                      </p>
                    </div>
                  )}
              </>

              {/* CITY & COUNTRY */}
              <div>
                <p className="font-light text-low-contrast">
                  {paymentReservation?.guest.city !== undefined &&
                    paymentReservation?.guest.city !== null &&
                    `${paymentReservation?.guest.city}, `}
                  {paymentReservation?.guest.country}
                </p>
              </div>
            </div>
          </div>

          {/* RATING */}
          {paymentReservation && paymentReservation.guest.rate !== null ? (
            <div>
              <Rating
                value={paymentReservation.guest.rate}
                maxStars={5}
                loading={loading}
              />
            </div>
          ) : (
            <div className="flex items-center gap-x-1">
              <StarIcon />
              <p className="text-low-contrast">
                {t("Payments.PaymentReservationList.ratingNotDefined")}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-3">
          <div className="space-y-1">
            {paymentReservation?.platform?.name?.toUpperCase() === "DIRECT" ? (
              <p className="font-bold text-high-contrast">
                {t("Payments.PaymentReservationList.directReservationLabel")}
              </p>
            ) : (
              <p className="font-bold text-high-contrast">
                {t("Payments.PaymentReservationList.platformReservationLabel")}
              </p>
            )}
            {paymentReservation?.platform?.reservation_reference ? (
              <p className="text-low-contrast">
                {t("Payments.PaymentReservationList.reference", {
                  value: paymentReservation?.platform.reservation_reference,
                })}
              </p>
            ) : null}
            <p className="text-low-contrast">
              {t("Payments.PaymentReservationList.rangeDate", {
                startDate: moment(paymentReservation?.checkin).format(
                  "DD MMM YYYY"
                ),
                endDate: moment(paymentReservation?.checkout).format(
                  "DD MMM YYYY"
                ),
              })}
            </p>
          </div>

          {/* RENTAL */}
          <div className="flex items-center flex-1 space-x-3">
            <img
              src={paymentReservation?.rental?.cover}
              alt={paymentReservation?.rental.name}
              className="w-12 h-12 rounded-6px bg-slate-200"
            />
            <div className="flex flex-col">
              <div className="flex items-center">
                <p className="text-base text-wrap w-52 text-high-contrast font-semibold">
                  {paymentReservation?.rental.name}
                </p>
                {/* TODO: [PaymentReservation] Implement link to current reservation */}
                {/* <Link to={`${paths.RENTALS}/6`} target="_blank">
                        <LinkIcon />
                      </Link> */}
              </div>
              <p className="text-wrap w-52 text-low-contrast">{`${paymentReservation?.rental.address} ${paymentReservation?.rental.postal_code} ${paymentReservation?.rental.city}`}</p>
            </div>
          </div>
        </div>

        {/* VALUES */}
        <div className="flex flex-col items-end flex-1 space-y-2">
          <div className="flex items-center space-x-2">
            <p className="text-base font-bold text-high-contrast">
              {t("Global.nights", {
                count: paymentReservation?.nights_count ?? 0,
              })}
            </p>
            <MoonIcon width={20} height={20} />
          </div>

          <div className="flex items-center space-x-2">
            <p className="text-base text-low-contrast">
              {t("Global.adults", {
                count: paymentReservation?.adults_count ?? 0,
              })}
            </p>
            <UsersIcon width={20} height={20} />
          </div>

          {paymentReservation?.children_count! > 0 && (
            <div className="flex gap-2 items-center">
              {t("Global.children", {
                count: paymentReservation?.children_count,
              })}
              <EmojiHappyIcon className="w-5 h-5" />
            </div>
          )}

          <div className="flex items-center space-x-2">
            <p className="text-base text-low-contrast">
              {`
                ${typeof paymentReservation?.payment_schedule.total_price_value === "number"
                  ? Number(paymentReservation?.payment_schedule.total_price_value).toFixed(2)
                  : paymentReservation?.payment_schedule.total_price_value
                }${hiddenDevice(paymentReservation?.payment_schedule.total_price_value, t("Global.currencySymbol"))}
              `}
            </p>
            <MoneyIcon width={20} height={20} />
          </div>
        </div>
      </div>
    </>
  );
};
