import React, {useEffect, useState} from "react";
import {DashboardPageProps} from "./DashboardPage.type";
import {Button} from "../../components/Common/Button/Button";
import {useTranslation} from "react-i18next";
import {Separator} from "../../components/Common/Separator/Separator";
import {Title} from "../../components/Common/Title/Title";
import {RightModal} from "../../components/Common/RightModal/RightModal";
import {HistoricalStep} from "../../components/Common/HistoricalStep/HistoricalStep";
import {useNavigate} from "react-router-dom";
import paths from "../../constants/paths";
import moment from "moment";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";

import BookingIcon from "../../assets/icons/booking.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import EyeIcon from "../../assets/icons/eye.svg?react";
import CheckIcon from "../../assets/icons/check-success.svg?react";
import StarFilledIcon from "../../assets/icons/star-filled.svg?react";
import StarHalfIcon from "../../assets/icons/star-half.svg?react";
import ClockIcon from "../../assets/icons/clock.svg?react";
import MessageIcon from "../../assets/icons/message.svg?react";
import SettingsIcon from "../../assets/icons/settings.svg?react";

import UserAvatar from "../../assets/images/user-avatar.svg?react";
import Rental1Thumbnail from "../../assets/images/rental1.webp";
import MaritzImg from "../../assets/images/maritz.png";
import {InfoModal} from "../../components/Modal/InfoModal/InfoModal";

import resBookings from "../../res/bookings";
import resRentals from "../../res/rentals";
import {useGetRentals} from "../../hooks/useGetRentals";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";
import {useCheckAuth} from "../../hooks/useCheckAuth";
import DashboardTips from "../../components/Dashboard/DashboardTips";
import DashboardStats from "../../components/Dashboard/DashboardStats";
import DashboardCheckin from "../../components/Dashboard/DashboardCheckin";
import DashboardTasks from "../../components/Dashboard/DashboardTasks";
import {DashboardData, TaskItemResponse} from "../../types/GETTypes";
import {get, post} from "../../helpers/APIHelper";
import {Tooltip} from "../../components/Common/Tooltip/Tooltip";
import {useModal} from "../../hooks/useModal";
import {AcceptTaskModal} from "../../components/Management/AcceptTaskModal/AcceptTaskModal";
import {DeclineTaskModal} from "../../components/Management/DeclineTaskModal/DeclineTaskModal";
import {
  formatTime,
  formatTimeStringDashboard,
  normalizeTimeString,
} from "../../helpers/dateHelper";
import {TFunction} from "i18next";
import AddTaskModal from "../../components/Management/AddTask/AddTaskModal";

export type updateAssignationType = "accept" | "decline";

export const DashboardPage: React.FC<DashboardPageProps> = () => {
  const {t} = useTranslation();
  const {user} = useCheckAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [refresh, setRefresh] = useState(false);
  const acceptTaskModal = useModal<TaskItemResponse>();
  const declineTaskModal = useModal<TaskItemResponse>();
  const createTaskModal = useModal<{date?:string}>();

  const isOwner =
    user?.type === "owner" ? true : user?.type === "member" ? false : true;

  const fetchDashboardData = async () => {
    setIsLoading(true);
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.DASHBOARD}`
    );

    if (response.data.success) {
      setDashboardData(response.data.result);
    }
    setIsLoading(false);
  };

  const updateAssignation = () => {
    if (acceptTaskModal.data || declineTaskModal.data) {
      fetchDashboardData();
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  useEffect(() => {}, [dashboardData]);

  const hasTips = isOwner
    ? dashboardData?.reservations.recommendations.automated_messages_enabled ===
        1 ||
      dashboardData?.reservations.recommendations.smart_lock_enabled === 1 ||
      dashboardData?.reservations.recommendations.welcome_guide_enabled === 1
    : false;

  const timeString = isOwner
    ? dashboardData?.reservations?.stats?.saved_time?.value + " 🎉🥳"
    : "";

  const displaySavedTime =
    dashboardData?.reservations?.stats?.saved_time?.display_value ?? false;

  return (
    <>
      <AcceptTaskModal
        task={acceptTaskModal.data}
        isVisible={acceptTaskModal.isVisible}
        onClose={acceptTaskModal.close}
        onSuccess={updateAssignation}
      />

      <DeclineTaskModal
        task={declineTaskModal.data}
        isVisible={declineTaskModal.isVisible}
        onClose={declineTaskModal.close}
        onSuccess={updateAssignation}
      />

      <RightModal
        title={t("Task.Modal.titleBooking")}
        classNames={{
          mainContentParent: "overflow-y-hidden pe-0",
        }}
        isVisible={createTaskModal.isVisible}
        onClose={createTaskModal.close}
      >
        <AddTaskModal
          onClose={createTaskModal.close}
          onSuccess={() => fetchDashboardData()}
          fromDashboard={true}
          date={createTaskModal.data?.date}
        />
      </RightModal>

      <MainLayout
        title={t("Dashboard.title")}
        sidebarActiveItem="dashboard"
        sidebarActiveSubItem="dashboard_reservations"
      >
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row mt-4 justify-between">
            <div className="flex flex-col">
              <Title>
                {t("Dashboard.welcome")} {user?.first_name},
              </Title>
              <p className="text-low-contrast text-sm">
                {t("Dashboard.summary")}
              </p>
            </div>
            {isOwner && displaySavedTime && (
              <div className="flex flex-col">
                <div className="flex items-center space-x-2 justify-end">
                  <Title style="text-end">{timeString}</Title>
                  <Tooltip
                    value={t("Dashboard.infoTimeGained")}
                    size="large"
                    classNames={{container: "top-0 right-[10px]"}}
                  />
                </div>
                <p className="text-low-contrast text-sm">
                  {t("Dashboard.timeGained")}
                </p>
              </div>
            )}
          </div>
          <div className="mt-8">
            <DashboardStats
              dashboardData={dashboardData}
              isOwner={isOwner}
              isLoading={isLoading}
              user={user}
            />
          </div>
          <div className="flex justify-between mt-8">
            <DashboardTasks
              openCreateTaskModal={createTaskModal.open}
              isLoading={isLoading}
              isOwner={isOwner}
              dashboardData={dashboardData}
              onAcceptTask={acceptTaskModal.open}
              onDeclineTask={declineTaskModal.open}
              user={user}
            />
          </div>
          {/* <div className="mt-8">
            {hasTips && (
              <DashboardTips
                dashboardData={dashboardData}
                isLoading={isLoading}
                isOwner={isOwner}
              />
            )}
          </div> */}
        </div>
      </MainLayout>
    </>
  );
};
