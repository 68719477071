import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "../../../Common/Card/Card";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {RentalBillingInfosCardSkeleton} from "./BillingInfosCardSkeleton";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import {Controller, useForm, UseFormReturn} from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import {TextInput} from "../../../Common/TextInput/TextInput";
import TagIcon from "../../../../assets/icons/tag.svg?react";
import {
  RentalBillingInfosHostType,
  RentalBillingInfosResponse,
} from "../../../../types/GETTypes";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {useRentalPageContext} from "../../../../pages/RentalPage/RentalPageContext";
import {ValueType} from "../../../../types/commonTypes";

interface RentalBillingInfosCardProps {
  loading: boolean;
  billingInfos: RentalBillingInfosResponse | undefined;
  rentalId: number | undefined;
}
interface RentalBillingInfosCardForm {
  host_type: RentalBillingInfosHostType;
  host_name: string | undefined;
  tax_identity_number: string | undefined;
  company_name: string | undefined;
  siren: string | undefined;
  address: string | undefined;
  country: string | undefined;
  postal_code: string | undefined;
  city: string | undefined;
  property_registration_number: string | undefined;
}

const CardForm = ({
  form,
  billingInfos,
}: {
  form: UseFormReturn<RentalBillingInfosCardForm, any, undefined>;
  billingInfos: RentalBillingInfosResponse | undefined;
}) => {
  const {t} = useTranslation();

  const hostType = form.watch("host_type");
  const isProForm = hostType === RentalBillingInfosHostType.PROFESSIONAL;

  const hostTypeSelect = [
    {
      label: t("Rental.BillingInfos.particular"),
      value: RentalBillingInfosHostType.PARTICULAR,
    },
    {
      label: t("Rental.BillingInfos.pro"),
      value: RentalBillingInfosHostType.PROFESSIONAL,
    },
  ];

  const change = (
    value: ValueType,
    onChange: (...event: any[]) => void,
  ) => {
    if (value === RentalBillingInfosHostType.PROFESSIONAL) {
      form.setValue("host_name", "");
      form.setValue("tax_identity_number", "");
    } else if (value === RentalBillingInfosHostType.PARTICULAR) {
      form.setValue("company_name", "");
      form.setValue("siren", "");
    }
    onChange(value);
  };

  return isProForm ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <Controller
            control={form.control}
            name="host_type"
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Rental.BillingInfos.hostType"),
                }),
              },
            }}
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex flex-col">
                  <InputSelect
                    required={true}
                    label={t("Rental.BillingInfos.hostType")}
                    selectedValue={value}
                    items={hostTypeSelect}
                    onSelect={(value) => change(value, onChange)}
                    error={form.formState.errors.host_type?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="company_name"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.Pro.companyName"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.Pro.companyName")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.company_name?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="siren"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.Pro.siren"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.Pro.siren")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.siren?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="address"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.billingAddress"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.billingAddress")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.address?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="country"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.country"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.country")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.country?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="postal_code"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.postalCode"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.postalCode")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.postal_code?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="city"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.city"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.city")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.city?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 w-full">
          <Controller
            control={form.control}
            name="property_registration_number"
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex flex-col">
                  <TextInput
                    label={t("Rental.BillingInfos.propertyRegistrationNumber")}
                    required={false}
                    value={value}
                    onChangeText={(text) => {
                      onChange(text);
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <Controller
            control={form.control}
            name="host_type"
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Rental.BillingInfos.hostType"),
                }),
              },
            }}
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex flex-col">
                  <InputSelect
                    required={true}
                    label={t("Rental.BillingInfos.hostType")}
                    selectedValue={value}
                    items={hostTypeSelect}
                    onSelect={(value) => change(value, onChange)}
                    error={form.formState.errors.host_type?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="host_name"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.Particular.hostName"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.Particular.hostName")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.host_name?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="tax_identity_number"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Rental.BillingInfos.Particular.taxIdentityNumber"
                    ),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t(
                        "Rental.BillingInfos.Particular.taxIdentityNumber"
                      )}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.tax_identity_number?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="address"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.billingAddress"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.billingAddress")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.address?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="country"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.country"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.country")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.country?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="postal_code"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.postalCode"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.postalCode")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.postal_code?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <Controller
              control={form.control}
              name="city"
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.BillingInfos.city"),
                  }),
                },
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Rental.BillingInfos.city")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={form.formState.errors.city?.message}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 w-full">
          <Controller
            control={form.control}
            name="property_registration_number"
            render={({field: {value, onChange}}) => {
              return (
                <div className="flex flex-col">
                  <TextInput
                    label={t("Rental.BillingInfos.propertyRegistrationNumber")}
                    required={false}
                    value={value}
                    onChangeText={(text) => {
                      onChange(text);
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CardDisplay = ({
  billingInfos,
}: {
  billingInfos: RentalBillingInfosResponse | undefined;
}) => {
  const {t} = useTranslation();
  const hostTypeNameByType = (host_type?: RentalBillingInfosHostType) => {
    switch (host_type ? host_type : billingInfos?.host_type) {
      case RentalBillingInfosHostType.PARTICULAR:
        return t("Rental.BillingInfos.particular");
      case RentalBillingInfosHostType.PROFESSIONAL:
        return t("Rental.BillingInfos.pro");
      default:
        return "";
    }
  };

  const isPro = billingInfos?.host_type === RentalBillingInfosHostType.PROFESSIONAL;

  return isPro ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <p className="text-low-contrast">
            {t("Rental.BillingInfos.hostType")}
          </p>
          <p className="text-high-contrast font-bold">{hostTypeNameByType()}</p>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.Pro.companyName")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.company_name}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.Pro.siren")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.siren}
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.billingAddress")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.address}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.country")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.country}
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.postalCode")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.postal_code}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">{t("Rental.BillingInfos.city")}</p>
            <p className="text-high-contrast font-bold">{billingInfos?.city}</p>
          </div>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-full">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.propertyRegistrationNumber")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.property_registration_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 gap-y-4">
        <div className="flex flex-col gap-y-2 w-full">
          <p className="text-low-contrast">
            {t("Rental.BillingInfos.hostType")}
          </p>
          <p className="text-high-contrast font-bold">{hostTypeNameByType()}</p>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.Particular.hostName")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.host_name}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.Particular.taxIdentityNumber")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.tax_identity_number}
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.billingAddress")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.address}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.country")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.country}
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.postalCode")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.postal_code}
            </p>
          </div>
          <div className="flex  flex-col gap-y-2 w-1/2">
            <p className="text-low-contrast">{t("Rental.BillingInfos.city")}</p>
            <p className="text-high-contrast font-bold">{billingInfos?.city}</p>
          </div>
        </div>
        <div className="flex flex-row gap-y-2">
          <div className="flex flex-col gap-y-2 w-full">
            <p className="text-low-contrast">
              {t("Rental.BillingInfos.propertyRegistrationNumber")}
            </p>
            <p className="text-high-contrast font-bold">
              {billingInfos?.property_registration_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RentalBillingInfosCard: React.FC<RentalBillingInfosCardProps> = ({
  loading,
  billingInfos,
  rentalId,
}) => {
  const {rental, onUpdateRental} = useRentalPageContext();
  const {t} = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const defaultFormValues: RentalBillingInfosCardForm = {
    host_type: RentalBillingInfosHostType.PARTICULAR,
    host_name: "",
    tax_identity_number: "",
    company_name: "",
    siren: "",
    address: "",
    country: "",
    postal_code: "",
    city: "",
    property_registration_number: "",
  };
  const form = useForm<RentalBillingInfosCardForm>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (billingInfos) {
      form.setValue("host_type", billingInfos.host_type ?? RentalBillingInfosHostType.PARTICULAR);
      if (billingInfos.host_type === RentalBillingInfosHostType.PARTICULAR) {
        form.setValue("host_name", billingInfos?.host_name ?? "");
        form.setValue("tax_identity_number", billingInfos?.tax_identity_number ?? "");
      } else {
        form.setValue("company_name", billingInfos?.company_name ?? "");
        form.setValue("siren", billingInfos?.siren ?? "");
      }
      form.setValue("address", billingInfos?.address ?? "");
      form.setValue("country", billingInfos?.country);
      form.setValue("postal_code", billingInfos?.postal_code ?? "");
      form.setValue("city", billingInfos?.city ?? "");
      form.setValue(
        "property_registration_number",
        billingInfos?.property_registration_number ?? ""
      );
    }
  }, [billingInfos, editMode]);

  const handleUpdateBilling = async (data: RentalBillingInfosCardForm) => {
    setError(undefined);
    setValidationLoading(true);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_BILLING_INFOS
      }`,
      data
    );
    if (res?.data?.success) {
      if (rental) {
        onUpdateRental({
          ...rental,
          details: {
            ...rental.details,
            billing_infos: res.data.result?.details?.billing_infos ?? "",
          },
        });
      }
      setEditMode(false);
    } else {
      console.log("🚀 ~ handleUpdateBilling ~ res:", res);
      setError(res?.response?.data?.message);
    }
    setValidationLoading(false);
  };

  if (loading) return <RentalBillingInfosCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={TagIcon}
      label={t("Rental.BillingInfos.title")}
      anchor="billing-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(handleUpdateBilling)
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => {
                setEditMode(false);
                form.reset(defaultFormValues);
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <span className="text-low-contrast">
            {t("Rental.BillingInfos.fillDataInfo")}
          </span>
        ) : null}
        {editMode ? (
          <CardForm form={form} billingInfos={billingInfos} />
        ) : (
          <CardDisplay billingInfos={billingInfos} />
        )}
      </div>
    </Card>
  );
};
