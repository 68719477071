import { Card } from "../../../Common/Card/Card";
import { Badge } from "../../../Common/Badge/Badge";
import { Button } from "../../../Common/Button/Button";
import { CopyLink } from "../../../Common/CopyLink/CopyLink";
import { getFormattedPrice } from "../../../../helpers/currencyHelper";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import BourseIcon from "../../../../assets/icons/money-bag.svg?react";

import HandIcon from "../../../../assets/icons/giving-money.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import SendIcon from "../../../../assets/icons/send-white.svg?react";

import React from "react";
import { useTranslation } from "react-i18next";
import { Deposit, Payment } from "../../../../types/GETTypes";
import { cn } from "../../../../helpers/classHelper";
import moment from "moment";
import { PaymentsAndCautionProps } from "../../../../pages/BookingInvoice/BookingInvoicePage.type";

export default function PaymentsAndCaution({
  receipts,
  modalSendTicket,
  modalSendTicketCaution,
  onClick,
}: PaymentsAndCautionProps) {
  const { t } = useTranslation();

  const isManagedByPlatform = (): boolean => {
    return (
      receipts.payments_deposit.deposit.deposit_payment_status ===
      "MANAGED_BY_PLATFORM"
    );
  };

  const isRefundable = (): boolean => {
    return receipts.payments_deposit.cancel_conditions.is_refundable === 1;
  };

  const isPaid = (): boolean => {
    return receipts.payments_deposit.payments.status === "PAID";
  };

  const isMultiplePayments = (): boolean => {
    const paymentKeys = Object.keys(receipts.payments_deposit.payments).filter(
      (key) => !isNaN(Number(key)),
    );
    return paymentKeys.length > 1;
  };

  const getMultiplePayments = (): number => {
    const paymentKeys = Object.keys(receipts.payments_deposit.payments).filter(
      (key) => !isNaN(Number(key)),
    );
    return paymentKeys.length;
  };

  const getAllPayments = (): Payment[] => {
    const payments = receipts.payments_deposit.payments;
    const paymentKeys = Object.keys(payments).filter(
      (key) => !isNaN(Number(key)),
    );
    // @ts-ignore
    return paymentKeys.map((key) => payments[Number(key)]);
  };

  const paymentIsPaid = (payment: Payment): boolean => {
    return payment.status === "PAID";
  };

  const paymentIsRefund = (payment: Payment): boolean => {
    return payment.status === "REFUNDED";
  };

  const cautionIsRefund = (payment: Deposit): boolean => {
    return payment.deposit_payment_status === "REFUNDED";
  };

  const cautionIsPaid = (payment: Deposit): boolean => {
    return payment.deposit_payment_status === "PAID";
  };

  const onClickButtonSendPayment = (payment: Payment) => {
    modalSendTicket.open({
      payment: payment,
    });
  };

  return (
    <>
      <Card
        Icon={HandIcon}
        label={t("Booking.suiviePayment")}
        classNames={{
          label: "text-lg",
        }}
      >
        <div
          className={
            "bg-gray-100 border border-gray-200 rounded-md p-4 py-6 font-bold text-gray-500 text-lg"
          }
        >
          <header className={"flex items-center justify-between"}>
            <h1 className={"flex items-center gap-2"}>
              <MoneyIcon />
              {t("Booking.payment")}
            </h1>
            <div className={"flex items-center gap-2"}>
              <Badge
                label={t(
                  isPaid()
                    ? "Booking.PaymentStatus.paid"
                    : "Booking.PaymentStatus.unpaid",
                )}
                status={isPaid() ? "success" : "fail"}
              />
              <Button
                onClick={onClick}
                type={"secondary"}
                RightIcon={ArrowRightIcon}
              >
                {t("Booking.ExtrasOrder.ExtraOrderModal.displayDetails")}
              </Button>
            </div>
          </header>
          <main className={"mt-4"}>
            <p className={"font-bold text-black"}>
              {t(
                isManagedByPlatform()
                  ? "Booking.managedBy"
                  : isMultiplePayments()
                    ? "Booking.paymentsInMultiple"
                    : "Booking.paymentsInOne",
                {
                  number: getMultiplePayments(),
                  days: receipts.payments_deposit.deposit
                    .deposit_payment_days_delay,
                },
              )}
            </p>
            <p
              className={cn(
                "font-normal mt-1 mb-4",
                isManagedByPlatform() && "hidden",
              )}
            >
              {t("Booking.msgAutoWithPayment")}
            </p>

            {isMultiplePayments() && (
              <div className={"flex flex-col gap-2 mt-4"}>
                {getAllPayments().map((payment) => (
                  <div className={"p-2 border border-gray-200 rounded-md"}>
                    <div className={"flex justify-between items-center"}>
                      <div className={"flex flex-col"}>
                        <h1 className={"text-black text-[15px]"}>
                          {payment.name} -{" "}
                          {t("Booking.thisDate", {
                            date: payment.specific_date,
                          })}
                        </h1>
                        <p className={"text-gray-500 text-xs font-normal"}>
                          {getFormattedPrice({
                            price: payment.price ?? 0,
                            decimals: 2,
                          })}{" "}
                          {t("Booking.via")} Stripe.
                        </p>
                      </div>
                      <div className={"flex items-center gap-2"}>
                        <Badge
                          label={t(
                            paymentIsPaid(payment)
                              ? "Booking.PaymentStatus.paid"
                              : paymentIsRefund(payment)
                                ? "Booking.PaymentStatus.refunded"
                                : "Booking.PaymentStatus.unpaid",
                          )}
                          status={
                            paymentIsPaid(payment)
                              ? "success"
                              : paymentIsRefund(payment)
                                ? "fail"
                                : "pending"
                          }
                        />
                      </div>
                    </div>
                    <div className={"mt-4"}>
                      <CopyLink
                        label={t(
                          "Payments.PaymentReservationList.paymentLinkLabel",
                          {
                            number: 1,
                          },
                        )}
                        link={payment.payment_link}
                        button={{
                          type: "primary",
                          text: t("Booking.PaymentAndDepositLink.sendPayment"),
                          Icon: SendIcon,
                          onClick: () => {
                            onClickButtonSendPayment(payment);
                          },
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!isMultiplePayments() && !isPaid() && (
              <div className={"mt-4"}>
                <CopyLink
                  label={t("Payments.PaymentReservationList.paymentLinkLabel", {
                    number: 1,
                  })}
                  link={receipts.payments_deposit.deposit.deposit_payment_link}
                  isCopied={false}
                  button={{
                    type: "primary",
                    text: t("Booking.PaymentAndDepositLink.sendPayment"),
                    Icon: SendIcon,
                    onClick: () => {
                      modalSendTicketCaution.open({
                        caution: receipts.payments_deposit.deposit,
                      });
                    },
                  }}
                />
              </div>
            )}
          </main>
        </div>
        <div
          className={
            "bg-gray-100 border border-gray-200 rounded-md p-4 py-6 font-bold text-gray-500 text-lg mt-4"
          }
        >
          <header className={"flex items-center justify-between"}>
            <h1 className={"flex items-center gap-2"}>
              <BourseIcon />
              {t("Global.deposit")}
            </h1>
            <div className={"flex items-center gap-2"}>
              <Badge
                label={t(
                  isManagedByPlatform()
                    ? "Booking.cautionManagedBy"
                    : cautionIsRefund(receipts.payments_deposit.deposit)
                      ? "Booking.PaymentStatus.refunded"
                      : cautionIsPaid(receipts.payments_deposit.deposit)
                        ? "Booking.PaymentStatus.paid"
                        : "Booking.PaymentStatus.unpaid",
                )}
                status={
                  isManagedByPlatform()
                    ? "success"
                    : cautionIsRefund(receipts.payments_deposit.deposit)
                      ? "fail"
                      : cautionIsPaid(receipts.payments_deposit.deposit)
                        ? "success"
                        : "fail"
                }
              />
            </div>
          </header>
          <main className={"mt-4"}>
            {isManagedByPlatform() ? (
              <>
                <p className={"font-bold text-black"}>
                  {t("Booking.cautionDirectlyManaged")}
                </p>
              </>
            ) : (
              <>
                <p className={"font-bold text-black"}>
                  {t("Booking.cautionParam", {
                    money: getFormattedPrice({
                      price: receipts.payments_deposit.deposit.deposit_value,
                    }),
                  })}
                </p>
                <p className={"font-normal mt-1 mb-4"}>
                  {t("Booking.msgAutoWithDate", {
                    date: moment(
                      receipts.payments_deposit.deposit
                        .deposit_payment_specific_date,
                    ).format("LL"),
                    hours: moment(
                      receipts.payments_deposit.deposit
                        .deposit_payment_specific_date,
                    ).format("HH:mm"),
                  })}
                </p>
                <CopyLink
                  label={t("Payments.PaymentReservationList.cautionLinkLabel", {
                    number: 1,
                  })}
                  link={receipts.payments_deposit.deposit.deposit_payment_link}
                  isCopied={false}
                  button={{
                    type: "primary",
                    text: t("Booking.PaymentAndDepositLink.sendPayment"),
                    Icon: SendIcon,
                    onClick: () => {
                      modalSendTicketCaution.open({
                        caution: receipts.payments_deposit.deposit,
                      });
                    },
                  }}
                />
              </>
            )}
          </main>
        </div>
        <div
          className={
            "bg-gray-100 border border-gray-200 rounded-md p-4 py-6 font-bold text-gray-500 text-lg mt-4"
          }
        >
          <h1 className={"flex items-center gap-2"}>
            <BourseIcon />
            {t("Booking.cancelCondition")}
          </h1>
          <p className={"mt-4 text-black font-bold"}>
            {t(isRefundable() ? "Booking.daysRefund" : "Booking.daysNoRefund")}
          </p>
        </div>
      </Card>
    </>
  );
}
