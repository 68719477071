import { t } from "i18next";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  ADD_RESERVATION_STEPS,
  AddReservationCalendarType,
  AddReservationGeneralType,
  AddReservationGuestType,
  AddReservationTarificationType,
  AddReservationType,
} from "../../components/AddReservation/AddReservation.type";
import { AddReservationCloseModal } from "../../components/AddReservation/CloseModal";
import { AddReservationDone } from "../../components/AddReservation/Done/AddReservationDone";
import { AddReservationGuest } from "../../components/AddReservation/Guest/AddReservationGuest";
import { AddReservationInfos } from "../../components/AddReservation/Infos/AddReservationInfos";
import { AddReservationPayment } from "../../components/AddReservation/Payment/AddReservationPayment";
import { AddReservationPaymentModal } from "../../components/AddReservation/Payment/AddReservationPaymentModal";
import { AddReservationPrices } from "../../components/AddReservation/Prices/AddReservationPrices";
import { AddReservationPricesModal } from "../../components/AddReservation/Prices/AddReservationPricesModal";
import { AddReservationSummary } from "../../components/AddReservation/Summary/AddReservationSummary";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import {
  PaymentPolicyForm,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyResponse,
  PaymentPolicyValueTypeEnum,
} from "../../components/Payment/Payment.type";
import { useModal } from "../../hooks/useModal";
import {
  NotificationStatusesListItemResponse,
  PaymentReservationScheduleResponse,
  RentalLightListItemResponse,
} from "../../types/GETTypes";

export const AddReservationPage: React.FC<{}> = () => {
  const [closeVisible, setCloseVisible] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<ADD_RESERVATION_STEPS>(
    ADD_RESERVATION_STEPS.INFORMATIONS
  );
  const [currentReservation, setCurrentReservation] =
    useState<AddReservationType>();

  const handlePreviousStep = () => {
    setCurrentStep((previousStep) => previousStep - 1);
  };

  const handleNextStepInfos = ({
    rental,
    general,
    tarification,
    calendar,
  }: {
    rental: RentalLightListItemResponse;
    general: AddReservationGeneralType;
    tarification: AddReservationTarificationType;
    calendar: AddReservationCalendarType;
  }) => {
    setCurrentReservation({
      ...currentReservation,
      rental,
      general,
      tarification,
      calendar,
    });

    setCurrentStep(ADD_RESERVATION_STEPS.PRICES);
  };

  // * -- PRICES --
  const handleNextStepPrices = (
    tarification: AddReservationTarificationType
  ) => {
    setCurrentReservation({ ...currentReservation, tarification });

    setCurrentStep(ADD_RESERVATION_STEPS.GUEST);
  };

  // * Reservation prices step
  const reservationModalPrices = useModal<AddReservationType | undefined>();

  const handleNextStepGuest = (guest: AddReservationGuestType) => {
    setCurrentReservation({ ...currentReservation, guest });

    setCurrentStep(ADD_RESERVATION_STEPS.PAYMENT);
  };

  const handleNextStepPayment = (
    reservation_id: number,
    payment_schedule: PaymentReservationScheduleResponse,
    notification_statuses: NotificationStatusesListItemResponse[]
  ) => {
    setCurrentReservation({
      ...currentReservation,
      reservation_id,
      payment_schedule,
      notification_statuses,
    });

    setCurrentStep(ADD_RESERVATION_STEPS.DONE);
  };

  // * Reservation payment step
  const reservationModalPayment = useModal<{}>();

  const paymentPolicyForm = useForm<PaymentPolicyForm>({
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      is_favorite_policy: false,
      is_single_payment: true,
      payments_policy_items: [
        {
          valueType: PaymentPolicyValueTypeEnum.PERCENT,
          value: 100,
          specificDate: new Date(),
          trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
          paymentAccountId: null,
        },
      ],
      is_refundable: false,
      refund_value: null,
      refund_condition: null,
      is_deposit_required: true,
      deposit_value: 100,
      deposit_payment_option: null,
      deposit_payment_account_id: null,
      deposit_payment_days_delay: null,
    },
  });

  const [currentPolicy, setCurrentPolicy] = useState<
    PaymentPolicyResponse | undefined
  >();

  const paymentPolicyItems = useFieldArray({
    control: paymentPolicyForm.control,
    name: "payments_policy_items",
  });

  // useEffect(() => {
  //   console.log("append policy item !!!");
  //   paymentPolicyItems.append({
  //     trigger: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
  //     valueType: PaymentPolicyValueTypeEnum.PERCENT,
  //     value: 100,
  //     specificDate: new Date(),
  //     paymentAccountId: null,
  //   });
  // }, []);

  return (
    <>
      <AddReservationCloseModal
        isVisible={closeVisible}
        onClose={() => setCloseVisible(false)}
      />

      {currentStep === ADD_RESERVATION_STEPS.PRICES && (
        <AddReservationPricesModal modal={reservationModalPrices} />
      )}

      {currentStep === ADD_RESERVATION_STEPS.PAYMENT && (
        <AddReservationPaymentModal
          modal={reservationModalPayment}
          form={paymentPolicyForm}
          paymentPolicyItems={paymentPolicyItems}
          reservation={currentReservation}
          currentPolicy={currentPolicy}
          onChangeCurrentPolicy={(currentPolicy) =>
            setCurrentPolicy(currentPolicy)
          }
          onNext={(nextPaymentPolicy) => setCurrentPolicy(nextPaymentPolicy)}
        />
      )}

      <FullScreenLayout onClose={() => setCloseVisible(true)}>
        {/* Left */}
        <div className="w-2/5 max-w-md p-2 pb-0">
          <AddReservationSummary
            reservation={currentReservation}
            currentStep={currentStep}
          />
        </div>

        {/* Right */}
        <div className="flex-1 px-12 pt-8">
          {currentStep === ADD_RESERVATION_STEPS.INFORMATIONS && (
            <AddReservationInfos
              reservation={currentReservation}
              onNext={handleNextStepInfos}
              onCancel={() => setCloseVisible(true)}
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.PRICES && (
            <AddReservationPrices
              reservation={currentReservation!}
              onNext={handleNextStepPrices}
              onCancel={() => setCloseVisible(true)}
              onBack={handlePreviousStep}
              onOpeningPricesModal={() =>
                reservationModalPrices.open(currentReservation)
              }
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.GUEST && (
            <AddReservationGuest
              reservation={currentReservation!}
              onNext={handleNextStepGuest}
              onCancel={() => setCloseVisible(true)}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.PAYMENT && (
            <AddReservationPayment
              modal={reservationModalPayment}
              submitError={
                Object.values(paymentPolicyForm.formState.errors).length > 0
                  ? t("AddReservation.Payment.paymentPolicyFormError")
                  : ""
              }
              paymentPolicyFormValues={paymentPolicyForm.getValues}
              reservation={currentReservation!}
              currentPolicy={currentPolicy}
              onNext={handleNextStepPayment}
              onCancel={() => setCloseVisible(true)}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RESERVATION_STEPS.DONE && (
            <AddReservationDone reservation={currentReservation!} />
          )}
        </div>
      </FullScreenLayout>
    </>
  );
};
