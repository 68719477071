import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckGreenIcon from "../../../../assets/icons/check-circle-green.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import CloseCirclecon from "../../../../assets/icons/close-circle.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import FileCheckmark from "../../../../assets/icons/file-checkmark.svg?react";
import { useUpdateInformationGuestPage } from "../../../../hooks/api/guestPage";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { Badge } from "../../../Common/Badge/Badge";
import { BadgeStatus } from "../../../Common/Badge/Badge.type";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { Switch } from "../../../Common/Switch/Switch";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import { ResourceCardSkeleton } from "./ResourceCardSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const ResourceCard: React.FC<any> = ({
  loading,
  rentalId,
  guest_page,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const { onUpdateRental } = useRentalPageContext();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [data, setData] = useState(guest_page);

  const {
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues: {},
  });

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const handleActiveInformationTravelerPage = () => {
    setValidationLoading(true);
    const allStatus = {
      id_card_enabled: data?.id_card_enabled ? 1 : 0,
      passport_enabled: data?.passport_enabled ? 1 : 0,
      selfie_enabled: data?.selfie_enabled ? 1 : 0,
      cb_enabled: data?.cb_enabled ? 1 : 0,
      // police_record_enabled: data?.police_record_enabled ? 1 : 0,
    };
    useUpdateInformationGuestPage(
      rentalId,
      allStatus,
      (response) => {
        setValidationLoading(false);
        console.log(response, "response");
        onUpdateRental(response);
        setEditMode(false);
      },
      (error) => {
        setValidationLoading(false);
        setError(error);
      }
    );
  };

  if (loading) return <ResourceCardSkeleton />;

  return (
    <Card
      loading={validationLoading}
      Icon={FileCheckmark}
      label={t("Rental.Infos.TravelerPage.resource_requested")}
      anchor="resources-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: editMode
          ? handleSubmit(() => {
              handleActiveInformationTravelerPage();
            })
          : () => {
              setEditMode(!editMode);
              /** Fix reset values to inital values */
              setData(guest_page);
            },
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-5">
            <p className="text-low-contrast">
              {t("Rental.Infos.TravelerPage.RessourceCard.title")}
            </p>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t(
                  "Rental.Infos.TravelerPage.RessourceCard.national_identity_card"
                )}
              </p>
              <Switch
                value={data?.id_card_enabled ?? false}
                onClick={() => {
                  setData({ ...data, id_card_enabled: !data?.id_card_enabled });
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RessourceCard.passport")}{" "}
              </p>
              <Switch
                value={data?.passport_enabled ?? false}
                onClick={() => {
                  setData({
                    ...data,
                    passport_enabled: !data?.passport_enabled,
                  });
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RessourceCard.selfie_photo")}
              </p>
              <Switch
                value={data?.selfie_enabled ?? false}
                onClick={() => {
                  setData({ ...data, selfie_enabled: !data?.selfie_enabled });
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RessourceCard.cb")}
              </p>
              <Switch
                value={data?.cb_enabled ?? false}
                onClick={() => {
                  setData({ ...data, cb_enabled: !data?.cb_enabled });
                }}
              />
            </div>
            {/* <div className="flex flex-row justify-between">
                            <p className="font-bold">{t("Rental.Infos.TravelerPage.RessourceCard.police_record")}</p>
                            <Switch value={data?.police_record_enabled ?? false} onClick={() => {
                                setData({...data, police_record_enabled: !data?.police_record_enabled})
                            }}/>
                        </div> */}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t(
                  "Rental.Infos.TravelerPage.RessourceCard.national_identity_card"
                )}
              </p>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page.id_card_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={
                      (guest_page?.id_card_enabled ? "success" : "failed") as
                        | BadgeStatus
                        | undefined
                    }
                    size="large"
                    customColor={
                      !guest_page?.id_card_enabled && "text-gray-800"
                    }
                    customBg={!guest_page?.id_card_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.id_card_enabled
                        ? CloseCirclecon
                        : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.id_card_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RessourceCard.passport")}
              </p>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page?.passport_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={
                      guest_page?.passport_enabled ? "success" : undefined
                    }
                    size="large"
                    customColor={
                      !guest_page?.passport_enabled && "text-gray-800"
                    }
                    customBg={!guest_page?.passport_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.passport_enabled
                        ? CloseCirclecon
                        : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.passport_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RessourceCard.selfie_photo")}
              </p>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page?.selfie_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={guest_page?.selfie_enabled ? "success" : undefined}
                    size="large"
                    customColor={!guest_page?.selfie_enabled && "text-gray-800"}
                    customBg={!guest_page?.selfie_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.selfie_enabled
                        ? CloseCirclecon
                        : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.selfie_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RessourceCard.cb")}
              </p>
              <Tooltip
                Icon={
                  <Badge
                    label={
                      guest_page.cb_enabled
                        ? t("Rental.Infos.TravelerPage.requested")
                        : t("Rental.Infos.TravelerPage.notRequest")
                    }
                    status={guest_page?.cb_enabled ? "success" : undefined}
                    size="large"
                    customColor={!guest_page?.cb_enabled && "text-gray-800"}
                    customBg={!guest_page?.cb_enabled && "bg-gray-200"}
                    Icon={
                      !guest_page?.cb_enabled ? CloseCirclecon : CheckGreenIcon
                    }
                  />
                }
                value={
                  guest_page?.cb_enabled
                    ? t("Rental.Infos.TravelerPage.requested")
                    : t("Rental.Infos.TravelerPage.notRequest")
                }
                classNames={{
                  container: "top-full mt-1 right-0",
                }}
              />
            </div>
            {/* <div className="flex flex-row justify-between">
                            <p className="font-bold">{t("Rental.Infos.TravelerPage.RessourceCard.police_record")}</p>
                            <Tooltip
                                Icon={
                                    <Badge
                                        label={
                                            guest_page?.police_record_enabled
                                                ? t("Rental.Infos.TravelerPage.requested")
                                                : t("Rental.Infos.TravelerPage.notRequest")
                                        }
                                        status={guest_page?.police_record_enabled ? "success" : ""}
                                        size="large"
                                        customColor={!guest_page?.police_record_enabled && "text-gray-800"}
                                        customBg={!guest_page?.police_record_enabled && "bg-gray-200"}
                                        Icon={!guest_page?.police_record_enabled ? CloseCirclecon : CheckGreenIcon}
                                    />
                                }
                                value={
                                    guest_page?.police_record_enabled
                                        ? t("Rental.Infos.TravelerPage.requested")
                                        : t("Rental.Infos.TravelerPage.notRequest")
                                }
                                classNames={{
                                    container: "top-full mt-1 right-0",
                                }}
                            />
                        </div> */}
          </div>
        )}
      </div>
    </Card>
  );
};
