import React, {ReactNode} from "react";

export interface FlexItemCenterProps {
    children?: ReactNode;
    gap?: number;
    className?: string;
}

export const FlexItemCenter: React.FC<FlexItemCenterProps> = ({
    children,
    gap = 2,
    className = "",
}) => {
    return (
        <section
            className={`flex items-center ${className}`}
            style={{ gap: `${gap}px` }}
        >
            {children}
        </section>
    );
};
