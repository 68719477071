/**
 * Modal form for :
 * - Delete external platform / show informations
 * - Insert new external platform
 * - copy link .ics from SuperHote Calendar
 */

import { RightModal } from "../../../../Common/RightModal/RightModal";
import { TextInput } from "../../../../Common/TextInput/TextInput";
import { Separator } from "../../../../Common/Separator/Separator";
import { Button } from "../../../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIcalendar } from "../../../../../hooks/api/calendar";
import { ErrorMessage } from "../../../../Common/ErrorMessage/ErrorMessage";

import CopyIcon from "../../../../../assets/icons/copy.svg?react";
import SuccessIcon from "../../../../../assets/icons/check-success.svg?react";

import {
  ConnectPlatformType,
  ExternalPlatformModalProps,
} from "./ExternalPlatform.type";


export const ExternalPlatformModal = ({
  icalendarSuperhoteLink,
  modal,
  rental,
  isSynchronized = false,
  platform = null,
  onFinish = null,
}: ExternalPlatformModalProps) => {
  /** all hooks */
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConnectPlatformType>({
    defaultValues: {
      icalendarLink: "",
      platformName: "",
    },
  });

  const { connectRental, disconnectRental } = useIcalendar(); // hooks for interact with icalendar endpoints

  /** all states */
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  /**
   * Function handling closing Modal.
   */
  const handleCloseModal = () => {
    modal.close();
  };

  /**
   * Handle click in input link, and copy icalendar link to
   * client.
   */
  const handleCopyLinkCalendar = () => {
    navigator.clipboard.writeText(icalendarSuperhoteLink).then(() => {
      setIsCopied(true);
    });
  };

  /**
   * Function for call API to connect external platform
   * with a link icalendar.
   *
   * @param formData
   */
  const handleSubmitForm = async (formData: ConnectPlatformType) => {
    setLoading(true);
    const icalendarLink = formData.icalendarLink;
    const platformName = formData.platformName;

    try {
      await connectRental(rental.id, icalendarLink, platformName);
      if (typeof onFinish === "function") {
        onFinish();
      }
    } catch (error) {
      setError(error?.message);
    }
    setLoading(false);
  };

  /**
   * Function for handle deleting platform.
   */
  const handleDeleteExternalConnection = async () => {
    setLoading(true);

    try {
      await disconnectRental(rental.id, platform?.id);
      if (typeof onFinish === "function") {
        // is called when modal form is finished, is good for update
        // externals platforms list.
        onFinish();
      }
    } catch (error) {
      setError(error?.message);
    }
    setLoading(false);
  };

  return (
    <RightModal
      classNames={{
        mainContentParent: `overflow-y-auto`,
      }}
      title={t("Rental.Infos.Platforms.syncWithOtherCalendar")}
      isVisible={modal.isVisible}
      onClose={handleCloseModal}
    >
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className={"flex flex-col justify-between"}
      >
        <section>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <p className={"text-gray-500 text-xs font-bold"}>
            1. {t("Rental.Infos.Platforms.stepOne")}
          </p>
          <TextInput
            label={t("Rental.Infos.Platforms.linkIcal")}
            classNames={{
              divLabel: "mt-4",
              divInput: "cursor-pointer bg-gray-100",
              input: "cursor-pointer bg-gray-100",
            }}
            RightIcon={isCopied ? SuccessIcon : CopyIcon}
            value={icalendarSuperhoteLink}
            onClick={handleCopyLinkCalendar}
          />
          <Separator />
          <p className={"text-gray-500 text-xs font-bold"}>
            2. {t("Rental.Infos.Platforms.stepTwo")}
          </p>
          <TextInput
            label={t("Rental.Infos.Platforms.platformName")}
            placeholder={t("Rental.Infos.Platforms.platformPlaceholder")}
            classNames={{
              divLabel: "mt-4",
            }}
            required={!isSynchronized}
            register={
              !isSynchronized &&
              register("platformName", {
                required: {
                  value: true,
                  message: t("Rental.Infos.Platforms.requiredPlatformName"),
                },
              })
            }
            disabled={isSynchronized}
            error={errors.platformName && errors.platformName?.message}
            value={platform?.connexion_name}
          />
          <TextInput
            label={t("Rental.Infos.Platforms.externalLinkIcal")}
            placeholder={t(
              "Rental.Infos.Platforms.externalLinkIcalPlaceholder",
            )}
            classNames={{
              divLabel: "mt-4",
            }}
            required={!isSynchronized}
            register={
              !isSynchronized &&
              register("icalendarLink", {
                required: {
                  value: true,
                  message: t("Rental.Infos.Platforms.requiredIcalendarLink"),
                }
              })
            }
            disabled={isSynchronized}
            error={errors.icalendarLink && errors.icalendarLink?.message}
            value={platform?.icalendar_link}
          />
          <p className={"text-gray-500 text-xs font-bold mt-4"}>
            {t("Rental.Infos.Platforms.help")}
          </p>
        </section>
        <section className={"flex items-center gap-4"}>
          {!isLoading && (
            <Button type={"secondary"}>{t("Global.cancel")}</Button>
          )}
          <Button
            loading={isLoading}
            onClick={isSynchronized && handleDeleteExternalConnection}
          >
            {isSynchronized ? t("Global.remove") : t("Global.save")}
          </Button>
        </section>
      </form>
    </RightModal>
  );
};
