import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RentalDescriptionCardProps } from "./DescriptionCard.type";
import { RentalDescriptionCardSkeleton } from "./DescriptionCardSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const RentalDescriptionCard: React.FC<RentalDescriptionCardProps> = ({
  loading,
  rentalId,
  descriptions,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm<{
    description: string;
  }>({
    mode: "onChange",
    defaultValues: {
      description: "",
    },
  });

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  useEffect(() => {
    // TODO waiting translation
    if (descriptions && descriptions.length > 0) {
      setValue("description", descriptions[0].description);
      setDescription(descriptions[0].description);
    }
  }, [descriptions]);

  const handleDescriptionEdit = () => {
    if (editMode) {
      if (descriptions !== undefined) {
        const updateDescription = async () => {
          setLoadingValidation(true);
          const response = await post(
            `${import.meta.env.VITE_API_URL}${
              paths.API.RENTALS_UPDATE
            }/${rentalId}${paths.API.DESCRIPTION_DETAILS}`,
            {
              description: getValues("description"),
            }
          );

          if (response.data?.success) {
            setDescription(getValues("description"));
            setEditMode(!editMode);
            setLoadingValidation(false);
          }
        };
        updateDescription();
      }
    } else {
      setEditMode(true);
    }
  };

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...restDescriptionRegister } = register("description", {
    required: {
      value: false,
      message: t("Global.Errors.requiredField", {
        fieldName: t("Rental.Infos.Description.title"),
      }),
    },
  });

  useEffect(() => {
    if (editMode) {
      if (descriptionRef.current) {
        descriptionRef.current.style.height =
          descriptionRef.current.scrollHeight + "px";
      }
    }
  }, [editMode, descriptionRef.current]);

  const handleCancel = () => {
    setEditMode(false);
    setValue("description", description);
  };

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return editMode && (!isValid || loadingValidation);
  };

  if (loading) return <RentalDescriptionCardSkeleton />;

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Rental.Infos.Description.title")}
      anchor="description-card"
      loading={loadingValidation}
      hasBody={editMode || Boolean(getValues("description"))}
      button={{
        Icon: editMode
          ? CheckIcon
          : Boolean(getValues("description"))
          ? EditIcon
          : PlusIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode
          ? t("Global.record")
          : Boolean(getValues("description"))
          ? t("Global.edit")
          : t("Global.add"),
        onClick: editMode
          ? handleSubmit(handleDescriptionEdit)
          : () => setEditMode(!editMode),
        disabled: isEditButtonDisabled(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => handleCancel(),
            }
          : undefined
      }
    >
      {editMode ? (
        <div className="flex flex-col gap-2">
          <textarea
            ref={(e) => {
              ref(e);
              descriptionRef.current = e;
            }}
            className="w-full p-2 rounded resize-y text-high-contrast border-1 border-element-border focus:outline-none h-max"
            disabled={loadingValidation}
            {...restDescriptionRegister}
          ></textarea>
          <ErrorMessage errorType="FORM">
            {errors.description?.message}
          </ErrorMessage>
        </div>
      ) : (
        <p className="text-low-contrast whitespace-break-spaces">
          {getValues("description")}
        </p>
      )}
    </Card>
  );
};
