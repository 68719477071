import currencies from "../res/currencies";
import { ValueType } from "../types/commonTypes";

export const getCurrencySigle = (value: ValueType) => {
  const index = currencies.findIndex((c) => c.id === value);
  if (index <= -1) return "";
  else return currencies[index].symbol;
};

/**
 * Formats a given price according to specified locale, currency, and decimal options.
 *
 * @param {Object} params - The parameters for formatting the price.
 * @param {number} params.price - The price value to be formatted.
 * @param {"fr-FR" | "en-US"} [params.locale] - The locale to use for formatting. Defaults to "fr-FR".
 * @param {"EUR" | "USD" | "GBP"} [params.currency] - The currency to use for formatting. Defaults to "EUR".
 * @param {0 | 1 | 2} [params.decimals] - The number of decimal places to display. Defaults to 0.
 * @param {"." | ","} [params.decimalSeparator] - The decimal separator to use. If specified, replaces the default separator.
 *
 * @returns {string} The formatted price string.
 */
export const getFormattedPrice = ({
  price,
  locale = "fr-FR",
  currency = "EUR",
  decimals = 2,
  decimalSeparator = ",",
}: {
  price: number;
  locale?: "fr-FR" | "en-US";
  currency?: "EUR" | "USD" | "GBP";
  decimals?: 0 | 1 | 2;
  decimalSeparator?: "." | ",";
}) => {
  let formattedPrice = price.toLocaleString(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  if (decimalSeparator) {
    formattedPrice = formattedPrice.replace(
      decimalSeparator === "." ? "," : ".",
      decimalSeparator
    );
  }

  return formattedPrice;
};
