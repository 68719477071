import React from "react";
import {useTranslation} from "react-i18next";
import {cn} from "../../helpers/classHelper";
import {getPlatformIcon} from "../../helpers/platformHelper";
import {Button} from "../Common/Button/Button";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import LogInIcon from "../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../assets/icons/log-out.svg?react";
import MailIcon from "../../assets/icons/mail.svg?react";
import PhoneIcon from "../../assets/icons/phone.svg?react";
import ShowIcon from "../../assets/icons/show.svg?react";
import FileIcon from "../../assets/icons/file-text.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";
import moment from "moment-timezone";
import {updateAssignationType} from "../../pages/Dashboard/DashboardPage";
import {getRelativeDay} from "../../helpers/dateHelper";
import {
  TaskItemResponse,
  TaskLastReservation,
  TaskNextReservation,
} from "../../types/GETTypes";
import {PlatformNameEnum} from "../../enums/GETenums";
import {Tooltip} from "../Common/Tooltip/Tooltip";

function isReservationEmpty(
  reservation: TaskNextReservation | TaskLastReservation | null | undefined
): boolean {
  if (!reservation) {
    return true;
  } else if ("checkout_date" in reservation) {
    // It's a TaskLastReservation
    return !(
      reservation.guest_first_name ||
      reservation.guest_last_name ||
      (reservation.checkout_date != null && reservation.checkout_date !== "") ||
      (reservation.checkout_time != null && reservation.checkout_time !== "") ||
      reservation.nights_count != null ||
      reservation.guests_count != null
    );
  } else if ("checkin_date" in reservation) {
    // It's a TaskNextReservation
    return !(
      reservation.guest_first_name ||
      reservation.guest_last_name ||
      (reservation.checkin_date != null && reservation.checkin_date !== "") ||
      (reservation.checkin_time != null && reservation.checkin_time !== "") ||
      reservation.nights_count != null ||
      reservation.guests_count != null
    );
  }
  return true;
}

const DisplayReservation = ({
  reservation,
  type,
}: {
  reservation: TaskNextReservation | TaskLastReservation;
  type: "last" | "next";
}) => {
  const {t} = useTranslation();
  const isLast = type === "last";
  const time =
    isLast && "checkout_time" in reservation
      ? reservation.checkout_time
      : "checkin_time" in reservation
      ? reservation.checkin_time
      : "";
  if (!isReservationEmpty(reservation)) {
    return (
      <div className="flex flex-col space-y-2">
        <div className="flex items-center gap-1">
          {isLast ? (
            <>
              <LogOutIcon className="w-4 h-4 font-light  text-low-contrast" />
              <p className="font-bold text-md text-low-contrast">
                {t("Dashboard.lastReservation")}
              </p>
            </>
          ) : (
            <>
              <LogOutIcon className="w-4 h-4 font-light  text-low-contrast" />
              <p className="font-bold text-md text-low-contrast">
                {t("Dashboard.nextReservation")}
              </p>
            </>
          )}
        </div>

        <div className="flex flex-col px-2 py-1 min-w-[200px] rounded-4px h-[115px] space-y-2 border-1 border-element-border hover:cursor-pointer bg-element-background/30">
          <div className="flex justify-between">
            <p className="text-sm text-high-contrast">
              {reservation?.guest_first_name} {reservation?.guest_last_name}
            </p>
            {!isLast && (reservation as TaskNextReservation)?.private_notes ? (
              <Tooltip
                Icon={FileIcon}
                value={
                  (reservation as TaskNextReservation)?.private_notes ?? ""
                }
                size="large"
                maxLines={3}
                classNames={{container: "top-0 right-[5px]"}}
              />
            ) : null}
          </div>
          <p className="text-sm text-low-contrast">
            {reservation?.guest_phone}
          </p>
          <p className="text-sm text-high-contrast font-bold">
            {getRelativeDay(
              isLast && "checkout_date" in reservation
                ? reservation.checkout_date
                : "checkin_date" in reservation
                ? reservation.checkin_date
                : ""
            )}
            {time
              ? ` ${t("Dashboard.at")} ` +
                moment(time, "HH:mm").format("HH [h]")
              : ""}
          </p>

          <div className="flex space-x-2 justify-between">
            <div className="flex space-x-1 items-center w-full">
              <MoonIcon className="w-5 h-5" />
              <p className="text-xs text-low-contrast">
                {t("Global.night", {
                  count: reservation?.nights_count ?? 0,
                })}
              </p>
            </div>

            <div className="flex space-x-1 items-center w-full">
              <UsersIcon className="w-5 h-5" />
              <p className="text-xs text-low-contrast">
                {t("Global.adults", {
                  count: reservation?.guests_count ?? 0,
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center gap-1">
        {isLast ? (
          <>
            <LogOutIcon className="w-4 h-4 font-light  text-low-contrast" />
            <p className="font-bold text-md text-low-contrast">
              {t("Dashboard.lastReservation")}
            </p>
          </>
        ) : (
          <>
            <LogOutIcon className="w-4 h-4 font-light  text-low-contrast" />
            <p className="font-bold text-md text-low-contrast">
              {t("Dashboard.nextReservation")}
            </p>
          </>
        )}
      </div>

      <div className="flex flex-col px-2 py-1 min-w-[200px] rounded-4px h-[115px] space-y-2 border-1 border-element-border hover:cursor-pointer bg-element-background/30 items-center justify-center">
        <p className="text-sm text-high-contrast font-bold">
          {isLast
            ? t("Dashboard.emptyLastReservation")
            : t("Dashboard.emptyNextReservation")}
        </p>
      </div>
    </div>
  );
};

export const DashboardTasksItem: React.FC<{
  task: TaskItemResponse;
  onAcceptTask: (task: TaskItemResponse | undefined) => void;
  onDeclineTask: (task: TaskItemResponse | undefined) => void;
}> = ({task, onAcceptTask, onDeclineTask}) => {
  const {t} = useTranslation();

  const isAssigned =
    task.status === "COMPLETED" || task.status !== "NOT_ASSIGNED";
  const isDeclinedByMe = task.assigned_or_not_task_data?.is_declined_by_me;
  const guest = isAssigned
    ? task?.assigned_operators
      ? task.assigned_operators[0]
      : ""
    : "";
  const next_reservation = task?.next_reservation;
  const last_reservation = task?.last_reservation;

  return (
    <div
      className={cn(
        `flex items-center p-4 border-t-0 border-b-1 border-x-1 border-element-border hover:cursor-pointer hover:bg-element-background/30`
      )}
    >
      <div className="flex flex-1 items-center justify-between space-x-4">
        <div className="flex w-1/3 items-center space-x-2">
          {/* Rental */}
          <div className="p-4 relative">
            <img
              src={task?.rental?.cover!}
              className="rounded-4px w-10 h-10 bg-slate-200 border-1 border-element-border-light"
            />
            {task?.reservation_id && (
              <div className="absolute left-11 top-6 w-2 h-2">
                {getPlatformIcon(
                  task?.reservation_platform?.toUpperCase() as PlatformNameEnum
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-4">
            <p className="text-sm text-high-contrast">{task?.rental?.name}</p>
            <p className="text-sm text-low-contrast">
              {task?.rental?.address} {task?.rental?.postal_code}{" "}
              {task?.rental?.city}
            </p>
          </div>
        </div>

        <p className="w-1/5 text-sm font-bold text-high-contrast text-center">
          {task.name}
        </p>

        <p className="w-1/5 text-sm font-bold text-high-contrast text-center">
          {t("Dashboard.startingTime")}{" "}
          {moment(task.scheduled_time, "HH:mm").format("HH[h]mm")}
        </p>

        {/* Guest */}
        {guest ? (
          <div className="flex w-1/5 items-center space-x-2">
            <img
              src={guest?.photo!}
              className="w-12 h-12 rounded-full  border-1 border-element-light bg-slate-200"
            />

            <div className="flex space-x-1">
              <p className="text-sm font-bold text-low-contrast">
                {guest?.first_name}
              </p>
              <p className="text-sm font-bold text-low-contrast">
                {guest?.last_name}
              </p>
            </div>
          </div>
        ) : (
          <div className="w-1/5 flex flex-col space-y-2 items-center">
            <span className="text-active text-xs font-bold">
              {t("Dashboard.noneAssignedSingular")}
            </span>
            <div className="flex space-x-2">
              {!isDeclinedByMe && (
                <Button type="alert" onClick={() => onDeclineTask(task)}>
                  {t("Dashboard.refuse")}
                </Button>
              )}
              <Button onClick={() => onAcceptTask(task)}>
                {t("Dashboard.accept")}
              </Button>
            </div>
          </div>
        )}
        {/* Reservation */}
        <div className={cn(`flex items-center space-x-8`)}>
          <DisplayReservation reservation={last_reservation} type="last" />
          <DisplayReservation reservation={next_reservation} type="next" />
        </div>
      </div>
    </div>
  );
};
