import {useTranslation} from "react-i18next";
import {ReservationExtraOrderStatusEnum} from "../enums/GETenums";

export const getColorTextValidate = (
  status: ReservationExtraOrderStatusEnum
) => {
  const t = useTranslation().t;
  switch (status) {
    case ReservationExtraOrderStatusEnum.PAID:
    case ReservationExtraOrderStatusEnum.PARTIALLY_PAID:
      return {
        color: "text-low-contrast font-bold",
        text: t("Booking.ExtrasOrder.valid"),
      };
    case ReservationExtraOrderStatusEnum.UNPAID:
      return {color: "", text: ""};
    case ReservationExtraOrderStatusEnum.REFUNDED:
      return {color: "", text: ""};
    case ReservationExtraOrderStatusEnum.CANCELED:
      return {color: "", text: ""};
    case ReservationExtraOrderStatusEnum.PRE_AUTHORIZED:
      return {color: "text-active font-bold", text: t("Booking.ExtrasOrder.toValidate")};
  }
};
