import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import CopyIcon from "../../../assets/icons/copy-white.svg?react";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import EyeOffIcon from "../../../assets/icons/eye-off.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import MoonIcon from "../../../assets/icons/moon.svg?react";
import UsersIcon from "../../../assets/icons/user.svg?react";
import paths from "../../../constants/paths";
import {PaymentReservationDepositStatusEnum} from "../../../enums/GETenums";
import {get} from "../../../helpers/APIHelper";
import {getPlatformIcon} from "../../../helpers/platformHelper";
import {getDepositStatusBadge} from "../../../helpers/reservationHelper";
import {useModal} from "../../../hooks/useModal";
import {PaymentReservationResponse} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Rating} from "../../Common/Rating/Rating";
import {RightModal} from "../../Common/RightModal/RightModal";
import {Separator} from "../../Common/Separator/Separator";
import {PaymentDepositCaptureModal} from "./PaymentDepositCaptureModal";
import {PaymentDepositDetailsModalSkeleton} from "./PaymentDepositDetailsModalSkeleton";
import {PaymentDepositNoRefundModal} from "./PaymentDepositNoRefundModal";
import {PaymentDepositRefundModal} from "./PaymentDepositRefundModal";
import {PaymentDepositReleaseModal} from "./PaymentDepositReleaseModal";
import {hiddenDevice} from "../../../helpers/calendarHelper";

export const PaymentDepositDetailsModal: React.FC<{
  paymentReservation: PaymentReservationResponse | null | undefined;
  isVisible: boolean;
  onBack?: () => void;
  onClose: () => void;
  onAskRefresh?: () => void;
}> = ({
  paymentReservation,
  isVisible,
  onBack,
  onAskRefresh = () => {},
  onClose,
}) => {
  const { t } = useTranslation();

  const [currentDeposit, setCurrentDeposit] =
    useState<PaymentReservationResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const releaseModal = useModal<PaymentReservationResponse>();
  const captureModal = useModal<PaymentReservationResponse>();
  const refundModal = useModal<PaymentReservationResponse>();
  const noRefundModal = useModal<PaymentReservationResponse>();

  const fetchDeposit = async () => {
    setLoading(true);
    setError(null);

    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.DEPOSITS}/${
        paymentReservation?.id
      }`
    );

    if (res.data?.success) {
      setCurrentDeposit(res.data?.result);
    } else {
      setError(res.data?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isVisible) fetchDeposit();
  }, [isVisible]);

  const getTitle = () => {
    return `${t("Payments.PaymentDeposit.modalTitle")} ${
      paymentReservation?.id
    }`;
  };

  const getRightHeader = () => {
    return getDepositStatusBadge(
      paymentReservation?.payment_schedule.deposit_payment_status!
    );
  };

  const getDepositOption = () => {
    switch (currentDeposit?.payment_schedule.deposit_payment_option) {
      case "PRE_AUTHORISATION":
        return t("Payments.PaymentDepositList.preAuthorisationStatus");
      case "CARD_PAYMENT_TO_REFUND":
        return t("Payments.PaymentDepositList.cardPaymentStatus");
      case "BANK_TRANSFER_TO_REFUND":
        return t("Payments.PaymentDepositList.bankTransferStatus");
      case "BANK_CHECK_OR_CASH_AT_ARRIVAL":
        return t("Payments.PaymentDepositList.cashStatus");
    }
  };

  const paymentLink: string =
    location.protocol +
    "//" +
    location.host +
    "/reservation/" +
    currentDeposit?.id +
    "/deposit/" +
    currentDeposit?.payment_schedule.deposit_payment_link;

  const handleSuccessRelease = () => {
    fetchDeposit();
    onAskRefresh();
  };

  const handleSuccessCapture = () => {
    fetchDeposit();
    onAskRefresh();
  };

  const handleSuccessRefund = () => {
    fetchDeposit();
    onAskRefresh();
  };

  const handleSuccessNoRefund = () => {
    fetchDeposit();
    onAskRefresh();
  };

  return (
    <>
      <PaymentDepositReleaseModal
        isVisible={releaseModal.isVisible}
        deposit={releaseModal.data}
        onSuccess={handleSuccessRelease}
        onClose={releaseModal.close}
      />

      <PaymentDepositCaptureModal
        isVisible={captureModal.isVisible}
        deposit={captureModal.data}
        onSuccess={handleSuccessCapture}
        onClose={captureModal.close}
      />

      <PaymentDepositRefundModal
        isVisible={refundModal.isVisible}
        deposit={refundModal.data}
        onSuccess={handleSuccessRefund}
        onClose={refundModal.close}
      />

      <PaymentDepositNoRefundModal
        isVisible={noRefundModal.isVisible}
        deposit={noRefundModal.data}
        onSuccess={handleSuccessNoRefund}
        onClose={noRefundModal.close}
      />

      <RightModal
        isVisible={isVisible}
        onClose={onClose}
        onBack={onBack}
        title={getTitle()}
        rightHeaderNode={getRightHeader()}
      >
        {loading && <PaymentDepositDetailsModalSkeleton />}

        {!loading && (
          <div className="flex flex-col justify-between flex-1 w-full">
            <div className="flex flex-col w-full pb-4 overflow-y-auto">
              <div className="flex flex-col flex-1 gap-y-3">
                <p className="font-bold text-low-contrast">
                  {t("Payments.PaymentReservationList.contextLabel")}
                </p>

                <div>
                  <p className="font-bold text-high-contrast">
                    {t("Payments.PaymentReservationList.guestLabel")}
                  </p>
                  <div className="flex flex-row items-center justify-between p-2 space-x-3">
                    <div className="flex items-center space-x-4">
                      <div className="relative">
                        {/* LOGO */}
                        {paymentReservation?.platform ? (
                          <div
                            className="absolute z-10"
                            style={{ top: 22, left: 26, width: 16, height: 16 }}
                          >
                            {getPlatformIcon(
                              paymentReservation?.platform,
                              "small"
                            )}
                          </div>
                        ) : (
                          <div
                            className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                            style={{ top: 22, left: 26, width: 16, height: 16 }}
                          />
                        )}

                        {/* PHOTO */}
                        <img
                          src={paymentReservation?.guest.photo}
                          alt="Guest thumbnail"
                          className="w-10 h-10 rounded-full border-1 border-element-border/50"
                        />
                      </div>

                      <div>
                        {/* FIRSTNAME & LASTNAME */}
                        <>
                          <p className="font-semibold text-high-contrast">
                            {`${paymentReservation?.guest.first_name} ${paymentReservation?.guest.last_name}`}
                          </p>

                          {!paymentReservation?.guest.first_name &&
                            !paymentReservation?.guest.last_name && (
                              <div className="flex items-center space-x-2">
                                <EyeOffIcon width={15} height={15} />
                                <p className="text-low-contrast">
                                  {t("Booking.Guest.hiddenInformation")}
                                </p>
                              </div>
                            )}
                        </>

                        {/* CITY & COUNTRY */}
                        <div>
                          <p className="font-light text-low-contrast">
                            {paymentReservation?.guest.city !== undefined &&
                              paymentReservation?.guest.city !== null &&
                              `${paymentReservation?.guest.city}, `}
                            {paymentReservation?.guest.country}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* RATING */}
                    <div>
                      <Rating
                        value={paymentReservation?.guest.rate}
                        maxStars={5}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col space-y-3">
                    <div className="space-y-1">
                      <p className="font-bold text-high-contrast">
                        {t(
                          "Payments.PaymentReservationList.reservationPlatform",
                          {
                            name: paymentReservation?.platform.name,
                          }
                        )}
                      </p>

                      {paymentReservation?.platform?.reservation_reference && (
                        <p className="text-low-contrast">
                          {t("Payments.PaymentReservationList.reference", {
                            value: "",
                          })}
                          {paymentReservation?.platform.reservation_reference}
                        </p>
                      )}

                      <p className="text-low-contrast">
                        {t("Payments.PaymentReservationList.rangeDate", {
                          startDate: moment(currentDeposit?.checkin).format(
                            "DD MMM YYYY"
                          ),
                          endDate: moment(currentDeposit?.checkout).format(
                            "DD MMM YYYY"
                          ),
                        })}
                      </p>
                    </div>

                    <div className="flex items-center flex-1 space-x-3">
                      <img
                        src={currentDeposit?.rental?.cover}
                        alt=""
                        className="w-12 h-12 rounded-6px bg-slate-200"
                      />
                      <div className="flex flex-col">
                        <p className="text-base text-wrap w-52 text-high-contrast">
                          {paymentReservation?.rental.name}
                        </p>
                        <p className="text-wrap w-52 text-low-contrast">{`${paymentReservation?.rental.address} ${paymentReservation?.rental.postal_code} ${paymentReservation?.rental.city}`}</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-end flex-1 space-y-2">
                    <div className="flex items-center space-x-2">
                      <p className="text-base font-bold text-high-contrast">
                        {t("Global.nights", {
                          count: currentDeposit?.nights_count ?? 0,
                        })}
                      </p>
                      <MoonIcon width={26} height={26} />
                    </div>

                    <div className="flex items-center space-x-2">
                      <p className="text-base text-low-contrast">
                        {t("Global.adults", {
                          count: currentDeposit?.adults_count,
                        })}
                      </p>
                      <UsersIcon width={20} height={20} />
                    </div>

                    <div className="flex items-center space-x-2">
                      <p className="text-base text-low-contrast">
                        {t("Global.children", {
                          count: currentDeposit?.children_count,
                        })}
                      </p>
                      <EmojiHappyIcon width={20} height={20} />
                    </div>

                    <div className="flex items-center space-x-2">
                      <p className="text-base text-low-contrast">
                        {`
                          ${typeof currentDeposit?.payment_schedule?.total_price_value === "number"
                              ? currentDeposit?.payment_schedule?.total_price_value
                              : currentDeposit?.payment_schedule?.total_price_value
                          }${hiddenDevice(currentDeposit?.payment_schedule?.total_price_value, t("Global.currencySymbol"))}
                       `}
                      </p>
                      <MoneyIcon width={26} height={26} />
                    </div>
                  </div>
                </div>

                <Separator />

                <div className="flex flex-col gap-y-5">
                  <p className="font-bold text-low-contrast">
                    {t("Payments.PaymentReservationList.infosLabel")}
                  </p>

                  {currentDeposit?.payment_schedule.deposit_payment_status !==
                  PaymentReservationDepositStatusEnum.CANCELED ? (
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col">
                        <p className="text-low-contrast">
                          {t(
                            "Payments.PaymentReservationList.paymentLinkLabel"
                          )}
                        </p>
                        <Link
                          to={paymentLink}
                          className="truncate text-active w-72"
                          target="_blank"
                        >
                          {paymentLink}
                        </Link>
                      </div>
                      <div className="flex flex-col">
                        <Button
                          type="primary"
                          RightIcon={CopyIcon}
                          onClick={() => {
                            navigator.clipboard.writeText(paymentLink);
                          }}
                        >
                          {t("Payments.PaymentReservationList.copyPaymentLink")}
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  <div className="flex items-center justify-between">
                    <div className="flex flex-col items-start flex-1 space-y-0.5">
                      <p className="text-base text-low-contrast">
                        {t("Payments.PaymentDepositList.depositValue")}
                      </p>
                      <p className="text-base font-bold text-high-contrast">
                        {`
                          ${typeof currentDeposit?.payment_schedule.deposit_value === "number"
                            ? currentDeposit?.payment_schedule.deposit_value
                            : currentDeposit?.payment_schedule.deposit_value
                        }${hiddenDevice(currentDeposit?.payment_schedule.deposit_value, t("Global.currencySymbol"))}
                       `}
                      </p>
                    </div>

                    <div className="flex flex-col items-start flex-1">
                      <p className="text-base text-low-contrast">
                        {t("Payments.PaymentDepositList.depositType")}
                      </p>
                      <p className="text-base font-bold text-high-contrast">
                        {getDepositOption()}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex flex-col items-start flex-1 space-y-0.5">
                      <p className="text-base text-low-contrast">
                        {t("Payments.PaymentReservationList.emailLabel")}
                      </p>

                      <p className="font-semibold text-high-contrast">
                        {paymentReservation?.guest.email}
                      </p>
                    </div>

                    <div className="flex flex-col items-start flex-1">
                      <p className="text-base text-low-contrast">
                        {t("Payments.PaymentReservationList.paidOnLabel")}
                      </p>
                      <p className="font-semibold text-high-contrast">
                        {paymentReservation?.payment_schedule
                          .deposit_payment_success_date ?? "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <ErrorMessage>{error}</ErrorMessage>
            </div>

            {paymentReservation?.payment_schedule.deposit_payment_status ===
              PaymentReservationDepositStatusEnum.PRE_AUTHORIZED && (
              <div className="flex flex-row gap-2 pt-2 border-t-1 border-element-border">
                <Button type="secondary">{t("Global.cancel")}</Button>
                <Button
                  type="destructive"
                  onClick={() => releaseModal.open(currentDeposit)}
                >
                  {t("Payments.PaymentReservationList.release")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => captureModal.open(currentDeposit)}
                >
                  {t("Payments.PaymentReservationList.capture")}
                </Button>
              </div>
            )}

            {paymentReservation?.payment_schedule.deposit_payment_status ===
              PaymentReservationDepositStatusEnum.PAID && (
              <div className="flex flex-row gap-2 pt-2 border-t-1 border-element-border">
                <Button type="secondary">{t("Global.cancel")}</Button>
                <Button
                  type="destructive"
                  onClick={() => refundModal.open(currentDeposit)}
                >
                  {t("Payments.PaymentReservationList.refund")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => noRefundModal.open(currentDeposit)}
                >
                  {t("Payments.PaymentReservationList.notRefund")}
                </Button>
              </div>
            )}

            {paymentReservation?.payment_schedule.deposit_payment_status ===
              PaymentReservationDepositStatusEnum.REFUNDED && (
              <div className="flex flex-row gap-2 pt-2 border-t-1 border-element-border">
                <Button type="secondary">{t("Global.cancel")}</Button>
                <Button
                  type="primary"
                  onClick={() => refundModal.open(currentDeposit)}
                >
                  {t("Payments.PaymentReservationList.refund")}
                </Button>
              </div>
            )}
          </div>
        )}
      </RightModal>
    </>
  );
};
