import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {TextInput} from "../../Common/TextInput/TextInput";
import moment from "moment";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import paths from "../../../constants/paths";
import {TaskTypeEnum} from "../../../enums/GETenums";
import {get, post} from "../../../helpers/APIHelper";
import {cn} from "../../../helpers/classHelper";
import {formatTime} from "../../../helpers/dateHelper";
import {useCheckAuth} from "../../../hooks/useCheckAuth";
import {ValueType} from "../../../types/commonTypes";
import {
  ChecklistRemunerationType,
  CreateTaskForm,
  OperationalManagementTeamMemberResponse,
  RentalChecklistsResponse,
  RentalLightListItemResponse,
  TaskAssignationType,
  TaskItemResponse,
  TaskType,
  TeamsMembers,
} from "../../../types/GETTypes";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import {TextAreaInput} from "../../Common/TextAreaInput/TextAreaInput";
import {Separator} from "../../Common/Separator/Separator";
import {TimeInput} from "../../Common/TimeInput/TimeInput";
import {InputMultiSelect} from "../../Common/InputMultiSelect/InputMultiSelect";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Button} from "../../Common/Button/Button";
import {SimpleRadio} from "../../Common/SimpleRadio/SimpleRadio";
import InfoCard from "../../Common/Card/InfoCard";

const AddTaskModal = ({
  onClose,
  onSuccess,
  task,
  reservationData,
  fromDashboard = false,
  date = "",
}: {
  onClose: () => void;
  onSuccess: (task: TaskItemResponse) => void;
  task?: TaskItemResponse;
  reservationData?: {
    reservationId: ValueType | undefined | null;
    rentalId: ValueType | undefined | null;
  } | null;
  fromDashboard?: boolean;
  date?: string;
}) => {
  const {t} = useTranslation();
  const form = useForm<CreateTaskForm>({
    defaultValues: {
      task_type: "checklist",
      assignation_type: "OPERATORS",
      operator_team_ids: [],
      operator_ids: [],
      remuneration_type: "FIXED",
      amount_global: "30",
      amount_by_bed: null,
      scheduled_date: moment().format("DD/MM/YYYY"),
      scheduled_time: moment().add(30, "minute").format("HH:mm"),
      remark: "",
    },
    mode: "all",
  });
  const {user} = useCheckAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>([]);
  const [members, setMembers] = useState<
    OperationalManagementTeamMemberResponse[]
  >([]);
  const [teamMembers, setTeamMembers] = useState<TeamsMembers[]>([]);
  const [checklistsByType, setChecklistsByType] = useState<
    RentalChecklistsResponse[]
  >([]);

  useEffect(() => {
    loadData();
  }, [user]);

  useEffect(() => {
    loadEditData();
  }, [task, user]);

  useEffect(() => {
    if (reservationData) {
      form?.setValue("rental_id", reservationData.rentalId!);
    }
  }, [reservationData]);

  const loadEditData = async () => {
    if (task) {
      setLoading(true);
      const {
        name,
        rental: {id: rental_id},
        type,
        assignation_type,
        assigned_teams,
        assigned_operators,
        remuneration_type,
        amount_global,
        amount_by_bed,
        scheduled_date,
        scheduled_time,
        checklist_id, //missing from api
        description,
        remark,
      } = task;

      form.setValue("name", name);
      form.setValue("rental_id", rental_id);
      form.setValue("task_type", checklist_id ? "checklist" : "description");
      form.setValue("type", type);
      form.setValue("assignation_type", assignation_type);
      form.setValue(
        "operator_ids",
        assigned_operators?.map((operator) => operator.id)
      );
      form.setValue(
        "operator_team_ids",
        assigned_teams?.map((team) => team.id)
      );
      form.setValue("remuneration_type", remuneration_type);
      form.setValue("amount_global", Number(amount_global));
      form.setValue("amount_by_bed", Number(amount_by_bed));
      form.setValue(
        "scheduled_date",
        moment(scheduled_date, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
      form.setValue("scheduled_time", scheduled_time);
      form.setValue("checklist_id", checklist_id ?? "");
      form.setValue("description", description ?? "");
      form.setValue("remark", remark ?? "");
      //loadEdit to do

      if (user) {
        await loadRentals({type, rental_id});
        setTimeout(async () => {
          await loadChecklistsByType({type, checklist_id});
        }, 100);
        await loadTeamMembers();
        await loadMembers();
      }
      setLoading(false);
    } else {
      if (fromDashboard && date) {
        form.setValue("scheduled_date", moment(date).format("DD/MM/YYYY"));
        form.setValue(
          "scheduled_time",
          moment(date).add(30, "minute").format("HH:mm")
        );
      }
    }
  };
  const loadData = async () => {
    if (!task) {
      setLoading(true);
      if (user) {
        await loadRentals({});
        setTimeout(async () => {
          await loadChecklistsByType({type: TaskTypeEnum.CLEANING});
        }, 100);
        await loadTeamMembers();
        await loadMembers();
      }
      setLoading(false);
    }
  };
  const loadRentals = async ({
    rental_id,
    type,
  }: {
    rental_id?: ValueType;
    type?: TaskTypeEnum;
  }) => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );
    if (response?.data?.success) {
      if (response.data.result) {
        form.setValue(
          "rental_id",
          rental_id ? rental_id : response.data.result.rentals[0]?.id
        );
      }
      form.setValue("type", type ? type : TaskTypeEnum.CLEANING);
      setRentals(response.data.result.rentals);
    }
  };

  const loadChecklistsByType = async ({
    type,
    checklist_id,
  }: {
    type?: TaskTypeEnum;
    checklist_id?: ValueType;
  }) => {
    setLoading(true);
    const rental_id = form.getValues("rental_id");
    const typeFilter = type ? type : form.getValues("type");
    if (rental_id && typeFilter) {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.CHECKLIST.FILTER
        }?rental_id=${rental_id}&type=${typeFilter}`
      );
      if (response?.data?.success) {
        setChecklistsByType(response.data.result);
        if (form.getValues("task_type") === "checklist") {
          if (response.data.result?.length > 0) {
            form.setValue(
              "checklist_id",
              checklist_id ? checklist_id : response.data.result[0]?.id
            );
          } else {
            form.setValue("task_type", "description");
          }
        }
      }
    }
    setLoading(false);
  };

  const loadTeamMembers = async () => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.TEAMS_MEMBERS
      }/${user?.id}`
    );
    if (response?.data?.success) {
      setTeamMembers(response.data.result.teams);
    }
  };

  const loadMembers = async () => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.MEMBERS
      }`
    );
    if (response?.data?.success) {
      setMembers(response.data.result.members);
    }
  };

  const parseToNumber = (value) => {
    console.log("🚀 ~ parseToNumber ~ value:", value)
    if (value) {
      return Number(value.replace(",", "."));
    }
    return null;
  };

  const handleSave = async (values: CreateTaskForm) => {
    const {task_type, amount_by_bed, ...restValues} = values;
    console.log("values", form.getValues("amount_global"));
    setLoadingSubmit(true);
    setError(null);
    let res;
    if (task) {
      //handle edit
      res = "";
    } else {
      res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.OPERATIONNAL_MANAGEMENT.TASKS.CREATE
        }`,
        {
          ...restValues,
          remuneration_type: form.getValues("remuneration_type")?.toUpperCase(),
          amount_global: parseToNumber(form.getValues("amount_global")),
          ...(form.getValues("amount_by_bed")
            ? {amount_by_bed: parseToNumber(form.getValues("amount_by_bed"))}
            : {}), //to remove amount_by bed of the object if null
          operator_ids: form.getValues("operator_ids").join(","),
          operator_team_ids: form.getValues("operator_team_ids").join(","),
          type: form.getValues("type")?.toUpperCase(),
          assignation_type: form.getValues("assignation_type")?.toUpperCase(),
          checklist_id:
            form.getValues("task_type") === "checklist"
              ? String(form.getValues("checklist_id"))
              : undefined,
          rental_id: String(form.getValues("rental_id")),
          reservation_id: reservationData?.reservationId
            ? String(reservationData?.reservationId)
            : undefined,
          scheduled_date: moment(
            form.getValues("scheduled_date"),
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"),
        }
      );
    }

    setLoadingSubmit(false);

    if (res.data?.success) {
      onClose();
      onSuccess(res.data.result);
    } else {
      setError(res.response.data.message);
    }
  };

  const selectTypeTask = [
    {
      label: t("Task.Modal.cleaning"),
      value: TaskTypeEnum.CLEANING,
    },
    {
      label: t("Task.Modal.checkin"),
      value: TaskTypeEnum.CHECKIN,
    },
    {
      label: t("Task.Modal.checkout"),
      value: TaskTypeEnum.CHECKOUT,
    },
    {
      label: t("Task.Modal.incident"),
      value: TaskTypeEnum.INCIDENT,
    },
    {
      label: t("Task.Modal.other"),
      value: TaskTypeEnum.OTHER,
    },
  ];

  return (
    <form onSubmit={form.handleSubmit(handleSave)} className="w-full pr-4">
      <div className="flex flex-col w-full h-full">
        {/* Fields */}
        <div className="flex flex-col flex-1 w-full gap-5 overflow-y-scroll">
          <label className="text-sm font-bold text-low-contrast">
            {t("Global.info")}
          </label>
          <Controller
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Task.Modal.name"),
                }),
              },
            }}
            name="name"
            render={({field: {value, onChange}, formState: {errors}}) => {
              return (
                <div className="flex flex-col">
                  <TextInput
                    label={t("Task.Modal.name")}
                    required={true}
                    value={value}
                    onChangeText={(text) => {
                      onChange(text);
                    }}
                    error={errors?.name?.message}
                    disabled={loading || loadingSubmit}
                  />
                </div>
              );
            }}
          />

          {!reservationData && (
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.rental"),
                  }),
                },
              }}
              name="rental_id"
              render={({field: {value, onChange}, formState: {errors}}) => {
                const requestChecklist = async (txt: ValueType) => {
                  onChange(txt);
                  if (form.getValues("task_type") === "checklist") {
                    await loadChecklistsByType({});
                  }
                };
                return (
                  <div className="flex flex-col">
                    <InputSelect
                      label={t("Task.Modal.rental")}
                      required={true}
                      items={rentals.map((rental) => {
                        return {label: rental.name, value: rental.id};
                      })}
                      selectedValue={value}
                      onSelect={(text) => {
                        requestChecklist(text);
                      }}
                      error={errors?.rental_id?.message}
                      disabled={loading || loadingSubmit}
                    />
                  </div>
                );
              }}
            />
          )}

          <Controller
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Task.Modal.taskType"),
                }),
              },
            }}
            name="type"
            render={({field: {value, onChange}, formState: {errors}}) => {
              const requestChecklist = async (val: ValueType) => {
                onChange(val);
                if (val !== TaskTypeEnum.CLEANING) {
                  form.setValue("remuneration_type", "FIXED");
                }
                await loadChecklistsByType({});
              };
              return (
                <div className="flex flex-col">
                  <InputSelect
                    label={t("Task.Modal.taskType")}
                    items={selectTypeTask}
                    selectedValue={value}
                    required={true}
                    onSelect={(val) => {
                      requestChecklist(val);
                    }}
                    error={errors?.type?.message}
                    disabled={loading || loadingSubmit}
                  />
                </div>
              );
            }}
          />
          <Controller
            control={form.control}
            name="task_type"
            render={({field: {value, onChange}, formState: {errors}}) => {
              const reset = (value: TaskType) => {
                onChange(value);
                if (value === "checklist") {
                  if (checklistsByType && checklistsByType.length > 0) {
                    form.setValue("checklist_id", checklistsByType[0].id);
                  }
                  form.setValue("description", "");
                } else if (value === "description") {
                  form.setValue("checklist_id", "");
                }
              };
              return (
                <div className="flex items-center gap-3">
                  <div
                    className="flex items-center gap-1 cursor-pointer w-52"
                    onClick={() => !loading && reset("checklist")}
                  >
                    <SimpleRadio
                      value={value === "checklist"}
                      disabled={loading || loadingSubmit}
                    />
                    <p
                      className={
                        (cn("font-light"),
                        value === "checklist"
                          ? "text-active"
                          : "text-low-contrast")
                      }
                    >
                      {t("Task.Modal.checklist")}
                    </p>
                  </div>

                  <div
                    className="flex items-center flex-1 gap-1 cursor-pointer"
                    onClick={() => !loading && reset("description")}
                  >
                    <SimpleRadio
                      value={value === "description"}
                      disabled={loading || loadingSubmit}
                    />
                    <p
                      className={
                        (cn("font-light"),
                        value === "description"
                          ? "text-active"
                          : "text-low-contrast")
                      }
                    >
                      {t("Task.Modal.description")}
                    </p>
                  </div>
                </div>
              );
            }}
          />

          {form.watch("task_type") === "checklist" && (
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.checklistLink"),
                  }),
                },
              }}
              name="checklist_id"
              render={({field: {value, onChange}, formState: {errors}}) => {
                return (
                  <div className="flex flex-col">
                    <InputSelect
                      label={t("Task.Modal.checklistLink")}
                      items={checklistsByType.map((checklist) => {
                        return {label: checklist.name, value: checklist.id};
                      })}
                      selectedValue={value}
                      required={true}
                      onSelect={(val) => {
                        onChange(val);
                      }}
                      error={errors?.checklist_id?.message}
                      disabled={loading || loadingSubmit}
                    />
                  </div>
                );
              }}
            />
          )}
          {form.watch("task_type") === "description" && (
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.descriptionTask"),
                  }),
                },
              }}
              name="description"
              render={({field: {value, onChange}, formState: {errors}}) => {
                return (
                  <TextAreaInput
                    label={t("Task.Modal.descriptionTask")}
                    required={true}
                    value={value}
                    onTextChange={(text) => {
                      onChange(text);
                    }}
                    error={errors?.description?.message}
                    disabled={loading || loadingSubmit}
                  />
                );
              }}
            />
          )}
          <Separator />
          <label className="text-sm font-bold text-low-contrast">
            {t("Task.Modal.assignationType")}
          </label>
          <Controller
            control={form.control}
            name="assignation_type"
            render={({field: {value, onChange}, formState: {errors}}) => {
              const reset = (value: TaskAssignationType) => {
                onChange(value);
                if (value === "OPERATORS") {
                  form.setValue("operator_team_ids", []);
                } else if (value === "TEAMS") {
                  form.setValue("operator_ids", []);
                }
              };
              return (
                <div className="flex items-center gap-3">
                  <div
                    className="flex items-center gap-1 cursor-pointer w-52"
                    onClick={() => !loading && reset("OPERATORS")}
                  >
                    <SimpleRadio
                      value={value === "OPERATORS"}
                      disabled={loading || loadingSubmit}
                    />
                    <p
                      className={
                        (cn("font-light"),
                        value === "OPERATORS"
                          ? "text-active"
                          : "text-low-contrast")
                      }
                    >
                      {t("Task.Modal.assignationTypeDescription")}
                    </p>
                  </div>

                  <div
                    className="flex items-center flex-1 gap-1 cursor-pointer"
                    onClick={() => !loading && reset("TEAMS")}
                  >
                    <SimpleRadio
                      value={value === "TEAMS"}
                      disabled={loading || loadingSubmit}
                    />
                    <p
                      className={
                        (cn("font-light"),
                        value === "TEAMS" ? "text-active" : "text-low-contrast")
                      }
                    >
                      {t("Task.Modal.assignationTypeTeamDescription")}
                    </p>
                  </div>
                </div>
              );
            }}
          />
          {form.watch("assignation_type") === "OPERATORS" && (
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.assigned"),
                  }),
                },
              }}
              name="operator_ids"
              render={({field: {value, onChange}, formState: {errors}}) => {
                return (
                  <div className="flex flex-col">
                    <InputMultiSelect
                      label={t("Task.Modal.assigned")}
                      items={members?.map((member) => {
                        const label =
                          member.first_name !== "" && member.last_name !== ""
                            ? `${member.first_name} ${member.last_name}`
                            : `${member.email} (${t(
                                "Management.AddTeam.pending"
                              )})`;

                        return {
                          label,
                          value: member.id,
                          imgSrc: member.photo,
                        };
                      })}
                      selectedValues={value}
                      onSelect={(val) => {
                        onChange(val);
                      }}
                      error={errors?.operator_ids?.message}
                      required={true}
                      disabled={loading || loadingSubmit}
                    />
                  </div>
                );
              }}
            />
          )}

          {form.watch("assignation_type") === "TEAMS" && (
            <div className="flex flex-col space-y-4">
              <Controller
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Task.Modal.assigned"),
                    }),
                  },
                }}
                name="operator_team_ids"
                render={({field: {value, onChange}, formState: {errors}}) => {
                  return (
                    <div className="flex flex-col">
                      <InputMultiSelect
                        label={t("Task.Modal.assigned")}
                        items={teamMembers?.map((team) => {
                          const label = team.name;
                          return {label, value: team.id};
                        })}
                        selectedValues={value}
                        onSelect={(val) => {
                          onChange(val);
                        }}
                        error={errors?.operator_team_ids?.message}
                        required={true}
                        disabled={loading || loadingSubmit}
                      />
                    </div>
                  );
                }}
              />
              <InfoCard
                title={"Task.Modal.assignationTypeTeamDescriptionInfo"}
                description={"Task.Modal.assignationTypeTeamDescriptionInfo2"}
                useTransComponent
                classNames={{
                  title: "font-normal",
                  description: "font-normal",
                }}
              />
              <div className="flex flex-row items-center">
                <div className="text-sm bg-gray-100 p-1 w-[100px] rounded-md shadow">
                  {t("Task.Modal.cleaningTask")}
                </div>

                {/* Arrow to sendTask */}
                <ArrowRightIcon className="w-8 " />

                {/* "Envoy de la tâche non assignée" Node */}
                <div className="text-sm bg-gray-100 p-1 w-[130px] rounded-md shadow">
                  {t("Task.Modal.sendTask")}
                </div>

                {/* Arrows to Operators */}
                <div className="flex flex-col mt-2">
                  {/* Arrow to the left */}
                  <div className="flex flex-col">
                    <ArrowRightIcon className="w-[4rem] -rotate-[35deg] mb-[17px] mr-1" />
                    {/* <div className="flex">
                      <div className="w-[30px] bg-gray-200 h-[3px] float-left"></div>
                      <div className="w-0 h-[15px] border-t-[5px] border-b-[5px] border-l-[5px] border-t-transparent border-b-transparent border-l-gray-200 float-right mt-[-6px]"></div>
                    </div> */}
                  </div>

                  {/* Arrow to the middle */}
                  <div className="flex flex-col">
                    <ArrowRightIcon className="w-[3.5rem] text-success/10 rotate-0 ml-1.5" />
                  </div>

                  {/* Arrow to the right */}
                  <div className="flex flex-col items-center">
                    <ArrowRightIcon className="w-[4rem] rotate-[35deg] mt-[17px] mr-1" />
                  </div>
                </div>

                {/* Operators */}
                <div className="flex flex-col w-[130px] space-y-4">
                  <div className="w-auto p-1 bg-gray-100 rounded-md">
                    {t("Task.Modal.operator")} 1
                  </div>
                  <div className="w-auto p-1 font-bold rounded-md bg-success/20 text-success">
                    {t("Task.Modal.operator")} 2
                  </div>
                  <div className="w-auto p-1 bg-gray-100 rounded-md">
                    {t("Task.Modal.operator")} 3
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* //Ajouter explication team */}
          {form.watch("type") === TaskTypeEnum.CLEANING && (
            <>
              <label className="text-sm font-bold text-low-contrast">
                {t("Task.Modal.howToCalc")}
              </label>
              <Controller
                control={form.control}
                name="remuneration_type"
                render={({field: {value, onChange}, formState: {errors}}) => {
                  const reset = (value: ChecklistRemunerationType) => {
                    onChange(value);
                    if (value === "FIXED") {
                      form.setValue("amount_by_bed", null);
                    } else if (value === "BY_BED") {
                      form.setValue("amount_by_bed", 10);
                    }
                  };
                  return (
                    <div className="flex items-center gap-3">
                      <div
                        className="flex items-center gap-1 cursor-pointer w-52"
                        onClick={() => !loading && reset("FIXED")}
                      >
                        <SimpleRadio
                          value={value === "FIXED"}
                          disabled={loading || loadingSubmit}
                        />
                        <p
                          className={
                            (cn("font-light"),
                            value === "FIXED"
                              ? "text-active"
                              : "text-low-contrast")
                          }
                        >
                          {t("Task.Modal.amountGlobal")}
                        </p>
                      </div>
                      {form.watch("type") === TaskTypeEnum.CLEANING && (
                        <div
                          className="flex items-center flex-1 gap-1 cursor-pointer"
                          onClick={() => !loading && reset("BY_BED")}
                        >
                          <SimpleRadio
                            value={value === "BY_BED"}
                            disabled={loading || loadingSubmit}
                          />
                          <p
                            className={
                              (cn("font-light"),
                              value === "BY_BED"
                                ? "text-active"
                                : "text-low-contrast")
                            }
                          >
                            {t("Task.Modal.amountVariable")}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </>
          )}
          {form.watch("remuneration_type") === "FIXED" && (
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.amountTask"),
                  }),
                },
              }}
              name="amount_global"
              render={({field: {value, onChange}, formState: {errors}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("Task.Modal.amountTask")}
                      required={true}
                      value={String(value)}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={errors?.amount_global?.message}
                      disabled={loading || loadingSubmit}
                    />
                  </div>
                );
              }}
            />
          )}
          {form.watch("remuneration_type") === "BY_BED" &&
            form.watch("type") === TaskTypeEnum.CLEANING && (
              <>
                <div className="flex justify-between">
                  <Controller
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("Task.Modal.amountTask"),
                        }),
                      },
                    }}
                    name="amount_global"
                    render={({
                      field: {value, onChange},
                      formState: {errors},
                    }) => {
                      return (
                        <div className="flex flex-col w-1/2 mr-2">
                          <TextInput
                            label={t("Task.Modal.amountTask")}
                            required={true}
                            value={String(value)}
                            onChangeText={(text) => {
                              onChange(text);
                            }}
                            error={errors?.amount_global?.message}
                            disabled={loading || loadingSubmit}
                          />
                        </div>
                      );
                    }}
                  />
                  <Controller
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("Task.Modal.amountByBed"),
                        }),
                      },
                    }}
                    name="amount_by_bed"
                    render={({
                      field: {value, onChange},
                      formState: {errors},
                    }) => {
                      return (
                        <div className="flex flex-col w-1/2 ml-2">
                          <TextInput
                            label={t("Task.Modal.amountByBed")}
                            required={true}
                            value={String(value)}
                            onChangeText={(text) => {
                              onChange(text);
                            }}
                            error={errors?.amount_by_bed?.message}
                            disabled={loading || loadingSubmit}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
                <p className="text-sm text-low-contrast">
                  {t("Task.Modal.exampleAmount")}
                </p>
              </>
            )}

          <Separator />
          <div className="flex">
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.scheduleDate"),
                  }),
                },
              }}
              name="scheduled_date"
              render={({field: {value, onChange}, formState: {errors}}) => {
                return (
                  <div className="flex flex-col w-1/2 mr-2">
                    <TextInput
                      label={t("Task.Modal.scheduleDate")}
                      required={true}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                      error={errors?.scheduled_date?.message}
                      disabled={loading || loadingSubmit}
                    />
                  </div>
                );
              }}
            />
            <Controller
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Task.Modal.scheduleTime"),
                  }),
                },
              }}
              name="scheduled_time"
              render={({field: {value, onChange}, formState: {errors}}) => {
                return (
                  <div className="flex flex-col w-1/2 ml-2">
                    <TimeInput
                      label={t("Task.Modal.scheduleTime")}
                      required={true}
                      value={value}
                      onChange={(text) => {
                        onChange(formatTime(text));
                      }}
                      error={errors?.scheduled_time?.message}
                      disabled={loading || loadingSubmit}
                    />
                  </div>
                );
              }}
            />
          </div>
          <Separator />
          <Controller
            control={form.control}
            name="remark"
            render={({field: {value, onChange}, formState: {errors}}) => {
              return (
                <div className="flex flex-col">
                  <TextAreaInput
                    label={t("Task.Modal.remark")}
                    placeholder={t("Task.Modal.remarkPlaceholder")}
                    value={value}
                    onTextChange={(text) => {
                      onChange(text);
                    }}
                    error={errors.remark?.message}
                    disabled={loading || loadingSubmit}
                  />
                </div>
              );
            }}
          />
          <ErrorMessage>{error}</ErrorMessage>
        </div>
        {/* Buttons */}
        <div className="flex gap-4 pt-4 mt-4 me-4 border-t-1 border-element-border">
          <Button
            type="secondary"
            disabled={loading || loadingSubmit}
            onClick={onClose}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            loading={loadingSubmit}
            RightIcon={CheckWhiteIcon}
            disabled={loading}
          >
            {t(task ? `Task.Modal.editTask` : `Task.Modal.addTask`)}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddTaskModal;
