import {
  NotificationStatusesListItemResponse,
  PaymentReservationScheduleResponse,
  RentalLightListItemResponse,
} from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";

export enum ADD_RESERVATION_STEPS {
  INFORMATIONS = 1,
  PRICES = 2,
  GUEST = 3,
  PAYMENT = 4,
  DONE = 5,
}

export type AddReservationGeneralType = {
  platform_id: number;
  checkin: Date;
  checkout: Date;
  time_in: string;
  time_out: string;
  nights_count: number;
  adults_count: string | number;
  children_count: string | number;
};

export type AddReservationTarificationType = {
  nights_total_price: number;
  city_tax: number;
  cleaning_default: number;
  additional_guests_total_price: number;
  pet_default: number;
  infant_bed_default: number;
  other: number;
  total: number;
};

export type AddReservationCalendarType = {
  date: string;
  price: number;
  is_available: number;
  is_reserved: number;
  min_nights: number;
  max_nights: number;
  no_checkin: number;
  no_checkout: number;
}[];

export type AddReservationGuestType = {
  id: ValueType;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postal_code: string;
  country: string;
  photo: string;
  currency: string;
};

export type AddReservationType = {
  reservation_id?: number;
  rental?: RentalLightListItemResponse;
  general?: AddReservationGeneralType;
  tarification?: AddReservationTarificationType;
  calendar?: AddReservationCalendarType;
  guest?: AddReservationGuestType;
  payment_schedule?: PaymentReservationScheduleResponse;
  notification_statuses?: NotificationStatusesListItemResponse[];
};
