import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PricelabsIcon from "../../../../assets/icons/pricelabs.svg?react";
import ArrowTopRightIcon from "../../../../assets/icons/arrow-up-right.svg?react";
import { cn } from "../../../../helpers/classHelper";
import { Button } from "../../../Common/Button/Button";
import { Card } from "../../../Common/Card/Card";
import { PricelabsData, RentalPricelabsResponse } from "./Pricelabs.type";
import { PricelabsConnectionModal } from "./PricelabsConnectionModal";
import { PricelabsSettingsModal } from "./PricelabsSettingsModal";
import paths from "../../../../constants/paths";
import { Badge } from "../../../Common/Badge/Badge";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";
import { ValueType } from "../../../../types/commonTypes";

export const PricelabsCard: React.FC<{
  pricelabsData: PricelabsData | undefined;
  workspaceOwner: ValueType | undefined;
}> = ({ pricelabsData, workspaceOwner }) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const { rentalId } = useParams();
  const [connectionModalOpened, setConnectionModalOpened] = useState(false);
  const [settingsModalOpened, setConfigurationModalOpened] = useState(false);
  const [currentPricelabsData, setCurrentPricelabsData] =
    useState<RentalPricelabsResponse>();

  useEffect(() => {
    if (pricelabsData) {
      setCurrentPricelabsData(pricelabsData);
    }
  }, [pricelabsData]);

  // Connection modal management
  const handleOpenConnectionModal = () => {
    setConnectionModalOpened(true);
  };

  const handleCloseConnectionModal = () => {
    setConnectionModalOpened(false);
  };

  const handleSuccessConnection = (
    pricelabsResponse: RentalPricelabsResponse
  ) => {
    setCurrentPricelabsData(pricelabsResponse);
  };

  // Settings modal management
  const handleOpenSettingsModal = () => {
    setConfigurationModalOpened(true);
  };

  const handleCloseSettingsModal = () => {
    setConfigurationModalOpened(false);
  };

  const handleSuccessDisconnection = () => {
    setCurrentPricelabsData(undefined);
  };

  const handleSuccessUpdateSettings = (
    pricelabsResponse: RentalPricelabsResponse
  ) => {
    setCurrentPricelabsData(pricelabsResponse);
  };

  const handleOpenPricelabsWebsite = () => {
    window.open(paths.API.PRICELABS.WEBSITE_URL, "_blank");
  };

  const isCompletelySynchronized = () => {
    return (
      currentPricelabsData?.enabled &&
      currentPricelabsData?.last_synchronization_date !== null
    );
  };

  return (
    <>
      <PricelabsConnectionModal
        rentalId={rentalId!}
        isVisible={connectionModalOpened}
        onSuccessConnect={handleSuccessConnection}
        onClose={handleCloseConnectionModal}
      />
      <PricelabsSettingsModal
        rentalId={rentalId!}
        pricelabsData={currentPricelabsData!}
        isVisible={settingsModalOpened}
        onOpenPricelabsWebsite={handleOpenPricelabsWebsite}
        isCompletelySynchronized={isCompletelySynchronized}
        onSuccessDisconnect={handleSuccessDisconnection}
        onSuccessUpdate={handleSuccessUpdateSettings}
        onClose={handleCloseSettingsModal}
      />
      <Card
        Icon={PricelabsIcon}
        label={t("Rental.Services.Pricelabs.title")}
        anchor="pricelabs-card"
        button={{
          label: t("Rental.Services.Pricelabs.pricelabsButtonLabel"),
          Icon: ArrowTopRightIcon,
          disabled: !getCurrentCanEdit({
            user: userStore.user,
            workspaceOwner,
          }),
          onClick: () => handleOpenPricelabsWebsite(),
        }}
      >
        {!currentPricelabsData?.pricelabs_id && (
          <div className="flex flex-col items-center justify-center space-y-2">
            <p className="text-lg font-light text-center text-low-contrast">
              {t("Rental.Services.Pricelabs.notConnected")}
            </p>

            <Button
              className="font-bold text-white bg-element-background"
              disabled={
                !getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner,
                })
              }
              onClick={handleOpenConnectionModal}
            >
              {t("Rental.Services.Pricelabs.connectButtonLabel")}
            </Button>
          </div>
        )}
        {currentPricelabsData?.pricelabs_id && (
          <div className="flex flex-col items-center justify-center space-y-2">
            <div
              className={cn(
                `flex items-center justify-between w-full p-2 rounded bg-subtle border-1 border-element-border select-none`,
                getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner,
                })
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              )}
              onClick={() =>
                getCurrentCanEdit({
                  user: userStore.user,
                  workspaceOwner,
                }) && handleOpenSettingsModal()
              }
            >
              <div className="flex items-center justify-between w-full space-x-4">
                <div className="flex flex-col space-y-1">
                  <p className="text-base font-bold text-nowrap text-high-contrast">
                    {t("Rental.Services.Pricelabs.connectedTitle")}
                  </p>
                  <p className="text-base whitespace-normal text-nowrap text-low-contrast">
                    {t("Rental.Services.Pricelabs.connectedListingIdLabel")}{" "}
                    {currentPricelabsData?.pricelabs_id}
                  </p>
                  {currentPricelabsData?.last_synchronization_date !== null && (
                    <p className="text-base break-all whitespace-normal text-low-contrast">
                      {t("Rental.Services.Pricelabs.connectedLastSyncLabel")}{" "}
                      {currentPricelabsData?.last_synchronization_date}
                    </p>
                  )}
                </div>
                <Badge
                  label={
                    isCompletelySynchronized()
                      ? t("Global.connected")
                      : t("Rental.Services.Pricelabs.pendingBadgeLabel")
                  }
                  status={isCompletelySynchronized() ? "success" : "pending"}
                ></Badge>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};
