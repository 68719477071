import React, { useEffect, useRef, useState } from "react";
import { RightModal } from "../Common/RightModal/RightModal";
import {
  NotificationListItemResponse,
  WorkspaceResponse,
} from "../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import { CarouselWizard } from "../Common/CarouselWizard/CarouselWizard";
import { AutoMessageUpsertGeneral } from "./AutoMessageUpsertGeneral";
import {
  AutoMessageLanguageEnum,
  AutoMessageUpsertChannelsForm,
  AutoMessageUpsertContentsForm,
  AutoMessageUpsertGeneralForm,
} from "./AutoMessage.type";
import { AutoMessageUpsertChannels } from "./AutoMessageUpsertChannels";
import { AutoMessageUpsertContents } from "./AutoMessageUpsertContents";
import { ShortCodeList } from "../Message/ShortCodeList/ShortCodeList";
import { Title } from "../Common/Title/Title";
import CloseIcon from "../../assets/icons/close.svg?react";
import { ValueType } from "../../types/commonTypes";
import { insertTextAtPosition } from "../../helpers/stringHelper";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

enum AutoMessageUpsertStepsEnum {
  CONTENTS,
  GENERAL,
  CHANNELS,
}

export const AutoMessageUpsertModal: React.FC<{
  loading: boolean;
  error: string | null;
  data?: any | undefined | null;
  isVisible: boolean;
  onUpsert: (data: {
    general?: AutoMessageUpsertGeneralForm | undefined;
    contents?: AutoMessageUpsertContentsForm;
    channels?: AutoMessageUpsertChannelsForm | undefined;
  }) => void;
  onClose: () => void;
}> = ({
  data,
  isVisible,
  loading,
  error,
  onClose = () => {},
  onUpsert = () => {},
}) => {
  const { t } = useTranslation();
  const messageAuto: NotificationListItemResponse = data;

  const [currentStep, setCurrentStep] = useState<AutoMessageUpsertStepsEnum>(
    AutoMessageUpsertStepsEnum.CONTENTS
  );
  const [stepsData, setStepsData] = useState<
    | {
        contents?: AutoMessageUpsertContentsForm | undefined;
        general?: AutoMessageUpsertGeneralForm | undefined;
        channels?: AutoMessageUpsertChannelsForm | undefined;
      }
    | undefined
  >();
  const [isShortcodesVisible, setShortcodesVisible] = useState<boolean>(false);
  const [currentLang, setCurrentLang] = useState<ValueType>("french");
  const [currentContentFieldFocus, setCurrentContentFieldFocus] = useState<
    "message" | "title"
  >("message");
  const [currentContentCursor, setCurrentContentCursor] = useState<number>(0);

  const getContentByLanguage = (value: AutoMessageLanguageEnum) => {
    const index = messageAuto.contents.findIndex(
      (c) => c.language_id === value
    );

    if (index === -1) return { title: "", message: "" };
    else return messageAuto.contents[index];
  };

  const defaultContents = {
    english: { title: "", message: "" },
    french: { title: "", message: "" },
    spanish: { title: "", message: "" },
    german: { title: "", message: "" },
  };

  useEffect(() => {
    if (messageAuto) {
      setStepsData({
        general: {
          trigger: messageAuto.trigger,
          conditions: messageAuto.conditions,
          delay: messageAuto.delay,
        },
        contents: {
          name: messageAuto.name,
          english: getContentByLanguage(AutoMessageLanguageEnum.english),
          french: getContentByLanguage(AutoMessageLanguageEnum.french),
          spanish: getContentByLanguage(AutoMessageLanguageEnum.spanish),
          german: getContentByLanguage(AutoMessageLanguageEnum.german),
          isAllRentals: messageAuto.rentals === null,
          rentalsId: messageAuto?.rentals
            ? messageAuto.rentals?.map((r) => r.id)
            : [],
        },
        channels: {
          Airbnb: {
            active: !!messageAuto.channels.airbnb,
            value: messageAuto.channels.airbnb ?? "platform",
          },
          Booking: {
            active: !!messageAuto.channels.booking,
            value: messageAuto.channels.booking ?? "platform",
          },
          Direct: {
            active: !!messageAuto.channels.direct,
            value: messageAuto.channels.direct ?? "email",
          },
        },
      });
    }
  }, [messageAuto]);

  useEffect(() => {
    if (!isVisible) {
      setCurrentStep(AutoMessageUpsertStepsEnum.CONTENTS);
      setStepsData(undefined);
      setShortcodesVisible(false);
      setCurrentLang("french");
      setCurrentContentFieldFocus("message");
      setCurrentContentCursor(0);
    }
  }, [isVisible]);

  const getMode = () => {
    if (messageAuto) return "EDIT";
    else return "ADD";
  };

  const getTitle = () => {
    if (isShortcodesVisible)
      return (
        <div className="flex">
          <div className="flex justify-between w-modal">
            <Title>{t("Templates.shortcodes")}</Title>
            <div
              className="pt-1 hover:cursor-pointer mr-6"
              onClick={() => setShortcodesVisible(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex-1">
            {getMode() === "EDIT" ? (
              <Title>{t("AutoMessageList.Upsert.editTitle")}</Title>
            ) : (
              <Title>{t("AutoMessageList.Upsert.addTitle")}</Title>
            )}
          </div>
        </div>
      );
    else if (getMode() === "EDIT") return t("AutoMessageList.Upsert.editTitle");
    else return t("AutoMessageList.Upsert.addTitle");
  };

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const handleChangeStep = (value: AutoMessageUpsertStepsEnum) => {
    setShortcodesVisible(false);
    setCurrentStep(value);
  };

  const handleNextStepGeneral = (general: AutoMessageUpsertGeneralForm) => {
    setShortcodesVisible(false);
    setCurrentStep(AutoMessageUpsertStepsEnum.CHANNELS);
    setStepsData({
      ...stepsData,
      general,
      contents: stepsData?.contents ? stepsData?.contents : defaultContents,
    });
  };

  const handleNextStepContents = (contents: AutoMessageUpsertContentsForm) => {
    setShortcodesVisible(false);
    setCurrentStep(AutoMessageUpsertStepsEnum.GENERAL);
    setStepsData({ ...stepsData, contents });
  };

  const handleNextStepChannels = (channels: AutoMessageUpsertChannelsForm) => {
    setShortcodesVisible(false);
    const nextData = { ...stepsData, channels };

    setStepsData((prev) => nextData);

    onUpsert(nextData);
  };

  const handleChangeCurrentCursor = (value: number) => {
    setCurrentContentCursor(value);
  };

  const handleChangeContent = (
    lang: string,
    field: "message" | "title",
    value: string
  ) => {
    if (stepsData === undefined) return;
    const nextData = JSON.parse(JSON.stringify(stepsData));
    nextData.contents[lang][field] = value;

    setStepsData(nextData);
  };

  const handleInsertShortCode = (trigger: string) => {
    const nextUpdate = insertTextAtPosition(
      // @ts-ignore
      stepsData?.contents?.[currentLang]?.[currentContentFieldFocus] ?? "",
      trigger,
      currentContentCursor
    );

    const nextData = stepsData;
    // @ts-ignore
    if (nextData?.contents?.[currentLang]) {
      // @ts-ignore
      nextData.contents[currentLang][currentContentFieldFocus] = nextUpdate;
    }

    setStepsData(JSON.parse(JSON.stringify(nextData)));
  };

  const refButtonGeneral = useRef<HTMLInputElement>();
  const refButtonContents = useRef<HTMLInputElement>();
  const refButtonChannels = useRef<HTMLInputElement>();
  const handleClickInsideStep = () => {
    if (currentStep === AutoMessageUpsertStepsEnum.GENERAL)
      refButtonGeneral?.current?.click();

    if (currentStep === AutoMessageUpsertStepsEnum.CONTENTS)
      refButtonContents?.current?.click();

    if (currentStep === AutoMessageUpsertStepsEnum.CHANNELS)
      refButtonChannels?.current?.click();
  };

  return (
    <RightModal
      isVisible={isVisible}
      title={getTitle()}
      isSplit={isShortcodesVisible}
      onClose={handleClose}
    >
      {isShortcodesVisible && (
        <div className="flex-1 overflow-y-scroll pr-8">
          <ShortCodeList onInsert={handleInsertShortCode} />
        </div>
      )}

      <div
        className={`flex flex-col w-full ${
          isShortcodesVisible ? "w-modal-inside" : "w-full"
        }`}
      >
        <div className="w-full flex-1 overflow-y-auto">
          <CarouselWizard
            loading={loading}
            currentStepIndex={currentStep}
            steps={[
              {
                title: t("AutoMessageList.Upsert.Contents.title"),
                Node: (
                  <AutoMessageUpsertContents
                    refButton={refButtonContents}
                    lang={currentLang}
                    isShortcodesVisible={isShortcodesVisible}
                    contents={stepsData?.contents}
                    onNextStep={handleNextStepContents}
                    onShortcodesVisible={() =>
                      setShortcodesVisible(!isShortcodesVisible)
                    }
                    onChangeLang={setCurrentLang}
                    onFocus={setCurrentContentFieldFocus}
                    onChangeCursor={handleChangeCurrentCursor}
                    onChangeContent={handleChangeContent}
                  />
                ),
              },
              {
                title: t("AutoMessageList.Upsert.General.title"),
                Node: (
                  <AutoMessageUpsertGeneral
                    general={stepsData?.general}
                    refButton={refButtonGeneral}
                    onNextStep={handleNextStepGeneral}
                  />
                ),
              },
              {
                title: t("AutoMessageList.Upsert.Channels.title"),
                Node: (
                  <AutoMessageUpsertChannels
                    refButton={refButtonChannels}
                    channels={stepsData?.channels}
                    onNextStep={handleNextStepChannels}
                  />
                ),
              },
            ]}
            onChangeStep={handleChangeStep}
          />

          <ErrorMessage>{error}</ErrorMessage>
        </div>

        {/* Buttons */}
        <div className="flex gap-3 border-t-1 border-element-border pt-4 mt-4">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>

          {currentStep < AutoMessageUpsertStepsEnum.CHANNELS ? (
            <Button onClick={handleClickInsideStep}>
              {t("Global.nextStep")}
            </Button>
          ) : getMode() === "EDIT" ? (
            <Button onClick={handleClickInsideStep} loading={loading}>
              {t("AutoMessageList.Upsert.save")}
            </Button>
          ) : (
            <Button onClick={handleClickInsideStep} loading={loading}>
              {t("AutoMessageList.Upsert.add")}
            </Button>
          )}

          {}
        </div>
      </div>
    </RightModal>
  );
};
