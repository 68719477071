import { TFunction } from "i18next";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

export const getMonday = (d: Date) => {
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const getDayName = (date: Date) => {
  if (moment(date).format("ddd") === "Mon") return "Lun";
  else if (moment(date).format("ddd") === "Tue") return "Mar";
  else if (moment(date).format("ddd") === "Wed") return "Mer";
  else if (moment(date).format("ddd") === "Thu") return "Jeu";
  else if (moment(date).format("ddd") === "Fri") return "Ven";
  else if (moment(date).format("ddd") === "Sat") return "Sam";
  else if (moment(date).format("ddd") === "Sun") return "Dim";

  return moment(date).format("ddd");
};

export const getMonthName = (date: Date) => {
  if (moment(date).format("MMMM") === "January") return "Janvier";
  if (moment(date).format("MMMM") === "February") return "Février";
  if (moment(date).format("MMMM") === "March") return "Mars";
  if (moment(date).format("MMMM") === "April") return "Avril";
  if (moment(date).format("MMMM") === "May") return "Mai";
  if (moment(date).format("MMMM") === "June") return "Juin";
  if (moment(date).format("MMMM") === "July") return "Juillet";
  if (moment(date).format("MMMM") === "August") return "Août";
  if (moment(date).format("MMMM") === "September") return "Septembre";
  if (moment(date).format("MMMM") === "October") return "Octobre";
  if (moment(date).format("MMMM") === "November") return "Novembre";
  if (moment(date).format("MMMM") === "December") return "Décembre";

  return moment(date).format("MMMM");
};

export const getMonthList = () => {
  return Array.from({ length: 12 }, (_, index) => ({
    label:
      moment().month(index).format("MMMM").charAt(0).toUpperCase() +
      moment().month(index).format("MMMM").slice(1),
    value: (index + 1).toString().padStart(2, "0"),
  }));
};
export const getYearList = ({
  nbYear = 5,
  labelSameYear = false,
}: {
  nbYear?: number;
  labelSameYear?: boolean;
}) => {
  const t = useTranslation().t;
  const currentYear = moment().year();
  return Array.from({ length: nbYear + 1 }, (_, index) => {
    const year = currentYear - index;
    const same = year === moment().year();
    return {
      label:
        labelSameYear && same ? t("Global.yearInProgress") : year.toString(),
      value: year.toString(),
    };
  });
};

export const convertInputDateToLocaleFormat = (
  date: string,
  format: string
) => {
  let nextDate: Date = moment(date).toDate();
  return moment(nextDate).format(format);
};

export const isValidStringDate = (date: string): boolean =>
  moment(date, "DD/MM/YYYY", true).isValid();

export const isValidHour = (hour: string | null | undefined): boolean =>
  moment(hour, "HH:mm", true).isValid();

export const getDatesBetweenDates = (
  start: string,
  end: string,
  includeEdges: boolean = true
): string[] => {
  const startDate = includeEdges
    ? moment(start, "YYYY-MM-DD")
    : moment(start, "YYYY-MM-DD").add(1, "day");
  const endDate = includeEdges
    ? moment(end, "YYYY-MM-DD")
    : moment(end, "YYYY-MM-DD").subtract(1, "day");

  const dates: string[] = [];

  let currentDate = startDate.clone();

  while (currentDate.isSameOrBefore(endDate)) {
    // Format currentDate to YYYY-MM-DD and push to dates array
    dates.push(currentDate.format("YYYY-MM-DD"));

    // Move to the next day
    currentDate.add(1, "day");
  }

  return dates;
};

export const calculateDaysUntilCheckin = (checkinDateString: string) => {
  const today = moment().startOf("day");
  const checkinDate = moment(checkinDateString).startOf("day");

  return checkinDate.diff(today, "days");
};

export const formatTime = (time: string | null) => {
  if (!time) return "";

  return moment(time, ["H:mm", "HH:mm"]).format("HH:mm");
};

export function getRelativeDay(dateString: string | null) {
  if (!dateString) return "";
  const inputDate = new Date(dateString);
  const today = new Date();

  inputDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const diffTime = inputDate.getTime() - today.getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  const { t } = useTranslation();
  switch (diffDays) {
    case -2:
      return t("Dashboard.relativeDayBeforeYesterday");
    case -1:
      return t("Dashboard.relativeDayYesterday");
    case 0:
      return t("Dashboard.relativeDayToday");
    case 1:
      return t("Dashboard.relativeDayTomorrow");
    case 2:
      return t("Dashboard.relativeDayAfterTomorrow");
    default:
      return t("Dashboard.relativeDays", { days: Math.abs(diffDays) });
  }
}
export const createArrayOfTimes = (interval: number) => {
  const min = interval ? interval : 30;
  const times = [];
  const startTime = moment().startOf("day");
  const endTime = moment().endOf("day");

  while (startTime.isBefore(endTime)) {
    const time = startTime.format("HH:mm");
    times.push({ label: time, value: time });
    startTime.add(min, "minutes");
  }
  return times;
};

export const getFormattedHour = (hour: number): string => {
  console.log("hour :", hour);
  if (hour < 0 || hour > 24) {
    throw new Error("L'heure doit être comprise entre 0 et 24.");
  }
  const formattedHour = hour.toString().padStart(2, "0"); // Ajoute un zéro si nécessaire
  return `${formattedHour}:00`;
};

export const parseHour = (time: string): number => {
  // Validation pour s'assurer que le format est correct
  if (!/^\d{2}:00$/.test(time)) {
    throw new Error("Le format doit être 'HH:00' avec HH entre 0 et 24.");
  }

  const hour = parseInt(time.split(":")[0], 10);

  // Vérification que l'heure est entre 8 et 23
  if (hour < 0 || hour > 24) {
    throw new Error("L'heure doit être comprise entre 0 et 24.");
  }

  return hour;
};

export const normalizeTimeString = (timeString: string): string | null => {
  const match = timeString.match(/^(\d+)h(\d{1,2})$/); // Match hours and minutes
  if (!match) return null; // Return null if the format is invalid

  const hours = match[1].padStart(2, "0"); // Ensure hours have at least 2 digits
  const minutes = match[2].padStart(2, "0"); // Ensure minutes have at least 2 digits

  return `${hours}h${minutes}`; // Return the normalized time string
};

export const formatTimeStringDashboard = (
  t: TFunction<"translation", undefined>,
  timeString: string | undefined
) => {
  if (timeString) {
    const normalizedTimeString = timeString ? normalizeTimeString("34h0") : "";

    if (normalizedTimeString) {
      const match = normalizedTimeString.match(/^(\d+)h(\d{2})$/);
      if (match) {
        const hours = match[1].padStart(2, "0");
        const minutes = match[2].padStart(2, "0");
        if (minutes === "00" || minutes === "0") {
          return (
            t("Dashboard.hours", {
              hours,
            }) + " 🎉🥳"
          );
        }
        return (
          t("Dashboard.hoursMin", {
            hours,
            min: minutes,
          }) + " 🎉🥳"
        );
      }
      return "";
    }
    return "";
  }
  return "";
};
