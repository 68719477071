import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams, useSearchParams} from "react-router-dom";
import AlertCircle from "../../assets/icons/alert-circle.svg?react";
import SuperhoteIcon from "../../assets/icons/superhote.svg";
import {InputSelectOptionProps} from "../../components/Common/InputSelect/InputSelect.type";
import GuestPageFormComponent from "../../components/GuestPageUser/GuestPageForm/GuestPageForm";
import GuestPageHomeComponent from "../../components/GuestPageUser/GuestPageHome/GuestPageHomeComponent";
import paths from "../../constants/paths";
import {get, post} from "../../helpers/APIHelper";
import {getLanguageIcon} from "../../helpers/languageHelper";
import {capitalizeFirstLetter} from "../../helpers/stringHelper";
import useGuestPageDataStore from "../../stores/guestPageStore/useGuestPageDataStore";
import {
  GuestPageConnectResponse,
  GuestPageLoginDetailsResponse,
  LanguageResponse,
} from "../../types/GETTypes";
import GuestPageSkeleton from "./GuestPageSkeleton";

const GuestPageUser = () => {
  const {t} = useTranslation();
  const {guestPageLink} = useParams();
  const [searchParams] = useSearchParams();
  const rentalId = searchParams.get("rentalId");
  const [step, setStep] = useState(0);
  const [loaderLoginDetails, setLoaderLoginDetails] = useState(true);
  const [guestLoginDetails, setGuestLoginDetails] = useState<
    GuestPageLoginDetailsResponse | undefined
  >();
  const [languages, setLanguages] = useState<InputSelectOptionProps[]>([]);
  const {
    guestPageData,
    storeGuestPageData,
    isPreviewMode,
    storeIsPreviewMode,
    storeUserPreview,
    storeRentalPreview,
  } = useGuestPageDataStore();
  const [errorLink, setErrorLink] = useState(false);
  const [guestPageDisable, setGuestPageDisable] = useState(false);

  const loadData = async () => {
    setLoaderLoginDetails(true);
    const responseUser = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER}`
    );
    const user = responseUser?.data?.result;
    if (
      responseUser?.data?.success &&
      guestPageLink === user?.guest_page_link &&
      rentalId &&
      rentalId !== "0"
    ) {
      //Is previewMode
      const responseRental = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}/${rentalId}`
      );
      const rental = responseRental?.data?.result;
      storeRentalPreview(rental);
      storeUserPreview(user);
      await loadLanguages();
      storeIsPreviewMode(true);
      setErrorLink(false);
      setLoaderLoginDetails(false);
      return;
    }
    storeIsPreviewMode(false);
    // Récupérer guest login screen details
    const {data, ...res} =
      (await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_PAGE.GET_SCREEN_DETAILS
        }/${guestPageLink}`
      )) || {};
    const {result, success} =
      (data as {result: GuestPageLoginDetailsResponse; success: boolean}) || {};
    if (!res?.response?.data?.success) {
      if (res?.response?.data?.error_code === 1) {
        setErrorLink(true);
        setLoaderLoginDetails(false);
        return;
      }
    }
    const {
      guest_page_connected,
      guest_email,
      guest_phone,
      reservation_id,
      languages,
      enabled,
    } = result || {};
    console.log("🚀 ~ loadData ~ result:", result)


    if (!enabled) {
      setGuestPageDisable(true);
      setLoaderLoginDetails(false);
      return;
    }
    setGuestPageDisable(false);
    if (guest_page_connected) {
      const dataConnected = {
        reservation_id: String(reservation_id),
        guest_page_link: guestPageLink,
        guest_page_connected: 1,
        email: guest_email,
        phone: guest_phone,
      };
      try {
        const resConnected = await post(
          `${import.meta.env.VITE_API_URL}${paths.API.GUEST_PAGE.CONNECT}`,
          dataConnected
        );
        if (resConnected?.data?.success) {
          const guestDataConnected = resConnected?.data?.result;
          const {rental, guest, checkin, checkout} = guestDataConnected || {};
          const title = `${capitalizeFirstLetter(
            rental?.name
          )} - ${capitalizeFirstLetter(
            guest?.first_name
          )} ${capitalizeFirstLetter(
            guest?.last_name
          )} - ${capitalizeFirstLetter(checkin)} - ${capitalizeFirstLetter(
            checkout
          )}`;
          changeTitle(title);
          handleLogin(guestDataConnected);
          setLoaderLoginDetails(false);
          return;
        } else {
          setLoaderLoginDetails(false);
        }
      } catch (e) {
        setLoaderLoginDetails(false);
      }
    }

    if (success) {
      const {rental_name, guest_last_name, guest_first_name} = result || {};
      const title = `${capitalizeFirstLetter(
        rental_name
      )} - ${capitalizeFirstLetter(guest_first_name)} ${capitalizeFirstLetter(
        guest_last_name
      )}`;
      changeTitle(title);
      setGuestLoginDetails(result);
    }
    loadLanguages(languages);
    setLoaderLoginDetails(false);
  };

  const loadLanguages = async (languages?: LanguageResponse[]) => {
    let langs;
    let success = false;
    if (languages) {
      langs = languages;
      success = true;
    } else {
      // Récupérer les langues
      const {data: dataLanguages} =
        (await get(`${import.meta.env.VITE_API_URL}${paths.API.LANGUAGES}`)) ||
        {};
      const {result: resultLanguages, success: successLanguages} =
        dataLanguages || {};
      langs = resultLanguages?.languages;
      success = successLanguages;
    }

    if (success) {
      const excludedCodes = ["PT", "IT"];
      const nextLanguages = langs
        ?.filter((l: LanguageResponse) => !excludedCodes.includes(l.code))
        .map((l: LanguageResponse) => {
          return {
            label: l.name,
            value: l.code,
            Icon: getLanguageIcon(l.code),
          };
        });

      setLanguages(nextLanguages);
    }

    return {resultLanguages: langs, successLanguages: success};
  };

  const handleLogin = (data?: GuestPageConnectResponse) => {
    if (isPreviewMode) {
      setStep(1);
      return;
    }
    if (data) {
      storeGuestPageData({...data});
      setStep(1);
    }
  };

  useEffect(() => {
    loadData();
  }, [guestPageLink]);

  const changeTitle = (title: string) => {
    document.title = title;
  };

  useEffect(() => {}, [guestLoginDetails, languages, guestPageData]);

  const isForm = step === 0;

  if (loaderLoginDetails) {
    return <GuestPageSkeleton />;
  }

  if (errorLink) {
    return (
      <div className="flex flex-col flex-1 w-screen h-screen">
        <img
          src={`${SuperhoteIcon}`}
          className="w-screen h-32 px-8 mt-16 bg-cover md:h-32"
        />
        <div className="flex flex-col items-center justify-center flex-1 mx-4">
          <AlertCircle className={`fill-error`} width={50} height={50} />
          <div
            className={` text-xl font-light text-center flex flex-row items-center justify-center text-error mt-8`}
          >
            {t("GuestPage.Global.guestPageErrorLink")}
          </div>
        </div>
      </div>
    );
  }

  if (guestPageDisable) {
    return (
      <div className="flex flex-col flex-1 w-screen h-screen">
        <img
          src={`${SuperhoteIcon}`}
          className="w-screen h-32 px-8 mt-16 bg-cover md:h-32"
        />
        <div className="flex flex-col items-center justify-center flex-1 mx-4">
          <AlertCircle className={`fill-error`} width={50} height={50} />
          <div
            className={` text-xl font-light text-center flex flex-row items-center justify-center text-error mt-8`}
          >
            {t("GuestPage.Global.guestPageDisable")}
          </div>
        </div>
      </div>
    );
  }

  return isForm ? (
    <GuestPageFormComponent
      guestLoginDetails={guestLoginDetails}
      languages={languages}
      handleLogin={handleLogin}
    />
  ) : (
    <GuestPageHomeComponent />
  );
};

export default GuestPageUser;
