import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import ExportIcon from "../../../../assets/icons/export.svg?react";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import { PaymentReservationStatusEnum, ReservationExtraOrderStatusEnum } from "../../../../enums/GETenums";
import { Button } from "../../../Common/Button/Button";
import { CalendarInputValue } from "../../../Common/CalendarInput/CalendarInput.type";
import { DateFilter } from "../../../Common/DateFilter/DateFilter";
import { DateRangePreset } from "../../../Common/DateFilter/DateFilter.type";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import { GroupButtonItemType } from "../../../Common/GroupButton/GroupButton.type";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { PaymentReservationExtrasFiltersProps } from "./PaymentReservationFilters.type";

export const PaymentExtrasReservationFilters: React.FC<
PaymentReservationExtrasFiltersProps
> = ({
  onSearchText,
  totals,
  onFilterButtonClick,
  currentActiveButtonFilter,
  tablePage,
}) => {
  const { t } = useTranslation();

  const [dates, setDates] = useState<CalendarInputValue>([null, null]);
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");

  const getGroupButtonItems = (): GroupButtonItemType[] => [
    {
      label: t("Global.unpaid", {
        context: "male",
      }),
      value: ReservationExtraOrderStatusEnum.UNPAID,
      badge: totals.unpaid,
      isActive:
        currentActiveButtonFilter === ReservationExtraOrderStatusEnum.UNPAID,
    },
    {
      label: t("Booking.PaymentExtraStatus.partiallyPaid",{
        count: Number(totals.partiallyPaid),
      }),
      value: ReservationExtraOrderStatusEnum.PARTIALLY_PAID,
      badge: totals.partiallyPaid,
      isActive:
        currentActiveButtonFilter === ReservationExtraOrderStatusEnum.PARTIALLY_PAID,
    },
    {
      label: t("Global.paid", {
        context: "male",
        count: Number(totals.paid),
      }),
      value: ReservationExtraOrderStatusEnum.PAID,
      badge: totals.paid,
      isActive: currentActiveButtonFilter === ReservationExtraOrderStatusEnum.PAID,
    },
    {
      label: t("Booking.PaymentExtraStatus.preAuthorized", {
        count: Number(totals.preAuthorized),
      }),
      value: ReservationExtraOrderStatusEnum.PRE_AUTHORIZED,
      badge: totals.preAuthorized,
      isActive: currentActiveButtonFilter === ReservationExtraOrderStatusEnum.PRE_AUTHORIZED,
    },
    {
      label: t("Global.canceled", {
        context: "male",
      }),
      value: ReservationExtraOrderStatusEnum.REFUNDED,
      badge: totals.canceled,
      isActive:
        currentActiveButtonFilter === ReservationExtraOrderStatusEnum.REFUNDED,
    },
    {
      label: t("Global.all", {
        context: "male",
        count: 2,
      }),
      value: "ALL",
      badge: totals.all,
      isActive: currentActiveButtonFilter === "ALL",
    },
  ];

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-row items-center space-x-3">
        <div className="flex-1">
          <TextInput
            size="large"
            Icon={SearchIcon}
            placeholder={t("Global.search")}
            onChangeText={onSearchText}
          />
        </div>

        {/* TODO: [PaymentReservation] Implement export button logic */}
        <div title={t("Global.wip")}>
          <Button
            type="secondary"
            onClick={() => {}}
            RightIcon={ExportIcon}
            disabled={true}
          >
            {t("Global.export")}
          </Button>
        </div>
      </div>

      <div className="flex flex-row items-center space-x-3">
        <GroupButton
          items={getGroupButtonItems()}
          onClick={onFilterButtonClick}
        />
      </div>

      <div className="flex flex-row items-center space-x-3">
        <div className="flex">
          <DateFilter
            onDatesChanged={(dates, nbRangeDate) => {
              if (Array.isArray(dates) && dates.length >= 2) {
                const startDate: Date | null = dates[0];
                const endDate: Date | null = dates[1];
                if (
                  (startDate === null && endDate === null) ||
                  (startDate instanceof Date && endDate instanceof Date)
                ) {
                  if (startDate === null || endDate === null) {
                    tablePage.fetch({});
                  } else {
                    tablePage.fetch({
                      from: moment(startDate).format("YYYY-MM-DD"),
                      to: moment(endDate).format("YYYY-MM-DD"),
                    });
                  }
                }
              }
            }}
            onDateRangePresetChanged={(dateRangePreset) => {
              setDateRangePreset(dateRangePreset);
            }}
          />
        </div>
      </div>
    </div>
  );
};
