import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../../assets/icons/alert-circle.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import paths from "../../../constants/paths";
import { get, post } from "../../../helpers/APIHelper";
import { formatTime, getDatesBetweenDates } from "../../../helpers/dateHelper";
import { useCalendar, useGetRestriction } from "../../../hooks/api/calendar";
import {
  CalendarRentalResponse,
  RentalLightListItemResponse,
} from "../../../types/GETTypes";
import { RequiredFields, ValueType } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { CalendarInput } from "../../Common/CalendarInput/CalendarInput";
import { CalendarInputValueRange } from "../../Common/CalendarInput/CalendarInput.type";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../Common/NumberInput/NumberInput";

import {
  AddReservationCalendarType,
  AddReservationGeneralType,
  AddReservationTarificationType,
  AddReservationType,
} from "../AddReservation.type";

type formSchema = {
  rental: ValueType;
  type: ValueType;
  adults: number;
  children: number;
  dates: CalendarInputValueRange;
  checkinTime: string | null;
  checkoutTime: string | null;
};

export const AddReservationInfos: React.FC<{
  reservation: AddReservationType | undefined;
  onCancel: () => void;
  onNext: (value: {
    rental: RentalLightListItemResponse;
    general: AddReservationGeneralType;
    tarification: AddReservationTarificationType;
    calendar: AddReservationCalendarType;
  }) => void;
}> = ({ reservation, onCancel, onNext }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const form = useForm<formSchema>({
    mode: "all",
    defaultValues: {
      rental: "",
      type: 54,
      adults: 2,
      children: 0,
      dates: [new Date(), moment(new Date()).add(1, "days").toDate()],
      checkinTime: "",
      checkoutTime: "11:00",
    },
  });

  const requiredFields: RequiredFields<formSchema> = {
    rental: true,
    type: true,
    adults: true,
    children: true,
    dates: true,
    checkinTime: true,
    checkoutTime: true,
  };

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [alerts, setAlerts] = useState<object>({});

  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>([]);

  // We check if one rental exists
  useEffect(() => {
    if (!initialLoading && rentals.length === 0) {
      navigate(-1);
    }
  }, [initialLoading, rentals]);

  const fetchRentals = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}?limit=-1`
    );

    if (res?.data?.success) {
      setRentals(res?.data?.result?.rentals_access);
      if (!form.getValues("rental") || form.getValues("rental") === "") {
        const rental: RentalLightListItemResponse =
          res?.data?.result?.rentals[0];
        form.setValue("rental", rental?.id);
        setInAndOutTime(rental);
      } else {
        form.setValue("rental", form.getValues("rental"));
      }

      setInitialLoading(false);
    } else {
      setError(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchRentals();
  }, []);

  useEffect(() => {
    if (reservation?.general && reservation?.rental) {
      form.setValue("rental", reservation?.rental?.id);
      form.setValue("type", reservation?.general.platform_id);
      form.setValue("adults", Number(reservation?.general.adults_count));
      form.setValue("children", Number(reservation?.general.children_count));
      form.setValue("dates", [
        reservation?.general.checkin,
        reservation?.general.checkout,
      ]);
      form.setValue("checkinTime", formatTime(reservation?.general.time_in));
      form.setValue("checkoutTime", formatTime(reservation?.general.time_out));
    }
  }, [reservation]);

  const setInAndOutTime = (rental: RentalLightListItemResponse) => {
    form.setValue("checkinTime", formatTime(rental.checkin_min_time));
    form.setValue("checkoutTime", formatTime(rental.checkout_max_time));
  };

  const handleNextStep = async (values: formSchema) => {
    setError(undefined);

    let isError = false;
    const index = rentals.findIndex((rental) => rental.id === values.rental);
    const currentRental = rentals[index];

    if (values.adults > currentRental.adults_max_capacity) {
      isError = true;
      form.setError("adults", {
        message: t("AddReservation.Infos.Error.maxAdults", {
          count: currentRental.adults_max_capacity,
        }),
      });
    }

    if (values.children > currentRental.children_max_capacity) {
      isError = true;
      form.setError("children", {
        message: t("AddReservation.Infos.Error.maxChildren", {
          count: currentRental.children_max_capacity,
        }),
      });
    }

    if (values.adults + values.children === 0) {
      isError = true;
      form.setError("adults", {
        message: t("AddReservation.Infos.Error.minGuest"),
      });
    }

    if (isError) {
      return;
    }

    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.RESERVATION_ADD_CHECK_AVAILABILITY
      }`,
      {
        rental_id: values.rental.toString(),
        platform_id: values.type,
        checkin: moment(values.dates[0]).format("YYYY-MM-DD"),
        checkout: moment(values.dates[1]).format("YYYY-MM-DD"),
        time_in: formatTime(values.checkinTime),
        time_out: formatTime(values.checkoutTime),
        adults_count: values.adults,
        children_count: values.children,
      }
    );

    if (res?.data?.success) {
      const result = res.data.result;

      onNext({
        rental: result.rental,
        general: {
          ...result.general_informations,
          checkin: moment(result.general_informations.checkin).toDate(),
          checkout: moment(result.general_informations.checkout).toDate(),
        },
        tarification: {
          nights_total_price: result.tarification.nights_total_price,
          city_tax: result.tarification.city_tax,
          cleaning_default: result.tarification.cleaning_default,
          additional_guests_total_price:
            result.tarification.additional_guests_total_price,
          pet_default: result.tarification.pet_default,
          infant_bed_default: result.tarification.infant_bed_default,
          other: result.tarification.other,
          total: result.tarification.total,
        },
        calendar: result.calendar,
      });

      setLoading(false);
    } else {
      setError(res?.response?.data?.message);
      setLoading(false);
      return;
    }
  };

  // * Handle date fields
  const [rentalsCalendar, setRentalsCalendar] = useState<
    CalendarRentalResponse[] | undefined
  >();
  const [excludeDates, setExcludeDates] = useState<string[] | undefined>();

  useEffect(() => {
    if (rentalsCalendar === undefined) {
      useCalendar(
        (data: CalendarRentalResponse[] | undefined) => {
          if (data) {
            setRentalsCalendar(data);
          }
        },
        (message: string | undefined) => {
          setError(message);
        },
        () => {
          setError(undefined);
          setLoading(true);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  const countDays = (dateArray) => {
    const parseDate = (dateStr) => {
      if (dateStr === null) return null;
      return new Date(dateStr);
    };
    const date1 = parseDate(dateArray[0]);
    const date2 = parseDate(dateArray[1]);

    if (date1 === null && date2 === null) {
      return 0;
    } else if (date1 === null) {
      const now = new Date();
      const diffTime = Math.abs(now - date2);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    } else if (date2 === null) {
      const now = new Date();
      const diffTime = Math.abs(now - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    const diffTime = Math.abs(date2 - date1);
    const numberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return numberOfDays;
  };

  useEffect(() => {
    const dates = form.watch("dates");
    const rentalId = form.watch("rental");
    const numbersOfDays = countDays(dates);
    setAlerts({});

    const fetchRestrictions = (date, key, errorKey, errorMessage) => {
      const data = {
        rental_id: rentalId,
        date: moment(date).format("YYYY-MM-DD"),
      };

      useGetRestriction(
        data,
        (result: any) => {
          if (result) {
            if (key && numbersOfDays < result.min_nights) {
              setAlerts((prevAlerts) => ({
                ...prevAlerts,
                min_nights: t("AddReservation.Infos.Error.minNights", {
                  count: result.min_nights_default,
                }),
              }));
            }

            if (result.no_checkin) {
              setAlerts((prevAlerts) => ({
                ...prevAlerts,
                no_checkin: t("AddReservation.Infos.Error.noCheckin", {
                  date: moment(date).format("YYYY-MM-DD"),
                }),
              }));
            }

            if (numbersOfDays > result.max_nights) {
              setAlerts((prevAlerts) => ({
                ...prevAlerts,
                max_nights: t("AddReservation.Infos.Error.maxNights", {
                  count: result.max_nights,
                }),
              }));
            }

            if (errorKey && result[errorKey]) {
              setAlerts((prevAlerts) => ({
                ...prevAlerts,
                [errorKey]: t(errorMessage, {
                  date: moment(date).format("YYYY-MM-DD"),
                }),
              }));
            }
          }
        },
        () => {
          setError(undefined);
          setLoading(true);
        },
        () => {
          setLoading(false);
        }
      );
    };

    if (dates[0]) {
      fetchRestrictions(dates[0], true);
    }

    if (dates[1]) {
      fetchRestrictions(
        dates[1],
        false,
        "no_checkout",
        "AddReservation.Infos.Error.noCheckout"
      );
    }
  }, [form.watch("rental"), form.watch("dates")]);

  useEffect(() => {
    if (Boolean(form.getValues("rental"))) {
      if (rentalsCalendar !== undefined) {
        setExcludeDates(() => {
          const disabledDates: Set<string> = new Set();

          const rentalCalendar: CalendarRentalResponse | undefined =
            rentalsCalendar.find((v) => v && v.id === form.getValues("rental"));

          if (rentalCalendar) {
            const restrictedPreviousDate = rentalCalendar?.restrictions
              .filter((v) => moment(v.date).diff(Date.now(), "days") < 0)
              .map((v) => v.date);

            restrictedPreviousDate.forEach((date) => disabledDates.add(date));

            const checkinDates = rentalCalendar?.reservations
              .filter((v) => {
                if (Boolean(v.checkin)) {
                  return Boolean(v.checkin);
                }

                return false;
              })
              .map((v) => v.checkin);

            const checkoutDates = rentalCalendar?.reservations
              .filter((v) => {
                if (Boolean(v.checkout)) {
                  return Boolean(v.checkout);
                }

                return false;
              })
              .map((v) => v.checkout);

            rentalCalendar.reservations.forEach((reservation, index) => {
              getDatesBetweenDates(
                reservation.checkin,
                reservation.checkout
              ).forEach((d, index) => {
                const existsInCheckin = checkinDates.includes(d);
                const existsInCheckout = checkoutDates.includes(d);

                if (
                  (existsInCheckin && existsInCheckout) ||
                  (reservation.checkin < d && reservation.checkout > d)
                ) {
                  disabledDates.add(d);
                }
              });
            });
          }

          return Array.from(disabledDates);
        });
      }
    }
  }, [form.getValues("rental"), rentalsCalendar]);

  form.watch();

  return (
    <div className="flex flex-col text-sm">
      <p className="text-lg font-semibold text-high-contrast">
        {t("AddReservation.Infos.title")}
      </p>

      <p className="mt-4 font-light text-low-contrast">
        {t("AddReservation.Infos.subTitle")}
      </p>

      {alerts.min_nights && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.min_nights}</p>
            </div>
          </div>
        </div>
      )}
      {alerts.no_checkin && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.no_checkin}</p>
            </div>
          </div>
        </div>
      )}

      {alerts.no_checkout && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.no_checkout}</p>
            </div>
          </div>
        </div>
      )}

      {alerts.max_nights && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.max_nights}</p>
            </div>
          </div>
        </div>
      )}
      <div className="mt-8">
        <Controller
          control={form.control}
          name="rental"
          rules={{
            required: requiredFields.rental,
            onChange: (e) => {
              const index = rentals?.findIndex((r) => r.id === e.target.value);
              const rental = rentals[index];
              setInAndOutTime(rental);
              form.setValue("rental", e.target.value);
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <div className="max-w-[45rem]">
                <InputSelect
                  required={requiredFields.rental}
                  label={`${t("AddReservation.Infos.rental")}`}
                  items={rentals?.map((rental) => {
                    return { value: rental.id, label: rental.name };
                  })}
                  selectedValue={value}
                  onSelect={onChange}
                  disabled={initialLoading || loading}
                  error={form.formState.errors.rental?.message}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="mt-6">
        <Controller
          control={form.control}
          name="type"
          rules={{
            required: requiredFields.type,
          }}
          render={({ field: { onChange, value } }) => (
            <InputSelect
              required={requiredFields.type}
              label={`${t("AddReservation.Infos.type")}`}
              items={[
                { value: 54, label: t("AddReservation.Infos.direct") },
                {
                  value: 69,
                  label: t("AddReservation.Infos.website"),
                },
                {
                  value: 31,
                  label: t("AddReservation.Infos.ical"),
                },
              ]}
              selectedValue={value}
              onSelect={onChange}
              disabled={initialLoading || loading}
              error={form.formState.errors.type?.message}
            />
          )}
        />
      </div>

      <div className="flex items-start gap-4 mt-6">
        <Controller
          control={form.control}
          name="adults"
          rules={{
            required: requiredFields.adults,
          }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              required={requiredFields.adults}
              label={`${t("AddReservation.Infos.adults")}`}
              size="large"
              disabled={initialLoading || loading}
              value={value}
              max={
                rentals.find((rental) => rental.id === form.getValues("rental"))
                  ?.adults_max_capacity
              }
              onChangeText={onChange}
              error={form.formState.errors.adults?.message}
            />
          )}
        />

        <Controller
          control={form.control}
          name="children"
          rules={{
            required: requiredFields.children,
          }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              required={requiredFields.children}
              label={`${t("AddReservation.Infos.children")}`}
              size="large"
              disabled={initialLoading || loading}
              value={value}
              max={
                rentals.find((rental) => rental.id === form.getValues("rental"))
                  ?.children_max_capacity
              }
              onChangeText={onChange}
              error={form.formState.errors.children?.message}
            />
          )}
        />
      </div>

      <div className="mt-6">
        <Controller
          control={form.control}
          name="dates"
          rules={{
            required: requiredFields.dates,
            onChange: (e) => {
              const dates: CalendarInputValueRange = e.target.value;

              if (dates.length !== 2 || dates.includes(null)) {
                form.setError("dates", {
                  type: "manual",
                  message: t("AddReservation.Infos.Error.dates"),
                });
              }
            },
          }}
          render={({ field: { onChange, value } }) => (
            <CalendarInput
              classNames={{
                button: "rounded-md bg-white p-2",
              }}
              required={requiredFields.dates}
              disabled={initialLoading || loading}
              label={`${t("AddReservation.Infos.dates")}`}
              height="full"
              dateType={"range"}
              allowSingleDateInRange={false}
              value={value}
              onChangeStartDateInput={() => {}}
              onChangeEndDateInput={() => {}}
              onChangeDate={onChange}
              isExcludeDate={(date: Date) => {
                if (excludeDates && excludeDates.length > 0) {
                  return Boolean(
                    excludeDates.find(
                      (v) => moment(date).format("YYYY-MM-DD").toString() === v
                    )
                  );
                }

                return false;
              }}
              error={form.formState.errors?.dates?.message}
            />
          )}
        />
      </div>

      <div className="flex mt-6 gap-x-4">
        <div className="flex-1">
          <InputSelect
            register={form.register("checkinTime", {
              required: {
                value: requiredFields.checkinTime,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Infos.checkinTime"),
                }),
              },
              onChange: (e) => form.setValue("checkinTime", e.target.value),
            })}
            required={requiredFields.checkinTime}
            label={t("AddReservation.Infos.checkinTime")}
            items={[
              {
                label: "Flexible",
                value: "Flexible",
              },
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={form.getValues("checkinTime") ?? ""}
            error={form.formState.errors.checkinTime?.message}
            disabled={initialLoading || loading}
          />
        </div>
        <div className="flex-1">
          <InputSelect
            register={form.register("checkoutTime", {
              required: {
                value: requiredFields.checkoutTime,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Infos.checkoutTime"),
                }),
              },
            })}
            required={requiredFields.checkoutTime}
            label={t("AddReservation.Infos.checkoutTime")}
            items={[
              {
                label: "00:00",
                value: "00:00",
              },
              {
                label: "01:00",
                value: "01:00",
              },
              {
                label: "02:00",
                value: "02:00",
              },
              {
                label: "03:00",
                value: "03:00",
              },
              {
                label: "04:00",
                value: "04:00",
              },
              {
                label: "05:00",
                value: "05:00",
              },
              {
                label: "06:00",
                value: "06:00",
              },
              {
                label: "07:00",
                value: "07:00",
              },
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={form.getValues("checkoutTime") ?? ""}
            error={form.formState.errors.checkoutTime?.message}
            disabled={initialLoading || loading}
          />
        </div>
      </div>

      <div className="mt-6">
        <ErrorMessage>{error}</ErrorMessage>
      </div>

      <div className="flex gap-4 pb-4 mt-8">
        <Button
          type="secondary"
          onClick={onCancel}
          disabled={initialLoading || loading}
        >
          {t("Global.cancel")}
        </Button>
        {/* TODO: [AddReservationInfos] You should ideally use form.formState.isValid ⬇️ */}
        <Button
          RightIcon={ArrowRightWhiteIcon}
          disabled={
            initialLoading ||
            loading ||
            Object.values(form.formState.errors).length > 0
          }
          loading={loading}
          onClick={form.handleSubmit(handleNextStep)}
        >
          {t("AddReservation.Infos.nextStep")}
        </Button>
      </div>
    </div>
  );
};
