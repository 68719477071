import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import LockIcon from "../../assets/icons/lock.svg?react";
import FundedEuropeanUnionImage from "../../assets/images/funded_european_union.png";
import PciImage from "../../assets/images/pci.png";
import VerifiedImage from "../../assets/images/visa_verified.png";
import { Badge } from "../../components/Common/Badge/Badge";
import { Button } from "../../components/Common/Button/Button";
import { CenterModal } from "../../components/Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import paths from "../../constants/paths";
import {
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../../enums/GETenums";
import { post } from "../../helpers/APIHelper";
import useExtrasStore from "../../stores/guestPageStore/useExtrasStore";
import { SendExtraOrderPaymentResponse } from "../../types/GETTypes";
import { PaymentReservationCheckoutFormProps } from "./PaymentReservationCheckoutForm.type";
import { PaymentReservationSuccess } from "./PaymentReservationSuccess";

export const PaymentReservationCheckoutForm: React.FC<
  PaymentReservationCheckoutFormProps
> = ({
  amount,
  billingDetails,
  onValidatePayment,
  from,
  displayModalSuccessGuestPage = false,
  onAction,
  onCloseSuccess,
}) => {
  const { t } = useTranslation();
  const { extrasValidateData, sendExtraData } = useExtrasStore();
  const { extras_order_confirmation_enabled } = extrasValidateData || {};
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError(t("Stripe.noStripe"));
      return;
    }

    // use a different stripe workflow for extra order payments
    // as stripe payment intents should be created only after guests have provided their payment method
    // to avoid a bunch of incomplete orders on stripe and in SH database
    // so get a token from stripe and send it to the backend to create and confirm the paymlent intent at the same time
    if (from === "extraForm") {
      setError(null);
      // trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();

      if (submitError) {
        if (submitError.type !== "validation_error") {
          setError(submitError.message);
        }
        setLoading(false);
        return;
      }

      const { error, confirmationToken } = await stripe.createConfirmationToken(
        {
          elements,
        }
      );

      if (error) {
        // this point is only reached if there's an immediate error when
        // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
        setError(error.message);
        setLoading(false);
      } else {
        const {
          reservation_id,
          email,
          ordered_extras_ids,
          ordered_extras_quantities,
        } = sendExtraData || {};
        // create and confirm the payment intent backend side
        const data = {
          reservation_id: String(reservation_id),
          email,
          stripe_confirmation_token: confirmationToken.id,
          ordered_extras_ids,
          ordered_extras_quantities,
        };
        const response = await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.GUEST_PAGE.SEND_EXTRAS_ORDER_PAYMENT
          }`,
          data
        );
        // handle any next actions or errors
        handleServerResponseForExtraOrderPayment(response.data.result);
      }
    }
    // for all other payments use the classic stripe workflow
    else {
      const confirmed = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
        redirect: "if_required",
      });

      if (confirmed.error) {
        setError(confirmed.error.message);
        setLoading(false);
      } else {
        setLoading(false);
        setSuccess(true);
      }
    }
  };

  // manage returned response from payment intent created and confirmed on server side (only used for extras orders payments)
  const handleServerResponseForExtraOrderPayment = async (
    response: SendExtraOrderPaymentResponse
  ) => {
    if (!stripe || !elements) {
      setError(t("Stripe.noStripe"));
      return;
    }

    if (response.stripe_error) {
      // show error from server on payment form
      setError(response.stripe_error);
      setLoading(false);
    } else if (response.stripe_payment_status === "requires_action") {
      // use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.stripe_client_secret,
      });

      if (error) {
        // show error from Stripe.js in payment form
        setError(error.message);
        setLoading(false);
      } else {
        // actions handled, show success message
        setLoading(false);
        setSuccess(true);
        onValidatePayment(
          ReservationExtraOrderStatusEnum.PRE_AUTHORIZED,
          response
        );
      }
    } else {
      // no actions needed, show success message
      setLoading(false);
      setSuccess(true);
      onValidatePayment(
        ReservationExtraOrderStatusEnum.PRE_AUTHORIZED,
        response
      );
    }
  };

  useEffect(() => {
    if (success && !displayModalSuccessGuestPage && from !== "extraForm") {
      onValidatePayment(PaymentReservationStatusEnum.PAID);
    }
  }, [success]);

  const getButtonText = () => {
    if (from === "extraForm" && extras_order_confirmation_enabled) {
      return t("GuestPage.ExtrasPayment.validatePreAuthorized");
    }
    return t("Global.payNow");
  };

  const getTitle = () => {
    if (from === "extraForm" && extras_order_confirmation_enabled) {
      return t("GuestPage.ExtrasPayment.preAuthorized", {
        amount: `\n ${amount} ${t("Global.currencySymbol")}`,
      });
    }
    return t("Payments.PaymentReservation.toPay", {
      amount: `\n ${amount} ${t("Global.currencySymbol")}`,
    });
  };

  const getDescription = () => {
    if (from === "extraForm" && extras_order_confirmation_enabled) {
      return t("GuestPage.ExtrasPayment.preAuthorizedDescription");
    }
    return "";
  };

  const getCenterModalTitle = () => {
    if (from === "extraForm" && extras_order_confirmation_enabled) {
      return (
        <h2 className="text-base font-bold text-center text-high-contrast">
          {t("GuestPage.PaymentModal.preAuthTitle")}
        </h2>
      );
    } else {
      return (
        <h2 className="text-base font-bold text-center text-high-contrast">
          {t("GuestPage.PaymentModal.title")}
        </h2>
      );
    }
  };

  const getCenterModalDescription = () => {
    if (from === "extraForm" && extras_order_confirmation_enabled) {
      return (
        <>
          <p className="mt-2 text-sm font-light text-low-contrast">
            {t("GuestPage.PaymentModal.preAuthDescription1")}
          </p>
          <p className="mt-2 text-sm font-light text-low-contrast">
            {t("GuestPage.PaymentModal.preAuthDescription2")}
          </p>
          <p className="mt-2 text-sm font-light text-low-contrast">
            {t("GuestPage.PaymentModal.preAuthDescription3")}
          </p>
        </>
      );
    } else {
      return (
        <p className="mt-2 text-sm font-light text-low-contrast">
          {t("GuestPage.PaymentModal.description")}
        </p>
      );
    }
  };

  return (
    <>
      {success && !displayModalSuccessGuestPage ? (
        <PaymentReservationSuccess amount={amount} />
      ) : (
        <>
          <CenterModal
            isVisible={success && displayModalSuccessGuestPage}
            onClose={() => onCloseSuccess && onCloseSuccess()}
            CustomHeader={
              <div className="relative flex items-center justify-center flex-1 h-32 bg-element-background-success rounded-t-16px">
                <div className="absolute cursor-pointer">
                  <Badge
                    status={"success"}
                    size="large"
                    customBg="bg-transparent"
                    iconSize={30}
                  />
                </div>
              </div>
            }
            classNames={{
              divParentModal: "w-full h-full px-8 md:px-0",
              divModal: "md:ml-[20%] w-auto",
            }}
          >
            {getCenterModalTitle()}

            {getCenterModalDescription()}

            <div className="flex flex-row gap-2 pt-4">
              <Button onClick={onCloseSuccess && onCloseSuccess}>
                {t("GuestPage.PaymentModal.button")}
              </Button>
            </div>
          </CenterModal>

          <div className="w-full">
            <div className="flex flex-col justify-between w-full h-full p-6 rounded-lg bg-element-background">
              <h3 className="font-bold text-center whitespace-pre-line text-xxl">
                {getTitle()}
              </h3>
              <p className="mt-2 mb-5 text-sm text-center text-low-contrast">
                {getDescription()}
              </p>

              <form>
                <PaymentElement
                  options={{
                    layout: "tabs",
                    terms: {
                      card: "never",
                    },
                  }}
                />
              </form>

              <ErrorMessage>{error}</ErrorMessage>

              {from === "extraForm" && (
                <div className="flex flex-row flex-1 mt-3 text-sm text-low-contrast">
                  <label>
                    {t("GuestPage.ExtrasPayment.acceptCgv")}{" "}
                    <label
                      className="underline cursor-pointer underline-offset-2"
                      onClick={onAction && onAction}
                    >
                      {t("GuestPage.ExtrasPayment.cgv")}
                    </label>{" "}
                    <label className="">↗︎.</label>
                  </label>
                </div>
              )}
              <div className="mt-6 mb-3">
                <Button
                  RightIcon={CheckIcon}
                  onClick={handleSubmit}
                  disabled={!stripe}
                  loading={loading}
                  size="normal"
                  buttonClassName=""
                >
                  {getButtonText()}
                </Button>
              </div>

              <div className="flex items-center justify-center mt-3 space-x-2">
                <LockIcon width={25} height={25} />
                <p className="text-[14px] font-light text-low-contrast">
                  {t("Global.securedTransaction")}
                </p>
              </div>
              <div className="flex justify-center mt-4 space-x-6">
                <img
                  className="w-28"
                  src={VerifiedImage}
                  alt="Verified by VISA"
                />
                <img
                  className="w-28"
                  src={FundedEuropeanUnionImage}
                  alt="Funded by the European Union"
                />
                <img className="w-28" src={PciImage} alt="PCI DSS compliant" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
