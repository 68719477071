import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import EyeOffIcon from "../../../assets/icons/eye-off.svg?react";
import StarIcon from "../../../assets/icons/star.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import countries from "../../../constants/countries";
import {getLanguageString} from "../../../helpers/languageHelper";
import {getPlatformIcon} from "../../../helpers/platformHelper";
import {useLanguages} from "../../../hooks/api/languages";
import {useUpdateReservationGuest} from "../../../hooks/api/reservations";
import {LanguageResponse} from "../../../types/GETTypes";
import {RequiredFields} from "../../../types/commonTypes";
import {Card} from "../../Common/Card/Card";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import {InputSelectOptionProps} from "../../Common/InputSelect/InputSelect.type";
import {PhoneInput} from "../../Common/PhoneInput/PhoneInput";
import {Rating} from "../../Common/Rating/Rating";
import {TextInput} from "../../Common/TextInput/TextInput";
import {ReservationGuestCardSkeleton} from "./Guest";
import {ReservationGuestForm, ReservationGuestProps} from "./GuestCard.type";
import {PlatformEnum} from "../../../enums/GETenums";
import {useModal} from "../../../hooks/useModal";
import {PreviewImage} from "../../Common/PreviewImage/PreviewImage";

export const ReservationGuestCard: React.FC<ReservationGuestProps> = ({
  loading,
  reservation,
  disabled,
}) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = useForm<ReservationGuestForm>({
    mode: "all",
  });

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [guestLanguages, setGuestLanguages] = useState(
    reservation?.guest?.languages,
  );
  const [guest, setGuest] = useState<any>(reservation?.guest);

  const imgPreview = useModal();

  console.log("guest", reservation?.guest);

  const requiredFields: RequiredFields<ReservationGuestForm> = {
    photo: false,
    firstName: true,
    lastName: true,
    address: false,
    city: false,
    postalCode: false,
    country: false,
    email: true,
    phoneNumber: true,
    timezone: true,
    communicationLanguage: true,
    countryCode: false,
  };

  useEffect(() => {
    setValue("firstName", reservation?.guest?.first_name?.trim() ?? "");
    setValue("lastName", reservation?.guest?.last_name?.trim() ?? "");
    setValue("address", reservation?.guest?.address?.trim() ?? "");
    setValue("city", reservation?.guest?.city?.trim() ?? "");
    setValue("postalCode", reservation?.guest?.postal_code?.trim() ?? "");
    setValue("country", reservation?.guest?.country?.trim() ?? "");
    setValue("email", reservation?.guest?.email?.trim() ?? "");
    setValue("phoneNumber", reservation?.guest?.phone?.trim() ?? "");
    setValue("photo", reservation?.guest?.photo?.trim() ?? "");
    // TODO: [RESERVATIONS] - Set timezone when if exists
    // setValue("timezone", reservation?.guest?.timezone.trim() ?? "");
    setValue(
      "communicationLanguage",
      reservation?.guest?.locale?.toLocaleLowerCase()?.trim() ?? "placeholder",
    );
    setValue("countryCode", getCountryCode());
    setGuestLanguages(reservation?.guest?.languages);
    setGuest(reservation?.guest);
  }, [reservation]);

  const getCountryCode = () => {
    const code = countries.find(
      (country) => country.label === reservation?.guest?.country,
    )?.code;
    return code ? code : "FR";
  };

  const handleReservationGuestEdit = () => {
    useUpdateReservationGuest(
      reservation?.guest.id,
      getValues,
      (res: any) => {
        setGuest(res);
        setGuestLanguages(res?.languages);
        setEditMode(false);
      },
      (message: string | undefined) => {
        setError(message);
      },
      () => {
        setError(undefined);
        setValidationLoading(true);
      },
      () => {
        setValidationLoading(false);
      },
    );
  };

  // * GET ALL LANGUAGES
  const [languages, setLanguages] = useState<
    InputSelectOptionProps[] | undefined
  >([]);

  const fetchAllLanguages = () =>
    useLanguages(
      (languages: LanguageResponse[] | undefined) => {
        setLanguages(
          languages?.map((i) => {
            return {
              label: i.name,
              value: i.code.toLowerCase(),
            };
          }),
        );
      },
      (message: string | undefined) => setError(message),
      () => {
        setError(undefined);
        setValidationLoading(true);
      },
      () => {
        setValidationLoading(false);
      },
    );

  useEffect(() => {
    fetchAllLanguages();
  }, []);

  if (loading) return <ReservationGuestCardSkeleton />;

  return (
    <Card
      Icon={UsersIcon}
      label={t("Booking.Guest.title")}
      loading={validationLoading}
      anchor="guest-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: (editMode && (!isValid || validationLoading)) || disabled,
        onClick: editMode
          ? handleSubmit(handleReservationGuestEdit)
          : () => setEditMode(true),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <ErrorMessage>{error}</ErrorMessage>
      {editMode ? (
        <div className="flex flex-col gap-y-3">
          <div className="flex items-end justify-between w-full space-x-3">
            <div>
              <p className="mb-1 text-sm font-semibold text-left">
                {t("Booking.Guest.photoLabel")}
              </p>

              <img
                src={reservation?.guest?.photo}
                alt={`${reservation?.guest?.first_name} ${reservation?.guest?.last_name}`}
                className="w-32 h-32 rounded border-1 border-low-contrast/50 cursor-pointer"
                onClick={imgPreview.open}
              />
              <PreviewImage
                imageUrl={reservation?.guest?.photo}
                imageModal={imgPreview}
              />
            </div>
            <div className="flex flex-col flex-1 space-y-3">
              <TextInput
                label={t("Booking.Guest.firstNameLabel")}
                placeholder={t("Booking.Guest.firstNamePlaceholder")}
                value={getValues().firstName}
                disabled={reservation?.platform.id !== PlatformEnum.DIRECT}
                required={requiredFields.firstName}
                register={register("firstName", {
                  required: t("Global.Errors.requiredField", {
                    fieldName: t("Booking.Guest.firstNameLabel"),
                  }).toString(),
                })}
                error={errors.firstName?.message}
              />
              <TextInput
                label={t("Booking.Guest.lastNameLabel")}
                placeholder={t("Booking.Guest.lastNamePlaceholder")}
                value={getValues().lastName}
                disabled={reservation?.platform.id !== PlatformEnum.DIRECT}
                required={requiredFields.lastName}
                register={register("lastName", {
                  required: t("Global.Errors.requiredField", {
                    fieldName: t("Booking.Guest.lastNameLabel"),
                  }).toString(),
                })}
                error={errors.lastName?.message}
              />
            </div>
          </div>

          <div className="flex justify-between w-full mt-3 space-x-3">
            <TextInput
              label={t("Booking.Guest.addressLabel")}
              placeholder={t("Booking.Guest.addressPlaceholder")}
              value={getValues().address}
              required={requiredFields.address}
              register={register("address")}
              error={errors.address?.message}
            />
            <TextInput
              label={t("Booking.Guest.cityLabel")}
              placeholder={t("Booking.Guest.cityPlaceholder")}
              value={getValues().city}
              required={requiredFields.city}
              register={register("city")}
              error={errors.city?.message}
            />
          </div>

          <div className="flex justify-between w-full space-x-3">
            <TextInput
              label={t("Booking.Guest.postalCodeLabel")}
              placeholder={t("Booking.Guest.postalCodePlaceholder")}
              value={getValues().postalCode}
              required={requiredFields.postalCode}
              register={register("postalCode")}
              error={errors.postalCode?.message}
            />
            <TextInput
              label={t("Booking.Guest.countryLabel")}
              placeholder={t("Booking.Guest.countryPlaceholder")}
              value={getValues().country}
              required={requiredFields.country}
              register={register("country")}
              error={errors.country?.message}
              disabled={loading}
            />
          </div>

          <div className="flex justify-between w-full space-x-3">
            <TextInput
              label={t("Booking.Guest.emailLabel")}
              placeholder={t("Booking.Guest.emailPlaceholder")}
              value={getValues().email}
              required={requiredFields.email}
              register={register("email", {
                required: t("Global.Errors.requiredField", {
                  fieldName: t("Booking.Guest.emailLabel"),
                }).toString(),
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("Global.Errors.invalidField", {
                    fieldName: t("Booking.Guest.emailLabel"),
                  }).toString(),
                },
              })}
              error={errors.email?.message}
              disabled={loading}
            />
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: t("Global.Errors.requiredField", {
                  fieldName: t("Booking.Guest.phoneNumberLabel"),
                }).toString(),
                minLength: {
                  value: 10,
                  message: t("Global.Errors.minLengthField", {
                    fieldName: t("Booking.Guest.phoneNumberLabel"),
                    length: 10,
                    unit: t("Global.Units.digits", { count: 10 }),
                  }).toString(),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  label={t("Booking.Guest.phoneNumberLabel")}
                  placeholder={t("Booking.Guest.phoneNumberPlaceholder")}
                  value={value}
                  required={requiredFields.phoneNumber}
                  onChangeText={onChange}
                  error={errors.phoneNumber?.message}
                  disabled={
                    loading || reservation?.platform.id !== PlatformEnum.DIRECT
                  }
                />
              )}
            />
          </div>

          <div className="flex justify-between w-full space-x-3">
            {/* TIMEZONE */}
            {/* <div className="flex-1">
              <Controller
                control={control}
                name="timezone"
                rules={{
                  required: {
                    value: requiredFields.timezone,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t(
                        "Booking.Reservation.reservationStatusLabel"
                      ),
                    }),
                  },
                  onChange: (event) => {
                    setValue("timezone", event.target.value);
                  },
                }}
                render={({ field: { onChange } }) => (
                  <div className="w-64">
                    <InputSelect
                      required={requiredFields.timezone}
                      disabled={validationLoading}
                      label={t("Booking.Reservation.reservationStatusLabel")}
                      selectedValue={"gmt+1"}
                      items={[
                        {
                          label: "GMT +1",
                          value: "gmt+1",
                        },
                      ]}
                      onSelect={onChange}
                    />
                  </div>
                )}
              />
            </div> */}
            {/* SPOKEN LANGUAGES */}
            <div className="flex-1">
              {/* TODO: [GuestCard] Implement multi input select */}
              <Controller
                control={control}
                name="communicationLanguage"
                rules={{
                  required: {
                    value: requiredFields.communicationLanguage,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Booking.Guest.languagesSpokenLabel"),
                    }),
                  },
                  onChange: (event) => {
                    setValue("communicationLanguage", event.target.value);
                  },
                }}
                render={({ field: { onChange } }) => (
                  <InputSelect
                    required={requiredFields.communicationLanguage}
                    disabled={validationLoading}
                    label={t("Booking.Guest.languagesSpokenLabel")}
                    selectedValue={getValues("communicationLanguage")}
                    items={
                      languages
                        ? [
                            {
                              value: "placeholder",
                              label: t(
                                "Booking.Guest.languagesSpokenPlaceholder",
                              ),
                              disabled: true,
                            },
                            ...languages,
                          ]
                        : []
                    }
                    onSelect={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-row items-center justify-between pb-5 space-x-3 border-b-1 border-element-border">
            <div className="flex items-center space-x-4">
              <div className="relative">
                {/* LOGO */}
                {reservation?.platform ? (
                  <div
                    className="absolute z-10"
                    style={{ top: 22, left: 26, width: 16, height: 16 }}
                  >
                    {getPlatformIcon(reservation?.platform!, "small")}
                  </div>
                ) : (
                  <div
                    className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                    style={{ top: 22, left: 26, width: 16, height: 16 }}
                  />
                )}

                {/* PHOTO */}
                {reservation?.guest?.photo ? (
                  <img
                    src={reservation?.guest?.photo}
                    alt="Guest thumbnail"
                    className="w-10 h-10 rounded-full border-1 border-element-border/50  cursor-pointer"
                    onClick={imgPreview.open}
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full border-1 border-element-border/50 bg-slate-200" />
                )}
              </div>

              <div>
                {/* FIRSTNAME & LASTNAME */}
                <>
                  <p className="font-semibold text-high-contrast">
                    {`${reservation?.guest?.first_name} ${reservation?.guest?.last_name}`}
                  </p>

                  {reservation?.guest?.first_name === null &&
                    reservation?.guest?.last_name === null && (
                      <div className="flex items-center space-x-2">
                        <EyeOffIcon width={15} height={15} />
                        <p className="text-low-contrast">
                          {t("Booking.Guest.hiddenInformation")}
                        </p>
                      </div>
                    )}
                </>

                {/* CITY & COUNTRY */}
                <>
                  <p className="font-light text-low-contrast">
                    {reservation?.guest?.city
                      ? `${reservation?.guest?.city}, `
                      : ""}
                    {reservation?.guest?.country
                      ? reservation?.guest?.country
                      : ""}
                  </p>

                  {reservation?.guest?.city === null &&
                    reservation?.guest?.country === null && (
                      <p className="font-light text-low-contrast">
                        {t("Global.notDefined")}
                      </p>
                    )}
                </>
              </div>
            </div>

            {/* RATING */}
            {reservation?.guest.rate !== null ? (
              <Rating
                loading={loading}
                maxStars={5}
                value={reservation?.guest.rate}
              />
            ) : (
              <div className="flex items-center gap-x-1">
                <StarIcon />
                <p className="text-low-contrast">
                  {t("Booking.Guest.ratingNotDefined")}
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-4 py-5">
            <div className="flex flex-row items-center space-x-10">
              {/* EMAIL */}
              <div className="flex-1 space-y-1">
                <p className="font-light text-low-contrast">
                  {t("Booking.Guest.emailLabel")}
                </p>
                <p className="text-base font-semibold text-high-contrast">
                  {guest?.email === null ||
                  guest?.email === "ND"
                    ? t("Global.notDefined")
                    : guest?.email
                  }
                </p>
              </div>

              {/* PHONE NUMBER */}
              <div className="flex-1 space-y-1">
                <p className="font-light text-low-contrast">
                  {t("Booking.Guest.phoneNumberLabel")}
                </p>
                <p className="text-base font-semibold text-high-contrast">
                  {guest?.phone === null ||
                  guest?.phone === "ND"
                    ? t("Global.notDefined")
                    : guest?.phone}
                </p>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-10">
              {/* TIMEZONE */}
              {/* <div className="flex-1 space-y-1">
                <p className="font-light text-low-contrast">
                  {t("Booking.Guest.timezoneLabel")}
                </p>
                {/* TODO: [RESERVATIONS] - Set timezone when if exists
                {/* <p className="font-semibold text-high-contrast">
                  {!reservation?.guest?.locale || reservation?.guest?.locale === "ND"
                    ? t("Global.notDefined")
                    : reservation?.guest?.locale}
                </p> */}
              {/* <ValuePlaceholder Icon={WarningIcon} />
                <p className="font-bold">{t("Global.notDefined")}</p>
              </div> */}

              {/* LANGUAGE */}
              <div className="flex-1 space-y-1">
                <p className="font-light text-low-contrast">
                  {t("Booking.Guest.languagesSpokenLabel")}
                </p>
                <div className="flex items-center space-x-2 capitalize">
                  {guest?.locale
                    ? getLanguageString(guestLanguages)
                    : t("Global.notDefined")}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};
