import React, {useEffect, useState} from "react";
import {DashboardServicesPageProps} from "./DashboardServicesPage.type";
import {Button} from "../../components/Common/Button/Button";
import {useTranslation} from "react-i18next";
import {Separator} from "../../components/Common/Separator/Separator";
import {Title} from "../../components/Common/Title/Title";
import {RightModal} from "../../components/Common/RightModal/RightModal";
import {HistoricalStep} from "../../components/Common/HistoricalStep/HistoricalStep";
import {useNavigate} from "react-router-dom";
import paths from "../../constants/paths";
import moment from "moment";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";

import BookingIcon from "../../assets/icons/booking.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import EyeIcon from "../../assets/icons/eye.svg?react";
import CheckIcon from "../../assets/icons/check-success.svg?react";
import StarFilledIcon from "../../assets/icons/star-filled.svg?react";
import StarHalfIcon from "../../assets/icons/star-half.svg?react";
import ClockIcon from "../../assets/icons/clock.svg?react";
import MessageIcon from "../../assets/icons/message.svg?react";
import SettingsIcon from "../../assets/icons/settings.svg?react";

import UserAvatar from "../../assets/images/user-avatar.svg?react";
import Rental1Thumbnail from "../../assets/images/rental1.webp";
import MaritzImg from "../../assets/images/maritz.png";
import {InfoModal} from "../../components/Modal/InfoModal/InfoModal";

import resBookings from "../../res/bookings";
import resRentals from "../../res/rentals";
import {useGetRentals} from "../../hooks/useGetRentals";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";
import {useCheckAuth} from "../../hooks/useCheckAuth";
import DashboardServicesStats from "../../components/DashboardServices/DashboardServicesStats";
import DashboardServicesTasks from "../../components/DashboardServices/DashboardServicesTasks";
import {DashboardData, TaskItemResponse} from "../../types/GETTypes";
import {get, post} from "../../helpers/APIHelper";
import {updateAssignationType} from "../Dashboard/DashboardPage";
import {DeclineTaskModal} from "../../components/Management/DeclineTaskModal/DeclineTaskModal";
import {AcceptTaskModal} from "../../components/Management/AcceptTaskModal/AcceptTaskModal";
import AddTaskModal from "../../components/Management/AddTask/AddTaskModal";
import {useModal} from "../../hooks/useModal";

export const DashboardServicesPage: React.FC<
  DashboardServicesPageProps
> = () => {
  const {t} = useTranslation();
  const {user} = useCheckAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [refresh, setRefresh] = useState(false);
  const acceptTaskModal = useModal<TaskItemResponse>();
  const declineTaskModal = useModal<TaskItemResponse>();
  const createTaskModal = useModal<{date?: string}>();

  const fetchDashboardData = async () => {
    setIsLoading(true);
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.DASHBOARD}`
    );

    if (response.data.success) {
      setDashboardData(response.data.result);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const updateAssignation = () => {
    if (acceptTaskModal.data || declineTaskModal.data) {
      fetchDashboardData();
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [refresh]);

  useEffect(() => {
  }, [dashboardData]);

  return (
    <>
      <AcceptTaskModal
        task={acceptTaskModal.data}
        isVisible={acceptTaskModal.isVisible}
        onClose={acceptTaskModal.close}
        onSuccess={updateAssignation}
      />

      <DeclineTaskModal
        task={declineTaskModal.data}
        isVisible={declineTaskModal.isVisible}
        onClose={declineTaskModal.close}
        onSuccess={updateAssignation}
      />
      <RightModal
        title={t("Task.Modal.titleBooking")}
        classNames={{
          mainContentParent: "overflow-y-hidden pe-0",
        }}
        isVisible={createTaskModal.isVisible}
        onClose={createTaskModal.close}
      >
        <AddTaskModal
          onClose={createTaskModal.close}
          onSuccess={() => fetchDashboardData()}
          fromDashboard={true}
          date={createTaskModal.data?.date}
        />
      </RightModal>
      <MainLayout
        title={t("Dashboard.title")}
        sidebarActiveItem="dashboard"
        sidebarActiveSubItem="dashboard_services"
      >
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row mt-8 justify-between">
            <div className="flex flex-col">
              <Title>
                {t("Dashboard.welcome")} {user?.first_name}
              </Title>
              <p className="text-low-contrast text-sm">
                {t("Dashboard.summary")}
              </p>
            </div>
          </div>
          <div className="mt-8">
            <DashboardServicesStats
              dashboardData={dashboardData}
              isLoading={isLoading}
              user={user}
            />
          </div>
          <div className="flex justify-between mt-8">
            <DashboardServicesTasks
              isLoading={isLoading}
              dashboardData={dashboardData}
              onAcceptTask={acceptTaskModal.open}
              onDeclineTask={declineTaskModal.open}
              openCreateTaskModal={createTaskModal.open}
              user={user}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
};
